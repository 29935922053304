import React, {PureComponent} from 'react';
import Moment from "moment/moment";
import {Row, Col, FormFeedback, FormGroup, Label} from "reactstrap";

const MONTHS = {
  '01': 'January', '02': 'February', '03': 'March',
  '04': 'April', '05': 'May', '06': 'June',
  '07': 'July', '08': 'August', '09': 'September',
  '10': 'October', '11': 'November', '12': 'December'
};

const AGE_RANGE = {youngest: 18, oldest: 65};
const YOUNGEST_YEAR = () => Moment().year() - AGE_RANGE.youngest - 1;
const OLDEST_YEAR = () => Moment().year() - AGE_RANGE.oldest;


class InputDateOfBirth extends PureComponent {
  static defaultProps = {
    proportion: [4, 8]
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      month: '01',
      year: YOUNGEST_YEAR(),
      day: '01'
    };
  }

  static renderYears() {
    return [...Array(AGE_RANGE.oldest - AGE_RANGE.youngest).keys()].map((i) => {
      const year = OLDEST_YEAR() + i;
      return <option value={year} key={`option-year-${i}`}>{year}</option>;
    }).reverse()
  }

  static renderMonths() {
    const result = [];
    Object.keys(MONTHS).sort().forEach((month) => {
      result.push(
        <option value={month} key={`option-month-${month}`}>{MONTHS[month]}</option>
      )
    });
    return result;
  }

  renderDays() {
    const daysCount = Moment(`${this.state.year} ${this.state.month}`, 'YYYY-MM').daysInMonth();

    return [...Array(daysCount).keys()].map((i) => {
      const string = '00' + (i + 1);
      const day = string.substr(string.length - 2);
      return <option value={day} key={`option-day-${i}`}>{day}</option>;
    })
  }

  onChange(value) {
    const {input} = this.props;

    this.setState({
      ...this.state,
      ...value
    }, () => {
      input.onChange(`${this.state.year}-${this.state.month}-${this.state.day}`)
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {input: {value, onChange}} = this.props;

    if (value && value !== 'null') {
      const [year, month, day] = value.split('-');
      this.setState({year, month, day});
    } else {
      onChange(`${this.state.year}-${this.state.month}-${this.state.day}`)
    }

    if (this.props.item) {
      this.setState({
        ...this.state,
        text: this.props.item.content || ''
      })
    }
  }

  render() {
    const {input, label, meta: {touched, error}, proportion} = this.props;

    return (
      <FormGroup row className="align-items-center">
        {label ? (<Label md={proportion[0]} htmlFor={input.name}
                         className={touched && error ? 'text-danger' : ''}>{label}</Label>) : ''}

        <Col md={label ? proportion[1] : 12}>
          <Row noGutters>
            <Col md={3}>
              <select
                className="form-control"
                value={this.state.day}
                onChange={(e) => this.onChange({day: e.target.value})}
              >
                <option value={''} key={`option-day-none`}>-</option>
                {this.renderDays()}
              </select>
            </Col>

            <Col md={5} style={{padding: '0 3px'}}>
              <select
                className="form-control"
                value={this.state.month}
                onChange={(e) => this.onChange({month: e.target.value})}
              >
                {InputDateOfBirth.renderMonths()}
              </select>
            </Col>

            <Col md={4}>
              <select
                className="form-control"
                value={this.state.year}
                onChange={(e) => this.onChange({year: e.target.value})}
              >
                {InputDateOfBirth.renderYears()}
              </select>
            </Col>
          </Row>

          {touched && error && <FormFeedback>{error}</FormFeedback>}
        </Col>
      </FormGroup>
    )
  }
}

export default InputDateOfBirth;
