import {createAction} from "redux-api-middleware";
import {withAuth} from "../reducers";

export const USERS_CREATE_REQUEST = '@@users/USERS_CREATE_REQUEST';
export const USERS_CREATE_SUCCESS = '@@users/USERS_CREATE_SUCCESS';
export const USERS_CREATE_FAILURE = '@@users/USERS_CREATE_FAILURE';

export const USERS_UPDATE_REQUEST = '@@users/USERS_UPDATE_REQUEST';
export const USERS_UPDATE_SUCCESS = '@@users/USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_FAILURE = '@@users/USERS_UPDATE_FAILURE';

export const USERS_DELETE_REQUEST = '@@users/USERS_DELETE_REQUEST';
export const USERS_DELETE_SUCCESS = '@@users/USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = '@@users/USERS_DELETE_FAILURE';

export const submitUser = (data) => {
  const url = `user/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PATCH' : 'POST';

  return createAction({
    endpoint: url,
    method: method,
    headers: withAuth({'Content-Type': 'application/json'}),
    types: data.id ? [
        USERS_UPDATE_REQUEST,
        USERS_UPDATE_SUCCESS,
        USERS_UPDATE_FAILURE
      ] :
      [
        USERS_CREATE_REQUEST,
        USERS_CREATE_SUCCESS,
        USERS_CREATE_FAILURE
      ],
    body: JSON.stringify(data)
  })
};

export const deleteUser = (data) => {
  const url = `user/${data.id + '/'}`;
  const method = 'DELETE';

  return createAction({
    endpoint: url,
    method: method,
    headers: withAuth({'Content-Type': 'application/json'}),
    types: [
      USERS_DELETE_REQUEST,
      USERS_DELETE_SUCCESS,
      USERS_DELETE_FAILURE
    ]
  })
}