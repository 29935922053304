export const CASE_STATUS_PENDING = 'pending';
export const CASE_STATUS_SHIPPED = 'shipped';
export const CASE_STATUS_DELIVERED = 'delivered';
export const CASE_STATUS_PROCESSING = 'processing';
export const CASE_STATUS_READY = 'ready';
export const CASE_STATUS_REPORT_HOLD = 'report_hold';
export const CASE_STATUS_REPORT_READY = 'report_ready';
export const CASE_STATUS_ACCEPTED = 'case_accepted';
export const CASE_STATUS_GC_SCHEDULED = 'gc_scheduled';
export const CASE_STATUS_SETUP_COMPLETE = 'setup_complete';
export const CASE_STATUS_EMBRYO_SAMPLES_RECEIVED = 'embryo_samples_received';

export const ETHNICITIES_PREVIOUS = {
  1: 'American Indian or Alaska Native',
  2: 'Asian',
  3: 'Black or African American',
  4: 'Native Hawaiian or Other Pacific Islander',
  5: 'White'
};


export const ETHNICITIES = {
  3: 'African American',
  5: 'Caucasian(Non-Hispanic)',
  6: 'Caucasian(Hispanic)',
  7: 'East Asian',
  8: 'South Asian',
  9: 'Other',
};

export const ETHNICITIES_WITH_REVIOUS = {
  1: 'American Indian or Alaska Native',
  2: 'Asian',
  3: 'African American',
  4: 'Native Hawaiian or Other Pacific Islander',
  5: 'Caucasian(Non-Hispanic)',
  6: 'Caucasian(Hispanic)',
  7: 'East Asian',
  8: 'South Asian',
  9: 'Other',
};
