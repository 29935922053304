import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, withRouter} from "react-router-dom";
import {logout} from "actions/auth";

import {BrowserView} from "react-device-detect";


export default withRouter(
  connect(
    state => ({
      user: state.auth,
      selected_provider_id: state.common.selected_provider_id
    }), {
      logout: logout,
    }
  )(
    class extends PureComponent {
      constructor(props) {
        super(props);
        this._pathname = props.location.pathname;
      }

      render() {
        return (
          <div className="top-bar" style={{marginLeft:"220px", width: "calc(100% - 220px)"}}>
            <BrowserView>
              <Container fluid>
                <Row className="align-items-center justify-content-md-center">
                  <Col style={{paddingLeft: '50px', color:'#6e6893'}}>
                    LAB
                  </Col>
                  <Col xs lg="2" className="text-right">
                    <Link to={'/login'} onClick={this.props.logout} className="nav-link">
                      <FontAwesomeIcon icon="sign-out-alt" fixedWidth className="sidebar-icon"/>{' '}Sign out
                      {this.props.user.first_name && <span> ({this.props.user.first_name})</span>}
                    </Link>
                  </Col>
                </Row>
              </Container>
            </BrowserView>
          </div>
        )
      }
    }
  )
)
