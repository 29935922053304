export const ACTION_LOG_DETAIL_LINK = 'action-log/:id/';
export const ACTION_LOG_LIST_LINK = 'action-log/';
export const CASE_DETAIL_LINK = 'case/:id/';
export const CASE_LIST_LINK = 'case/';
export const CASE_LOG_DETAIL_LINK = 'patients/:id/get_log';
export const CASE_LOG_LIST_LINK = 'patients/:id/get_log';
export const CLINIC_LIST_LINK = 'clinic-list/';
export const PERSON_DETAIL_LINK = 'persons/:id/';
export const PERSON_LIST_LINK = 'persons/with_cases';
export const PROVIDER_SAMPLES_LIST_LINK = 'providers/:id/samples';
export const PROVIDERS_SIMPLE_LIST_LINK = 'providers-simple-list/';
export const ROLE_LIST_LINK = 'role/';
export const USER_LIST_LINK = 'user/';



