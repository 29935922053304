import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import classNames from "classnames";
import * as Yup from "yup";


const getToken = () => {
  const data = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
  const token = JSON.parse(data.auth).token;
  return token ? token : null;
};

const getProvider = () => {
  const data = JSON.parse(window.localStorage.getItem('provider'));
  return data ? data.id : null;
};


const Schema = Yup.object().shape({
  description: Yup.string().required("Issue text can't be empty")
});


export default (props) => {
  const [issues, setIssues] = useState([]);
  const [initialValues, setInitialValues] = useState({description: ''});
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const provider = getProvider();

  useEffect(() => {
    if (provider) {
      setLoading(true);
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + `issue/?provider_id=${provider}`,
        headers: {
          "Authorization": `Token ${getToken()}`
        },
      }).then((resp) => {
        setIssues(resp.data)
      }).catch(() => {
        // setError('Error while fetching issues');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [provider]);

  const submitIssue = (data, afterSuccess) => {
    setLoading(true);
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_HOST + `issue/?provider_id=${provider}`,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data
    }).then((resp) => {
      const items = issues.slice();
      items.push(resp.data);
      setIssues(items);
      afterSuccess();
    }).catch(() => {
      // setError('Error while creating issue');
    }).finally(() => {
      setLoading(false);
      setInitialValues({description: ''});
    });
  };

  return (
    <div>
      <button
        className={classNames('nav-link', 'link-button')}
        onClick={(e) => {
          e.preventDefault();
          !provider ? alert('Select clinic first') : setModalOpen(true);
        }}
      >
        <FontAwesomeIcon icon="bug" fixedWidth className="sidebar-icon"/>{' '}Report an Issue{' '}
        {issues.length ? `(${issues.length})` : ''}
      </button>
      {/*<Button*/}
      {/*  color="light"*/}
      {/*  onClick={() => {!provider ? alert('Select clinic first') : setModalOpen(true)}}*/}
      {/*  //disabled={!provider}*/}
      {/*  title={!provider ? 'Select clinic first' : ''}*/}
      {/*>*/}
      {/*  <FontAwesomeIcon icon="bug" color="#aaaaaa"/>{' '}*/}
      {/*  <span>Report Issue</span>{' '}*/}
      {/*  {issues.length ? `(${issues.length})` : ''}*/}
      {/*</Button>*/}

      <Modal
        isOpen={modalOpen}
        modalTransition={{timeout: 700}}
        backdropTransition={{timeout: 1300}}
        toggle={() => setModalOpen(false)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            submitIssue(values, () => {
              resetForm({});
            });
            setSubmitting(false);
          }}
        >
          {
            ({
               values,
               errors,
               touched,
               handleChange,
               handleBlur,
               handleSubmit,
               setFieldTouched,
               /* and other goodies */
             }) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader toggle={() => setModalOpen(false)}>Report Issue</ModalHeader>
                <ModalBody>
                  <div style={{margin: '20px 0 20px 0'}}>
                  {
                    issues.length ? (
                      <div>
                        {issues.map((item, i) => {
                          return (
                            <p className="issue" key={'item-issue-' + i}>
                              {item.description}<br/>
                              <div style={{fontSize: '10px'}}>
                                <span>{moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MMM Do YYYY h:mm a')}</span> |{' '}
                                <span>status: {item.status === 'completed' ? 'completed' : 'pending'}</span>
                              </div>
                            </p>
                          )
                        })}
                      </div>
                    ) : (
                      <div style={{padding: 40}}>
                        No Issues
                      </div>
                    )
                  }
                  </div>

                  <textarea
                    name="description"
                    onBlur={e => {
                      setFieldTouched("description", true)
                      handleBlur(e)
                    }}
                    onChange={handleChange}
                    className={classNames('form-control',
                        {'is-invalid': touched['description'] && errors['description']}
                    )}
                    value={values.description || ''}
                  >

                  </textarea>

                  <p className="text-danger">
                    {errors.description && touched.description && errors.description}
                  </p>

                  <Row style={{marginTop: 20}} className="justify-content-start">
                    <Col md="auto">
                      <Button color="success"
                              type="submit"
                              disabled={!values.description}
                      >
                        Submit
                      </Button>{' '}
                    </Col>
                    <Col md="auto">
                      <Button color="light" onClick={() => setModalOpen(false)}>Cancel</Button>
                    </Col>
                  </Row>

                </ModalBody>

              </form>
            )
          }
        </Formik>

        {
          loading && <div className="modal-overlay"/>
        }
      </Modal>
    </div>
  )
}
