import React from "react";

const PaginateGoto = ({ table, options }) => {
  return (
    <>
      <label style={{paddingRight: '35px', marginTop: '5px'}}>Rows per page:&nbsp;
        <select style={{height: '1.5rem'}}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {options.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </label>
      <div style={{paddingRight: '18px'}}>
        Page:&nbsp;
        <input
          type="number"
          style={{width: '45px', textAlign: 'right'}}
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            if (!e.target.value || Number(e.target.value) < 1)
              e.target.value = "1";
            else if (Number(e.target.value) > table.getPageCount())
              e.target.value = table.getPageCount();

            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);           }
          }
        />
        &nbsp;&nbsp;of&nbsp;{table.getPageCount()}
      </div>
    </>
  );
};

export default PaginateGoto;