import React from 'react';
import {Button, Col, Row} from "reactstrap";


export default (props) => (
  <div className="page-header">
    {
      props.children ? props.children :
        <Row className="align-items-center justify-content-between">
          <Col>
            <h3 className="page-title">{props.title}</h3>
          </Col>

          {
            props.actionButtonTitle &&
            <Col md="auto">
              <Button color="success" onClick={(e) => props.onClick(e)}>
                {props.actionButtonIcon} {props.actionButtonTitle}
              </Button>
            </Col>
          }
        </Row>
    }
  </div>
)
