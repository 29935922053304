import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import KitOrderForm from "components/Kit/Form";
import {kitModalClose, kitOrderModalSetOpen} from "actions/modal";
import {submit} from 'actions/provider_kit';


export default connect(
  state => ({
    isOpen: state.modal.kitModalIsOpen,
    kitId: state.modal.kitId,
    kitData: state.modal.kitData,
  }),
  {
    kitOrderModalSetOpen: kitOrderModalSetOpen,
    kitModalClose: kitModalClose,
    submit: submit
  }
)(
  class extends PureComponent{
    render(){
        return (
        <Modal className="kit-order" isOpen={this.props.isOpen} toggle={() => this.props.kitModalClose()}>
          <ModalHeader toggle={() => this.props.kitModalClose()}>
            { this.props.kitId === 0 && 'Test Run' }
            { this.props.kitId === 1 && 'Saliva' }
            { this.props.kitId === 2 && 'Biopsy' }
            {' '}
            Kit Order</ModalHeader>
          <ModalBody>
            <KitOrderForm
              kitId={this.props.kitId}
              initialValues={this.props.kitData}
              onSubmit={(data) => this.props.submit(data)}
              onCancel={() => this.props.kitModalClose()}
            />
          </ModalBody>
        </Modal>
      )
    }
  }
)
