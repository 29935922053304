import React, {useEffect, useState} from 'react';
import { FormGroup, Input, FormFeedback } from "reactstrap";
import { ErrorMessage } from "formik";
import classNames from "classnames";
import './style/dob.css';


export default (
  {
    field, onChange, // { name, value, onBlur }
    form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }
) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (field.value) {
      setDate(field.value);
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (date) {
      setFieldValue(field.name, date);
      setFieldTouched(field.name, true);
    }
  }, [date, ]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="dob">
      <FormGroup>
        <Input
          type="date"
          name="dob"
          value={date}
          onChange={e => {
              setDate(e.target.value)
              onChange()
          }}
          onBlur={() => setFieldTouched(field.name, true)}
          className={classNames('form-control', {
            'is-invalid': touched.dob && !!errors.dob
          })}
        />
        <FormFeedback>
          <ErrorMessage name="dob" component="span" />
        </FormFeedback>
      </FormGroup>

      {/*<Datetime*/}
        {/*value={Moment(field.value)}*/}
        {/*inputProps={props}*/}
        {/*timeFormat={false}*/}
        {/*utc={true}*/}
        {/*timeConstraints={{minutes: {step: 15}}}*/}
        {/*closeOnSelect={true}*/}
        {/*onChange={*/}
          {/*(val) => {*/}
            {/*setFieldValue(field.name, val ? Moment(val).format('YYYY-MM-DD') : '');*/}
            {/*setFieldTouched(field.name, true);*/}
          {/*}*/}
        {/*}*/}
        {/*onBlur={() => setFieldTouched(field.name, true)}*/}
      {/*/>*/}
    </div>
  )
}
