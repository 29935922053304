import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {CardCVCElement, CardExpiryElement, CardNumberElement} from "react-stripe-elements";
import {injectStripe} from 'react-stripe-elements';
import {Button, Col, Container, FormGroup, Input, Row} from "reactstrap";
import {addCard} from 'actions/card';
import {Alert} from 'utils';
import card_unknown from 'images/card/unknown.svg';
import card_visa from 'images/card/visa.svg';
import card_mastercard from 'images/card/mastercard.svg';
import card_discover from 'images/card/discover.svg';
import card_amex from 'images/card/amex.svg';
import card_diners from 'images/card/diners.svg';
import card_jcb from 'images/card/jcb.svg';
import card_unionpay from 'images/card/union.svg';


class CardForm extends PureComponent {
  constructor(props) {
    super(props);

    this.onElementChange = this.onElementChange.bind(this);
    this.onElementReady = this.onElementReady.bind(this);
    this.renderCardBrandImage = this.renderCardBrandImage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onNameChange = this.onNameChange.bind(this);

    this.state = {
      cardBrandIcon: '',
      elementsReady: 0,
      nameOnCard: '',
      cardNumber: {complete: false, error: undefined, value: undefined, brand: 'unknown'},
      cardExpiry: {complete: false, error: undefined, value: undefined},
      cardCvc: {complete: false, error: undefined, value: undefined},
      formComplete: false,
    };

    this.cardBrandImages = {
      card_unknown, card_visa, card_mastercard,
      card_discover, card_amex, card_diners, card_jcb, card_unionpay
    };

    this.commonCardElementStyles = {
      base: {
        color: '#132c4c',
        fontSize: '16px',
        fontFamily: 'Roboto',
        fontSmoothing: 'antialiased',
        lineHeight: '24px',
        padding: '6px 12px 6px 12px'
      }
    };
  }

  onElementReady() {
    this.setState({
      ...this.state,
      elementsReady: this.state.elementsReady + 1
    }, () => {
      if (this.state.elementsReady === 3) {
        this.props.onReady()
      }
    })
  }

  onElementChange(e) {
    this.setState({
      ...this.state,
      [e.elementType]: e
    }, () => {
      this.checkForm();
    })
  }

  onNameChange(e) {
    const value = e.target.value;
    this.setState({
      ...this.state,
      nameOnCard: value ? value : ''
    }, () => {
      this.checkForm();
    })
  }

  checkForm() {
    const {cardNumber, cardExpiry, cardCvc, nameOnCard} = this.state;
    const cardNumberValid = !cardNumber.error && cardNumber.complete;
    const cardExpiryValid = !cardExpiry.error && cardExpiry.complete;
    const cardCvcValid = !cardCvc.error && cardCvc.complete;
    const nameOnCardValid = nameOnCard && nameOnCard !== '';

    this.setState({
      ...this.state,
      formComplete: cardNumberValid && cardExpiryValid && cardCvcValid && nameOnCardValid
    })
  }

  renderCardBrandImage() {
    return <img
      src={this.cardBrandImages[`card_${this.state.cardNumber.brand}`]}
      alt=""
      className="card-brand"
    />
  }

  submitForm() {
    this.props.stripe.createToken({
      name: this.state.nameOnCard
    }).then(({token}) => {
      this.props.addCard({
        token: token.id,
        name: token.card.name,
        last4: token.card.last4,
        expiry: token.card.exp_month + '/' + token.card.exp_year.toString().slice(-2),
        brand: token.card.brand.toLowerCase(),
        ...this.props.owner
      });
    });
  }

  render() {
    return (
      <div>
        <FormGroup>
          <Row className="align-items-center">
            <Col md={4}>
              Name on Card
            </Col>
            <Col md={7}>
              <Input
                type="text"
                value={this.state.nameOnCard}
                onChange={this.onNameChange}
                placeholder="Jane Doe"
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row className="align-items-center">
            <Col md={4}>
              Card Number
            </Col>
            <Col md={7}>
              <CardNumberElement
                style={this.commonCardElementStyles}
                className="form-control"
                onReady={this.onElementReady}
                onChange={this.onElementChange}
              />
              {this.renderCardBrandImage()}
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row className="align-items-center">
            <Col md={4}>
              Expiry Date
            </Col>
            <Col md={4}>
              <CardExpiryElement
                style={this.commonCardElementStyles}
                className="form-control"
                onReady={this.onElementReady}
                onChange={this.onElementChange}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row className="align-items-center">
            <Col md={4}>
              Security Code
            </Col>
            <Col md={4}>
              <CardCVCElement
                style={this.commonCardElementStyles}
                onReady={this.onElementReady}
                onChange={this.onElementChange}
                classes={{base: 'form-control'}}
              />
            </Col>
          </Row>
        </FormGroup>

        {
          this.props.cardAddError ? (
            <Alert>
              {this.props.cardAddError}
            </Alert>
          ) : ''
        }

        <div className="btn-row">
          <Container fluid>
            <Row className="justify-content-between">
              <Col md="auto">
                <Button color="light" onClick={this.props.onCancel}>Cancel</Button>
              </Col>
              <Col md="auto">
                <Button
                  color={this.state.formComplete ? 'success' : 'dark'}
                  disabled={!this.state.formComplete}
                  onClick={this.submitForm}
                  outline
                >
                  {/*{this.state.formComplete ? 'Submit' : 'Submit: Fill All The Fields'}*/}
                  Add This Card
                </Button>{' '}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

CardForm = connect(
  state => ({
    cardAddError: state.card.cardAddError
  }),
  {
    addCard: addCard
  }
)(CardForm);

export default injectStripe(CardForm);