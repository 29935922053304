import React from 'react';
import {ScrollToTopOnMount} from "utils";


export default (props) => (
  <div className="page">
    <ScrollToTopOnMount/>
    {props.children}
  </div>
)
