import React from 'react';
import {Link} from "components/RBAC";


export default (props) =>
  <td
    className={props.className + ' clickable-cell'}
    style={{
      width: props.width ? props.width : 'auto'
    }}
  >
    <Link
      to={props.to}
      toParams={props.toParams || null}
    >
      {props.children}
    </Link>
  </td>