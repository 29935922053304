import {isRSAA, RSAA} from "redux-api-middleware";
import URI from "urijs";
import {DISTRIBUTOR_ALLOWED_ROLES} from 'components/RBAC'


/**
 * A Redux middleware that adds parameters to the API calls needed for RBAC.
 *
 * @type {function}
 * @returns {ReduxMiddleware}
 * @access public
 */

export default store => next => action => {
  //const selected_provider_id = store.getState().common.selected_provider_id;
  const role = store.getState().auth.role;
  const tempProvider = window.localStorage.getItem('temp-provider');
  const provider = !!tempProvider && window.location.pathname === JSON.parse(tempProvider).location
    ? tempProvider
    : window.localStorage.getItem('provider');

  if (isRSAA(action)) {
    const new_url = new URI(
      process.env.REACT_APP_API_HOST + action[RSAA].endpoint
    ).segment('/').normalizeSearch();

    if (
      provider &&
      DISTRIBUTOR_ALLOWED_ROLES.includes(role) &&
      action[RSAA].endpoint !== 'providers/' &&
      action[RSAA].endpoint !== 'providers-simple-list'
    ) {
      new_url.addSearch({provider_id: JSON.parse(provider).id}).normalizeSearch();
    }

    action[RSAA].endpoint = new_url.toString();
  }

  return next(action);
}
