import React, {useState} from 'react';
import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import axios from "axios";
import {getToken} from "auth/token";


export default (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newUsername, setNewUsername] = useState('');

  const {id} = props.provider;

  const submit = () => {
    setLoading(true);
    setError(null);

    axios({
      method: 'PATCH',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `providers/${id}/update_username/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: {username: newUsername}
    })
      .then((resp) => {
        if (resp.data && resp.data.username) {
          setIsOpen(false);
          props.onSuccess(resp.data.username)
        } else {
          setError('Unexpected Error #2')
        }
      })
      .catch((e) => {
        console.log(e.response)
        if (e.response && e.response.data) {
          if (e.response.data.status === 'already_exists') {
            setError('Username already exists. Choose a different one')
          }

          if (e.response.data.status === 'wrong_length') {
            setError('Wrong length. Must be between 5 and 30.')
          }
        } else {
          setError('Unexpected error')
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <button className="link-button" onClick={(e) => {
        e.preventDefault();
        setIsOpen(true)
      }}>
        Update
      </button>

      <Dialog
        icon="user"
        onClose={() => setIsOpen(false)}
        title="Update Username"
        isOpen={isOpen}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <div className={Classes.DIALOG_BODY}>

            <FormGroup
              helperText={error ? error : null}
              label="New Username"
              labelFor="username"
              intent={error ? Intent.DANGER : null}
            >
              <InputGroup
                id="username"
                placeholder="New Username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                intent={error ? Intent.DANGER : null}
              />
            </FormGroup>

          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                type="submit"
                intent="primary"
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}
