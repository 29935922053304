import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getApplication} from "actions/patient";
import Application from './Application';
import Error from './Error';
import Submitted from "./Submitted";
import './style/main.css';


export default connect(
  state => ({
    patientApplication: state.patientApplication
  }),
  {
    getApplication: getApplication
  }
)(
  class extends PureComponent {
    componentWillMount() {
      const {id} = this.props.route.match.params;
      this.props.getApplication(id);
    }

    render() {
      const {patient, error, submitted} = this.props.patientApplication;

      return patient || error || submitted ? (
        <div>
          {
            submitted ? (
              <div>
                <Submitted/>
              </div>
            ) : (
              <div>
                {error ? <Error message={error}/> : (
                  <div>
                    {patient ? <Application/> : <Error message={'Data not found'}/>}
                  </div>
                )}
              </div>
            )
          }
        </div>
      ) : ''
    }
  }
);
