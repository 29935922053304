import * as modal from 'actions/modal';
import * as sample_batch from 'actions/sample_batch';
import * as sample from 'actions/sample';
import * as patient from 'actions/patient';
import * as clinician from 'actions/clinician';
import * as nurse from 'actions/nurse';
import * as sampleCase from 'actions/case';
import * as card from "actions/card";
import * as provider_kit from "actions/provider_kit";

const initialState = {
  sampleModalIsOpen: false,
  shippingModalIsOpen: false,
  kitModalIsOpen: false,
  batchModalIsOpen: false,
  clinicianModalIsOpen: false,
  nurseModalIsOpen: false,
  patientModalIsOpen: false,
  chromosomeModalIsOpen: false,
  caseModalIsOpen: false,
  cardModalIsOpen: false,
  kitOrderModalIsOpen: false,
  cardOwner: null,
  patientPaymentModalIsOpen: false,
  caseID: null,
  batch: null,
  patient: null,
  patientPayment: null,
  clinician: null,
  clinicianIndex: null,
  nurse: null,
  nurseIndex: null,
  sample_id: null,
  chromosome: {},
  case: null,
  sample: null,
  patientID: null,
  shippingData: null,
  data: null,
  openModals: [],
  kitId: null,
  kitData: null
};


export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modal.SAMPLE_MODAL_OPEN:
      return {
        ...state,
        sampleModalIsOpen: true,
        caseID: action.caseID,
        sample: action.sample,
        patientID: action.patientID
      };

    case modal.SAMPLE_MODAL_CLOSE:
    case sample.SAMPLE_CREATE_SUCCESS:
    case sample.SAMPLE_UPDATE_SUCCESS:
      return {
        ...state,
        sampleModalIsOpen: false,
        caseID: null,
        sample: null,
        patientID: null,
      };

    case modal.SAMPLE_RESULTS_MODAL_OPEN:
      return {
        ...state,
        openModals: [...['SAMPLE_RESULTS_MODAL']],
        data: action.data
      };

    case modal.SAMPLE_RESULTS_MODAL_CLOSE:
      const newOpenModals = state.openModals.slice();
      const index = newOpenModals.indexOf('SAMPLE_RESULTS_MODAL');
      if (index !== -1) newOpenModals.splice(index, 1);

      return {
        ...state,
        openModals: newOpenModals,
        data: null
      };

    case modal.KIT_MODAL_OPEN:
      return {
        ...state,
        kitModalIsOpen: true,
        kitData: action.data,
        kitId: action.kit_id
      };

    case modal.KIT_MODAL_CLOSE:
    case provider_kit.PROVIDER_KIT_CREATE_SUCCESS:
    case provider_kit.PROVIDER_KIT_UPDATE_SUCCESS:
      return {
        ...state,
        kitModalIsOpen: false
      };

    case modal.BATCH_MODAL_OPEN:
      return {
        ...state,
        batchModalIsOpen: true,
        batch: action.data
      };

    case modal.BATCH_MODAL_CLOSE:
    case sample_batch.SAMPLE_BATCH_CREATE_SUCCESS:
    case sample_batch.SAMPLE_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        batchModalIsOpen: false,
        batch: null
      };

    case modal.CLINICIAN_MODAL_OPEN:
      return {
        ...state,
        clinicianModalIsOpen: true,
        clinician: action.data,
        clinicianIndex: action.index
      };

    case modal.CLINICIAN_MODAL_CLOSE:
    case clinician.CLINICIAN_CREATE_SUCCESS:
    case clinician.CLINICIAN_UPDATE_SUCCESS:
    case clinician.CLINICIAN_DELETE_SUCCESS:
      return {
        ...state,
        clinicianModalIsOpen: false,
        clinician: null,
        clinicianIndex: null
      };

    case modal.NURSE_MODAL_OPEN:
      return {
        ...state,
        nurseModalIsOpen: true,
        nurse: action.data,
        nurseIndex: action.index
      };

    case modal.NURSE_MODAL_CLOSE:
    case nurse.NURSE_CREATE_SUCCESS:
    case nurse.NURSE_UPDATE_SUCCESS:
    case nurse.NURSE_DELETE_SUCCESS:
      return {
        ...state,
        nurseModalIsOpen: false,
        nurse: null,
        nurseIndex: null
      };

    case modal.PATIENT_MODAL_OPEN:
      return {
        ...state,
        patientModalIsOpen: true,
        patient: action.data
      };

    case modal.PATIENT_MODAL_CLOSE:
    case patient.PATIENT_CREATE_SUCCESS:
    case patient.PATIENT_UPDATE_SUCCESS:
      return {
        ...state,
        patientModalIsOpen: false,
        patient: null
      };

    case modal.CASE_MODAL_OPEN:
      return {
        ...state,
        caseModalIsOpen: true,
        case: action.data
      };

    case modal.CASE_MODAL_CLOSE:
    case sampleCase.CASE_CREATE_SUCCESS:
    case sampleCase.CASE_UPDATE_SUCCESS:
      return {
        ...state,
        caseModalIsOpen: false,
        case: null
      };

    case modal.CARD_MODAL_OPEN:
      return {
        ...state,
        cardModalIsOpen: true,
        cardOwner: action.data
      };

    case modal.CARD_MODAL_CLOSE:
    case card.CARD_PATIENT_ADD_SUCCESS:
    case card.CARD_PROVIDER_ADD_SUCCESS:
      return {
        ...state,
        cardModalIsOpen: false,
        cardOwner: null
      };

    case modal.PATIENT_PAYMENT_MODAL_OPEN:
      return {
        ...state,
        patientPaymentModalIsOpen: true,
        patientPayment: action.data
      };

    case modal.PATIENT_PAYMENT_MODAL_CLOSE:
      return {
        ...state,
        patientPaymentModalIsOpen: false,
        patientPayment: null
      };

    case modal.CHROMOSOME_MODAL_OPEN:
      return {
        ...state,
        chromosomeModalIsOpen: true,
        chromosome: action.data
      };

    case modal.CHROMOSOME_MODAL_CLOSE:
      return {
        ...state,
        chromosomeModalIsOpen: false,
        chromosome: {}
      };

    case modal.SHIPPING_MODAL_OPEN:
      return {
        ...state,
        shippingModalIsOpen: true,
        shippingData: action.data
      };

    case modal.SHIPPING_MODAL_CLOSE:
    case sampleCase.UPDATE_SHIPPING_SUCCESS:
      return {
        ...state,
        shippingModalIsOpen: false,
        shippingData: null
      };

    case modal.KIT_ORDER_MODAL_OPEN:
      return {
        ...state,
        kitOrderModalIsOpen: true
      };

    case modal.KIT_ORDER_MODAL_CLOSE:
      return {
        ...state,
        kitOrderModalIsOpen: false
      };

    default:
      return state;
  }
};
