import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default class extends Component {
  constructor(props){
    super(props);
    this.removeItem = this.removeItem.bind(this);
    this.state = {
      opacity: 0,
      top: 0
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      opacity: 1
    });

    this.timeout = setTimeout(() => {
      this.setState({
        ...this.state,
        opacity: 0
      }, () => {
        this.removeItem();
      });
    },8000);
  }

  removeItem(){
    this.props.remove();
    clearTimeout(this.timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render(){
    return (
      <div
        className={'toast-popup ' + this.props.type}
        style={{
          opacity: this.state.opacity,
          top: this.state.top
        }}
      >

        <div className="toast-popup-body">
          {this.props.children}
        </div>

        <div className="toast-popup-close" onClick={() => this.removeItem()}>
          <FontAwesomeIcon icon="times"/>
        </div>
      </div>
    )
  }
}