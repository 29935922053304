import {useState} from 'react';
import axios from "axios";
import {getProviderID, getToken} from "auth/token";
import URI from 'urijs';


export default (endpoint) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const callAPI = (url, method, params, payload) => {
    let uri = new URI(url);
    uri.setSearch({...params, provider: getProviderID()});
    uri = uri.normalizeSearch();

    setLoading(true);
    setError(null);

    return axios({
      url: uri.toString(),
      method: method,
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: payload
    }).finally(() => {
      setLoading(false);
    })
  };


  const list = (params = {}) => {
    callAPI(endpoint, 'get', params)
      .then(response => setData({
        ...response.data,
        pages: Math.ceil(response.data.count / response.data.page_size)
      }))
      .catch(error => setError(error.response));
  };

  const retrieve = (id, params = {}) => {
    callAPI(endpoint + '/' + id, 'get', params)
      .then(response => setData(response.data))
      .catch(error => setError(error.response));
  }

  const create = (params = {}, load = {}) => {

  }

  const update = (id, params = {}, load = {}) => {

  }

  const remove = (id, params = {}) => {

  }

  return [
    {data, error, loading},
    {
      list,
      retrieve,
      create,
      update,
      remove,
    }
  ]
}
