//import socket from '../socket';

export const WSS = '@@wsclient/WSS';
export const WSCLIENT_CONNECTING = '@@wsclient/CONNECTING';
export const WSCLIENT_CONNECTED = '@@wsclient/CONNECTED';
export const WSCLIENT_SEND_MESSAGE = '@@wsclient/SEND_MESSAGE';

export const connectToWSS = () => {
  //const connection = openSocket(process.env.REACT_APP_WSS_HOST);

  // connection.on('connect', () => {
  //   return {
  //     type: WSCLIENT_CONNECTED,
  //     connection
  //   }
  // });

  return ({
    [WSS]: {
      types: [WSCLIENT_CONNECTING, WSCLIENT_CONNECTED]
    }
  })
};

export const sendMessage = (m) => {
  //socket.emit('hello', m);
  return ({
    type: WSCLIENT_SEND_MESSAGE
  })
};
