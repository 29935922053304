import React from 'react';
import './style/main.css';


export default (props) => {
  const children = React.Children.map(props.children, (child, index) => {
    return React.cloneElement(child, {index, to: props.to, toParams: props.toParams});
  });

  return (
    <tr
      className={props.className + ' clickable-row'}
      onClick={props.onClick}
    >
      {children}
    </tr>
  )
}