import React from 'react';
import Moment from "moment/moment";


export default (props) => {
  const {items} = props;

  return (
    <div className="card-table">
      <table>
        <thead>
        <tr>
          <th>{props.m2 ? 'Sample Type': 'Person'}</th>
          <th>Label</th>
          <th>Tube Label</th>
          <th>Kit Sent</th>
          <th>Collected</th>
          <th>Received</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {
          items.map((item, i) => (
            <tr key={'ready-sample-' + i}>
              {
                props.m2 ?
                    <td>{item.sample_type ? item.sample_type : 'Saliva'}</td>
                      :
                        <td>
                          {item.person_type === 'patient' && <div>
                            {props.patient && <div><strong>{props.patient.first_name} {props.patient.last_name}</strong></div>}
                            Patient
                          </div>}

                          {item.person_type === 'partner' && <div>
                            {props.partner && <div><strong>{props.partner.first_name} {props.partner.last_name}</strong></div>}
                            Partner
                          </div>}
                          {
                            ((item.person_type === 'other' || item.person_type === 'donor' || item.person_type === null) && item.person) && (
                              <div>
                                <strong>{item.person.first_name}{' '}{item.person.last_name}</strong>

                                {
                                  item.person.dob && (
                                    <div>
                                      Age: {Moment().diff(item.person.dob, 'years')}{' '}
                                      ({Moment(item.person.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')})
                                    </div>
                                  )
                                }

                                {
                                  item.person.related_to &&
                                  <div><span className="text-capitalize">{item.person.related_as}</span> To: {item.person.related_to}</div>
                                }
                              </div>
                            )
                          }
                        </td>
              }
              <td>{item.label}</td>
              <td>{item.tube_label}</td>

              <td>
                {item.sent_at && Moment(item.sent_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
              </td>
              <td>
                {item.collection_date && Moment(item.collection_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
              </td>
              <td>
                {item.received_at && Moment(item.received_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
              </td>
              <td>-</td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  )
}
