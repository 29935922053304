import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from "classnames";
import {AVAILABLE_PERMISSIONS} from "../../constants/permissions";


export default (props) => {
  const {initialValues} = props;

  return (
    <Formik
      initialValues={{
        id: initialValues.id,
        role_name: initialValues.role_name,
        role_description: initialValues.role_description,
        is_super_user: initialValues.is_super_user || false,
        manage_users: initialValues.manage_users || false,
        manage_roles: initialValues.manage_roles || false
      }}
      enableReinitialize={true}
      onSubmit={
        (values, {setSubmitting}) => {
          props.onSubmit(values);
          setSubmitting(false)
        }
      }
    >
      {
        ({errors, touched, values, isSubmitting, handleChange}) => {
          return (
            <Form autoComplete="off">
              <FormGroup row>
                <Label for="role_name" sm={4}>Role Name</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="role_name"
                    className={classNames('form-control', {
                      'is-invalid': touched.role_name && !!errors.role_name
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="role_name" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="role_description" sm={4}>Role Description</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="role_description"
                    className={classNames('form-control', {
                      'is-invalid': touched.role_description && !!errors.role_description
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="role_description" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={4}>Permissions</Label>
                {Object.keys(AVAILABLE_PERMISSIONS).map(item => (
                  <FormGroup check>
                    <Label check>
                      <Field
                        name={item}
                        render={({field, form: {isSubmitting}}) => (
                          <input
                            {...field}
                            disabled={isSubmitting}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        )}
                      />
                      {' ' + AVAILABLE_PERMISSIONS[item]}
                    </Label>
                  </FormGroup>)
                )}
              </FormGroup>

              <Row style={{marginTop: 20}} className="justify-content-end">
                {values.id &&
                  <Col md="auto">
                    <Button color="danger" onClick={() => props.onDelete(values.id)}>Delete</Button>
                  </Col>
                }
                <Col md="auto">
                  <Button color="light" onClick={() => props.onCancel()}>Cancel</Button>
                </Col>
                <Col md="auto">
                  <Button type="submit" color="success">
                    {
                      values.id ? 'Save Changes' : 'Create Role'
                    }
                  </Button>
                </Col>
              </Row>
            </Form>
          )
        }
      }
    </Formik>
  )
}
