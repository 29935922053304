import React from 'react';
import './style/main.css';


export default (props) => {
  return (
    <div className="card-table">
      <table>
        {
          props.columns && (
            <thead>
            <tr>
              {props.columns.map((column, i) => <th key={props.id + '-' + i}>{column}</th>)}
            </tr>
            </thead>
          )
        }

        <tbody>
        {props.children}
        </tbody>
      </table>
    </div>
  )
}
