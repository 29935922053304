import React, { useEffect, useState } from 'react';
import axios from "axios";
import { call_delete_token } from "./utils/delete_token_call";


export default ({ store, ...props }) => {
  const [loading, setLoading] = useState(false);
  
  function logoutIfTokenExpire() {
    const token_expiration_time = window.localStorage.getItem('token_expiration_time');
    if (token_expiration_time) {
      const token_expire_date = new Date(token_expiration_time);
      if (token_expire_date < new Date()) {
        const token = store.getState().auth.token;
        const maxRetries = 3;
        let retryCount = 0;
        let deletionSuccessful = false;
  
        while (!deletionSuccessful && retryCount < maxRetries) {
          try {
            call_delete_token(token);
            deletionSuccessful = true;
          } catch (error) {
            console.error(`Failed to delete token: ${error}`);
  
            retryCount++;
  
            if (retryCount === maxRetries) {
              console.error(`Reached maximum number of deletion retries: ${maxRetries}`);
              break;
            }
          }
        }
  
        if (!deletionSuccessful) {
          console.error(`Failed to delete token after ${maxRetries} retries`);
        }
      }
    } else {
      window.location.href = '/login';
    }
  }

  const inactivityTime = () => {
    window.onload = resetTime;
    window.onclick = resetTime;
    window.onkeypress = resetTime;
    window.ontouchstart = resetTime;
    window.onmousemove = resetTime;
    window.addEventListener('scroll', resetTime, true);

    function resetTime() {
      const newValue = new Date(new Date().getTime() + 1000 * 600).toISOString(); // 10 minutes
      window.localStorage.setItem('token_expiration_time', newValue);
      const event = new StorageEvent('storage', {
        key: 'token_expiration_time',
        newValue
      });
      window.dispatchEvent(event);
    }
  };


  useEffect(() => {
    const auth = store.getState().auth;
    let time;

    const eventListener = (e) => {
      if (e.key === 'token_expiration_time') {
        const interval = new Date(e.newValue).getTime() - new Date().getTime();
        clearTimeout(time);
        time = setTimeout(logoutIfTokenExpire, interval);
      }
    }

    window.addEventListener("storage", eventListener);

    if (auth.token) {
      inactivityTime();
    }   

    const current_timestamp = Math.floor(Date.now() / 1000);
    const current_clinic_list = window.localStorage.getItem('clinic_list');
    const current_clinic_list_expire = window.localStorage.getItem('clinic_list_expire');
    let expired = false;

    //console.log(current_timestamp, parseInt(current_clinic_list_expire))

    if (current_clinic_list && current_clinic_list_expire && parseInt(current_clinic_list_expire) <= current_timestamp) {
      expired = true;
    }

    if (!current_clinic_list || !current_clinic_list_expire) {
      expired = true;
    }

    if (auth) {
      const role = auth.role;
      const token = auth.token;

      if ((role === 'super' || role === 'super-readonly' || role === 'distributor') && token && expired) {
        setLoading(true);

        axios({
          method: 'GET',
          responseType: 'json',
          baseURL: process.env.REACT_APP_API_HOST,
          url: 'provider-list/',
          headers: {
            "Authorization": `Token ${token}`
          }
        })
          .then((resp) => {
            const clinics = resp.data;
            const timestamp = current_timestamp + (60 * 10);
            window.localStorage.setItem('clinic_list', JSON.stringify(clinics));
            window.localStorage.setItem('clinic_list_expire', timestamp.toString());
            // setReady(true);
          })
          .catch((e) => {
            // setError('Error. Check the connection')
          })
          .finally(() => {
            setLoading(false);
          });
      }

      if (role === 'provider') {
        // setReady(true);
      }

      //checkTokenTimer();
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div style={{ position: 'absolute', top: '50%', textAlign: 'center', left: 0, right: 0 }}>
        <div style={{ fontSize: 24 }}>
          Loading...
        </div>
      </div>
    )
  }

  return (
    <div>
      {props.children}
    </div>
  )
}
