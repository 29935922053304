import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalHeader} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import Form from './CaseForm';
import {submitPatient} from "actions/patient";
import {withRouter} from 'react-router-dom';
import {getClinicianList} from "actions/clinician";
import {getNurseList} from "actions/nurse";

export default connect(
    null,
  {
    submitPatient: submitPatient,
    getClinicianList: getClinicianList,
    getNurseList: getNurseList
  }
)(
  withRouter((props) => {
    const [data, setData] = useState({});
    const [clinicianList, setClinicianList] = useState(null);
    const [nurseList, setNurseList] = useState(null);
    const [responseErrorMessages, setResponseErrorMessages] = useState({});

    useEffect(() => {
      const cleaned_data = {};
      if (props.data) {
        Object.keys(props.data).forEach((key) => {
          cleaned_data[key] = props.data[key] === null ? undefined : props.data[key]
        });
        setData(cleaned_data);
        //setData(props.data);
      }

    }, [props.data]);

    useEffect(() => {
      props.getClinicianList().then((resp) => {
        if (resp.type === '@@clinician/CLINICIAN_LIST_SUCCESS') {
          setClinicianList(resp.payload);
        }
      })
      props.getNurseList().then((resp) => {
        if (resp.type === '@@nurse/NURSE_LIST_SUCCESS') {
          setNurseList(resp.payload);
        }
      })
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const submit = async (values) => {
      return await props.submitPatient(values);
    };

    const handleResponse = (resp) => {
      if (resp.type === '@@patient/PATIENT_UPDATE_FAILURE') {
        setResponseErrorMessages(resp.payload.response)
      }
      if (resp.type === '@@patient/PATIENT_UPDATE_SUCCESS') {
        setResponseErrorMessages({})
        props.history.push(`/patients/${resp.payload.id}?${localStorage.getItem('temp-provider') ? 'temp-provider' : 'provider'}=${resp.payload.provider}`)
      }
    };

    return (
      <Modal isOpen={props.isOpen} toggle={props.close}>
        <ModalHeader toggle={props.close}>
          Case
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(values) => submit(values).then(handleResponse)}
            onCancel={props.close}
            initialValues={data}
            responseErrors={responseErrorMessages}
            clinicianList={clinicianList}
            nurseList={nurseList}
          />
        </ModalBody>
      </Modal>
    )
  })
)
