import React from 'react';
import {string} from 'prop-types';
import {ETHNICITIES_WITH_REVIOUS} from "constants/case";
//import {Sex} from 'components/utilities';
import Moment from "moment-timezone";
import FDH from "components/Patient/Detail/FDH";
import {Col, Row} from "reactstrap";
import AddressFormat from "components/Address/Format";
import TimeAgo from "utils/TimeAgo";

PatientInfoTable.propTypes = {
  fullName: string,
  dob: string,
  sex: string,
  ethnicity: string,
  fdh: string
};

function PatientInfoTable(props) {
  const {fullName, dob, sex, ethnicity, fdh, email, phone, address, address_same, list_cases, case_id} = props;

  return (
    <div>
      <Row>
        <Col md={6}>
          <Row>
            <Col md={4}><strong>Full Name</strong></Col>
            <Col data-dd-action-name="patient_name" md={8}>{fullName === 'Blinded Blinded' ? 'Blinded' : fullName}</Col>
          </Row>
          <Row>
            <Col md={4}><strong>Date&nbsp;of&nbsp;Birth</strong></Col>
            <Col data-dd-action-name="patient_dob" md={8}>{dob ?
              <span>{Moment(dob).format('MM/DD/YYYY')} (Age: {Moment().diff(dob, 'years')})</span> : '-'}</Col>
          </Row>
          <Row>
            <Col md={4}><strong>Ethnicity</strong></Col>
            <Col data-dd-action-name="patient_ethnicity" md={8}>{ETHNICITIES_WITH_REVIOUS[ethnicity] || '-'}</Col>
          </Row>
          <Row>
            <Col md={4}><strong>Sex</strong></Col>
            {/*<Col md={8}><Sex sex={sex} style={{display: 'inline-block', width: 18}}/></Col>*/}
            <Col data-dd-action-name="patient_sex" md={8}>{sex}</Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={4}><strong>Email</strong></Col>
            <Col data-dd-action-name="patient_email" md={8}>{email ? email : '-'}</Col>
          </Row>
          <Row>
            <Col md={4}><strong>Phone</strong></Col>
            <Col data-dd-action-name="patient_phone" md={8}>{phone ? phone : '-'}</Col>
          </Row>
          <Row>
            <Col md={4}><strong>Address</strong></Col>
            <Col data-dd-action-name="patient_address" md={8}>
              {
                props.partner && (
                  <div>
                    {
                      address_same ? (
                        <span>Same as Patient's</span>
                      ) : (
                        <div>
                          {address ? <AddressFormat value={address}/> : ''}
                        </div>
                      )
                    }
                  </div>
                )
              }

              {(props.patient && address) ? <AddressFormat value={address}/> : ''}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={2}><strong>Other Cases</strong></Col>
        <Col md={8}>
          {
            list_cases && list_cases.filter((item) => item.id !== case_id).map((item) => (
              <div key={item.id}>
                <a
                  href={window.localStorage.getItem('temp-provider') ? `/patients/${item.id}${window.location.search}` : `/patients/${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <span>
                  <strong>{item.label ? item.label : '(No Label)'}</strong> - <TimeAgo value={item.created_at} rawDate={true} /> ({item.type})
                </span>
                </a>
              </div>
            ))
          }
        </Col>
      </Row>
      <Row style={{marginTop: 20}}>
        <Col>
          <p><strong>Family Disease History</strong></p>
          {fdh ? <FDH value={fdh} prefix={dob}/> : <span className="text-muted">No information</span>}
          {/*<DiseaseHistoryModal*/}
          {/*initialData={fdh}*/}
          {/*onChange={(data) => {*/}
          {/*props.onFDHChange(JSON.stringify(data))*/}
          {/*}}*/}
          {/*/>*/}
        </Col>
      </Row>
    </div>
  )
}

export default PatientInfoTable;
