import React, {useState} from 'react';
import {HTMLSelect} from "@blueprintjs/core";
import {getToken} from "auth/token";
import axios from 'axios';


export default (props) => {
  const {sample, onUpdateSuccess} = props;

  const [currentValue, setCurrentValue] = useState(sample.status);
  // const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateStatus = (id, value) => {
    setLoading(true);
    //onLoading();

    axios({
      method: 'PATCH',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `saliva-samples/${id}/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: {
        status: value
      }
    })
      .then((resp) => {
      // all good. do nothing
        onUpdateSuccess();
        setCurrentValue(value)
      })
      .catch((e) => {
        // setError('Error saving data')
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <HTMLSelect
        onChange={(e) => {
          updateStatus(sample.id, e.currentTarget.value);
        }}
        value={currentValue}
        disabled={loading}
      >
        <option value={''} selected>Choose...</option>
        <option value="nm1">N/M1- Heterozygous Carrier</option>
        <option value="nm1.5">N/M1.5- Heterozygous Carrier</option>
        <option value="nm2">N/M2- Heterozygous Carrier</option>
        <option value="m1m2">M1/M2- Heterozygous Carrier</option>
        <option value="m1m1">M1/M1- Homozygous Carrier</option>
        <option value="m1.5m2">M1.5/M2- Homozygous Carrier</option>
        <option value="m2m2">M2/M2- Homozygous Carrier</option>
        <option value="nn">N/N- Homozygous Normal</option>
        <option value="inconclusive">Inconclusive</option>
        <option value="no_result">No Result</option>
      </HTMLSelect>
    </div>
  );
}
