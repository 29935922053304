import React, {PureComponent} from 'react';
import Moment from "moment-timezone";
import momentDurationFormatSetup from 'moment-duration-format';

class TimeAgo extends PureComponent {
  // componentDidMount() {
  //   this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  render() {
    momentDurationFormatSetup(Moment);
    const raw_date = this.props.rawDate;
    const end = Moment.utc(this.props.value, 'YYYY-MM-DD hh:mm:ss');
    const duration = Moment.duration(end.diff(Moment()));

    const months = Math.round(Math.abs(duration.asMonths()));
    const days = Math.round(Math.abs(duration.asDays()));
    const hours = Math.round(Math.abs(duration.asHours()));
    const minutes = Math.round(Math.abs(duration.asMinutes()));

    // let timeAgo = end.local().format('MMM DD YYYY');
    let timeAgo = end.local().format('MM/DD/YYYY');

    if (!!raw_date) {
      return <span>{timeAgo}</span>;
    }

    if (months < 1) {
      if (hours < 24) {
        if (minutes < 60 && minutes > 2) {
          timeAgo = `${minutes} min ago`
        } else {
          if (minutes <= 2) {
            timeAgo = 'just now';
          } else {
            timeAgo = end.format('hh:mm a');
          }
        }
      } else {
        timeAgo = `${days} days ago`;
      }
    }

    return (
      <span>{timeAgo}</span>
    )
  }
}

export default TimeAgo;
