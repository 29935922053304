export const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const DISEASES = [
  "Type 1 Diabetes",
  "Type 2 Diabetes",
  "Coronary Artery Disease",
  "Heart Attack",
  "Atrial Fibrillation",
  "Inflammatory Bowel Disease",
  "Asthma",
  "Testicular Cancer",
  "Breast Cancer",
  "Prostate Cancer",
  "Malignant Melanoma",
  "Basal Cell Carcinoma",
  "Schizophrenia"
];
