import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Form from './AddressForm';
import AddressFormat from "components/Address/Format";


export default class extends Component {
  constructor(props){
    super(props);

    this.state = {
      isOpen: false,
      values: {}
    };

    this.setValues = this.setValues.bind(this);
  }

  setValues(data){
    this.setState({
      ...this.state,
      isOpen: false,
      values: data
    }, () => {
      this.props.onSubmit(this.state.values);
    });
  }

  componentDidMount() {
    this.setValues(this.props.initialValues);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.initialValues !== this.props.initialValues){
      this.setValues(this.props.initialValues);
    }
  }

  render() {
    return (
      <div>
        <div>
          {
            this.state.values ? (
              <div>
                <div>
                  <AddressFormat value={this.state.values}/>
                </div>
                <div style={{marginTop: 6}}>
                  <button className="link-button" onClick={(e) => {e.preventDefault(); this.setState({...this.state, isOpen: true})}}>
                    Edit
                  </button>{' '}
                  <button
                    className="link-button text-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.onDelete();
                    }}>
                    Remove
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <button className="link-button" onClick={(e) => {e.preventDefault(); this.setState({...this.state, isOpen: true})}}>
                  Add Address
                </button>
              </div>
            )
          }

        </div>
        <Modal isOpen={this.state.isOpen} toggle={() => this.setState({...this.state, isOpen: false})}>
          <ModalHeader toggle={() => this.setState({...this.state, isOpen: false})}>
            Address
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(data) => this.setValues(data)} initialValues={this.state.values}
                  onCancel={() => this.setState({...this.state, isOpen: false})}/>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
