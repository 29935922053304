import * as provider_kit from 'actions/provider_kit';

const initialState = {
  items: null,
};

let array = [];

export const providerKitReducer = (state = initialState, action) => {
  switch (action.type) {
    case provider_kit.PROVIDER_KIT_GET_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        items_total: action.payload.count,
        page_size: action.payload.page_size,
        pages: Math.ceil(action.payload.count / action.payload.page_size)
      };

    case provider_kit.PROVIDER_KIT_CREATE_SUCCESS:
      array = state.items.slice();
      array.unshift(action.payload);

      return {
        ...state,
        items: array
      };

    case provider_kit.PROVIDER_KIT_UPDATE_SUCCESS:
      array = state.items.slice();
      array.splice(action.meta.index, 1, action.payload);

      return {
        ...state,
        items: array
      };

    case provider_kit.PROVIDER_KIT_DELETE_SUCCESS:
      array = state.items.slice();
      array.splice(action.meta.index, 1);

      if(action.meta.isLast){
        window.location.reload();
      }

      return {
        ...state,
        items_total: state.items_total - 1,  
        items: array
      };

    default:
      return state
  }
};
