import * as kit from "actions/kit";
import * as modal from "actions/modal";


const initialState = {
  sub_total: 0,
  shipping: 0,
  total: 0,
  fee: 0,
  order_id: null,
  total_qtty: 0,

  orderCompleteInProgress: false,
  orderCompleteError: null,
  orderCompleteSuccess: false,

  orders: []
};


export const kitReducer = (state = initialState, action) => {
  switch (action.type) {
    case kit.GET_ORDER_SUMMARY_SUCCESS:
      const {sub_total, shipping, total, fee, order_id, total_qtty} = action.payload;

      return {
        ...state,
        sub_total,
        shipping,
        total,
        fee,
        order_id,
        total_qtty
      };

    case kit.ORDER_COMPLETE_REQUEST:
      return {
        ...state,
        orderCompleteInProgress: true,
        orderCompleteError: null,
        orderCompleteSuccess: false,
      };

    case kit.ORDER_COMPLETE_SUCCESS:
      const new_list = state.orders.slice();
      new_list.unshift(action.payload.data);
      return {
        ...state,
        orderCompleteInProgress: false,
        orderCompleteError: null,
        orderCompleteSuccess: true,
        orders: new_list
      };

    case kit.ORDER_COMPLETE_FAILURE:
      return {
        ...state,
        orderCompleteInProgress: false,
        orderCompleteError: action.payload.response.details,
        orderCompleteSuccess: false,
      };

    case kit.GET_ORDER_LIST_REQUEST:
      return {
        ...state,
        orders: null
      };

    case kit.GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        orders: action.payload
      };

    case modal.KIT_ORDER_MODAL_CLOSE:
      return {
        ...state,
        sub_total: 0,
        shipping: 0,
        total: 0,
        fee: 0,
        order_id: null,
        total_qtty: 0,

        orderCompleteInProgress: false,
        orderCompleteError: null,
        orderCompleteSuccess: false,
      };

    default:
      return state;
  }
};
