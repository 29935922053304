import React, {useEffect, useState} from 'react';
import {Field, FieldArray, Form, Formik } from "formik";
import {Button, Col, Row} from "reactstrap";
import classNames from "classnames";
import * as Yup from "yup";
import Date from 'utils/Formik/Date';
import moment from 'moment';
import {IfAuthSuperReadonly, IfNotAuthSuperReadonly} from "../../RBAC";
import Moment from "moment";

const is_valid = (touched, errors, index, field) => (
    //touched['samples'] &&
    errors['samples'] &&
    //touched['samples'][index] &&
    errors['samples'][index] &&
    //touched['samples'][index][field] &&
    errors['samples'][index][field]
  );
const outcome_options = [
    {value: 'negative', title: 'Negative'},
    {value: 'loss-biochemical', title: 'Loss-Biochemical'},
    {value: 'loss-clinical', title: 'Loss-Clinical'},
    {value: 'ongoing_pregnancy', title: "Ongoing Pregnancy"},
    {value: 'delivered', title: 'Delivered'}
];
const today = moment();
  
const Schema = Yup.object().shape({
    samples: Yup.array()
      .of(
        Yup.object().shape({
         transferred: Yup.boolean(),
         transferred_date: Yup
         .date()
         .when("transferred", {
             is: (transferred) => transferred && transferred === true,
             then: Yup.date().max(today, "Date cannot be in the future.").required()
         }),
         transferred_outcome_is_reported: Yup
         .boolean(),
         transferred_outcome: Yup
         .string()
         .notRequired()
         .when("transferred_outcome_is_reported", {
            is: (transferred_outcome_is_reported) => transferred_outcome_is_reported && transferred_outcome_is_reported === true,
            then: Yup.string().required()
         }),
         transferred_outcome_date: Yup
         .date()
         .notRequired()
         .when((["transferred_outcome", "transferred_date"]), (transferred_outcome, transferred_date) => {
             if (transferred_outcome.length && (transferred_outcome === "loss" || transferred_outcome === 'delivered')) {
                 
                 return Yup.date().min(moment(transferred_date).add(1, 'days'), "Date must be after transfer.").max(today, "Date cannot be in the future.").required()
                }
            })
        })
      )
});    

const TransferOutcomeForm = (props) => {
    const [submitted, setSubmitted] = useState(false);
    
    useEffect(() => {
    setSubmitted(false);
    }, [props.initialValues]);

    return (
        <Formik
            initialValues={
                {
                    samples: props.initialValues
                }
            }
            enableReinitialize={true}
            validationSchema={Schema}
            onSubmit={
                (values, {setSubmitting}) => {
                    values.samples.map((item) => {
                        if (!item.transferred) {
                            item.transferred_outcome_is_reported = false;
                            item.transferred_outcome = "";
                        }
                        return item;
                      });
                    props.onSubmit(values)
                    setSubmitting(false);
                    setSubmitted(true);
                }
            }
        >
            {
                ({ errors, touched, values, isSubmitting, initialValues }) => {
                    return (
                        <Form>
                            <FieldArray name={'samples'}>
                                {
                                    (arrayHelpers) => (
                                        values.samples && values.samples.length > 0 ? (
                                            <div className="with-table"> 
                                                <table className="table table-sm" style={{overflowX: 'scroll'}}>
                                                    <thead>
                                                        <tr>
                                                        <th>Embryo #</th>
                                                        <th>Tube Label</th>
                                                        <th>Transferred</th>
                                                        <th>Date of Transfer</th>
                                                        <th>Outcome Reported</th>
                                                        <th>Transfer Outcome</th>
                                                        <th>Date of Outcome</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            values.samples.map((sample, index) => {
                                                                return (
                                                                    <tr key={`sample-transferred-row-${index}`}>
                                                                        <td>{sample.embryo_number}</td>
                                                                        <td>{sample.tube_label}</td>
                                                                        <td>
                                                                          <IfNotAuthSuperReadonly>
                                                                            <Field 
                                                                                name={`samples.${index}.transferred`}
                                                                                render={({ field, form: { isSubmitting } }) => {
                                                                                    return (
                                                                                        <input 
                                                                                            {...field}
                                                                                            disabled={isSubmitting}
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                                values.samples[index].transferred ? true : false
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                }}
                                                                            />
                                                                          </IfNotAuthSuperReadonly>
                                                                          <IfAuthSuperReadonly>
                                                                            {values.samples[index].transferred ? 'Y' : 'N'}
                                                                          </IfAuthSuperReadonly>
                                                                        </td>
                                                                        {
                                                                            values.samples[index].transferred ?
                                                                            <>
                                                                                <td>
                                                                                  <IfNotAuthSuperReadonly>
                                                                                    <Field 
                                                                                        name={`samples.${index}.transferred_date`}
                                                                                        component={Date}
                                                                                        changeComponentVal={true}
                                                                                        initialDate={(props.initialValues[index] && props.initialValues[index].transferred) ? values.samples[index].transferred_date : null}
                                                                                        className={classNames('form-control form-control-sm', {
                                                                                            'is-invalid': is_valid(touched, errors, index, 'transferred_date')
                                                                                        })}
                                                                                    />
                                                                                    <span className="error-message">{values.samples[index].transferred_date.length ? touched && errors.samples && errors.samples[index] && errors.samples[index].transferred_date : "Please enter a date."}</span>
                                                                                  </IfNotAuthSuperReadonly>
                                                                                  <IfAuthSuperReadonly>
                                                                                    {(props.initialValues[index] && props.initialValues[index].transferred) ?
                                                                                      Moment(values.samples[index].transferred_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                                                                                  </IfAuthSuperReadonly>
                                                                                </td>
                                                                                <td>
                                                                                  <IfNotAuthSuperReadonly>
                                                                                    <Field 
                                                                                        name={`samples.${index}.transferred_outcome_is_reported`}
                                                                                        render={({ field, form: { isSubmitting } }) => {
                                                                                            return (
                                                                                                <input 
                                                                                                    {...field}
                                                                                                    disabled={isSubmitting}
                                                                                                    type="checkbox"
                                                                                                    checked={values.samples[index].transferred_outcome_is_reported ? true : false}
                                                                                                />
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                  </IfNotAuthSuperReadonly>
                                                                                  <IfAuthSuperReadonly>
                                                                                    {values.samples[index].transferred_outcome_is_reported ? 'Y' : 'N'}
                                                                                  </IfAuthSuperReadonly>
                                                                                </td> 
                                                                            </> : 
                                                                            <>
                                                                                <td />
                                                                                <td />
                                                                            </>    
                                                                            }    
                                                                                {values.samples[index].transferred && values.samples[index].transferred_outcome_is_reported ?
                                                                                <>
                                                                                <td>
                                                                                  <IfNotAuthSuperReadonly>
                                                                                    <Field 
                                                                                        name={`samples.${index}.transferred_outcome`}
                                                                                        component="select"
                                                                                        className={classNames('form-control form-control-sm', {
                                                                                            'is-invalid': is_valid(touched, errors, index, 'transferred_outcome')
                                                                                        })}
                                                                                    >
                                                                                        <option value={''}>Select</option>
                                                                                        {outcome_options.map((outcome, j) => (
                                                                                                <option value={outcome.value} key={`samples.transferred_outcome.${j}`}>{outcome.title}</option>
                                                                                            )
                                                                                        )}
                                                                                    </Field>
                                                                                  </IfNotAuthSuperReadonly>
                                                                                  <IfAuthSuperReadonly>
                                                                                    {values.samples[index].transferred_outcome}
                                                                                  </IfAuthSuperReadonly>
                                                                                </td> 
                                                                                    {values.samples[index].transferred_outcome.length && (values.samples[index].transferred_outcome === "loss" || values.samples[index].transferred_outcome === "delivered") ?
                                                                                        <td>
                                                                                          <IfNotAuthSuperReadonly>
                                                                                            <Field
                                                                                                name={`samples.${index}.transferred_outcome_date`}
                                                                                                changeComponentVal={true}
                                                                                                initialDate={(props.initialValues[index] && props.initialValues[index].transferred_outcome.length) ? values.samples[index].transferred_outcome_date : null}
                                                                                                component={Date}
                                                                                                className={classNames('form-control form-control-sm', {
                                                                                                    'is-invalid': is_valid(touched, errors, index, 'transferred_outcome_date')
                                                                                                })}
                                                                                            />
                                                                                          </IfNotAuthSuperReadonly>
                                                                                          <IfAuthSuperReadonly>
                                                                                            {(props.initialValues[index] && props.initialValues[index].transferred_outcome.length) ?
                                                                                              Moment(values.samples[index].transferred_outcome_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                                                                                          </IfAuthSuperReadonly>
                                                                                        <span className="error-message">{!values.samples[index].transferred_outcome_date.length ? 'Please enter a date.' : touched && errors.samples && errors.samples[index] && errors.samples[index].transferred_outcome_date}</span>
                                                                                        </td>
                                                                                    :   <td/>
                                                                                    }   
                                                                                </>:
                                                                                <>
                                                                                    <td/>
                                                                                    <td/>
                                                                                </>    
                                                                            }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                <div style={{padding: 20}}>
                                                    <Row>
                                                      <IfNotAuthSuperReadonly>
                                                        <Col md="auto">
                                                            <Button
                                                                type="submit"
                                                                color="info"
                                                                size="sm"
                                                                disabled={isSubmitting}
                                                                hidden={submitted || (!submitted && values.samples === initialValues.samples)}
                                                                >Save</Button>
                                                        </Col>
                                                      </IfNotAuthSuperReadonly>
                                                    </Row>
                                                </div>
                                            </div>

                                        ) : (
                                            null
                                        )
                                    )
                                }
                            </FieldArray>
                        </Form>
                    )
                }
            }
        </Formik>    
    )
};
export default TransferOutcomeForm;