import React, {PureComponent} from 'react';
import classNames from 'classnames';
import './style.css';

export default class Badge extends PureComponent{
  static defaultProps = {
    color: 'default',
    size: 'md'
  };

  render(){
    const {color, icon, content, size, children}  = this.props;

    return(
      <span
        className={classNames('g-badge', color, size)}
      >
        {icon || ''}{icon && ' '}
        {content ? content : children}
      </span>
    )
  }
}