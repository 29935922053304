import React, {useState} from 'react';
import {
  Button,
  Classes,
  Colors,
  Dialog,
  HTMLTable,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
  Tag
} from "@blueprintjs/core";
import MutationForm from './MutationForm';
import FamilyMemberSelect from "./FamilyMemberSelect";
import {IfAuthSuperReadonly, IfNotAuthSuperReadonly} from "../../../RBAC";

// const PERSON_OPTIONS = [
//   'Maternal Grandmother',
//   'Maternal Grandfather',
//   'Paternal Grandmother',
//   'Paternal Grandfather',
//   'Daughter',
//   'Son',
//   'Aunt'
// ]

const PERSON_STATUS_OPTIONS = [
  'NA',
  'Affected',
  'Unaffected',
  'Carrier'
]

const MUTATION_STATUS_OPTIONS = [
  'Heterozygous Positive',
  'Hemizygous Positive',
  'Homozygous Positive',
  'Compound Heterozygous',
  'Negative',
  "NA"
]


export default (props) => {
  const {mutations, persons, statuses, onPersonRemove, onMutationRemove, onMutationUpdate, onPersonUpdate, onStatusUpdate} = props;

  const [mutationEdit, setMutationEdit] = useState(null);

  // const personMenu = (person_key) => (
  //   <Menu>
  //     <MenuDivider title="Patient"/>
  //     <MenuItem text="Patient" onClick={() => {
  //       onPersonUpdate(person_key, {name: 'Patient'})
  //     }}/>
  //
  //     {
  //       PERSON_OPTIONS.map(item => (
  //         <MenuItem text={item} onClick={() => {
  //           onPersonUpdate(person_key, {name: `Patient's ${item}`})
  //         }}/>
  //       ))
  //     }
  //
  //     <MenuDivider title="Partner"/>
  //     <MenuItem text="Partner" onClick={() => {
  //       onPersonUpdate(person_key, {name: 'Partner'})
  //     }}/>
  //
  //     {
  //       PERSON_OPTIONS.map(item => (
  //         <MenuItem text={item} onClick={() => {
  //           onPersonUpdate(person_key, {name: `Partner's ${item}`})
  //         }}/>
  //       ))
  //     }
  //   </Menu>
  // )

  const personStatusMenu = (personKey, personStatus) => {
    const personStatusOptions = personStatus ? ['Select'].concat(PERSON_STATUS_OPTIONS) : PERSON_STATUS_OPTIONS;
    return (
      <Menu>
        {
          personStatusOptions.map(item => (

            <MenuItem text={item} onClick={() => {
              onPersonUpdate(personKey, {status: item !== 'Select' ? item : ''})
            }}/>
          ))
        }
      </Menu>
    )
  }

  const statusMenu = (key) => (
    <Menu>
      {
        MUTATION_STATUS_OPTIONS.map(item => (
          <MenuItem text={item} onClick={() => {
            onStatusUpdate(key, item)
          }}/>
        ))
      }
    </Menu>
  )

  return (
    <div className="mutation-table">
      <HTMLTable
        style={{width: '100%'}}
        striped
        condensed
      >
        <thead>
        <tr>
          <td style={{width: 220}}>
            <strong>Person</strong>
          </td>
          {
            Object.keys(mutations).map((mutation_key) => {
              const mutation = mutations[mutation_key];

              return (
                <td style={{paddingLeft: 20}}>
                  <strong>{mutation.name}</strong><br/>
                  chr{mutation.chromosome}, pos: {mutation.position}

                  <div>
                    {mutation.linkage && <Tag minimal round intent={Intent.PRIMARY}>L</Tag>}
                    {mutation.taqman && <Tag minimal round intent={Intent.SUCCESS}>T</Tag>}
                  </div>

                  <IfNotAuthSuperReadonly>
                    <div>
                      <button
                        className="link-button"
                        onClick={(e) => {
                          e.preventDefault();
                          setMutationEdit({
                            ...mutation,
                            key: mutation_key
                          });
                        }}
                      >
                        edit
                      </button>{' '}
                      <button
                        className="link-button"
                        onClick={(e) => {
                          e.preventDefault();
                          onMutationRemove(mutation_key);
                        }}
                        style={{color: Colors.RED3}}
                      >
                        remove
                      </button>
                    </div>
                  </IfNotAuthSuperReadonly>
                </td>
              )
            })
          }
          <td style={{paddingLeft: 20}}>
            <strong>Status</strong>
          </td>
          <td/>
        </tr>
        </thead>

        <tbody>
        {
          Object.keys(persons).map((person_key) => {
            const person = persons[person_key];
            return (
              <tr>
                <td>
                  <IfNotAuthSuperReadonly>
                    <FamilyMemberSelect
                      value={person.name}
                      onChange={(value) => {
                        onPersonUpdate(person_key, {name: value});
                      }}
                    />
                  </IfNotAuthSuperReadonly>
                  <IfAuthSuperReadonly>
                    {person.name}
                  </IfAuthSuperReadonly>
                </td>

                {
                  Object.keys(mutations).map((mutation_key) => {
                    return (
                      <td>
                        <IfNotAuthSuperReadonly>
                          {/*<div>*/}
                          {/*  {statuses[person_key + '_' + mutation_key] || 'None'}*/}
                          {/*</div>*/}

                          <Popover content={statusMenu(person_key + '_' + mutation_key)} position={Position.RIGHT}>
                            <Button minimal fill text={statuses[person_key + '_' + mutation_key] || 'Select'}/>
                          </Popover>
                        </IfNotAuthSuperReadonly>
                        <IfAuthSuperReadonly>
                          {statuses[person_key + '_' + mutation_key] || 'none'}
                        </IfAuthSuperReadonly>
                      </td>
                    )
                  })
                }

                <td>
                  <IfNotAuthSuperReadonly>
                    <Popover content={personStatusMenu(person_key, person.status)} position={Position.RIGHT}>
                      <Button minimal fill text={person.status || 'Select'}/>
                    </Popover>
                  </IfNotAuthSuperReadonly>
                  <IfAuthSuperReadonly>
                    {person.status || 'none'}
                  </IfAuthSuperReadonly>
                </td>
                <td style={{width: 30}}>
                  <IfNotAuthSuperReadonly>
                    <Button
                      minimal
                      icon={'cross'}
                      intent={Intent.DANGER}
                      outlined={false}
                      onClick={() => onPersonRemove(person_key)}
                    />
                  </IfNotAuthSuperReadonly>
                </td>
              </tr>
            )
          })
        }
        </tbody>
      </HTMLTable>

      <Dialog
        isOpen={!!mutationEdit}
        onClose={() => {
          setMutationEdit(null)
        }}
        title={!!mutationEdit ? 'Mutation: ' + mutationEdit.name : 'Mutation'}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            (!!mutationEdit) && (
              <div>
                <MutationForm
                  data={mutationEdit}
                  onChange={(data) => {
                    setMutationEdit({...mutationEdit, ...data});
                  }}
                />
              </div>
            )
          }
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          {
            (!!mutationEdit) && (
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => setMutationEdit(null)}>Close</Button>
                <Button
                  onClick={() => {
                    const mutation_key = mutationEdit.key;
                    delete mutationEdit.key;
                    onMutationUpdate(mutation_key, mutationEdit);
                    setMutationEdit(null);
                  }}
                  intent={Intent.PRIMARY}
                >
                  Save
                </Button>
              </div>
            )
          }
        </div>
      </Dialog>
    </div>
  )
}
