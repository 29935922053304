import React, { useEffect } from 'react';
import Moment from 'moment/moment';
import Datetime from "react-datetime";


export default (
  {
    field, // { name, value, onChange, onBlur }
    form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }
) => {
  useEffect(() => {
    if (props.changeComponentVal) {
      if (!props.initialDate) {
        setFieldValue(field.name, Moment().format('YYYY-MM-DD'))
      }
    }
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <Datetime
        value={Moment(field.value)}
        timeFormat={false}
        utc={false}
        timeConstraints={{minutes: {step: 15}}}
        closeOnSelect={true}
        onChange={
          (val) => {
            console.log(val)
            setFieldValue(field.name, val ? Moment(val).format('YYYY-MM-DD') : '');
            setFieldTouched(field.name, true);
          }
        }
        onBlur={() => setFieldTouched(field.name, true)}
      />
    </div>
  )
}
