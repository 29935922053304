import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from "classnames";


export default (props) => {
    const {errorMessage, fieldsErrors: {username, password, email}, initialValues} = props;

    return (
        <Formik
            initialValues={{
                id: initialValues.id,
                username: initialValues.username,
                first_name: initialValues.first_name,
                last_name: initialValues.last_name,
                email: initialValues.email,
                is_active: initialValues.is_active || false,
                roles: initialValues.roles || {}
            }}
            enableReinitialize={true}
            onSubmit={
                (values, {setSubmitting}) => {
                    props.onSubmit(values);
                    setSubmitting(false)
                }
            }
        >
            {
                ({errors, touched, values, isSubmitting, handleChange}) => {
                    return (
                        <Form autoComplete="off">
                            <FormGroup row>
                                <Label for="username" sm={4}>Username</Label>
                                <Col sm={8}>
                                    <Field
                                        type="text"
                                        name="username"
                                        className={classNames('form-control', {
                                            'is-invalid': username || (touched.role_name && !!errors.role_name)
                                        })}
                                    />
                                    <FormFeedback>
                                        <ErrorMessage name="username" component="span"/>
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            {!values.id &&
                            <FormGroup row>
                                <Label for="password" sm={4}>Password</Label>
                                <Col sm={8}>
                                    <Field
                                        type="password"
                                        name="password"
                                        className={classNames('form-control', {
                                            'is-invalid': password || (touched.role_name && !!errors.role_name)
                                        })}
                                    />
                                    <FormFeedback>
                                        <ErrorMessage name="password" component="span"/>
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                            }

                            <FormGroup row>
                                <Label for="first_name" sm={4}>First name</Label>
                                <Col sm={8}>
                                    <Field
                                        type="text"
                                        name="first_name"
                                        className={classNames('form-control', {
                                            'is-invalid': touched.role_description && !!errors.role_description
                                        })}
                                    />
                                    <FormFeedback>
                                        <ErrorMessage name="first_name" component="span"/>
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="last_name" sm={4}>Last name</Label>
                                <Col sm={8}>
                                    <Field
                                        type="text"
                                        name="last_name"
                                        className={classNames('form-control', {
                                            'is-invalid': touched.role_description && !!errors.role_description
                                        })}
                                    />
                                    <FormFeedback>
                                        <ErrorMessage name="last_name" component="span"/>
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="email" sm={4}>Email</Label>
                                <Col sm={8}>
                                    <Field
                                        type="email"
                                        name="email"
                                        className={classNames('form-control', {
                                            'is-invalid': email || (touched.role_name && !!errors.role_name)
                                        })}
                                    />
                                    <FormFeedback>
                                        <ErrorMessage name="email" component="span"/>
                                    </FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="is_active" sm={4}>Is active</Label>
                                <Col sm={8}>
                                    <Field
                                        name="is_active"
                                        render={({field, form: {isSubmitting}}) => (
                                            <input
                                                {...field}
                                                disabled={isSubmitting}
                                                type="checkbox"
                                                checked={field.value}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label sm={4}>Permissions</Label>
                                <Col sm={8}/>
                                {props.roles.map((item, index) => (
                                    <Col sm={8}>
                                        <FormGroup check>
                                            <Label check>
                                                <Field
                                                    name={`roles.${item.id}`}
                                                    render={({field, form: {isSubmitting}}) => (
                                                        <input
                                                            {...field}
                                                            disabled={isSubmitting}
                                                            type="checkbox"
                                                            checked={field.value}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {' ' + item.role_name}
                                            </Label>
                                        </FormGroup>
                                    </Col>)
                                )}
                            </FormGroup>

                            <Row style={{color: 'red'}} className="justify-content-center">{errorMessage}</Row>

                            <Row style={{marginTop: 20}} className="justify-content-end">
                                {values.id && props.user.id !== values.id &&
                                <Col md="auto">
                                    <Button color="danger" onClick={() => props.onDelete(values.id)}>Delete</Button>
                                </Col>
                                }
                                <Col md="auto">
                                    <Button color="light" onClick={() => props.onCancel()}>Cancel</Button>
                                </Col>
                                <Col md="auto">
                                    <Button type="submit" color="success">
                                        {
                                            values.id ? 'Save Changes' : 'Create User'
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}
