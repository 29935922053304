import React, {useState, useEffect} from 'react';
import {Field, FieldArray, Form, Formik} from "formik";
import {Button, Col, FormGroup, Label, Modal, ModalHeader, Row, ModalBody} from "reactstrap";
import classNames from "classnames";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from "moment/moment";
import PersonForm from 'components/Patient/Form/PersonForm';
import {IfNotAuthSuperReadonly} from "../../RBAC";


const is_valid = (touched, errors, index, field) => (
  touched['samples'] &&
  errors['samples'] &&
  touched['samples'][index] &&
  errors['samples'][index] &&
  touched['samples'][index][field] &&
  errors['samples'][index][field]
);

const defaultSchema = Yup.object().shape({
  samples: Yup.array()
    .of(
      Yup.object().shape({
        received_at: Yup.string().nullable(),
        label: Yup.string()
          .when("received_at", {
            is: (received_at) => received_at && received_at.length > 0,
            then: Yup.string().required("Label required")
          }),
        tube_label: Yup.string().nullable(),
        person_type: Yup.string().required("Required"),
        person: Yup.object().nullable().when("person_type", {
          is: (person_type) => ['donor', 'other'].includes(person_type),
          then: Yup.object({
            first_name: Yup.string().required('First name required'),
            last_name: Yup.string().required('Last name required')
          })
        })
      })
      .uniqueProperty('tube_label', 'Tube Label must be unique.')
    )
});

const m2Schema = Yup.object().shape({
  samples: Yup.array()
    .of(
      Yup.object().shape({
        received_at: Yup.string().nullable(),
        label: Yup.string().required("Required"),
        tube_label: Yup.string().nullable(),
      })
      .uniqueProperty('tube_label', 'Tube Label must be unique.')
    )
});


export default (props) => {
  const [removed, setRemoved] = useState(0);
  // const [submitted, setSubmitted] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);
  const [personFormKey, setPersonFormKey] = useState(0);

  useEffect(() => {
    // setSubmitted(false);
  }, [props.initialValues]);

  const updateSampleTypes = (initialValues) => {
      if (!props.m2) {
          return initialValues
      }

      for (let sample of initialValues) {
          sample.sample_type = sample.sample_type ? sample.sample_type : 'Saliva'
      }
      return initialValues
  }

  return (
    <Formik
      initialValues={
        {
          samples: [...updateSampleTypes(props.initialValues)],
        }
      }
      validationSchema={props.m2 ? m2Schema : defaultSchema}
      onSubmit={
        (values, {setSubmitting}) => {
          values.samples.map((item) => {
            item.sample_type = item.sample_type ? item.sample_type : '';
            item.sent_at = item.sent_at ? item.sent_at : null;
            item.received_at = item.received_at ? item.received_at : null;
            item.collection_date = item.collection_date ? item.collection_date : null;
            if (!['donor', 'other'].includes(item.person_type)) {
              item.person = null;
            }
            return item;
          });

          props.onSubmit(values);
          setSubmitting(false);
          setRemoved(0);
          // setSubmitted(true);
        }
      }
    >
      {
        ({
           errors,
           touched,
           values,
           setFieldValue,
           handleChange,
           handleReset
         }) => {
          return (
            <Form>
              <FieldArray name={'samples'}>
                {
                  (arrayHelpers) => (

                    values.samples && values.samples.length > 0 ? (
                      <div className="with-table">
                        <table className="table table-sm">
                          <thead>
                          {
                            props.m2 ? (
                              <tr>
                                <th>Sample Type</th>
                                <th>Tube Label</th>
                                <th>Label</th>
                                <th>Kit Sent</th>
                                <th>Collected</th>
                                <th>Received</th>
                                <th/>
                              </tr>
                            ) : (
                              <tr>
                                <th style={{width: 250}}>Person</th>
                                <th>Tube Label</th>
                                <th>Label</th>
                                <th>Kit Sent</th>
                                <th>Collected</th>
                                <th>Received</th>
                                <th/>
                              </tr>
                            )
                          }

                          </thead>
                          <tbody>
                          {
                            values.samples.map((sample, index) => (
                              <tr key={`sample-row-${index}`}>
                                {
                                  !props.m2 && (
                                    <td>
                                      {sample.person_type === 'patient' && <div>
                                        {props.patient &&
                                        <div><strong>{props.patient.first_name} {props.patient.last_name}</strong></div>}
                                        Patient
                                      </div>}

                                      {sample.person_type === 'partner' && <div>
                                        {props.partner &&
                                        <div><strong>{props.partner.first_name} {props.partner.last_name}</strong></div>}
                                        Partner
                                      </div>}

                                      {
                                        ((sample.person_type === 'other' || sample.person_type === 'donor' || sample.person_type === null) && sample.person) && (
                                          <div>
                                            <strong>{sample.person.first_name}{' '}{sample.person.last_name}</strong>

                                            {
                                              sample.person.dob && (
                                                <div>
                                                  Age: {Moment().diff(sample.person.dob, 'years')}{' '}
                                                  ({Moment(sample.person.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')})
                                                </div>
                                              )
                                            }

                                            {
                                              sample.person.related_to &&
                                              <div><span
                                                className="text-capitalize">{sample.person.related_as}</span> To: {sample.person.related_to}
                                              </div>
                                            }
                                          </div>
                                        )
                                      }

                                      <IfNotAuthSuperReadonly>
                                        <div>
                                          <p>
                                            <button className="link-button" onClick={(e) => {
                                              e.preventDefault();
                                              setModalOpen(index);
                                            }}>
                                              {!sample.person_type ? 'Select Person' : 'Edit'}
                                            </button>
                                            <span
                                              className="error-message">{touched.samples && touched.samples[index] && errors.samples && errors.samples[index] && (errors.samples[index].person_type || (errors.samples[index].person && (errors.samples[index].person.first_name || errors.samples[index].person.last_name)))}</span>
                                          </p>

                                          <Modal isOpen={modalOpen === index} toggle={() => setModalOpen(null)}>
                                            <ModalHeader toggle={() => setModalOpen(null)}>
                                              Results
                                            </ModalHeader>
                                            <ModalBody>
                                              <FormGroup row>
                                                <Label md={4}>Person</Label>
                                                <Col md={8}>
                                                  <Field
                                                    name={`samples.${index}.person_type`}
                                                    component="select"
                                                    value={sample.person_type}
                                                    onChange={(e) => {
                                                        const personType = values.samples[index].person_type
                                                        if (['other', 'donor'].includes(personType)) {
                                                            setPersonFormKey(previousFormKey => (
                                                                previousFormKey + 1
                                                            ));
                                                        }
                                                        handleChange(e)
                                                    }}
                                                    className={classNames('form-control form-control-sm', {
                                                      'is-invalid': is_valid(touched, errors, index, 'person_type')
                                                    })}
                                                  >
                                                    <option value={''}>Select</option>
                                                    {
                                                      props.patient && (
                                                        <option
                                                          value="patient">{props.patient.first_name} {props.patient.last_name} (Patient)</option>
                                                      )
                                                    }
                                                    {
                                                      props.partner && (
                                                        <option
                                                          value="partner">{props.partner.first_name} {props.partner.last_name} (Partner)</option>
                                                      )
                                                    }
                                                    <option value="other">Relative</option>
                                                    <option value="donor">Donor</option>
                                                  </Field>
                                                </Col>
                                              </FormGroup>

                                              {
                                                (sample.person_type !== 'other' && sample.person_type !== 'donor') && (
                                                  <Row style={{marginTop: 20}} className="justify-content-end">
                                                    <Col md="auto">
                                                      <Button color="light"
                                                              onClick={() => {
                                                                  if (!sample.id) {
                                                                      sample.person_type = ''
                                                                  }
                                                                  setModalOpen(null)}}>
                                                          Cancel
                                                      </Button>
                                                    </Col>
                                                    <Col md="auto">
                                                      <Button type="button" color="success"
                                                              onClick={() => setModalOpen(null)}>Continue</Button>
                                                    </Col>
                                                  </Row>
                                                )
                                              }

                                              {
                                                (sample.person_type === 'other' || sample.person_type === 'donor') &&
                                                <div key={personFormKey}>
                                                  <PersonForm
                                                    onCancel={() => setModalOpen(false)}
                                                    onSuccess={data => {
                                                      setFieldValue(`samples.${index}.person`, data);
                                                      setModalOpen(false);
                                                    }}
                                                    initialValues={sample.person}
                                                    patient={props.patient}
                                                    partner={props.partner}
                                                    submitText={'Continue'}
                                                    salivaSample
                                                    addressRequired={false}
                                                  />
                                                </div>
                                              }
                                            </ModalBody>
                                          </Modal>
                                        </div>
                                      </IfNotAuthSuperReadonly>
                                    </td>
                                  )
                                }
                                {
                                    props.m2 &&
                                        <td>
                                            <Field
                                                component="select"
                                                name={`samples.${index}.sample_type`}
                                                className={classNames('form-control form-control-sm', {
                                                    'is-invalid': is_valid(touched, errors, index, 'sample_type')
                                                })}
                                            >
                                                <option value='Saliva'>Saliva</option>
                                                <option value='Extracted DNA'>Extracted DNA</option>
                                                <span className="error-message">
                                            {touched && errors.samples && errors.samples[index] &&
                                                errors.samples[index].sample_type}
                                        </span>
                                            </Field>
                                        </td>
                                }
                                <td>
                                  <Field
                                    name={`samples.${index}.tube_label`}
                                    className={classNames('form-control form-control-sm', {
                                      'is-invalid': is_valid(touched, errors, index, 'tube_label')
                                    })}
                                  />
                                  <span className="error-message">{touched && errors.samples && errors.samples[index] && errors.samples[index].tube_label}</span>
                                </td>
                                <td>
                                  <Field
                                    name={`samples.${index}.label`}
                                    className={classNames('form-control form-control-sm', {
                                      'is-invalid': is_valid(touched, errors, index, 'label')
                                    })}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    name={`samples.${index}.sent_at`}
                                    className={classNames('form-control form-control-sm', {
                                      'is-invalid': is_valid(touched, errors, index, 'sent_at')
                                    })}
                                    onChange={(d) => {setFieldValue(`samples.${index}.sent_at`, d.target.value)}}
                                    value={values.samples[index].sent_at || undefined}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    name={`samples.${index}.collection_date`}
                                    className={classNames('form-control form-control-sm', {
                                      'is-invalid': is_valid(touched, errors, index, 'collection_date')
                                    })}
                                    onChange={(d) => {setFieldValue(`samples.${index}.collection_date`, d.target.value)}}
                                    value={values.samples[index].collection_date || undefined}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    name={`samples.${index}.received_at`}
                                    className={classNames('form-control form-control-sm', {
                                      'is-invalid': is_valid(touched, errors, index, 'received_at')
                                    })}
                                    onChange={(d) => {setFieldValue(`samples.${index}.received_at`, d.target.value)}}
                                    value={values.samples[index].received_at || undefined}
                                  />
                                </td>
                                <td>
                                  <Button
                                    color="link"
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                      setRemoved(removed + 1)
                                    }}
                                  >
                                    <span className="text-danger"><FontAwesomeIcon icon="times"/></span>
                                  </Button>
                                </td>
                              </tr>
                            ))
                          }

                          <tr>
                            <td colSpan={8}>
                              <Row className="justify-content-center" style={{marginTop: 20}}>
                                <Col md="auto">
                                  <Button
                                    color="light"
                                    size="sm"
                                    onClick={() => {
                                      const initialValues = {
                                        collection_date: '',
                                        sent_at: "",
                                        received_at: "",
                                        label: "",
                                        tube_label: ""
                                      }
                                      if (!props.m2) {
                                        initialValues.person_type = ""
                                        initialValues.person = {
                                          first_name: "",
                                          last_name: ""
                                        }
                                      }
                                      arrayHelpers.push(initialValues)
                                    }}
                                  >
                                    <FontAwesomeIcon icon="plus"/> Add Sample
                                  </Button>
                                </Col>
                                <Col md="auto">
                                  <Button
                                    color="info"
                                    size="sm"
                                    type="submit"
                                    //hidden={submitted || (!submitted && values.samples === initialValues.samples)}
                                  >
                                    Save Changes
                                  </Button>
                                </Col>

                                <Col md="auto">
                                  <Button
                                    color="light"
                                    size="sm"
                                    type="button"
                                    //hidden={submitted || (!submitted && values.samples === initialValues.samples)}
                                    onClick={() => handleReset()}
                                  >
                                    Cancel Changes
                                  </Button>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                    ) : (
                      <div style={{paddingTop: 40, paddingBottom: 40, textAlign: 'center'}}>
                        <Button
                          color="light"
                          size="sm"
                          onClick={() => {
                            const initialValues = {
                              collection_date: '',
                              sent_at: "",
                              received_at: "",
                              label: "",
                              tube_label: ""
                            }
                            if (!props.m2) {
                              initialValues.person_type = ""
                              initialValues.person = {
                                first_name: "",
                                last_name: ""
                              }
                            }
                            arrayHelpers.push(initialValues)
                          }}
                        >
                          <FontAwesomeIcon icon="plus"/> Add Sample
                        </Button>
                        {
                          removed > 0 && <span>{' '}
                            <Button
                              color="info"
                              size="sm"
                              type="submit"
                            >
                            Save
                          </Button>
                        </span>
                        }
                      </div>
                    )
                  )
                }
              </FieldArray>
            </Form>
          )
        }
      }
    </Formik>
  )
}
