import {useEffect, useState} from 'react';
import axios from "axios";
import * as links from "../constants/links"
import {getProviderID} from "auth/token";
import URI from 'urijs';


export default (endpoint, p = {}, noCall = false) => {
  const [params, setParams] = useState(p);
  const [data, setData] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [createData, setCreateData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {id, page, search, ...otherParams} = params;

  const callAPI = (url, method, payload) => {
    setLoading(true);
    setError(null);

    let api_url = new URI(url);
    api_url.addSearch('provider_id', getProviderID());
    Object.keys(otherParams).forEach(key => {
      api_url.addSearch(key, otherParams[key])
    })
    api_url = api_url.normalizeSearch();

    const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
    const user = auth ? JSON.parse(auth.auth) : {};
    const token = user.token

    return axios({
      url: api_url.toString(),
      method: method,
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${token}`
      },
      data: payload
    }).finally(() => {
      setLoading(false);
    })
  };


  // Retrieve detail
  useEffect(() => {
    if (!noCall) {
      if (id) {
        const constantName = `${endpoint}_DETAIL_LINK`;
        callAPI(links[constantName].replace(':id', id), 'get')
          .then(response => setData(response.data))
          .catch(error => setError(error.response));
      } else {
        const constantName = `${endpoint}_LIST_LINK`;
        const uri = new URI(links[constantName]);
        uri.setSearch({page: page, search: search});

        callAPI(uri.normalizeSearch().toString(), 'get')
          .then(response => setData({
            ...response.data,
            pages: Math.ceil(response.data.count / response.data.page_size)
          }))
          .catch(error => setError(error.response));
      }
    }
  }, [endpoint, id, page, search]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Create
  useEffect(() => {
    if (createData) {
      console.log('create')
    }
  }, [createData]);

  // Update
  useEffect(() => {
    if (updateData) {
      const url = updateData.id ? links[`${endpoint}_DETAIL_LINK`].replace(':id', updateData.id)
          : links[`${endpoint}_LIST_LINK`];
      const method = updateData.id ? 'put' : 'post';

      callAPI(url, method, updateData)
        .then(response => setData(response.data))
        .catch(error => setError(error.response));
    }
  }, [endpoint, updateData]);  // eslint-disable-line react-hooks/exhaustive-deps


  const createItem = (d) => {
    setCreateData(d)
  };

  const updateItem = (d) => {
    setUpdateData(d);
  };

  const deleteItem = (id) => {
    console.log('delete')
  };

  const updateParams = (d) => {
    setParams({...params, ...d})
  };

  return [
    {data, error, loading},
    {
      createItem,
      updateItem,
      deleteItem,
      updateParams
    }
  ]
}
