import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Alert from "utils/Alert";
import {submitPatient, toggleTestTypeModal} from "actions/patient";
import {PatientFormContact} from 'components/Patient/Form';


export default connect(
  state => ({
    modal: state.patient.test_type_modal
  }),
  {
    toggleTestTypeModal: toggleTestTypeModal,
    submitPatient: submitPatient
  }
)(
  class extends Component {
    onSubmit(data) {
      this.props.submitPatient(data)
    }

    render() {
      const {is_open, data, errors} = this.props.modal;
      const close = () => this.props.toggleTestTypeModal(false);

      return (
        <Modal isOpen={is_open} toggle={() => close()}>
          <ModalHeader toggle={() => close()}>
            Clinic
          </ModalHeader>
          <ModalBody>
            <PatientFormContact
              onSubmit={(updated_data) => this.onSubmit({...data, ...updated_data})}
              initialValues={data}
            />
            {
              errors && errors.length > 0 ? (
                <Alert color="danger">
                  {
                    errors.map((error, i) => (
                      <div key={'error-' + i}>{error}</div>
                    ))
                  }
                </Alert>
              ) : ''
            }
          </ModalBody>
        </Modal>
      )
    }
  }
)
