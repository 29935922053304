import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, withRouter} from "react-router-dom";
import {logout} from "actions/auth";
import {SelectProvider} from "components/Common";
import {IfAuthDistributor} from "components/RBAC";
import MobileMenu from "layouts/Main/MobileMenu";
import Search from './Search';

import {BrowserView, MobileView} from "react-device-detect";


export default withRouter(
  connect(
    state => ({
      user: state.auth,
      selected_provider_id: state.common.selected_provider_id
    }), {
      logout: logout,
    }
  )(
    class extends PureComponent {
      constructor(props) {
        super(props);
        this._pathname = props.location.pathname;
        this.providerSelect = this.providerSelect.bind(this);
      }

      providerSelect(id) {
        this.props.history.push(id ? this._pathname + '?provider=' + id : this._pathname);
      }

      render() {
        return (
          <div className="top-bar">
            <BrowserView>
              <Container fluid>
                <Row className="align-items-center justify-content-end">
                  <Col md="auto">
                    <Search
                        user={this.props.user}
                    />
                  </Col>

                  <Col md="auto">
                    <IfAuthDistributor>
                      <SelectProvider />
                    </IfAuthDistributor>
                  </Col>

                  {/*<Col md="auto">*/}
                  {/*  <IssueDialog/>*/}
                  {/*</Col>*/}

                  <Col md="auto" className="text-right">
                    <Link to={'/login'} onClick={this.props.logout} className="nav-link">
                      <FontAwesomeIcon icon="sign-out-alt" fixedWidth className="sidebar-icon"/>{' '}Sign out
                      {this.props.user.first_name && <span> ({this.props.user.first_name})</span>}
                    </Link>
                  </Col>
                </Row>
              </Container>
            </BrowserView>
            <MobileView>
              <MobileMenu/>
            </MobileView>
          </div>
        )
      }
    }
  )
)
