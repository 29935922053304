import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProviderSimpleDetail, getProviderSimpleList } from "actions/provider";
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import './style/main.css';
import URI from 'urijs';
import { Position, Tooltip } from "@blueprintjs/core";

const ClinicModal = withRouter((props) => {
  const [items, setItems] = useState([]);
  const [itemsPlain, setItemsPlain] = useState({});
  const [distributors, setDistributors] = useState({});
  const [isLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedProviderName, setSelectedProviderName] = useState('All');
  const [selectedDistributorName, setSelectedDistributorName] = useState('');

  const search = props.location.search;

  useEffect(() => {
    try {
      const clinics = JSON.parse(window.localStorage.getItem('clinic_list'));
      const clinics_plain = {};
      const distrib = {};

      if (clinics) {
        Object.keys(clinics).forEach((group_name) => {
          const items = clinics[group_name];
          items.forEach((clinic) => {
            clinics_plain[clinic.id] = clinic;
            distrib[clinic.id] = group_name;
          });
        });

        setItems(clinics);
        setItemsPlain(clinics_plain);
        setDistributors(distrib);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);    // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateProviderStorage();
    setIsDisabled(localStorage.getItem('temp-provider') === 'disabled');
  }, [search, itemsPlain]);  // eslint-disable-line react-hooks/exhaustive-deps

  const updateProviderStorage = () => {
    if (itemsPlain && Object.keys(itemsPlain).length > 0) {
      let temp_provider = window.localStorage.getItem('temp-provider')
      const current_provider = window.localStorage.getItem('provider');
      const current_url = new URI(props.location.pathname + props.location.search);
      const params = current_url.search(true);
      let current_provider_data = {};

      const temp_provider_data = JSON.parse(temp_provider)
      if (!!temp_provider && temp_provider_data.location !== props.location.pathname && !props.location.pathname.startsWith('/kits')) {
        localStorage.removeItem('temp-provider');
        temp_provider = null
      }

      if (!!temp_provider) {
        try {
          current_provider_data = JSON.parse(temp_provider);
          setSelectedProviderName(current_provider_data.company_name);
          if (current_provider_data.id in distributors && distributors[current_provider_data.id] !== 'Clinics') {
            setSelectedDistributorName(distributors[current_provider_data.id]);
          } else {
            setSelectedDistributorName('');
          }
        } catch (e) {
          console.error(e);
        }
      } else if (!!current_provider) {
        try {
          current_provider_data = JSON.parse(current_provider);
          setSelectedProviderName(current_provider_data.company_name);
          if (current_provider_data.id in distributors && distributors[current_provider_data.id] !== 'Clinics') {
            setSelectedDistributorName(distributors[current_provider_data.id]);
          } else {
            setSelectedDistributorName('');
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (!!params['temp-provider']) {
        if (parseInt(params['temp-provider']) !== current_provider_data.id) {
          const item = itemsPlain[parseInt(params['temp-provider'])];
          item.location = props.location.pathname;
          window.localStorage.setItem('temp-provider', JSON.stringify(item));
          window.location.href = current_url;
        }
      } else if (!!params.provider) {
        if (parseInt(params.provider) !== current_provider_data.id || (props.location.pathname.startsWith('/kits') && !!temp_provider)) {
          const item = itemsPlain[parseInt(params.provider)];
          window.localStorage.setItem('provider', JSON.stringify(item));
          window.location.href = current_url;
          localStorage.removeItem('temp-provider');
        }
      } else {
        if (!!current_provider_data.id) {
          // not in url but is in memory

          props.history.replace(current_url.setSearch('provider', current_provider_data.id).normalizeSearch().toString())
        }
      }
    }
  };

  return (
    <div>
      <Tooltip
        content={selectedProviderName || 'Loading...'}
        position={Position.BOTTOM}
        disabled={isDisabled}
      >
        <Button
          onClick={() => setIsOpen(true)}
          color="light"
          disabled={isDisabled}
        >
          {!selectedProviderName && 'Loading...'}
          {selectedProviderName && (!selectedDistributorName || selectedDistributorName === 'Clinics') && (
            <span>Clinic: {selectedProviderName}</span>
          )}
          {selectedProviderName && selectedDistributorName && selectedDistributorName !== 'Clinics' && (
            <span>Clinic: {selectedProviderName}<br/>{selectedDistributorName}</span>
          )}
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader toggle={() => setIsOpen(false)}>
          Select Clinic
        </ModalHeader>
        <ModalBody>
          {
            isLoading ? <p>Loading...</p> : (
              <div>
                {
                  Object.keys(items).length > 0 ? (
                    <div className="select-clinic">
                      <p>
                        <a href="/" onClick={(e) => {
                          const { pathname, search } = props.location;

                          const all_clinics_url = new URI(
                              pathname.startsWith('/clinics') ? '/clinics' :
                                  pathname.startsWith('/patients/') ? '/patients' :
                                      URI(pathname + search).directory()
                          );
                          all_clinics_url.setSearch('p', '1').normalizeSearch();

                          e.preventDefault();
                          props.history.push(all_clinics_url.toString());
                          window.localStorage.removeItem('provider');
                          window.localStorage.removeItem('temp-provider');
                          window.location.reload(true)
                        }}>
                          All
                        </a>
                      </p>

                      {
                        Object.keys(items).map((key, i) => {
                          const distributor = items[key];

                          return (
                            <div key={`select-prov-${i}`}>
                              <p key={`select-provider-distributor-${i}`} className="text-uppercase">
                                {key === 'Clinics' ? 'Standalone' : key}
                              </p>

                              <div style={{ paddingLeft: 12 }}>
                                {
                                  distributor.map((item, j) => {
                                    const { pathname, search } = props.location;

                                    const url = new URI(pathname + search);
                                    const new_url = new URI(
                                        pathname.startsWith('/clinics') ? '/clinics/' + item.id.toString() :
                                            pathname.startsWith('/patients/') ? '/patients' :
                                                url.directory()
                                    );
                                    new_url.setSearch('provider', item.id).normalizeSearch();

                                    return (
                                      <p key={'select-provider-' + i + '-' + j}>
                                        <a href={new_url.toString()} onClick={(e) => {
                                          e.preventDefault();
                                          props.history.push(new_url.toString());
                                          window.localStorage.setItem('provider', JSON.stringify(item));
                                          window.location.reload(true)
                                        }}>
                                          {item.company_name}
                                        </a>
                                      </p>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : (
                    <Button
                      color="warning"
                    >
                      Create Clinic
                    </Button>
                  )
                }

              </div>
            )
          }

        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setIsOpen(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
});

export default withRouter(
  connect(
    () => ({}),
    {
      getProviderSimpleList: getProviderSimpleList,
      getProviderSimpleDetail: getProviderSimpleDetail
    }
  )(
    class extends Component {
      render() {
        return (
          <div className="provider-select">
            <div>
              <ClinicModal
                getList={this.props.getProviderSimpleList}
                getDetail={this.props.getProviderSimpleDetail}
              />
            </div>
          </div>
        )
      }
    }
  )
);
