import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, getFormMeta, getFormSyncErrors, reduxForm} from 'redux-form';
import InputText from 'utils/form/InputText';
import {Button, Col, Container, FormGroup, Row} from "reactstrap";
import {email, required} from "utils/validators";
import InputDateOfBirth from "utils/form/InputDateOfBirth";
import InputSelect from "utils/form/InputSelect";
import {ETHNICITIES} from "constants/case";
import DiseaseHistoryModal from "components/Patient/DiseaseHistory/Modal";
import {withRouter} from 'react-router-dom';
import queryString from "query-string";
import {IfAuthGC} from "components/RBAC";
import InputCheckbox from "utils/form/InputCheckbox";
import InputTextArea from "utils/form/InputTextArea";
import InputSelectDiseases from "utils/form/InputSelectDiseases";


class PatientForm extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
      invalidGroupPatient: false,
      invalidGroupPartner: false,
      invalidGroupContact: false
    };
    this._qs = queryString.parse(props.location.search);
  }

  componentDidMount() {
    this.props.change('provider', this._qs.provider || null)
  }

  toggle(tab) {
    this.setState({activeTab: tab});
  }

  updateGroupErrors(errors, fields = []) {
    const groupPatient = [];
    const groupPartner = [];
    const groupContact = [];

    for (const field_key in fields) {
      if (fields.hasOwnProperty(field_key) && errors.hasOwnProperty(field_key) && fields[field_key].touched) {
        if (['first_name', 'last_name', 'dob', 'ssn'].includes(field_key)) {
          groupPatient.push(field_key);
        }
        if (['partner_first_name', 'partner_last_name', 'partner_dob', 'partner_ssn'].includes(field_key)) {
          groupPartner.push(field_key);
        }
        if (['address', 'phone', 'email'].includes(field_key)) {
          groupContact.push(field_key);
        }
      }
    }

    this.setState({
      ...this.state,
      invalidGroupPatient: groupPatient.length > 0,
      invalidGroupPartner: groupPartner.length > 0,
      invalidGroupContact: groupContact.length > 0
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.fields !== this.props.fields || props.errors !== this.props.errors) {
      this.updateGroupErrors(props.errors, props.fields);
    }
  }

  renderEthnicities() {
    const items = [];
    for (const key in ETHNICITIES) {
      items.push(<option value={key} key={'ethnicity-' + key}>{ETHNICITIES[key]}</option>);
    }
    return items;
  }

  render() {
    const {handleSubmit, pristine, submitting, invalid, initialValues, change, pgt_a, pgt_m, pgt_p, pgt_s} = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={8}>
              <h4>Patient</h4>
              <Row>
                <Col>
                  <Field
                    label={<span>First Name <span className="text-danger">*</span></span>}
                    name="first_name"
                    component={InputText}
                    type="text"
                    validate={[required]}
                  />

                  <Field
                    label={<span>Last Name <span className="text-danger">*</span></span>}
                    name="last_name"
                    component={InputText}
                    type="text"
                    validate={[required]}
                  />

                  <Field
                    label={<span>DOB <span className="text-danger">*</span></span>}
                    name="dob"
                    component={InputDateOfBirth}
                    type="text"
                    validate={[required]}
                  />

                  <Field
                    label={<span>Sex <span className="text-danger">*</span></span>}
                    name="sex"
                    component={InputSelect}
                    validate={[required]}
                  >
                    <option value="">Select</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </Field>

                  <Field
                    label="Ethnicity"
                    name="ethnicity"
                    component={InputSelect}
                  >
                    <option value={''}>Select</option>
                    {this.renderEthnicities()}
                  </Field>

                  <FormGroup>
                    <p>Family Disease History</p>
                    <DiseaseHistoryModal
                      initialData={initialValues ? initialValues.fdh : null}
                      onChange={(data) => {
                        change('fdh', JSON.stringify(data))
                      }}
                    />
                  </FormGroup>

                </Col>
              </Row>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: 40}}>
            <Col md={8}>
              <h4>Partner</h4>
              <Row>
                <Col>
                  <Field
                    label={<span>First Name <span className="text-danger">*</span></span>}
                    name="partner_first_name"
                    component={InputText}
                    type="text"
                    validate={[required]}
                  />

                  <Field
                    label={<span>Last Name <span className="text-danger">*</span></span>}
                    name="partner_last_name"
                    component={InputText}
                    type="text"
                    validate={[required]}
                  />

                  <Field
                    label={<span>DOB <span className="text-danger">*</span></span>}
                    name="partner_dob"
                    component={InputDateOfBirth}
                    type="text"
                    validate={[required]}
                  />

                  <Field
                    label={<span>Sex <span className="text-danger">*</span></span>}
                    name="partner_sex"
                    component={InputSelect}
                    validate={[required]}
                  >
                    <option value="">Select</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </Field>

                  <Field
                    label="Ethnicity"
                    name="partner_ethnicity"
                    component={InputSelect}
                  >
                    <option value="">Select</option>
                    {this.renderEthnicities()}
                  </Field>

                  <FormGroup>
                    <p>Family Disease History</p>
                    <DiseaseHistoryModal
                      initialData={initialValues ? initialValues.partner_fdh : null}
                      onChange={(data) => {
                        change('partner_fdh', JSON.stringify(data))
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr/>

          <h4>Contact</h4>
          <Row>
            <Col>
              <Field
                label={<span>Address <span className="text-danger">*</span></span>}
                name="address"
                component={InputText}
                type="text"
                validate={[required]}
                proportion={[2, 10]}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                label={<span>Phone 1 <span className="text-danger">*</span></span>}
                name="phone"
                component={InputText}
                type="text"
                validate={[required]}
                //mask="phone"
              />

              <Field
                label={<span>Email 1 <span className="text-danger">*</span></span>}
                name="email"
                component={InputText}
                type="text"
                validate={[required, email]}
              />
            </Col>
            <Col md={6}>
              <Field
                label="Phone 2"
                name="phone2"
                component={InputText}
                type="text"
                //mask="phone"
              />

              <Field
                label="Email 2"
                name="email2"
                component={InputText}
                type="text"
                validate={[email]}
              />
            </Col>
          </Row>

          <hr/>

          <h4>Test Type</h4>
          <Row>
            <Col>
              <Field name="pgt_a" id="pgt_a" component={InputCheckbox} type="checkbox" label="PGT-A" inline={true}/>
              <Field name="pgt_m" id="pgt_m" component={InputCheckbox} type="checkbox" label="PGT-M" inline={true}/>
              <Field name="pgt_p" id="pgt_p" component={InputCheckbox} type="checkbox" label="PGT-P" inline={true}/>
              <Field name="pgt_s" id="pgt_s" component={InputCheckbox} type="checkbox" label="PGT-SR" inline={true}/>
            </Col>
          </Row>

          <hr/>

          {
            pgt_p && (
              <div>
                <h4>Conditions</h4>
                <Row>
                  <Col>
                    <Field
                      label="Diseases"
                      name="diseases"
                      testType={{pgt_a, pgt_m, pgt_p, pgt_s}}
                      component={InputSelectDiseases}
                    />
                  </Col>
                </Row>

                <hr/>
              </div>
            )
          }
          <Row>
            <Col>
              <Field
                label="Notes"
                name="notes"
                component={InputTextArea}
                type="textarea"
              />
            </Col>
          </Row>

          <IfAuthGC>
            <Row>
              <Col>
                <Field
                  label="Internal Notes"
                  name="gc_notes"
                  component={InputTextArea}
                  type="textarea"
                />
              </Col>
            </Row>
          </IfAuthGC>

          <div className="btn-row">
            {
              !initialValues && (
                <div>
                  <Container>
                    <Row style={{padding: '0 15px 0 15px'}}>
                      <p className="text-muted">
                        By clicking the "Create" button, I confirm that I am ordering a PGT test for this patient,
                        referring this patient for testing by GPCL.
                      </p>
                      <div style={{marginTop: 10, marginBottom: 10}}>
                        <Field
                          label="Send Registration Email"
                          name="send_registration_email"
                          component={InputCheckbox}
                        />
                      </div>
                      <p className="text-muted" style={{paddingLeft: 20}}>
                        An email will be sent to the patient by GPCL,
                        enabling the patient to view and edit the information I have entered into this portal, to upload
                        medical records relevant to PGT, and to schedule an appointment with GPCL's Genetic Counselor.
                      </p>
                    </Row>
                  </Container>
                </div>
              )
            }

            <Container fluid>
              <Row className={'justify-content-center'}>
                <Col md="auto">
                  <Button
                    type="submit"
                    color="success"
                    disabled={submitting || pristine || invalid}
                    outline
                  >
                    {initialValues && initialValues.id ? 'Update' : 'Create'}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </form>
      </div>
    )
  }
}

const selector = formValueSelector('patientForm');

PatientForm = reduxForm({form: 'patientForm'})(PatientForm);

PatientForm = connect(
  state => ({
    errors: getFormSyncErrors('patientForm')(state),
    fields: getFormMeta('patientForm')(state),
    providers: state.provider.items,
    user: state.auth,
    pgt_a: selector(state, 'pgt_a'),
    pgt_m: selector(state, 'pgt_m'),
    pgt_p: selector(state, 'pgt_p'),
    pgt_s: selector(state, 'pgt_s'),
  })
)(PatientForm);

export default withRouter(PatientForm);
