import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Form from './Form';
import logo from 'images/lab-logo-light.svg';
import {Container} from "reactstrap";
import Success from './Success';
import './style/main.css';
import {submitRequest} from "actions/provider";


export default connect(
  state => ({
    submitted: state.provider.request_submitted,
    error: state.provider.request_submit_error
  }),
  {
    submitRequest: submitRequest
  }
)(
  class extends PureComponent {
    render() {
      return (
        <div className="request-page">
          <div className={'header'}>
            <img src={logo} className="logo" alt=""/>
            {!this.props.submitted && (
              <div className="text-center">
                <h1 className="text-center">Become a Provider</h1>
                <div style={{marginTop: 20}}>
                  <p>Already have account with us? <a href="/login" className="login-link">Log in</a></p>
                </div>
              </div>
            )}
          </div>

          {
            this.props.submitted ? (
              <Success/>
            ) : (
              <Container>
                <Form onSubmit={(data) => {
                  this.props.submitRequest(data);
                }}/>
              </Container>
            )
          }

          {
            this.props.error &&
              <p className="text-center text-danger">{this.props.error}</p>
          }
        </div>
      )
    }
  }
);