import React, {PureComponent} from 'react';

export default class extends PureComponent {
  render() {
    return (
      <div className="error">
        <h4>Request is invalid</h4>
        <p>{this.props.message}</p>
      </div>
    )
  }
}