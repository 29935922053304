import React , {useEffect, useState} from 'react';
import {Dialog, Classes, Intent, Button, HTMLSelect, Icon} from "@blueprintjs/core";


const OPTIONS_FIRST = {
  'patient': 'Patient',
  'partner': 'Partner',
  'patient_partner': 'Patient and Partner',
  'egg_donor': 'Egg Donor',
  'sperm_donor': 'Sperm Donor'
}

const OPTIONS = {
  'mother': 'Mother',
  'father': 'Father',
  'sister': 'Sister',
  'brother': 'Brother',
  'son': 'Son',
  'daughter': 'Daughter',
  'fetus': 'Fetus'
}

const ALL_OPTIONS = {
  ...OPTIONS_FIRST,
  ...OPTIONS
}

export default (props) => {
  const {value, onChange} = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState([]);
  const [items, setItems] = useState([]);
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    if (!!value) {
      const parts = value.split('-');
      setItems(parts);
      setDisplayValue(parts);
    }
  }, [value])

  useEffect(() => {
    setNewValue(items.join('-'))
  }, [items]);

  const addMember = () => {
    setItems([...items, ''])
  }

  return (
    <div>
      <div>
        <Button
          minimal
          onClick={() => setDialogIsOpen(true)}
        >
          {
            displayValue.length === 0 ? (
              <span>Not Selected</span>
            ) : (
              displayValue.map((item, i) => {
                return (
                  <span
                    key={'display_item_' + i}
                    //className={'text-capitalize'}
                  >
                    <span>{ALL_OPTIONS[item]}</span>
                    {
                      (i < (displayValue.length - 1)) && (
                        <Icon icon={'arrow-right'} style={{marginLeft: 3, marginRight: 3}} />
                      )
                    }
                  </span>
                )
              })
            )
          }
        </Button>
      </div>

      <Dialog
        icon="person"
        onClose={() => setDialogIsOpen(false)}
        title="Family Member"
        isOpen={dialogIsOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>
            {
              items.map((item, item_index) => {
                const options = item_index === 0 ? OPTIONS_FIRST : OPTIONS;

                return (
                  <div style={{marginBottom: 10}}>
                    <HTMLSelect
                      fill
                      value={items[item_index]}
                      onChange={(e) => {
                        const selected_value = e.target.value
                        const updated_items = items.slice();
                        updated_items[item_index] = selected_value;
                        setItems(updated_items);
                      }}
                    >
                      <option value={''}>Select...</option>
                      {
                        Object.keys(options).map((option_value) => {
                          const option_text = options[option_value];
                          return (
                            <option value={option_value}>{option_text}</option>
                          )
                        })
                      }
                    </HTMLSelect>
                  </div>
                )
              })
            }
          </div>

          <Button
            minimal
            icon={'plus'}
            onClick={() => addMember()}
          >
            Add Member
          </Button>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => {
              setDialogIsOpen(false);
              setItems([])}}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onChange(newValue);
                setDialogIsOpen(false);
              }}
              intent={Intent.PRIMARY}
              disabled={items.includes('') || (items[0] === 'patient_partner' && items.length === 1)}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
