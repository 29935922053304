import React, {PureComponent} from 'react';
//import ReactPlayer from 'react-player'
import classNames from 'classnames';
import './style.css';

// function get_cn_radius(inner_radius, outer_radius, copy_number) {
//   return inner_radius + copy_number / 4 * (outer_radius - inner_radius);
// };

const get_coordinates = (radius, degrees, offset) => {
  const radians = (degrees - 90) * (Math.PI / 180);
  const x = radius + radius * Math.cos(radians) + offset;
  const y = radius + radius * Math.sin(radians) + offset;
  return x + ',' + y;
};

class GPlot extends PureComponent {
  static get defaultProps() {
    return {
      sample: null,
      padding: 1.2,
      thickness: 4,
      size: 42,
      radius: 15.91549430918954,
      gap: 0.35,
      chr_lengths: [250, 240, 200, 190, 180, 170, 160, 145, 140, 135, 135, 130, 115, 110, 100, 90, 80, 80, 60, 60, 50, 50, 150, 60],
      chromosomes: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', 'X', 'Y'],
      timeLapseGif: null,
      video: null,
      disableHover: false,
      disableClick: false,
      onHover: () => {
        console.log('you need to use this callback')
      },
      onClick: () => {
        console.log('you need to use this callback')
      }
    }
  }

  constructor(props) {
    super(props);

    this.hoverVideo = this.hoverVideo.bind(this);
    this.setHoveredChromosome = this.setHoveredChromosome.bind(this);

    this.state = {
      videoHovered: false,
      hoveredChr: null
    }
  }

  hoverVideo(status) {
    this.setState({
      ...this.state,
      videoHovered: status
    })
  }

  setHoveredChromosome(chr) {
    this.setState({
      ...this.state,
      hoveredChromosome: chr
    })
  }

  render() {
    const total_length = this.props.chr_lengths.reduce((a, b) => a + b, 0);
    const size = this.props.size;
    const r = (total_length) / (2 * Math.PI);
    const sample = this.props.sample;

    const karyotype = this.props.karyotype ? this.props.karyotype :
      (sample.karyotype && sample.karyotype.display ? sample.karyotype.display : null);

    const parseKaryotype = (karyotype) => {
      const result = {
        abnormal: []
      };

      if(karyotype){
        const items = karyotype.split(',');

        items.forEach((item) => {
          if(item.indexOf('+') > -1){
            result.abnormal.push(item.replace('+',''))
          }
          if(item.indexOf('-') > -1){
            result.abnormal.push(item.replace('-',''))
          }
        });
      }

      return result;
    };

    const abnormal = parseKaryotype(karyotype).abnormal;

    return sample ? (
      <div
        className="gplot-container"
        onMouseEnter={() => this.hoverVideo(true)}
        onMouseLeave={() => this.hoverVideo(false)}
      >

        <svg
          width="100%"
          height="100%"
          viewBox={'0 0 ' + size + ' ' + size}
          className="gplot"
          xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        >

          {
            this.props.chr_lengths.map((chr, i) => {
              const chromosome = this.props.chromosomes[i];
              const prev_values = (i - 1) >= 0 ? this.props.chr_lengths.slice(0, i) : [];
              const prev_values_sum = prev_values.reduce((a, b) => a + b, 0);
              const gap_v = total_length * this.props.gap / 100;
              const outer_radius = size / 2 - this.props.padding;
              const inner_radius = outer_radius - this.props.thickness;
              const label_radius = outer_radius + 0.5;

              const deg_gap = (gap_v / r) * (180 / Math.PI);
              const deg_start = (prev_values_sum / r) * (180 / Math.PI);
              const deg_end = deg_start + (chr / r) * (180 / Math.PI) - deg_gap;

              const point1 = get_coordinates(outer_radius, deg_start, this.props.padding);
              const point2 = get_coordinates(outer_radius, deg_end, this.props.padding);
              const point3 = get_coordinates(inner_radius, deg_end, this.props.padding + this.props.thickness);
              const point4 = get_coordinates(inner_radius, deg_start, this.props.padding + this.props.thickness);

              const label_point1 = get_coordinates(label_radius, deg_start, this.props.padding + (outer_radius - label_radius));
              const label_point2 = get_coordinates(label_radius, deg_end, this.props.padding + (outer_radius - label_radius));

              const outer_arc = `A ${outer_radius} ${outer_radius} 0 0 1`;
              const inner_arc = `A ${inner_radius} ${inner_radius} 0 0 0`;
              const arc_d = `M ${point1} ${outer_arc} ${point2} L ${point3} ${inner_arc} ${point4} Z`;
              const label_arc = `A ${label_radius} ${label_radius} 0 0 1`;
              const label_arc_d = `M ${label_point1} ${label_arc} ${label_point2}`;

              const is_hovered = this.state.hoveredChromosome === chromosome;

              const chrClassNames = classNames('chr', {
                'hovered': is_hovered && !this.props.disableHover,
                'high-risk': abnormal && abnormal.indexOf(chromosome) >= 0,
                'empty': !karyotype
              });

              return (
                <g
                  key={i}
                  className={chrClassNames}
                  onMouseEnter={() => this.setHoveredChromosome(chromosome)}
                  onMouseLeave={() => this.setHoveredChromosome(null)}
                  onClick={() => {
                    if (!this.props.disableClick) {
                      this.props.onClick(chromosome)
                    }
                  }}
                >

                  <path d={arc_d} className={`bg ${is_hovered ? 'hovered' : ''}`}/>
                  <path d={label_arc_d} id={'text-arc-' + i} fill="transparent" strokeWidth="0"/>
                  {/*<path d={cn_arc_d} className="cn-line" fill="transparent" strokeWidth="0.15"/>*/}

                  <text fontSize="1.05" className="label" fill="#000000" textAnchor="middle">
                    <textPath
                      xlinkHref={'#text-arc-' + i}
                      startOffset="50%"
                    >
                      {chromosome}
                    </textPath>
                  </text>
                </g>
              )
            })
          }
        </svg>

        {
          this.props.video ? (
            <div
              className="timelapse"
            >
              {/*<ReactPlayer*/}
                {/*url={this.props.video}*/}
                {/*playing={this.state.videoHovered}*/}
                {/*width={270}*/}
                {/*height={270}*/}
                {/*muted*/}
                {/*loop*/}
              {/*/>*/}
            </div>
          ) : ''
        }
      </div>
    ) : (
      <p>Loading...</p>
    )
  }
}

export default GPlot;
