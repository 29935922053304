export const isAuthenticated = () => (window.localStorage.getItem('user') || null) !== null;

export const logout = () => window.localStorage.removeItem('user');

export const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));

export const user = auth ? JSON.parse(auth.auth) : {};

export const provider = JSON.parse(window.localStorage.getItem('provider'));

export const tempProvider = JSON.parse(window.localStorage.getItem('temp-provider'));

//export const getToken = () => user.token;
export const getToken = () => {
    const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
    const user = auth ? JSON.parse(auth.auth) : {};
    return user.token;
};

export const getProviderID = () => tempProvider ? tempProvider.id : (provider ? provider.id : null);

export const getCurrentUser = () => user ? user : null;

//export const getToken = () => '8507f6035f4e49d159d25d6ece45a8d76ad57399';
