import React from 'react';
import {Card} from "@blueprintjs/core";
import CaseList from "./CaseList";
import {Col, Row} from "reactstrap";
import Moment from 'moment';
import TimeAgo from "utils/TimeAgo";


export default (props) => {
  const {data} = props;
  const {first_name, last_name, dob, cases, created_at, email, phone, address} = data;

  return data ? (
    <div style={{marginTop: 20, marginBottom: 20}}>
      <Card>
        <Row>
          <Col>
            <h6 data-dd-action-name="patient_name">{first_name} {last_name}</h6>
          </Col>
          <Col md="auto">
            <TimeAgo value={created_at}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <p data-dd-action-name="patient_dob">DOB: {Moment(dob).format('DD/MM/YYYY')}</p>
            <p data-dd-action-name="patient_email">Email: {email}</p>
            <p data-dd-action-name="patient_phone">Phone: {phone}</p>
            <p data-dd-action-name="patient_address">Address: {
              address ? (
                <span>{`${address.address_1} ${address.address_2}, ${address.city}, ${address.state}, ${address.zip}`}</span>
              ) : ''
            }</p>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <p>Cases</p>
          <div style={{marginTop: 12}}>
            {
              (cases && cases.length) ? (
                <CaseList data={cases}/>
              ) : (
                'No cases'
              )
            }
          </div>
        </div>
      </Card>
    </div>
  ) : null
}
