import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getPatientList} from "actions/patient";

export default connect(
  state => ({
    items: state.patient.items,
  }),
  {
    getList: getPatientList,
  }
)(
  class extends PureComponent {
    componentDidMount() {
      if (!this.props.items) {
        this.props.getList();
      }
    }

    render() {
      const {items, disabled} = this.props;

      return items ? (
        <div>
          {
            items.length > 0 ? (
              <select
                onChange={(e) => this.props.onChange(parseInt(e.target.value))}
                value={this.props.value || ''}
                disabled={disabled}
                id="select-provider"
                className="form-control"
              >
                <option
                  key={'select-patient-null'}
                  value={''}
                >
                  Select...
                </option>
                {
                  items.map((item) =>
                    <option
                      key={'select-patient-' + item.id}
                      value={item.id}
                    >
                      {item.first_name} {item.last_name}, {item.partner_first_name} {item.partner_last_name}
                    </option>
                  )
                }
              </select>
            ) : ''
          }
        </div>
      ) : ''
    }
  }
);