/**
 * Placeholder for table views
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.css';


class TablePlaceholder extends Component {
  static defaultProps = {
    ...Component.defaultProps,
    rowHeight: 60,
    margin: 4
  };

  render() {
    const rows = Array(48).fill().map((_, i) => {
      return <div
        key={`placeholder-row-${i}`}
        className="row bg-animated"
        style={
          {
            height: this.props.rowHeight,
            marginTop: this.props.margin,
            marginBottom: this.props.margin,
          }
        }/>;
    });

    return (
      <div className="placeholder placeholder-table">
        {rows}
      </div>
    )
  }
}

TablePlaceholder.propTypes = {
  rowHeight: PropTypes.number,
  margin: PropTypes.number
};

export default TablePlaceholder;