import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {deletePatientFile, submitPatientFile} from "actions/patient";
import ImageSelect from "utils/ImageSelect";


export default connect(
  null,
  {
    submitPatientFile: submitPatientFile,
    deletePatientFile: deletePatientFile
  }
)(
  class extends PureComponent {
    render() {
      const {patient} = this.props;

      return (
        <ImageSelect
          value={patient.files}
          onFileUpdate={(data, old_file) => this.props.submitPatientFile(patient.id, data, old_file)}
          onFileDelete={(url) => this.props.deletePatientFile(patient.id, url)}
        />
      )
    }
  }
)