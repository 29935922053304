import React, {useState} from 'react';
import './style/mobile_menu.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IfAuthDistributor, IfAuthSuperAdminOrReadonly, Link} from "components/RBAC";
import classNames from "classnames";


export default (props) => {
  const pathname = '';
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mobile-menu">
      <div className="switch" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? 'times' : 'bars' } />
      </div>


      <div className={classNames('menu', {open: isOpen})}>
        <ul className="nav">

          <IfAuthDistributor>
            <li className="nav-item">
              <Link
                to={'/clinics'}
                className={classNames('nav-link', {
                  active: ['/clinics', '/clinics/:id'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="building" fixedWidth className="sidebar-icon"/>{' '}Clinics
              </Link>
            </li>
          </IfAuthDistributor>

          <li className="nav-item">
            <Link
              to={'/providers'}
              className={classNames('nav-link', {
                active: ['/providers'].includes(pathname)
              })}
            >
              <FontAwesomeIcon icon="user-md" fixedWidth className="sidebar-icon"/>{' '}
              Providers
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to={'/nurses'}
              className={classNames('nav-link', {
                active: ['/nurses'].includes(pathname)
              })}
            >
              <FontAwesomeIcon icon="user-md" fixedWidth className="sidebar-icon"/>{' '}
              Nurses
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to={'/patients'}
              className={classNames('nav-link', {
                active: ['/patients', '/patients/:id'].includes(pathname)
              })}
            >
              <FontAwesomeIcon icon="users" fixedWidth className="sidebar-icon"/>{' '}Cases
            </Link>
          </li>

          {/*<li className="nav-item">*/}
          {/*<Link*/}
          {/*to={'/cases'}*/}
          {/*className={classNames('nav-link', {*/}
          {/*active: ['/', '/cases', '/cases/:id'].includes(pathname)*/}
          {/*})}*/}
          {/*>*/}
          {/*<FontAwesomeIcon icon="microscope" fixedWidth className="sidebar-icon"/>{' '}Cases*/}
          {/*</Link>*/}
          {/*</li>*/}

          {/*<li className="nav-item">*/}
          {/*<Link*/}
          {/*to={'/saliva-samples'}*/}
          {/*className={classNames('nav-link', {*/}
          {/*active: ['/saliva-samples', '/saliva-samples/:id'].includes(pathname)*/}
          {/*})}*/}
          {/*>*/}
          {/*<FontAwesomeIcon icon="vial" fixedWidth className="sidebar-icon"/>{' '}Saliva Samples*/}
          {/*</Link>*/}
          {/*</li>*/}


          <li className="nav-item">
            <Link
              to={'/kits'}
              className={classNames('nav-link', {
                active: ['/kits'].includes(pathname)
              })}
            >
              <FontAwesomeIcon icon="boxes" fixedWidth className="sidebar-icon"/>{' '}
              Kits
            </Link>
          </li>

          <IfAuthSuperAdminOrReadonly>
            <li className="nav-item">
              <Link
                to={'/action-log'}
                className={classNames('nav-link', {
                  active: ['/action-log'].includes(pathname)
                })}
              >
                <FontAwesomeIcon icon="list" fixedWidth className="sidebar-icon"/>{' '}
                Action Log
              </Link>
            </li>
          </IfAuthSuperAdminOrReadonly>
        </ul>
      </div>
    </div>
  )
}
