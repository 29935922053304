import React from 'react';
import SelectPerson from "components/Patient/Form/SelectPerson";


export default props => {
    const {handleResponse, submit} = props;

    return (
        <div>
            <SelectPerson
                onSelect={person => {
                    submit(person).then(handleResponse);
                }}
                isPatient={props.patient}
                caseId={props.case_id}
            />
        </div>
    )
}
