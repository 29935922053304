import storage from 'redux-persist/es/storage'
import {applyMiddleware, compose, createStore} from 'redux'
import {apiMiddleware} from "redux-api-middleware";
import {createFilter} from 'redux-persist-transform-filter';
import {persistReducer, persistStore} from "redux-persist";
import rootReducer from 'reducers';
import {RBACApiMiddleware} from "middleware";

const persistedFilter = createFilter('auth',
  [
    'token',
    'email',
    'first_name',
    'last_name',
    'company_name',
    'company_address',
    'created_at',
    'last_login',
    'id',
    'role',
    'm2_enabled',
    'can_report',
    'permissions',
    'username'
  ]
);

const persistConfig = {
  key: 'clinic-portal',
  storage: storage,
  whitelist: ['auth'],
  transforms: [persistedFilter]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        RBACApiMiddleware,
        apiMiddleware
      )
    )
  );

  const persistor = persistStore(store);
  return {store, persistor};
}
