import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardFooter, CardTitle, Col, Row} from "reactstrap";
import {cardModalOpen} from "actions/modal";
import CardBrand from "utils/CardBrand";
import {deleteCard} from "actions/card";


export default connect(
  null,
  {
    cardModalOpen: cardModalOpen,
    deleteCard: deleteCard
  }
)(
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.deleteCard = this.deleteCard.bind(this);
    }

    deleteCard(id) {
      const result = window.confirm("This card entry will be removed, and you'll be able to add a new one. Proceed?");
      if (result) {
        this.props.deleteCard(id);
      }
    }

    render() {
      const {card, patient} = this.props;

      return (
        <Card>
          <CardBody>
            <CardTitle>
              <span>Payment</span>
            </CardTitle>
            {
              card ? (
                <div>
                  Card: <CardBrand brand={card.brand}/> ending in {card.last4}<br/>
                  Expires: {card.expiry}
                </div>
              ) : (
                <Button
                  color="success"
                  outline
                  onClick={() => this.props.cardModalOpen({patient: patient.id})}
                >
                  <i className="ion ion-md-add"/>{' '}
                  Add Card
                </Button>
              )
            }
          </CardBody>

          {
            card &&
            <CardFooter>
              <Row className="align-items-center">
                <Col>
                  Wrong card?{' '}
                  <Button
                    color="light"
                    onClick={() => this.deleteCard(card.id)}
                    size="sm"
                  >
                    Correct
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          }

        </Card>
      )
    }
  }
)
