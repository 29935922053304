export const ALERT_ADD = '@@alert/ADD';
export const ALERT_REMOVE= '@@alert/REMOVE';

export const addAlert = ({type, message}) => ({
  type: ALERT_ADD,
  alert_type: type,
  message
});

export const removeAlert = (index) => ({
  type: ALERT_REMOVE,
  index: index
});