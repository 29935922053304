import React, {PureComponent} from 'react';
import {Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';


class InputText extends PureComponent {
  static defaultProps = {
    proportion: [4, 8],
    mask: null
  };

  getMask(){
    switch(this.props.mask){
      case 'phone':
        return '+1 (999) 999 9999';
      case 'ssn':
        return '999 - 99 - 9999';
      default:
        return null
    }
  }

  render() {
    const {input, label, meta: {touched, error}, type, proportion, placeholder, mask} = this.props;

    return (
      <FormGroup row className="align-items-center">
        {label ? (<Label md={proportion[0]} htmlFor={input.name} className={touched && error ? 'text-danger' : ''}>{label}</Label>) : ''}

        <Col md={label ? proportion[1] : 12}>
          <Input
            {...input}
            type={type ? type : 'text'}
            value={input.value !== 'null' ? input.value : ''}
            placeholder={placeholder ? placeholder : ''}
            invalid={touched && !!error}
            mask={this.getMask()}
            // maskChar={' '}
            tag={mask ? InputMask : ''}
          />

          {touched && error && <FormFeedback>{error}</FormFeedback>}
        </Col>
      </FormGroup>
    )
  }
}

InputText.propTypes = {
  proportion: PropTypes.arrayOf(PropTypes.number),
  mask: PropTypes.oneOf(['phone', 'ssn']),
};

export default InputText;