import React, {PureComponent} from 'react';
import {Col, FormGroup, Input, Label} from "reactstrap";


class InputTextArea extends PureComponent {
  static defaultProps = {
    inline: false
  };

  render() {
    const {input, label, meta: {touched, error}} = this.props;

    return (
      <FormGroup row className="align-items-center">
        {
          label ? (
            <Label md={this.props.inline ? 4 : 12} htmlFor={input.name}>{label}</Label>
          ) : ''
        }
        <Col md={this.props.inline ? 8 : 12}>
          <Input {...input} type="textarea" value={input.value !== 'null' ? input.value : ''}/>
          {touched && error && <span className="error">{error}</span>}
        </Col>
      </FormGroup>
    )
  }
}

export default InputTextArea;