import React, {PureComponent} from 'react';
import {Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row} from "reactstrap";
import FileUpload from "components/Patient/FileUpload";
import Moment from 'moment/moment';


export default class extends PureComponent {
  constructor(props) {
    super(props);
    this.onCheck = this.onCheck.bind(this);
    this.onGCTimeSelect = this.onGCTimeSelect.bind(this);

    this.state = {
      checked: props.value,
      selectedGCTime: null
    }
  }

  onGCTimeSelect(value){
    this.setState({
      ...this.state,
      selectedGCTime: value
    }, () => {
      this.props.onGCDateTimeChange(value);
    })
  }

  onCheck() {
    this.setState({
      ...this.state,
      checked: !this.state.checked
    }, () => {
      this.props.onChange(this.state.checked);
    });
  }

  render() {
    const {patient} = this.props;

    const slots = {};

    for (const slot of patient.free_busy) {
      const start = Moment(slot[0]);
      const end = Moment(slot[1]);
      const day = start.format('MMMM DD');

      if (!(day in slots)) {
        slots[day] = [];
      }

      slots[day].push([
        start.format('h:mma'),
        end.format('h:mma'),
        JSON.stringify([slot[0], slot[1]])
      ]);
    }

    return (
      <Card>
        <CardBody>
          <CardTitle>Genetic Counseling</CardTitle>

          <Row>
            <Col>
              <p className="text-muted">
                A genetic counselor is a healthcare professional who has received graduate education and training in
                medical genetics and counseling. The GPCL genetic counselor will review the benefits and limitations of
                the PGT studies recommended by your physician and answer your questions about these studies. This
                consultation with a Genetic Counselor is included in your case fee with Genomic Prediction. Genetic
                counseling is required for cases of PGT-M, PGT-SR, and PGT-P.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.checked}
                    onChange={() => this.onCheck()}
                  />{' '}
                  I need Genetic Counseling
                </Label>
              </FormGroup>
            </Col>
          </Row>

          {
            this.state.checked && (
              <div>
                <Row style={{marginTop: 30}}>
                  <Col md={12}>
                    {
                      Object.keys(slots).length && (
                        <div>
                          {
                            Object.keys(slots).map((day, i) => {
                              return (
                                <div key={'slot-day-' + i} style={{marginBottom: 20}}>
                                  <h5>{day}</h5>
                                  <div style={{marginTop: 12}}>
                                    {
                                      slots[day].map((slot, j) => (
                                        <Button
                                          color={'info'}
                                          active={slot[2] === this.state.selectedGCTime}
                                          outline={true}
                                          key={'slot-day-'+ i + '-slot-' + j}
                                          style={{marginRight: 6, marginBottom: 6}}
                                          onClick={() => {
                                            this.onGCTimeSelect(slot[2])
                                          }}
                                        >
                                          {slot[0] + ' - ' + slot[1]}
                                        </Button>
                                      ))
                                    }
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    }
                    {/*<FormGroup row className="align-items-center">*/}
                      {/*<Label md={4} htmlFor="gc_date_time">Select date and time convenient for you</Label>*/}
                      {/*<Col md={8}>*/}
                        {/*<Datetime*/}
                          {/*inputProps={{className: 'form-control'}}*/}
                          {/*timeConstraints={{minutes: {step: 15}}}*/}
                          {/*utc={true}*/}
                          {/*onChange={(val) => this.props.onGCDateTimeChange(Moment(val).format('YYYY-MM-DD hh:mm:ss'))}*/}
                        {/*/>*/}
                      {/*</Col>*/}
                    {/*</FormGroup>*/}
                  </Col>
                </Row>

                <Row style={{marginTop: 20}}>
                  <Col>
                    <p className="text-muted">
                      In order to assist your Genetic Counselor, please upload any genetic test results, hospital
                      records, and other information relevant to the health history of yourself, or of relevant family
                      members. By uploading files, you consent to having these files viewed by your clinician, and by
                      GPCL's genetic counselors and scientific personnel.
                    </p>
                  </Col>
                </Row>

                <Row style={{marginTop: 30}}>
                  <Col>
                    <FileUpload patient={patient}/>
                  </Col>
                </Row>
              </div>
            )
          }

        </CardBody>
      </Card>
    )
  }
}
