import {createAction} from "redux-api-middleware";
import {withAuth} from "../reducers";

export const ROLES_CREATE_REQUEST = '@@roles/ROLES_CREATE_REQUEST';
export const ROLES_CREATE_SUCCESS = '@@roles/ROLES_CREATE_SUCCESS';
export const ROLES_CREATE_FAILURE = '@@roles/ROLES_CREATE_FAILURE';

export const ROLES_UPDATE_REQUEST = '@@roles/ROLES_UPDATE_REQUEST';
export const ROLES_UPDATE_SUCCESS = '@@roles/ROLES_UPDATE_SUCCESS';
export const ROLES_UPDATE_FAILURE = '@@roles/ROLES_UPDATE_FAILURE';

export const ROLES_DELETE_REQUEST = '@@roles/ROLES_DELETE_REQUEST';
export const ROLES_DELETE_SUCCESS = '@@roles/ROLES_DELETE_SUCCESS';
export const ROLES_DELETE_FAILURE = '@@roles/ROLES_DELETE_FAILURE';

export const submitRole = (data) => {
  const url = `role/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PATCH' : 'POST';

  return createAction({
    endpoint: url,
    method: method,
    headers: withAuth({'Content-Type': 'application/json'}),
    types: data.id ? [
        ROLES_UPDATE_REQUEST,
        ROLES_UPDATE_SUCCESS,
        ROLES_UPDATE_FAILURE
      ] :
      [
        ROLES_CREATE_REQUEST,
        ROLES_CREATE_SUCCESS,
        ROLES_CREATE_FAILURE
      ],
    body: JSON.stringify(data)
  })
};

export const deleteRole = (data) => {
  const url = `role/${data.id + '/'}`;
  const method = 'DELETE';

  return createAction({
    endpoint: url,
    method: method,
    headers: withAuth({'Content-Type': 'application/json'}),
    types: [
      ROLES_DELETE_REQUEST,
      ROLES_DELETE_SUCCESS,
      ROLES_DELETE_FAILURE
    ]
  })
}