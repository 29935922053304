import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import '../CaseStatus/style.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import pdf_thumb from './images/blank_pdf.svg';
import docx_thumb from './images/blank_docx.svg';
import image_thumb from './images/image.svg';
import ovation_logo from './images/ovation_logo_small.png';
import {IfAuthSuper} from "components/RBAC";
import axios from "axios";
import {getToken} from "../../auth/token";
// import classNames from "classnames";


class FinalReportSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: '',
      files: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value && this.props.value) {
      this.updateFiles(this.props.value);
    }
  }

  componentDidMount() {
    this.updateFiles(this.props.value);
  }

  updateFiles(value) {
    if (value) {
      this.setState({
        ...this.state,
        files: value
      });
    }
  }

  gotoSignedUrl(file_name) {
    const token = getToken();
    axios.post('/api/get-uploaded-files-presigned-url', {
      file_name
    }, {
      headers: {
        "Authorization": `Token ${token}`
      },
    }).then((res) => {
      window.open(res.data.presigned_url, '_blank')
    })
  }

  sendToOvation(orderId, file) {
    const { file_name, url } = file
    const token = getToken();
    axios.post('/api/send-file-ovation/', {
      orderId,
      file_name,
      url
    }, {
      headers: {
        "Authorization": `Token ${token}`
      },
    }).then((res) => {
      if (res.status === 200) {
        alert(`${file_name}\nsent to Ovation successfully`);
      } else {
        alert(`Could not send to Ovation:\n${file_name}\n\nstatus ${res.status}: ${res.message}`);
        console.log('res', res);
      }
    }).catch((reason) => {
        alert(`Could not send to Ovation:\n${file_name}\n\n${reason}`);
        console.log('reason', reason);
      }
    )
  }

  handleChange(e, url = null) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        }, () => {
          const file_name = (file.name).toLowerCase().split('.').slice(0, -1).join('.');
          this.props.onFileUpdate(reader.result, file_name, url);
        });
      };

      reader.readAsDataURL(file);
    }
  }

  handleDelete(e, file) {
    e.preventDefault();
    const result = window.confirm("Are you sure?");
    if (result) {
      if (file.id === undefined) {
        delete file.id
      }
      this.props.onFileDelete(file);
    }
  }

  render() {
    return (
        <div>
          {
            this.state.files.map((file, i) => {
                  const { datetime, ext, file_name, url } = file
                  let bg_url = null;

                  if (ext === 'pdf') {
                    bg_url = pdf_thumb;
                  } else if (ext === 'doc' || ext === 'docx') {
                    bg_url = docx_thumb;
                  } else {
                    bg_url = image_thumb;
                  }

                  return (
                      <Row
                          key={'file-item-' + url}
                          className="justify-content-between align-items-center file-row"
                          onClick={(e) => {
                            this.gotoSignedUrl(url)
                          }}
                      >
                        <Col md="auto">
                          <img
                              src={bg_url}
                              alt=""
                              style={{width: 40}}
                          />
                        </Col>

                        <Col>
                          {file_name}
                        </Col>

                        <Col>
                          {datetime}
                        </Col>

                        <IfAuthSuper>
                          {this.props.ovation &&
                            <Col md="auto">
                                <Button title="Send Report to Ovation" onClick={(e) => {
                                  e.stopPropagation();
                                  this.sendToOvation(this.props.orderId, file);
                                }}
                                style={{'backgroundColor': 'white', 'color': 'black', 'border': '0'}}>
                                  <img
                                      src={ovation_logo}
                                      alt="Ovation"
                                  />
                                  <span style={{'marginLeft': '3px'}}>Send</span>
                                </Button>
                            </Col>
                          }
                        </IfAuthSuper>

                        <Col md="auto"
                            //  className={
                            //    classNames(
                            //        'd-flex justify-content-between', {
                            //          'w-25': this.props.sendEmails
                            //        }
                            //    )
                            //  }
                        >
                          <IfAuthSuper>
                            {/* {
                              this.props.sendEmails && (
                                  <Button color="secondary" onClick={e => {
                                    e.stopPropagation();
                                    this.props.onModalOpen(id)}
                                  }>
                                    Send emails
                                  </Button>
                              )
                            } */}
                            <Button color="link" size="sm" onClick={e => {
                              e.stopPropagation();
                              this.handleDelete(e, file)
                            }}>
                            <span className="text-danger">
                              <FontAwesomeIcon icon="trash-alt"/>
                            </span>
                            </Button>
                          </IfAuthSuper>
                        </Col>
                      </Row>
                  )
                }
            )
          }
          <IfAuthSuper>
            {
              !this.props.hold && <Row style={{marginTop: 40}}>
                                    <Col className="text-center">
                                      <label className="file-controls">
                                        <input
                                            className="fileInput"
                                            type="file"
                                            onChange={(e) => this.handleChange(e)}
                                            style={{display: 'none'}}
                                        />
                                        <span className="btn btn-primary btn-sm">
                                    <FontAwesomeIcon icon="cloud-upload-alt"/> Upload File
                                  </span>
                                      </label>
                                    </Col>
                                  </Row>
            }
          </IfAuthSuper>
        </div>
    )
  }
}

export default FinalReportSelect;
