import {RSAA} from 'redux-api-middleware';
import {withAuth} from "reducers";

export const SAMPLE_BATCH_CREATE_REQUEST = '@@sample_batch/SAMPLE_BATCH_CREATE_REQUEST';
export const SAMPLE_BATCH_CREATE_SUCCESS = '@@sample_batch/SAMPLE_BATCH_CREATE_SUCCESS';
export const SAMPLE_BATCH_CREATE_FAILURE = '@@sample_batch/SAMPLE_BATCH_CREATE_FAILURE';

export const SAMPLE_BATCH_UPDATE_REQUEST = '@@sample_batch/SAMPLE_BATCH_UPDATE_REQUEST';
export const SAMPLE_BATCH_UPDATE_SUCCESS = '@@sample_batch/SAMPLE_BATCH_UPDATE_SUCCESS';
export const SAMPLE_BATCH_UPDATE_FAILURE = '@@sample_batch/SAMPLE_BATCH_UPDATE_FAILURE';

export const SAMPLE_BATCH_DELETE_REQUEST = '@@sample_batch/SAMPLE_BATCH_DELETE_REQUEST';
export const SAMPLE_BATCH_DELETE_SUCCESS = '@@sample_batch/SAMPLE_BATCH_DELETE_SUCCESS';
export const SAMPLE_BATCH_DELETE_FAILURE = '@@sample_batch/SAMPLE_BATCH_DELETE_FAILURE';


export const submitSampleBatch = (data) => {
  const form_data = new FormData();

  for (const prop in data) {
    if (data.hasOwnProperty(prop) && data[prop]) {
      form_data.append(prop, data[prop]);
    }
  }

  const url = `sample-batches/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  return ({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({}),
      types: data.id ? [SAMPLE_BATCH_UPDATE_REQUEST, SAMPLE_BATCH_UPDATE_SUCCESS, SAMPLE_BATCH_UPDATE_FAILURE] :
        [SAMPLE_BATCH_CREATE_REQUEST, SAMPLE_BATCH_CREATE_SUCCESS, SAMPLE_BATCH_CREATE_FAILURE],
      body: form_data
    }
  })
};

export const deleteSampleBatch = (id, index) => {
  return ({
    [RSAA]: {
      endpoint: 'sample-batches/' + id + '/',
      method: 'DELETE',
      headers: withAuth({}),
      types: [
        SAMPLE_BATCH_DELETE_REQUEST,
        {
          type: SAMPLE_BATCH_DELETE_SUCCESS,
          payload: {},
          meta: {
            id: id,
            index: index
          }
        },
        SAMPLE_BATCH_DELETE_FAILURE
      ]
    }
  })
};