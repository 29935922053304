import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getProviderList, openModal} from "actions/provider";
import {Page, PageContent, PageHeader} from 'components/Views';
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProviderModal from "./Modal";
import {
  IfAuthSuper,
  IfAuthSuperAdminOrReadonly,
  IfNotAuthSuperReadonly,
  isAuthSuperAdminOrReadonly,
  Link
} from "components/RBAC";
import ListCard from './ListCard';

const STANDALONE = 'standalone_with_no_distributor';
const SIX_MONTHS_MS = 31 * 24 * 60 * 60 * 1000;

function List(props) {
  const [hideInactive, setHideInactive] = useState(true);
  // items grouped by distributor.  distributors[STANDALONE] are those without a distributor.
  const [distributors, setDistributors] = useState({});
  const {items} = props;

  const shouldHideThisProvider = (item) => {
    if (!hideInactive) return false;  // Don't hide.  "hideInactive" checkbox is unchecked.
    if (!item.latest_case_created || !item.latest_case_created.value) return true;  // Hide.  Provider has no cases.
    return (Date.now() - Date.parse(item.latest_case_created.value) > SIX_MONTHS_MS);  // Hide if provider's latest case created over 6 months ago
  }

  useEffect(() => {
    setDistributors({});
    props.getProviderList();
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps

  // Group items by distributor.  Filter out inactive items is box checked.
  useEffect( () => {
    if (isAuthSuperAdminOrReadonly(props.user) && items) {  // items empty until loading by getProviderList finishes
      const distribs = {};
      for (const item of items) {
        if (!shouldHideThisProvider(item)) {
          const name = item.distributor_data ? item.distributor_data.name : STANDALONE;
          if (name in distribs) {
            distribs[name].push(item);
          } else {
            distribs[name] = [item];
          }
        }
      }
      setDistributors(distribs);
    }
  }, [items, hideInactive]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page>
      <PageHeader>
        <Row className="align-items-center justify-content-between">
          <Col>
            <h3 className="page-title">Clinics</h3>
          </Col>
          <IfAuthSuper>
            <Col>
              <Link to={'/map'}>Show Map</Link>
            </Col>
          </IfAuthSuper>
          <Col md="auto">
            <IfNotAuthSuperReadonly>
              <Button
                color="light"
                onClick={() => {
                  props.openModal();
                }}
              >
                <FontAwesomeIcon icon="plus"/>{' '}Create
              </Button>
            </IfNotAuthSuperReadonly>
          </Col>
        </Row>
        <Row>
          <IfAuthSuperAdminOrReadonly>
          <Col>
            &nbsp;&nbsp;
            <input type="checkbox" checked={hideInactive} onChange={() => setHideInactive(!hideInactive)}/>
            &nbsp;Hide inactive clinics
          </Col>
          </IfAuthSuperAdminOrReadonly>
        </Row>
      </PageHeader>

      <PageContent>
        <ProviderModal/>
        {
          items ? (
            <div>
              {
                isAuthSuperAdminOrReadonly(props.user) ? (
                  <div>
                    {
                      // Present all items grouped by distributor (except STANDALONE items with none)
                      Object.keys(distributors).filter((name) => name !== STANDALONE).map((name) => (
                        <div key={name}>
                          <div style={{marginTop: 20, marginBottom: 40}}>
                            <h5 style={{marginBottom: 20}}>{name}</h5>
                            <ListCard items={distributors[name]}/>
                          </div>
                        </div>
                      ))
                    }

                    {
                      (STANDALONE in distributors) && (
                        <div>
                          <div style={{marginTop: 20, marginBottom: 40}}>
                            <h5 style={{marginBottom: 20}}>Standalone</h5>
                            <ListCard items={distributors[STANDALONE]}/>
                          </div>
                        </div>
                      )
                    }
                  </div>
                ) : (
                  <div>
                    <ListCard items={items}/>
                  </div>
                )
              }
            </div>
          ) : 'Loading...'
        }
      </PageContent>
    </Page>
  )
}


export default connect(
  // mapStateToProps
  state => ({
    items: state.provider.items,
    selected_provider_id: state.common.selected_provider_id,
    user: state.auth
  }),
  // mapDispatchToProps
  {
    getProviderList: getProviderList,
    openModal: openModal
  }) (List);

