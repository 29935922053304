import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import './style.css';
import LoadingIndicator from 'components/LoadingIndicator';


class LoadingOverlay extends PureComponent{
  render(){
    return this.props.loading ? (
      <div className="loading-overlay">
        <LoadingIndicator/>
        <h4>Please wait...</h4>
      </div>
    ) : '';
  }
}

export default connect(
  state => ({
    loading: state.common.loading
  })
)(LoadingOverlay);
