import React from 'react';

export default (props) => {
  const {items, cycle} = props;

  const TISSUE_TYPES = {
    'trophectoderm': 'Trophectoderm',
    'blastomere': 'Blastomere',
    'polar_body': 'Polar Body',
    'bgnc': 'BG/NC',
    'arrested': 'Whole Embryo',
    'fetal_tissue': 'Fetal Tissue'
  };

  return (
    <div className="card-table">
      <table>
        <thead>
        <tr>
          <th>Embryo Number</th>
          <th>Embryo ID</th>
          <th>Tube Label</th>
          <th>Tissue Type</th>
          <th>Embryo Grade</th>
          <th>Biopsy Day</th>
          <th>Cycle</th>
          <th>Biopsy By</th>
          <th>Loading By</th>
          <th>Witness By</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {
          items.map((item, i) => (
            <tr key={'ready-sample-' + i}>
              <td>{item.embryo_number}</td>
              <td>{item.embryo_id}</td>
              <td>{item.tube_label}</td>
              <td>{item.tissue_type in TISSUE_TYPES ? TISSUE_TYPES[item.tissue_type] : item.tissue_type}</td>
              <td>{item.embryo_grade}</td>
              <td>{item.biopsy_day}</td>
              <td>{cycle}</td>
              <td>{item.biopsy_clinician}</td>
              <td>{item.loading_clinician}</td>
              <td>{item.witness_clinician}</td>
              <td/>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  )
}
