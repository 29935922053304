import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {deleteKit, list} from "actions/provider_kit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TablePlaceholder from 'components/Placeholders/TablePlaceholder';
import {CardTable, Page, TableCellWithLink, TableRowWithLink} from 'components/Views';
import TimeCreated from "utils/TimeCreated";
import {kitModalOpen} from "actions/modal";
import ReactPaginate from 'react-paginate';
import URI from 'urijs';
import {
  IfAuthSuper2, IfAuthSuperReadonly,
  IfNotAuthSuperReadonly,
  isAuthDistributor,
  isAuthSuper,
  isAuthSuperAdminOrReadonly
} from "components/RBAC";
import KitModal from "components/Kit/Modal";


export default connect(
  state => ({
    user: state.auth,
    items: state.providerKit.items,
    items_total: state.providerKit.items_total,
    pages: state.providerKit.pages,
    page_size: state.providerKit.page_size,
    selected_provider_id: state.common.selected_provider_id,
    providers: state.provider.items
  }),
  {
    getList: list,
    deleteKit: deleteKit,
    kitModalOpen: kitModalOpen,
  }
)(
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.updateState = this.updateState.bind(this);
      this.loadItems = this.loadItems.bind(this);
      

      this.state = {
        current_page: null
      }
    }


    componentDidMount() {
      this.updateState();
    }

    updateState() {
      const search = this.props.route.location.search;
      const url = new URI(search);
      const current_page = url.p ? parseInt(url.p) : 1;

      this.setState({
        ...this.state,
        current_page: current_page
      }, () => {
        this.loadItems();
      });
    }

    loadItems(page = null, filter) {
      const search = this.props.route.location.search;
      let url = new URI(search);
      const params = url.search(true);

      const {p: url_page, ...other_url_params} = params;

      const data = {page: page ? page : this.state.current_page, ...other_url_params};

      this.props.getList(data);
    }

    render() {
      const {items} = this.props;

      return (
        <Page>
          <div className="page-header">
            <Row className="align-items-center justify-content-between">
              <Col>
                <h3 className="page-title">Kits</h3>
              </Col>
              <Col md="auto">
                <IfNotAuthSuperReadonly>
                  <Button
                    color="light"
                    onClick={() => {
                      if (isAuthSuperAdminOrReadonly(this.props.user)) {
                        const provider = window.localStorage.getItem('provider');
                        if (provider) {
                          this.props.kitModalOpen(0)
                        } else {
                          alert('Please select clinic first');
                        }
                      } else {
                        if (isAuthDistributor(this.props.user) && this.props.providers && this.props.providers.length <= 0) {
                          alert('Create clinic first')
                        } else {
                          this.props.kitModalOpen(0)
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon icon="plus"/>{' '}Test Run Kits
                  </Button>
                </IfNotAuthSuperReadonly>
              </Col>
              <Col md="auto">
                <IfNotAuthSuperReadonly>
                  <Button
                    color="light"
                    onClick={() => {
                      if (isAuthSuper(this.props.user)) {
                        const provider = window.localStorage.getItem('provider');
                        if (provider) {
                          this.props.kitModalOpen(1)
                        } else {
                          alert('Please select clinic first');
                        }
                      } else {
                        if (isAuthDistributor(this.props.user) && this.props.providers && this.props.providers.length <= 0) {
                          alert('Create clinic first')
                        } else {
                          this.props.kitModalOpen(1)
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon icon="plus"/>{' '}Saliva Kits
                  </Button>
                </IfNotAuthSuperReadonly>
              </Col>
              <Col md="auto">
                <IfNotAuthSuperReadonly>
                  <Button
                    color="light"
                    onClick={() => {
                      if (isAuthSuper(this.props.user)) {
                        const provider = window.localStorage.getItem('provider');
                        if (provider) {
                          this.props.kitModalOpen(2)
                        } else {
                          alert('Please select clinic first');
                        }
                      } else {
                        if (isAuthDistributor(this.props.user) && this.props.providers && this.props.providers.length <= 0) {
                          alert('Create clinic first')
                        } else {
                          this.props.kitModalOpen(2)
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon icon="plus"/>{' '}Biopsy Kits
                  </Button>
                </IfNotAuthSuperReadonly>
              </Col>
            </Row>
          </div>

          <div>
            <KitModal/>
            {
              items ? (
                <div>
                  {
                    items.length > 0 ? (
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h6 style={{margin: 0}}>Total: {this.props.items_total}</h6>
                            </Col>
                            <Col md="auto">
                                <ReactPaginate
                                  previousLabel={'previous'}
                                  nextLabel={'next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={this.props.pages}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={(data) => {
                                    this.setState({
                                      ...this.state,
                                      current_page: data.selected + 1
                                    }, () => this.loadItems())
                                  }}
                                  containerClassName={'pagination'}
                                  subContainerClassName={'pages pagination'}
                                  activeClassName={'active'}
                                  initialPage={parseInt(this.state.current_page) - 1}
                                  disableInitialCallback={true}
                                />
                              </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {
                            items.length > 0 ? (
                              <CardTable
                                id={'patients'}
                                columns={['', 'Qtty', 'Barcodes', 'EB', 'Case', 'Ship To', 'Created', 'Status', '']}
                              >
                                {
                                  items.map((item, i) =>
                                    {
                                      return (
                                        <>
                                        <IfNotAuthSuperReadonly>
                                          <TableRowWithLink
                                            key={'provider-kit-' + item.id}
                                            onClick={() => {this.props.kitModalOpen(item.kit, {...item, index: i})}}
                                          >
                                            <TableCellWithLink>
                                              {item.kit_name}
                                              <IfAuthSuper2 component={'div'}>
                                                <div
                                                  className={'text-muted'}
                                                  style={{
                                                    fontSize: 12,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    width: 200
                                                  }}
                                                >
                                                  {item.clinic_name}
                                                </div>
                                              </IfAuthSuper2>
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              <div style={{width: 80}}>
                                                {item.qtty}{' '}
                                                {item.swab_qtty && item.kit === 1 && <span>{`(Swabs: ${item.swab_qtty})`}</span>}
                                              </div>
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              {!!item.n_barcodes ? item.n_barcodes : '-'}
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              <div style={{width: 40}}>
                                                {!!item.extra_buffer ? item.extra_buffer : '0'}
                                              </div>
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              {item.case_name ? item.case_name : '-'}
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              <div data-dd-action-name="patient_address" style={{width: 180, fontSize: 12}}>
                                                {item.shipping_address_as_string}
                                                {item.patient && <div data-dd-action-name="patient_name" className="text-muted">Patient: {item.patient}</div>}
                                                {item.recipient && <div data-dd-action-name="recipient_name" className="text-muted">Recipient: {item.recipient}</div>}
                                                {item.phone && <div data-dd-action-name="patient_phone" className="text-muted">Phone Number: {item.phone}</div>}
                                                {item.tracking && <div className="text-muted">Tracking Number: {item.tracking}</div>}
                                                {item.tracking_return && <div className="text-muted">Tracking Number Return: {item.tracking_return}</div>}
                                                {item.notes && <div className="text-muted">Notes: {item.notes}</div>}
                                              </div>
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              <div style={{width: 80}}>
                                                <TimeCreated value={item.created_at}/>
                                              </div>
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              {item.status ? item.status : (
                                                <span className={(item.tracking_return || item.tracking) && 'text-warning'}>
                                                {item.tracking_return ? 'shipped back' : (
                                                  <span>
                                                    {item.tracking ? 'shipped' : 'pending'}
                                                  </span>
                                                )}
                                              </span>
                                              )}
                                            </TableCellWithLink>

                                            <TableCellWithLink>
                                              {
                                                (!item.tracking_return && !item.tracking) && (
                                                  <div className="text-right">
                                                    <button className="link-button" onClick={
                                                      (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        this.props.deleteKit(item.id, i, items.length === 1);
                                                      }
                                                    }>Cancel</button>
                                                  </div>
                                                )
                                              }
                                              <IfAuthSuper2 component={'div'}>
                                                <div className="text-right" style={{marginTop: 12}}>
                                                  <button className="link-button" onClick={
                                                    (e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      if (window.confirm('Are you sure you want to delete?')) {
                                                        this.props.deleteKit(item.id, i, items.length === 1);
                                                      }
                                                    }
                                                  }
                                                     style={{color: 'red'}}
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </IfAuthSuper2>
                                            </TableCellWithLink>
                                          </TableRowWithLink>
                                        </IfNotAuthSuperReadonly>
                                          <IfAuthSuperReadonly>
                                            <tr key={'provider-kit-' + item.id}>
                                              <td>
                                                {item.kit_name}
                                                <IfAuthSuper2 component={'div'}>
                                                  <div
                                                    className={'text-muted'}
                                                    style={{
                                                      fontSize: 12,
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis',
                                                      width: 200
                                                    }}
                                                  >
                                                    {item.clinic_name}
                                                  </div>
                                                </IfAuthSuper2>
                                              </td>

                                              <td>
                                                <div style={{width: 80}}>
                                                  {item.qtty}{' '}
                                                  {item.swab_qtty && item.kit === 1 && <span>{`(Swabs: ${item.swab_qtty})`}</span>}
                                                </div>
                                              </td>

                                              <td>
                                                {!!item.n_barcodes ? item.n_barcodes : '-'}
                                              </td>

                                              <td>
                                                <div style={{width: 40}}>
                                                  {!!item.extra_buffer ? item.extra_buffer : '0'}
                                                </div>
                                              </td>

                                              <td>
                                                {item.case_name ? item.case_name : '-'}
                                              </td>

                                              <td>
                                                <div data-dd-action-name="patient_address" style={{width: 180, fontSize: 12}}>
                                                  {item.shipping_address_as_string}
                                                  {item.patient && <div data-dd-action-name="patient_name" className="text-muted">Patient: {item.patient}</div>}
                                                  {item.recipient && <div data-dd-action-name="recipient_name" className="text-muted">Recipient: {item.recipient}</div>}
                                                  {item.phone && <div data-dd-action-name="patient_phone" className="text-muted">Phone Number: {item.phone}</div>}
                                                  {item.tracking && <div className="text-muted">Tracking Number: {item.tracking}</div>}
                                                  {item.tracking_return && <div className="text-muted">Tracking Number Return: {item.tracking_return}</div>}
                                                  {item.notes && <div className="text-muted">Notes: {item.notes}</div>}
                                                </div>
                                              </td>

                                              <td>
                                                <div style={{width: 80}}>
                                                  <TimeCreated value={item.created_at}/>
                                                </div>
                                              </td>

                                              <td>
                                                {item.status ? item.status : (
                                                  <span className={(item.tracking_return || item.tracking) && 'text-warning'}>
                                                  {item.tracking_return ? 'shipped back' : (
                                                    <span>
                                                      {item.tracking ? 'shipped' : 'pending'}
                                                    </span>
                                                  )}
                                                </span>
                                                )}
                                              </td>
                                            </tr>
                                          </IfAuthSuperReadonly>
                                        </>
                                      )
                                    }
                                  )
                                }
                              </CardTable>
                            ) : (
                              <p>No kit orders</p>
                            )
                          }
                        </CardBody>
                      </Card>
                    ) : <p>No Kits</p>
                  }
                </div>
              ) : <TablePlaceholder rowHeight={34}/>
            }
          </div>
        </Page>
      )
    }
  }
)
