import React, {Component} from 'react'
import {connect} from 'react-redux'
import axios from "axios";

class ReportDownload extends Component {
    constructor(props){
        super(props);

        this.state = {
            errorText: '',
            url: ''
        };

        this.getUrl = this.getUrl.bind(this);
    }

    componentDidMount() {
        const { patientId, reportId } = this.props.route.match.params
        this.getUrl(patientId, reportId)
    }

    getUrl(patientId, reportId) {
        axios({
            baseURL: process.env.REACT_APP_API_HOST,
            url: '/report/final/url/',
            method: 'POST',
            data: {patientId, reportId: reportId !== undefined ? reportId : ''},
            headers: {
                "Authorization": `Token ${this.props.token}`
            },
        }).then((res) => {
            this.setState({...this.state, url: res.data.presigned_url})
        }).catch(error => {
            let errorText = "Server failure";
            if (error.response !== undefined) {
                if ([400, 403].includes(error.response.status)) {
                    errorText = error.response.data.detail
                } else {
                    errorText = error.response.status
                }
            }
            this.setState({...this.state, errorText})
        })
    }

    render() {
        const { errorText, url } = this.state

        return (
            <div className="text-center" style={{marginTop: 20}}>
                {
                    (url) ?
                    (
                        <div>
                            <h3 className="page-title">Your link is ready</h3>
                            <a href={url} className="btn btn-primary btn-lg active mt-5" role="button" aria-pressed="true">
                                Download report
                            </a>
                        </div>
                    ) :
                    (
                        <h3 className="page-title">{errorText}</h3>
                    )
                }
            </div>
        )
    }
}

export default connect(
    state => ({
        token: state.auth.token,
        }),
    null
)(ReportDownload);