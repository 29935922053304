import React, {PureComponent} from 'react';
import './style/main.css';
import LoadingOverlay from "utils/LoadingOverlay";


export default class extends PureComponent {
  render() {
    const Component = this.props.component;
    const route = this.props.route;

    return (
      <div className="basic-wrap">
        <Component route={route} />
        <LoadingOverlay/>
      </div>
    );
  }
}