import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import URI from "urijs";


export default connect(
  state => ({
    selected_provider_id: state.common.selected_provider_id
  })
)(
  class extends PureComponent {
    render() {
      const {selected_provider_id, children, to, toParams} = this.props;
      let url = null;

      if (to) {
        url = new URI(to);

        if (selected_provider_id) {
          url.addSearch('provider', selected_provider_id);

          if (toParams) {
            url.search(toParams);
          }

          url.normalizeSearch();
        }
      }

      return url ? (
        <Link
          className={this.props.className}
          to={url.toString()}
        >
          {children}
        </Link>
      ) : (
        <span
          className={this.props.className}
        >
          {children}
        </span>
      )
    }
  }
)