import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {patientModalOpen} from "actions/modal";
import {Button, Card, CardBody, CardFooter, CardTitle, Col, Row} from "reactstrap";
import PatientInfoTable from "components/Patient/Application/PatientInfoTable";


export default connect(
  null,
  {
    patientModalOpen: patientModalOpen,
  }
)(
  class extends PureComponent {
    render() {
      const {patient} = this.props;

      return (
        <Card>
          <CardBody>
            <CardTitle>Patient & Partner Info</CardTitle>
            <div>
              <Row>
                <Col>
                  <h6 className="text-muted">Patient</h6>
                  <PatientInfoTable
                    fullName={patient.first_name + ' ' + patient.last_name}
                    dob={patient.dob}
                    sex={patient.sex}
                    ethnicity={patient.ethnicity}
                    fdh={patient.fdh}
                  />
                </Col>
                <Col>
                  <h6 className="text-muted">Partner</h6>
                  <PatientInfoTable
                    fullName={patient.partner_first_name + ' ' + patient.partner_last_name}
                    dob={patient.partner_dob}
                    sex={patient.partner_sex}
                    ethnicity={patient.partner_ethnicity}
                    fdh={patient.partner_fdh}
                  />
                </Col>
                <Col>
                  <h6 className="text-muted">Contact Info</h6>
                  <table className="table table-sm table-borderless">
                    <tbody>
                    <tr>
                      <th>Email</th>
                      <td>{patient.email}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{patient.phone}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{patient.address}</td>
                    </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </CardBody>

          <CardFooter>
            See a mistake?{' '}
            <Button color="light" size="sm" onClick={() => this.props.patientModalOpen(patient)}>
              Correct
            </Button>
          </CardFooter>
        </Card>
      )
    }
  }
)