import React, {PureComponent} from 'react';
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
// import InputMask from 'react-input-mask';
// import PropTypes from 'prop-types';
// import {Checkbox} from 'gp-utilities';


class InputCheckbox extends PureComponent {
  render() {
    const {input, label, meta: {touched, error}, inline} = this.props;

    return (
      <FormGroup className="align-items-center" check inline={inline || false}>
        <Label className={touched && error ? 'text-danger' : ''} check>
          <Input
            {...input}
            type="checkbox"
            value={input.value !== 'null' ? input.value : ''}
            invalid={touched && !!error}
          />
          {/*<Checkbox label={label}/>*/}
          {label}
        </Label>
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    )
  }
}

export default InputCheckbox;
