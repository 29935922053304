import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Row, Col} from "reactstrap";


export default connect(

)(
  class extends Component {
    render() {
      return (
        <div style={{padding: '40px'}}>
          <Container fluid>
            <Row>
              <Col>
                <p>C</p>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
  }
)
