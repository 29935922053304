import React, {useState, useMemo, useEffect} from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import '../accessioning.css';

//
// tanstack headless UI components -- see tanstack.com for docs, etc.
//

// import styles from "./index.module.css"
import Pagination from "./Pagination";
import TableHeader from "./TableHeader";

import {data} from "../../../accession_data";   // Fake data, for now.

import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {Button, InputGroup} from "@blueprintjs/core";
import table from "reactstrap/es/Table";

/**
 * dateRangeFilterFn - filter function for date columns
 * @param row - row object being evaluated for filtering (from which .value can be accessed for column)
 * @param columnId - id of column being evaluated for filtering
 * @param filterValue - array of date range to filter within [yyyy-mm-dd from-date string, yyyy-mm-dd to-date string]
 * @returns {boolean} - If true, include the row from display.  If false, exclude row from display.
 */
const dateRangeFilterFn = (row, columnId, filterValue) => {
  const rawRowDateString = row.getValue(columnId);  // string: "mm/dd/yyyy" or '' or null
  if (!rawRowDateString) return (!filterValue[0] && !filterValue[1]);  // allow row with blank date unless a from-date is set

  const [mm, dd, yyyy] = row.getValue(columnId).split('/');
  const rowDateString = `${yyyy}-${mm}-${dd}`;

  // Exclude if date less than from-date
  if (filterValue[0] && rowDateString < filterValue[0]) return false;

  // Exclude if date greater than to-date
  return !(filterValue[1] && rowDateString > filterValue[1]);
}

// remove the filter value from filter state if it is falsy (empty string in this case)
dateRangeFilterFn.autoRemove = val => !val;

/**
 * Convert database sample_type value to capitalized (or ALL CAPS) display value
 * @param st - lowercase value of sample_type from db
 * @returns string - display-value of given sample_type
 */
const displaySampleType = st => {
  if (st === 'dna') return 'DNA';
  if (st === 'other') return st;
  if (st && st.length > 1) return st.charAt(0).toUpperCase() + st.slice(1); // capitalize
  return st;
}

/**
 * Convert database accessioning_type value to capitalized (or ALL CAPS) display value
 * @param at - lowercase value of accessioning_type from db
 * @returns string - display-value of given accessioning_type
 */
const displayAccessioningType = at => {
  if (at === 'qc') return 'QC';
  if (at === 'unprocessed') return at;
  if (at && at.length > 1) return at.charAt(0).toUpperCase() + at.slice(1); // capitalize
  return at;
}


// Define the columns to show in the tanstack table
// accessor - columns linked to data model that can be filtered, sorted, etc.
// display - display-only columns not linked to data.  May contain controls.
// grouping - non-data columns used for grouping.  e.g. header, footer ...
const columnHelper = createColumnHelper();
const columnDefinitions = [
  columnHelper.accessor("id", {
    id: "id",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Accessioning Id" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: 'equalsString'  // searches must match exact number
  }),
  columnHelper.accessor("case_id", {
    id: "case_id",
    header: ({ column, showFeatures}) =>
      <TableHeader title="Case Id" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: 'equalsString'  // searches must match exact number
  }),
  columnHelper.accessor("clinic", {
    id: "clinic",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Clinic" column={column} showFeatures={showFeatures} />,
    cell: (info) => info.getValue(),
    enableSorting: true,
    enableColumnFilter: true,
    filterFn: 'includesString'  // searches can match any part of clinic name - case-insensitive
  }),
  columnHelper.accessor("sample_type", {
    id: "sample_type",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Sample Type" column={column} showFeatures={showFeatures} />,
    cell: (info) =>
      <div style={{textAlign: "center"}}>
        {displaySampleType(info.getValue())}
      </div>,
    enableSorting: false,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'select_sample_type',
    },
  }),
  columnHelper.accessor("accessioning_type", {
    id: "accessioning_type",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Type" column={column} showFeatures={showFeatures} />,
    cell: (info) =>
      <div style={{textAlign: "center"}}>
        {displayAccessioningType(info.getValue())}
      </div>,
    enableSorting: false,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'select_type',
    },
  }),
  columnHelper.accessor("preamp_date", {
    id: "preamp_date",
    header: ({ column, showFeatures }) =>
      <TableHeader title="PreAmped" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'date-range',
    },
    filterFn: dateRangeFilterFn
  }),
  columnHelper.accessor("axiom_date", {
    id: "axiom_date",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Axiomed" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: true,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'date-range',
    },
    filterFn: dateRangeFilterFn
  }),
  columnHelper.accessor("accessioning_status", {
    id: "accessioning_status",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Status" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: false,
    enableColumnFilter: true,
    meta: {
      filterVariant: 'select_status',
    },
  }),
  columnHelper.accessor("sample_quantity", {
    id: "sample_quantity",
    header: ({ column, showFeatures }) =>
      <TableHeader title="Samples" column={column} showFeatures={showFeatures} />,
    cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
    enableSorting: false,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: "kebab_menu",
    header: () => null,
    cell: (info) => (
      <button className="kebabButton" style={{marginRight:"35px", fontSize: "1.2rem"}}>&nbsp;&#8942;&nbsp;</button>),
    enableSorting: false,
    enableColumnFilter: false,
  }),
];

/**
 * Compute the sum of sample-quantity column for currently filtered row-set
 * @param table - tanstack table instance
 * @returns number - sum of sample-quantity column values of currently filtered row-set
 */
const sumSampleQuantity = (table) => {
  const filteredRowModel = table.getFilteredRowModel();
  if (!('rows' in filteredRowModel)) return 0;
  return filteredRowModel.rows.reduce((total, row) => total + row.getValue('sample_quantity'), 0);
}

export default function AllTab({setTotalSampleQuantity}) {
  const pagination = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 10,
    }),
    [],
  );

  const [searchValue, setSearchValue] = useState('');  // Value from big search input box at top
  const [sorting, setSorting] = useState([]);  // sorting for each column in tanstack table state
  const [columnFilters, setColumnFilters] = useState([]);  // filtering for each column in tanstack table state

  // array of which columns are showing their features components (sorting and/or filtering) in header
  const [showColumnFeatures, setShowColumnFeatures] = useState([]);

  // tanstack table def
  const tsTable = useReactTable({
    data,
    columns: columnDefinitions,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pagination,
    },
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {},
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel()
  });

  // https://profy.dev/article/react-useeffect-with-object-dependency
  useEffect(() => {
    setTotalSampleQuantity(sumSampleQuantity(tsTable));
  }, [...Object.values(tsTable)])  // eslint-disable-line

  const resetAll = () => {
    tsTable.resetColumnFilters(true);
    tsTable.getColumn('id').toggleSorting(false);
    setShowColumnFeatures([]);  // remove all feature components from column headers
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Container fluid>
            <Row>
              <Col>
                <div style={{display: "inline-block", width: "300px"}}>
                  <InputGroup
                    disabled={false}
                    large={false}
                    placeholder="Search patient name or dob ..."
                    rightElement={<Button type="submit" icon={'search'} style={{borderRadius: 99}} loading={false}
                                          minimal/>}
                    small={false}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                  />
                </div>
                <div className="sortFilterResetButton" title="restore default sorting and remove all filtering"
                     onClick={resetAll}>
                  reset sort/filter
                </div>
              </Col>
              <Col xs lg="2" className="text-right">
                <button className="btn btn-primary btn-sm" title="Add new Accessioning">
                  +Add
                </button>
              </Col>
            </Row>
          </Container>
        </CardHeader>

        <table style={{borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray"}}>
          <thead style={{backgroundColor: '#ebe9f2'}}>
          {tsTable.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className='accessioningTR' style={{height: "50px"}}>
              {headerGroup.headers.map((header) => {
                const headerContext = header.getContext();
                // Inject this into header's context so TableHeader can access it
                headerContext.showFeatures = {
                  showColumnFeatures: showColumnFeatures,
                  setShowColumnFeatures: setShowColumnFeatures,
                };
                return (
                  <th key={header.id} className='accessioningTH' style={header.column.id === 'clinic' ? {textAlign: 'left'} : {}}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        headerContext,
                      )}
                  </th>
                )
              })}
            </tr>
          ))}
          </thead>
          <tbody style={{borderBottom: "1px solid lightgray"}}>
          {tsTable.getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={100} style={{padding: "2rem", textAlign: "center"}}><i>No matching entries found</i></td>
            </tr>
          ) : (
            tsTable.getRowModel().rows.map((row) => (
            <tr key={row.id} className='accessioningTR'>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={{height: "50px"}}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          )))}
          </tbody>
        </table>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          height: '50px',
          backgroundColor: '#ebe9f2'
        }}>
          {/*<Pagination*/}
          {/*    table={tsTable}*/}
          {/*    pageRange={3}*/}
          {/*    pageOptions={tsTable.getPageOptions()}*/}
          {/*>*/}
          <Pagination.Goto table={tsTable} options={[10, 20, 30, 40, 50]}/>
          {/*</Pagination>*/}
        </div>
      </Card>
    </>
  );
};
