import React, {PureComponent} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import DiseaseHistoryForm from 'components/Patient/DiseaseHistory/Form';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class DiseaseHistoryModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      list: props.initialData ? JSON.parse(props.initialData) : []
    };

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  close() {
    this.setState({
      ...this.state,
      isOpen: false
    })
  }

  open() {
    this.setState({
      ...this.state,
      isOpen: true
    })
  }

  onSubmit(data) {
    const new_list = this.state.list.slice();
    new_list.push(data);

    this.setState({
      ...this.state,
      list: new_list,
      isOpen: false
    }, () => {
      this.props.onChange(this.state.list)
    })
  }

  removeItem(e, i) {
    e.preventDefault();

    const new_list = this.state.list.slice();
    new_list.splice(i, 1);
    this.setState({
      ...this.state,
      list: new_list,
    }, () => {
      this.props.onChange(this.state.list)
    })
  }

  render() {
    return (
      <div>
        <div>
          <div>
            {
              this.state.list.map((item, i) => {
                return (
                  <Row key={'fdh-entry-' + i} className="justify-content-between align-items-center">
                    <Col>
                      <strong>{item.person}</strong>
                    </Col>
                    <Col>
                      {item.status}
                    </Col>
                    <Col>
                      {item.disease}
                    </Col>
                    <Col md="auto">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <button className="link-button text-danger" onClick={(e) => this.removeItem(e, i)}>remove</button>
                    </Col>
                  </Row>
                )
              })
            }
          </div>

          <div style={{marginTop: this.state.list.length > 0 ? 12 : 0}}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={(e) => {
              e.preventDefault();
              this.open();
            }}><FontAwesomeIcon icon="plus"/>{' '}Add</a>
          </div>
        </div>

        <Modal isOpen={this.state.isOpen} toggle={this.close}>
          <ModalHeader toggle={this.close}>
            Disease History Entry
          </ModalHeader>
          <ModalBody>
            <DiseaseHistoryForm
                onSubmit={(data) => this.onSubmit(data)}
                onCancel={() => this.close()}
            />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default DiseaseHistoryModal;
