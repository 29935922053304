import * as patient from 'actions/patient';
import * as card from 'actions/card';


const initialState = {
  item: null,
  items: null,
  items_total: 0,
  pages: 1,
  page_size: 15,
  archived_items: null,
  card: null,
  test_type_modal: {
    is_open: false,
    data: null
  },
};


export const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case patient.PATIENT_LIST_REQUEST:
    case patient.PATIENT_DETAIL_REQUEST:
    case patient.PATIENT_CREATE_REQUEST:
      return {
        ...state,
        item: null,
        cards: null,
        archived_items: null,
        items_total: 0,
        page_size: 15,
        pages: 1
      };

    case patient.PATIENT_LIST_SUCCESS:
      const items = action.payload.results;
      return {
        ...state,
        items: items.filter(item => !item.is_archived),
        archived_items: items.filter(item => item.is_archived),
        items_total: action.payload.count,
        page_size: action.payload.page_size,
        pages: Math.ceil(action.payload.count / action.payload.page_size)
      };

    case patient.PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        item: action.payload,
        card: action.payload.card
      };

    case patient.PATIENT_UPDATE_SUCCESS:
      return {
        ...state,
        item: action.payload
      };

    case patient.PATIENT_CREATE_SUCCESS:
      return {
        ...state,
        item: action.payload
      };

    case patient.PATIENT_FILES_FAILURE:
      if (action.payload.response) {
        window.alert(action.payload.response.detail ? action.payload.response.detail : 'Error. Please try again later.')
      }
      return state;

    // case patient.PATIENT_DELETE_SUCCESS:
    //   const new_array = state.items.slice();
    //   new_array.splice(action.meta.index, 1);
    //
    //   return{
    //     ...state,
    //     items: new_array
    //   };

    case card.CARD_PATIENT_ADD_SUCCESS:
      return {
        ...state,
        card: action.payload.data
      };

    case card.CARD_DELETE_SUCCESS:
      return {
        ...state,
        card: null
      };

    case patient.PATIENT_CONSENT_FORM_SUCCESS:
    case patient.PATIENT_CONSENT_FORM_DELETE_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload
        }
      };

    case patient.PATIENT_FILES_SUCCESS:
    case patient.PATIENT_FILES_DELETE_SUCCESS:
    case patient.PATIENT_REPORTS_SUCCESS:
    case patient.PATIENT_REPORTS_DELETE_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload
        }
      };

    case patient.PATIENT_APPLICATION_RESEND_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          application_completed: false
        }
      };

    case patient.PATIENT_ARCHIVE_PUT_SUCCESS:
      return {
        ...state,
        item: action.payload
      };

    case patient.TOGGLE_TEST_TYPE_MODAL:
      return {
        ...state,
        test_type_modal: {
          is_open: action.is_open,
          data: action.data
        },
      };

    default:
      return state;
  }
};
