import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deletePatientFile, submitPatientFile} from "actions/patient";
import {FinalReportSelect} from "utils/FileUpload";
import {Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader} from "reactstrap";
import SendEmailForm from "../Form/SendEmailForm";
import {getToken} from "auth/token";

import axios from "axios";


export default connect(
  state => ({
    patient: state.patient.item,
    clinicians: state.clinician.clinicians
  }),
  {
    submitPatientFile: submitPatientFile,
    deletePatientFile: deletePatientFile
  }
)(
  class extends Component {
    constructor(props){
      super(props);

      this.state = {
        modalIsOpen: false,
        selectedFileId: '',
        submitError: '',
      };

      this.handleFormSubmit = this.handleFormSubmit.bind(this);
      this.handleFormCancel = this.handleFormCancel.bind(this);
      this.setSubmitError = this.setSubmitError.bind(this);
    }

    handleFormSubmit(emails_recipients) {
        const { patient: { id: patientId, case_id, patient: patientPatiant  } } = this.props;
        const { selectedFileId } = this.state;
        let report_url = `${process.env.REACT_APP_HOST}report/final/download/${patientId}/`;
        if (selectedFileId !== undefined) {
            report_url += selectedFileId
        }

        let patient_initials = '';
        if (patientPatiant) {
            const { first_name, last_name } = patientPatiant;
            for (let name of [first_name, last_name]) {
                if (name !== undefined) {
                    patient_initials += `${name[0].toUpperCase()}.`
                }
            }
        }

        axios({
            baseURL: process.env.REACT_APP_API_HOST,
            url: 'report/final/emails/send/',
            method: 'POST',
            responseType: 'json',
            headers: {
                "Authorization": `Token ${getToken()}`
            },
            data: {
                case_id,
                emails_recipients,
                patient_initials,
                report_url
            }
        })
            .then(response => {
                this.handleFormCancel()
            })
            .catch(error => this.setSubmitError('Mails were not sent. Try again later'))
    }

    handleFormCancel() {
        this.setState({modalIsOpen: false, selectedFileId: '', submitError: ''})
    }

    setSubmitError(errorText) {
        this.setState({...this.state, submitError: errorText})
    }

    render() {
      const { patient, clinicians } = this.props;
      const { modalIsOpen, submitError } = this.state;
      return (
        <Card className="final-report">
          <CardHeader>
            <h6 style={{margin: 0}}>GPCL Final Report</h6>
          </CardHeader>
          <CardBody>
            <FinalReportSelect
              value={patient.reports ? JSON.parse(patient.reports) : []}
              onFileUpdate={(data, file_name) => this.props.submitPatientFile(patient.id, data, file_name, 'reports')}
              onFileDelete={(data) => this.props.deletePatientFile(patient.id, data, 'reports')}
              onModalOpen={selectedFileId => this.setState({...this.state, modalIsOpen: true, selectedFileId})}
              sendEmails={clinicians && !!clinicians.length}
              hold={patient.case_status === 'report_hold'}
              ovation={patient.external_domain === 'OVATION'}
              orderId={patient.external_id}
            />
              {
                  clinicians && (
                      <Modal size="lg" isOpen={modalIsOpen} toggle={this.handleFormCancel}>
                          <ModalHeader toggle={this.handleFormCancel}>
                              Send emails
                          </ModalHeader>
                          <ModalBody>
                              <SendEmailForm
                                  emailsRecipients={
                                      clinicians.filter(clinician => clinician.email).map(recipient => {
                                          const { email, first_name, last_name } = recipient
                                          return {
                                              label: `${first_name} ${last_name}`,
                                              value: {email, first_name, last_name}
                                          }
                                      })
                                  }
                                  onSubmit={this.handleFormSubmit}
                                  onCancel={this.handleFormCancel}
                                  submitError={submitError}
                                  setSubmitError={this.setSubmitError}
                              />
                          </ModalBody>
                      </Modal>
                  )
              }
          </CardBody>
        </Card>
      )
    }
  }
)
