import React, {PureComponent} from 'react';
import {Container} from "reactstrap";
import './style/main.css';
import CardModal from "components/modals/CardModal";
import PatientModal from "components/modals/PatientModal";
import LoadingOverlay from "utils/LoadingOverlay";


export default class extends PureComponent {
  render() {
    const Component = this.props.component;
    const route = this.props.route;

    return (
      <div className="basic-wrap">
        <Container>
          <Component route={route} />
        </Container>

        <CardModal/>
        <PatientModal/>
        <LoadingOverlay/>
      </div>
    );
  }
}