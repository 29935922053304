import * as nurse from 'actions/nurse';


const initialState = {
  nurse: null,
  nurses: null,
};


let array = [];
let new_array = [];


export const nurseReducer = (state = initialState, action) => {
  switch (action.type) {
    case nurse.NURSE_LIST_REQUEST:
      return {
        ...state,
        nurses: null
      };

    case nurse.NURSE_LIST_SUCCESS:
      return {
        ...state,
        nurses: action.payload
      };

    case nurse.NURSE_DETAIL_SUCCESS:
      return {
        ...state,
        nurse: action.payload
      };

    case nurse.NURSE_UPDATE_SUCCESS:
      const id = action.payload.id;

      array = state.nurses.slice();
      new_array = array.map((item) => {
        if(item.id !== id){
          return item;
        }

        return{
          ...item,
          ...action.payload
        }
      });

      return {
        ...state,
        nurses: new_array
      };

    case nurse.NURSE_CREATE_SUCCESS:
      array = state.nurses.slice();
      array.unshift(action.payload);
      return {
        ...state,
        nurses: array
      };

    case nurse.NURSE_DELETE_SUCCESS:
      new_array = state.nurses.slice();
      new_array.splice(action.meta.index, 1);

      return {
        ...state,
        nurses: new_array
      };

    default:
      return state;
  }
};