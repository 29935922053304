import React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {CardTable, TableCellWithLink} from "components/Views";
import Moment from "moment-timezone";
import AddressFormat from "components/Address/FormatLegacy";
import {withRouter} from "react-router-dom";


export default withRouter((props) => {
    const {items} = props;

    const onClick = (e, item) => {
      e.preventDefault();
      props.history.push(`/clinics/${item.id}`);
      window.localStorage.setItem('provider', JSON.stringify(item));
      window.location.reload(true)
    };

    return (
      <div>
        {
          items.length > 0 ? (
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <h6 style={{margin: 0}}>Total: {items.length}</h6>
                  </Col>
                  <Col md="auto">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <CardTable
                  id={'clinics'}
                  columns={['Clinic', 'Cases', 'Kits', 'Providers', 'Nurses', 'Created']}
                >
                  {
                    items.map((item, i) => {
                      const creationDate = Moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MM/DD/YYYY');
                      return (
                        <tr key={item.company_name + '_' + i} className="clickable-row" onClick={(e) => onClick(e, item)}>
                          <TableCellWithLink width={320}>
                            <strong>{item.company_name || '[No Company Name]'}</strong><br/>
                            <span className="text-muted">{item.email}</span>
                            <span className="text-muted" style={{marginLeft: item.email ? 20 : 0}}>
                              <AddressFormat value={item.company_address}/>
                            </span>
                          </TableCellWithLink>

                          <TableCellWithLink>
                            {item.patients_count || 0}
                          </TableCellWithLink>

                         <TableCellWithLink>
                            {item.kits_count || 0}
                         </TableCellWithLink>

                         <TableCellWithLink>
                             {item.providers_count || 0}
                         </TableCellWithLink>

                         <TableCellWithLink>
                             {item.nurses_count || 0}
                         </TableCellWithLink>

                          <TableCellWithLink width={160}>
                            <span>{creationDate}</span>
                          </TableCellWithLink>
                        </tr>
                      )
                    })
                  }
                </CardTable>
              </CardBody>
            </Card>
          ) : (
            <p>No clinics</p>
          )
        }
      </div>
    )
  }
)
