import {PureComponent} from 'react';
import {connect} from 'react-redux';


export default connect(

)(
  class extends PureComponent {
    render() {
      return (
        'Item'
      )
    }
  }
)