import * as wsclient from 'actions/wsclient';


const initialState = {
  connecting: false,
  connected: false,
  connection: null
};

export const WSClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case wsclient.WSCLIENT_CONNECTING:
      console.log('connecting')
      return {
        ...state,
        connecting: true
      };

    case wsclient.WSCLIENT_CONNECTED:
      console.log('connected')
      return {
        ...state,
        connected: true
      };


    default:
      return state;
  }
};