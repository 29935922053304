import * as patient from 'actions/patient';
import * as card from "actions/card";


const initialState = {
  patient: null,
  card: null,
  error: null,
  submitted: false,
};


export const patientApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case patient.PATIENT_APPLICATION_GET_REQUEST:
      return {
        ...state,
        ...initialState
      };

    case patient.PATIENT_APPLICATION_GET_FAILURE:
      return {
        ...state,
        patient: null,
        card: null,
        error: action.payload.response.message
      };

    case patient.PATIENT_APPLICATION_GET_SUCCESS:
      return {
        ...state,
        patient: action.payload,
        card: action.payload.card,
        error: null
      };

    case patient.PATIENT_APPLICATION_SUBMIT_SUCCESS:
      return {
        ...state,
        patient: action.payload,
        card: action.payload.card,
        error: null,
        submitted: true
      };

    case patient.PATIENT_UPDATE_SUCCESS:
      return {
        ...state,
        patient: action.payload
      };

    case patient.PATIENT_FILES_SUCCESS:
    case patient.PATIENT_FILES_DELETE_SUCCESS:
      return {
        ...state,
        patient: {
          ...state.patient,
          files: action.payload.files
        }
      };

    case card.CARD_PATIENT_ADD_SUCCESS:
      return {
        ...state,
        card: action.payload.data
      };

    case card.CARD_DELETE_SUCCESS:
      return {
        ...state,
        card: null
      };

    default:
      return state;
  }
};