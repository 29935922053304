import React, {PureComponent} from 'react';
import Moment from "moment-timezone";
import momentDurationFormatSetup from 'moment-duration-format';

class TimeCreated extends PureComponent {
  // componentDidMount() {
  //   this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  render() {
    momentDurationFormatSetup(Moment);

    const end = Moment.utc(this.props.value, 'YYYY-MM-DD hh:mm:ss');

    let timeAgo = end.local().format('MMM DD, hh:mm a');


    return (
      <span>{timeAgo}</span>
    )
  }
}

export default TimeCreated;
