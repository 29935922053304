import React, {useState} from 'react';
import {Button, Callout, Card, FormGroup, H5, Icon, InputGroup, Intent, Spinner} from "@blueprintjs/core";
import {Col, Row} from "reactstrap";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
//import {getToken, getProviderID} from "auth/token";
import URI from "urijs";
import axios from "axios";


const Schema = Yup.object().shape({
  new_pass: Yup.string()
    .min(8, 'Too Short!')
    .max(24, 'Too Long!')
    .required('Required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character (@$!%*#?&)"
    ),
  new_pass_repeat: Yup.string()
    .oneOf([Yup.ref('new_pass'), null], 'Passwords don\'t match')
    .required('Required')
});

export default (props) => {
  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const getToken = () => {
    const auth = JSON.parse(window.localStorage.getItem('persist:clinic-portal'));
    const user = auth ? JSON.parse(auth.auth) : {};
    return user.token;
  };

  const sendData = (data) => {
    setLoading(true);
    setError(null);

    let api_url = new URI('auth/update-password/');
    //api_url.addSearch('provider_id', getProviderID());
    api_url = api_url.normalizeSearch();

    axios({
      url: api_url.toString(),
      method: 'POST',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: data
    })
      .then(response => {
        // setSuccess(true);
        props.onSuccess()
      })
      .catch(error => {setError(error.response); console.log(error.response)})
      .finally(() => {
        setLoading(false);
      });
  };

  const IconNo = () => <Icon icon="small-minus"/>;
  const IconYes = () => <Icon icon="small-tick" intent={Intent.SUCCESS}/>;

  const testLength = (value) => {
    return (value.length && value.length >= 8) ? <IconYes/> : <IconNo/>;
  };

  const testUppercase = (value) => {
    return (value.length && value.match(/^(?=.*[A-Z])/)) ? <IconYes/> : <IconNo/>;
  };

  const testLowercase = (value) => {
    return (value.length && value.match(/^(?=.*[a-z])/)) ? <IconYes/> : <IconNo/>;
  };

  const testDigit = (value) => {
    return (value.length && value.match(/^(?=.*[0-9])/)) ? <IconYes/> : <IconNo/>;
  };

  const testSpecial = (value) => {
    return (value.length && value.match(/^(?=.*[@$!%*#?&])/)) ? <IconYes/> : <IconNo/>;
  };


  return (
    <div>
      <Card style={{position: 'relative'}}>
        <H5><Icon icon={'lock'} size={Icon.SIZE_LARGE} intent={Intent.PRIMARY}/> Password Update</H5>

        <Formik
          initialValues={
            {
              new_pass: '',
              new_pass_repeat: '',
            }
          }
          validationSchema={Schema}
          onSubmit={
            (values, {setSubmitting}) => {
              //props.onSubmit(values);
              sendData({new_pass: values.new_pass});
              setSubmitting(false);
            }
          }
        >

          {
            ({errors, touched, values, isSubmitting, setFieldValue}) => (
              <Form>
                <div style={{marginTop: 30}}>
                  <Row>
                    <Col md={4}>
                      <Field
                        name={'new_pass'}
                        render={({field, form}) => {
                          return (
                            <FormGroup
                              label="Enter new password"
                              labelFor={field.name}
                              helperText={form.touched[field.name] && form.errors[field.name] && <span>{form.errors[field.name]}</span>}
                              intent={(form.touched[field.name] && form.errors[field.name]) ? Intent.DANGER : Intent.NONE}
                            >
                              <InputGroup
                                type="password"
                                intent={(form.touched[field.name] && form.errors[field.name]) ? Intent.DANGER : Intent.NONE}
                                {...field}
                              />
                            </FormGroup>
                          )
                        }}
                      />

                      <Field
                        name={'new_pass_repeat'}
                        render={({field, form}) => {
                          return (
                            <FormGroup
                              label="Confirm new password"
                              labelFor={field.name}
                              helperText={form.touched[field.name] && form.errors[field.name] && <span>{form.errors[field.name]}</span>}
                              intent={(form.touched[field.name] && form.errors[field.name]) ? Intent.DANGER : Intent.NONE}
                            >
                              <InputGroup
                                type="password"
                                intent={(form.touched[field.name] && form.errors[field.name]) ? Intent.DANGER : Intent.NONE}
                                {...field}
                              />
                            </FormGroup>
                          )
                        }}
                      />

                      <div style={{marginTop: 20}}>
                        <Button type="submit">Submit</Button>
                      </div>
                    </Col>

                    <Col>
                      <p>
                        Password requirements:
                      </p>
                      <p>
                        {testLength(values.new_pass)}{' '}At least 8 symbols<br/>
                        {testUppercase(values.new_pass)}{' '}At least 1 uppercase character<br/>
                        {testLowercase(values.new_pass)}{' '}At least 1 lowercase character<br/>
                        {testDigit(values.new_pass)}{' '}At least 1 digit<br/>
                        {testSpecial(values.new_pass)}{' '}At least 1 special character (one of these: @$!%*#?&)<br/>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Form>
            )
          }
        </Formik>

        <div style={{marginTop: 20}}>
          {
            (error && error.data && error.data.detail) && (
              <Callout intent={Intent.DANGER}>
                {error.data.detail}
              </Callout>
            )
          }
        </div>

        {
          (!error && loading) && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: 'rgba(255,255,255,0.82)'
            }}>
              <div style={{
                position: 'absolute',
                height: 50,
                width: '100%',
                left: 0,
                top: '50%',
                marginTop: -25
              }}>
                <Spinner />
              </div>
            </div>
          )
        }
      </Card>
    </div>
  )
}
