import * as card from 'actions/card';
import * as modal from "actions/modal";


const initialState = {
  cardAddError: null
};

export const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case card.CARD_ADD_FAILURE:
      return {
        ...state,
        cardAddError: action.payload.response.detail
      };

    case modal.CARD_MODAL_CLOSE:
      return {
        ...state,
        cardAddError: null
      };

    default:
      return state
  }
};
