import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deletePatient, getPatientDetail, putInArchive, submitPatient, toggleTestTypeModal} from 'actions/patient';
import {submitPerson} from "../../../actions/person";
import LoadingIndicator from 'components/LoadingIndicator';
import {patientModalOpen} from "actions/modal";
import {Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import PatientInfoTable from './PatientInfoTable';
import FileUploadSection from "./FileUploadSection";
import ReportUploadSection from "./ReportUploadSection";
import TestSection from './TestSection';
import {
  IfAuthCanReport,
  IfAuthGC,
  IfAuthSuper,
  IfAuthSuperAdminOrReadonly,
  IfNotAuthSuperReadonly,
  IfAuthSuperReadonly,
  isAuthSuper,
  isAuthSuperAdminOrReadonly
} from "components/RBAC";
import SampleForm from "./SampleForm";
import PersonModal from '../Form/PersonModal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormModal from '../Form/Modal';
import FormBillingModal from '../Form/BillingModal';
import SampleTable from './SampleTable';
import SalivaSampleTable from './SalivaSampleTable';
import SalivaSampleForm from './SalivaSampleForm';
import TransferOutcomeForm from './TransferOutcomeForm';
import {withRouter} from 'react-router-dom';
import ReportSection from './ReportSection';
import BillingSection from './BillingSection';
import classNames from 'classnames';
import LogSection from './LogSection';
import JournalSection from './JournalSection';
// import WSClient from './WSClient';
import GeneSection from './GeneSection';
import URI from "urijs";
import NotesSection2 from "components/Patient/Detail/NotesSection2";
import LinkedCases from './LinkedCases';


class PatientDetail extends Component {
  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.handleDeleteResponse = this.handleDeleteResponse.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.state = {
      patientOpen: false,
      partnerOpen: false,
      modalIsOpen: false,
      billingModalIsOpen: false,
      item: {},
      salivaSampleModelIsOpen: true,
      active_index: null,
      activeTab: 'case'  // 'case' or 'report' or 'logs'
    }
  }

  onItemClick(item, i) {
    this.setState({
      ...this.state,
      item: item,
      salivaSampleModelIsOpen: true,
      active_index: i
    })
  }

  async delete(id) {
    if (window.confirm("Are you sure?")) {
      return await this.props.deletePatient(id);
    }
  }

  handleDeleteResponse(data) {
    if (data.type === '@@patient/PATIENT_DELETE_SUCCESS') {
      this.props.history.push('/patients')
    }
    if (data.type === '@@patient/PATIENT_DELETE_FAILURE') {
      alert('Case may not be deleted as there is data associated with it. This data must be removed first.')
    }
  };

  async deletePerson(person_to_delete) {
    if (window.confirm("Are you sure?")) {
      const {patient} = this.props;
      const {id, first_name, last_name} = person_to_delete === 'partner' ? patient.partner : patient.patient

      const data = await this.props.submitPerson({id, first_name, last_name, case_id: patient.id, person_to_delete})
      if (data.type === '@@person/PERSON_UPDATE_SUCCESS') {
        this.setState({...this.state, ...patient[person_to_delete] = null})
      }
    }
  }

  componentDidMount() {
    this.loadData().then((data) => {
      if (data && data.type === '@@patient/PATIENT_DETAIL_FAILURE' && !(data.payload instanceof Error)) {
        this.props.history.push('/patients')
      }
    });
  }

  async loadData() {
    const params = this.props.route.match.params;
    const id = params.id;
    const search = this.props.route.location.search;

    // do we need this "if" block?
    if (isAuthSuperAdminOrReadonly(this.props.user)) {
      let saved_provider_id = null;
      let next_provider_id = null;

      const current_provider = window.localStorage.getItem('provider')
      if (!!current_provider) {
        saved_provider_id = parseInt(JSON.parse(current_provider).id);
      }

      const current_url = new URI(search);
      const current_url_params = current_url.search(true);

      if (!!current_url_params && current_url_params.provider) {
        next_provider_id = parseInt(current_url_params.provider);
      }

      if (saved_provider_id === next_provider_id) {
        return this.props.getPatientDetail(id);
      }
    }

    return this.props.getPatientDetail(id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.patient !== prevProps.patient) {
      this.setState({
        ...this.state,
        modalIsOpen: false,
        patientOpen: false,
        partnerOpen: false,
        billingModalIsOpen: false
      });
    }
  }

  render() {
    const {patient: item} = this.props;
    return item ? (
      <div className="page">
        {/* <WSClient data={item}/> */}

        <FormModal
          isOpen={this.state.modalIsOpen}
          data={item}
          close={() => {
            this.setState({...this.state, modalIsOpen: false})
          }}
        />

        <FormBillingModal
          isOpen={this.state.billingModalIsOpen}
          data={item}
          close={() => {
            this.setState({...this.state, billingModalIsOpen: false})
          }}
        />

        <div style={{padding: '20px 0'}} className="case-tabs">
          <Nav>
            <IfAuthCanReport>
              <NavItem>
                <NavLink
                  className={classNames({active: this.state.activeTab === 'case'})}
                  onClick={() => this.setState({...this.state, activeTab: 'case'})}
                >
                  Case
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({active: this.state.activeTab === 'report'})}
                  onClick={() => this.setState({...this.state, activeTab: 'report'})}
                >
                  Report
                </NavLink>
              </NavItem>
            </IfAuthCanReport>
            <IfAuthSuper>
              <NavItem>
                <NavLink
                  className={classNames({active: this.state.activeTab === 'logs'})}
                  onClick={() => this.setState({...this.state, activeTab: 'logs'})}
                >
                  Logs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({active: this.state.activeTab === 'journal'})}
                  onClick={() => this.setState({...this.state, activeTab: 'journal'})}
                >
                  Change Log
                </NavLink>
              </NavItem>
            </IfAuthSuper>
          </Nav>
        </div>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="case">
            <Row>
              <Col>
                <TestSection
                  patient={item}
                  user={this.props.user}
                  edit={() => this.props.toggleTestTypeModal(true, item)}
                  onEdit={() => {
                    this.setState({...this.state, modalIsOpen: true})
                  }}
                  putInArchive={() => {
                    this.props.putInArchive({...item, is_archived: !item.is_archived})
                  }}
                  onDelete={
                    () => this.delete(item.id).then(this.handleDeleteResponse)
                  }
                />
              </Col>
            </Row>

            {
              (item.pgt_m || item.pgt_s) && (
                <div>
                  <IfAuthSuperAdminOrReadonly>
                    <GeneSection data={item}/>
                  </IfAuthSuperAdminOrReadonly>
                </div>
              )
            }

            <IfAuthSuperAdminOrReadonly>
              <div style={{marginTop: 20, marginBottom: 20}}>
                <LinkedCases data={item}/>
              </div>
            </IfAuthSuperAdminOrReadonly>

            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Row className="justify-content-between align-items-center">
                      <Col>
                        <h6 style={{margin: 0}}>Patient</h6>
                      </Col>
                      <IfNotAuthSuperReadonly>
                        {
                          item.patient &&
                          <div className="d-flex justify-content-between">
                            <Col md="auto">
                              <Button
                                color="light"
                                size="sm"
                                onClick={() => this.setState({...this.state, patientOpen: true})}
                              >
                                <FontAwesomeIcon icon="pencil-alt"/>
                                Edit
                              </Button>
                            </Col>
                            <Col md="auto">
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => this.deletePerson('patient')}
                                >
                                <i className="fa fa-trash-o"/>
                                Delete
                              </Button>
                            </Col>
                          </div>
                        }
                      </IfNotAuthSuperReadonly>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {
                      item.patient ? (
                        <div>
                          <Row>
                            <Col>
                              <PatientInfoTable
                                patient
                                case_id={item.id}
                                fullName={item.patient.first_name + ' ' + item.patient.last_name}
                                onFDHChange={(value) => this.props.submitPatient({
                                  ...item,
                                  patient: {...item.patient, fdh: value}
                                })}
                                {...item.patient}
                              />
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <IfNotAuthSuperReadonly>
                          <div style={{paddingTop: 60, paddingBottom: 60}} className="text-center">
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => this.setState({...this.state, patientOpen: true})}
                            >
                              <FontAwesomeIcon icon="plus"/> Add Patient
                            </Button>
                          </div>
                        </IfNotAuthSuperReadonly>
                      )
                    }

                    <PersonModal
                      isOpen={this.state.patientOpen}
                      case_data={item}
                      patient
                      case_id={item.id}
                      data={item.patient}
                      close={() => this.setState({...this.state, patientOpen: false})}
                      onSuccess={() => this.loadData()}
                      onExistingSelect={(data) => {
                        this.props.submitPatient({
                          ...item,
                          patient: data
                        })
                      }}
                    />
                  </CardBody>
                </Card>

                {
                  !item.m2 && (
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center justify-content-between">
                          <Col>
                            <h6 style={{margin: 0}}>Partner</h6>
                          </Col>
                          {
                            item.partner &&
                            <div className="d-flex justify-content-between">
                              <Col md="auto">
                                <IfNotAuthSuperReadonly>
                                  <Button
                                    color="light"
                                    size="sm"
                                    onClick={() => this.setState({...this.state, partnerOpen: true})}
                                  >
                                    <FontAwesomeIcon icon="pencil-alt"/>
                                    Edit
                                  </Button>
                                </IfNotAuthSuperReadonly>
                              </Col>
                              <Col md="auto">
                                <IfNotAuthSuperReadonly>
                                  <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => this.deletePerson('partner')}
                                  >
                                    <i className="fa fa-trash-o"/>
                                    Delete
                                  </Button>
                                </IfNotAuthSuperReadonly>
                              </Col>
                            </div>
                          }
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {
                          item.partner ? (
                            <div>
                              <Row>
                                <Col>
                                  <PatientInfoTable
                                    partner
                                    case_id={item.id}
                                    fullName={item.partner.first_name + ' ' + item.partner.last_name}
                                    {...item.partner}
                                  />
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div style={{paddingTop: 60, paddingBottom: 60}} className="text-center">
                              <IfNotAuthSuperReadonly>
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => this.setState({...this.state, partnerOpen: true})}
                                >
                                  <FontAwesomeIcon icon="plus"/> Add Partner
                                </Button>
                              </IfNotAuthSuperReadonly>
                            </div>
                          )
                        }

                        <PersonModal
                          isOpen={this.state.partnerOpen}
                          case_data={item}
                          partner
                          case_id={item.id}
                          data={item.partner}
                          close={() => this.setState({...this.state, partnerOpen: false})}
                          onSuccess={() => this.loadData()}
                          onExistingSelect={(data) => {
                            this.props.submitPatient({
                              ...item,
                              partner: data
                            })
                          }}
                        />
                      </CardBody>
                    </Card>
                  )
                }

              </Col>
            </Row>

            {
              !item.m2 && (
                <>
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <h6 style={{margin: 0}}>Samples</h6>
                      </CardHeader>
                      <CardBody>
                        <IfNotAuthSuperReadonly>
                        {
                          (['pending', 'case_accepted', 'gc_scheduled', 'setup_complete'].indexOf(this.props.patient.case_status) > -1) ? (
                            <Row>
                              <Col>
                                <SampleForm
                                  initialValues={this.props.patient.samples}
                                  onSubmit={(data) => {
                                    this.props.submitPatient({...this.props.patient, samples: data.samples})
                                  }}
                                  isSuper={isAuthSuper(this.props.user)}
                                  case={this.props.patient}
                                />
                              </Col>
                            </Row>
                          ) : (
                            <div>
                              <SampleTable items={this.props.patient.samples} cycle={this.props.patient.cycle_number}/>
                            </div>
                          )
                        }
                        </IfNotAuthSuperReadonly>
                        <IfAuthSuperReadonly>
                          <div>
                            <SampleTable items={this.props.patient.samples} cycle={this.props.patient.cycle_number}/>
                          </div>
                        </IfAuthSuperReadonly>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                  {
                    !item.m2 && (
                      <Row>
                      <Col>
                        <Card>
                          <CardHeader>
                            <h6 style={{margin: 0}}>Transfer Outcomes</h6>
                          </CardHeader>
                          <CardBody>
                            <TransferOutcomeForm
                              initialValues={this.props.patient.samples}
                              onSubmit={(data) => {
                                this.props.submitPatient({...this.props.patient, samples: data.samples})
                              }}
                              isSuper={isAuthSuper(this.props.user)}
                              case={this.props.patei}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )
                }
                </>
              )
            }

            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h6 style={{margin: 0}}>Saliva Samples</h6>
                  </CardHeader>
                  <CardBody>
                    <IfNotAuthSuperReadonly>
                      {
                        (['pending', 'case_accepted', 'gc_scheduled', 'setup_complete'].indexOf(this.props.patient.case_status) > -1) ? (
                          <Row>
                            <Col>
                              <SalivaSampleForm
                                initialValues={this.props.patient.saliva_samples}
                                onSubmit={(data) => {
                                  this.props.submitPatient({...this.props.patient, saliva_samples: data.samples})
                                }}
                                isSuper={isAuthSuper(this.props.user)}
                                patient={this.props.patient.patient}
                                partner={this.props.patient.partner}
                                m2={item.m2}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <SalivaSampleTable
                              items={this.props.patient.saliva_samples}
                              patient={this.props.patient.patient}
                              partner={this.props.patient.partner}
                              m2={item.m2}
                            />
                          </div>
                        )
                      }
                    </IfNotAuthSuperReadonly>
                    <IfAuthSuperReadonly>
                      <div>
                        <SalivaSampleTable
                          items={this.props.patient.saliva_samples}
                          patient={this.props.patient.patient}
                          partner={this.props.patient.partner}
                          m2={item.m2}
                        />
                      </div>
                    </IfAuthSuperReadonly>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>

                <FileUploadSection
                  fieldName='test_requisitions'
                  headerText='Test Requisition Form'
                />

                <FileUploadSection
                  fieldName='samples_form'
                  headerText='Sample Submission Form'
                />

                <FileUploadSection
                    fieldName='genetic_reports'
                    headerText='Genetic Report'
                />

                {
                  !item.m2 &&
                    <FileUploadSection
                        description="For patients referred from clinics within the United States, genetic counseling is
                        required for all patients who are undergoing PGT-M, PGT-P or PGT-SR. Genetic counseling is
                        optional for PGT-A. The GPCL will obtain consent for all patients who have a consultation with
                        the GPCL genetic counselor. If your patient is not speaking with a GPCL genetic counselor, it is
                        the responsibility of the patient's provider to obtain consent. Please print off the appropriate
                        consent form(s) and upload them after they have been signed by both patient and partner."
                        fieldName='consent_form'
                        headerText='Consent Form'
                    />
                }

                <FileUploadSection
                    fieldName='files'
                    headerText='Upload Documents'
                />
                <IfAuthSuperAdminOrReadonly>
                  <FileUploadSection
                    fieldName='gp_files'
                    headerText='GP Internal Documents'
                  />
                </IfAuthSuperAdminOrReadonly>

                <ReportUploadSection/>

                {/*<NotesSection*/}
                {/*  patient={item}*/}
                {/*  m2={item.m2}*/}
                {/*  onSave={(notes, gc_notes) => {*/}
                {/*    this.props.submitPatient({*/}
                {/*      ...this.props.patient,*/}
                {/*      notes: notes,*/}
                {/*      gc_notes: gc_notes*/}
                {/*    });*/}
                {/*  }}*/}
                {/*/>*/}

                <IfAuthGC>
                  <NotesSection2
                    data={item.gc_notes}
                    case_id={item.id}
                    field={'gc_notes'}
                    title={'Internal Notes'}
                  />
                </IfAuthGC>

                <NotesSection2
                  data={item.notes}
                  case_id={item.id}
                  field={'notes'}
                  title={'Notes'}
                />

              </Col>
            </Row>
            <IfAuthSuperAdminOrReadonly>
              <Row>
                <Col>
                  <BillingSection
                    patient={item}
                    onEdit={() => {
                      this.setState({...this.state, billingModalIsOpen: true})
                    }}
                  />
                </Col>
              </Row>
            </IfAuthSuperAdminOrReadonly>
          </TabPane>

          <TabPane tabId="report">
            <ReportSection
              data={item}
              onSubmit={(values) => this.props.submitPatient(values)}
            />
          </TabPane>

          <TabPane tabId="logs">
            <LogSection data={item}/>
          </TabPane>

          <TabPane tabId="journal">
            <JournalSection data={item}/>
          </TabPane>
        </TabContent>
      </div>
    ) : <LoadingIndicator/>;
  }
}

export default withRouter(
  connect(
    state => ({
      patient: state.patient.item,
      user: state.auth
    }),
    {
      getPatientDetail,
      patientModalOpen,
      deletePatient,
      putInArchive,
      toggleTestTypeModal,
      submitPatient,
      submitPerson
    }
  )(PatientDetail)
);
