import React, {Component} from 'react';
import {Col, Container, Row} from "reactstrap";
import LoadingOverlay from "utils/LoadingOverlay";

class LoginLayout extends Component {
    render() {
        const Component = this.props.component;
        const route = this.props.route;
        return (
            <div className="login-wrap">
                <Container fluid>
                    <Row className="justify-content-center align-items-center">
                        <Col md={3}>
                            <Component route={route} />
                        </Col>
                    </Row>
                </Container>
                <LoadingOverlay/>
            </div>
        );
    }
}

export default LoginLayout;