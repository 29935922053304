import React, {PureComponent} from 'react';
import {Col, Row, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {DISEASES} from 'constants/index';


export default class extends PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      selected_items: DISEASES
    };
  }

  onChange(item, checked) {
    const {input: {onChange}} = this.props;
    const arr = this.state.selected_items.slice();

    if (checked && !arr.includes(item)) {
      arr.push(item);
    }

    if (!checked && arr.includes(item)) {
      arr.splice(arr.indexOf(item), 1);
    }

    this.setState({
      ...this.state,
      selected_items: arr
    }, () => {
      onChange(JSON.stringify(this.state.selected_items))
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.input.value !== prevProps.input.value && !!this.props.input.value){
      const value = this.props.input.value

      if (value && value !== 'null') {
        this.setState({...this.state, selected_items: JSON.parse(value)});
      } else {
        this.props.input.onChange(JSON.stringify(this.state.selected_items))
      }
    }
  }

  render() {
    const {meta: {touched, error}} = this.props;

    const cols_n = 3;
    const items_in_column = Math.ceil(DISEASES.length / cols_n);
    const cols = [...Array(cols_n).keys()];

    return (
      <FormGroup className="align-items-center" check inline={false}>
        <Row>
          {
            cols.map((i) => {
              const current = i * (items_in_column);

              return (
                <Col key={'disease-list-col' + i}>
                  {
                    DISEASES.slice(current, current + items_in_column).map((item, j) => {
                      return (
                        <p key={'disease-'+i+'-' + j}>
                          <Label
                            className={touched && error ? 'text-danger' : ''}
                            check
                          >
                            <Input
                              type="checkbox"
                              value={item}
                              checked={this.state.selected_items.includes(item)}
                              onChange={(e) => this.onChange(e.target.value, e.target.checked)}
                            />
                            {item}
                          </Label>
                        </p>
                      )
                    })
                  }
                </Col>
              )
            })
          }
        </Row>


        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    )
  }
}
