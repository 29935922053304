import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getSalivaSampleList, deleteSalivaSampleList} from "actions/sample";
import {Button, Card, Col, Row, CardHeader, CardBody} from "reactstrap";
import TablePlaceholder from "components/Placeholders/TablePlaceholder";
import Modal from './Modal'
import {CardTable, Page, PageContent, PageHeader, TableCellWithLink, TableRowWithLink} from 'components/Views';
import Moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isAuthDistributor} from "components/RBAC";


export default connect(
  state => ({
    items: state.sample.saliva_sample_list,
    user: state.auth,
    selected_provider_id: state.common.selected_provider_id,
    providers: state.provider.items
  }), {
    getList: getSalivaSampleList,
    deleteSalivaSampleList: deleteSalivaSampleList
  }
)(
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.onItemClick = this.onItemClick.bind(this);
      this.onDelete = this.onDelete.bind(this);
      this.state = {
        modalIsOpen: false,
        item: {},
        active_index: null
      };
    }

    componentDidMount() {
      this.props.getList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.selected_provider_id !== prevProps.selected_provider_id && !!this.props.selected_provider_id) {
        this.props.getList();
      }

      if (this.props.items !== prevProps.items) {
        this.setState({
          ...this.state,
          modalIsOpen: false,
          item: {}
        })
      }
    }

    onItemClick(item, i) {
      this.setState({
        ...this.state,
        item: item,
        modalIsOpen: true,
        active_index: i
      })
    }

    onDelete(item, i) {
      const result = window.confirm("Are you sure?");
      if (result) {
        this.props.deleteSalivaSampleList(item.id, i);
      }
    }

    render() {
      const {items} = this.props;

      return (
        <Page>
          <PageHeader>
            <Row className="align-items-center justify-content-between">
              <Col>
                <h3 className="page-title">Saliva Samples</h3>
              </Col>
              <Col md="auto">
                <Button
                  color="light"
                  onClick={() => {
                    if (isAuthDistributor(this.props.user) && this.props.providers && this.props.providers.length <= 0) {
                      alert('Create clinic first')
                    } else {
                      this.setState({...this.state, modalIsOpen: true, item: {}})
                    }
                  }}
                >
                  <FontAwesomeIcon icon="plus"/>{' '}Add New
                </Button>
              </Col>
            </Row>
          </PageHeader>

          <PageContent>
            {
              items ? (
                <div>
                  {
                    items.length > 0 ? (
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h6 style={{margin: 0}}>Total: {items.length}</h6>
                            </Col>
                            <Col md="auto">
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <CardTable
                            id={'patients'}
                            columns={['Label', 'Person', 'Case', 'Sent', 'Collected', 'Received', '']}
                          >
                            {
                              items.map((item, i) =>
                                <TableRowWithLink
                                  key={'list-item-' + i}
                                  onClick={() => this.onItemClick(item, i)}
                                >
                                  <TableCellWithLink>
                                    {item.label}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {
                                      item.person_type === 'partner' && <p>Partner</p>
                                    }
                                    {
                                      item.person_type === 'patient' && <p>Patient</p>
                                    }
                                    {
                                      ((item.person_type === 'other' || item.person_type === null) && item.person) && (
                                        <span className="text-muted">
                                          {item.person.first_name}{' '}{item.person.last_name}<br/>
                                          Age: {Moment().diff(item.person.dob, 'years')}{' '}
                                          ({Moment(item.person.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')})
                                          {
                                            item.person.related_to &&
                                            <div>Related To: {item.person.related_to} ({item.person.related_as})</div>
                                          }
                                        </span>
                                      )
                                    }
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.case_name}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.sent_at && Moment(item.sent_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.collection_date && Moment(item.collection_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.received_at && Moment(item.received_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    <div className="text-right text-danger">
                                      <button
                                        className="link-button text-danger"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          e.preventDefault();
                                          this.onDelete(item, i)
                                        }}
                                      >
                                        <FontAwesomeIcon icon="trash-alt"/>
                                      </button>
                                    </div>
                                  </TableCellWithLink>
                                </TableRowWithLink>
                              )
                            }
                          </CardTable>
                        </CardBody>
                      </Card>
                    ) : 'No Samples'
                  }
                </div>
              ) : <TablePlaceholder/>
            }
          </PageContent>

          <Modal
            isOpen={this.state.modalIsOpen}
            close={() => this.setState({...this.state, modalIsOpen: false, item: {}})}
            defaultValues={this.state.item}
            index={this.state.active_index}
          />
        </Page>
      )
    }
  }
)
