import React from 'react';
import Form from "components/Patient/Form/PersonForm";


export default (props) => {
  const { case_data, close, onSuccess, data, partner, patient } = props;

  return (
    <div>
      <Form
        onCancel={close}
        onSuccess={onSuccess}
        initialValues={data}
        partner={partner}
        patient={patient}
        addressRequired={!!patient}
        case_data={case_data}
      />
    </div>
  )
}
