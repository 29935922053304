import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";


const diseases = [
  'Type 1 Diabetes',
  'Type 2 Diabetes',
  'Breast Cancer',
  'Prostate Cancer',
  'Testicular Cancer',
  'Malignant Melanoma',
  'Basal Cell Carcinoma',
  'Heart Attack',
  'Coronary Artery Disease',
  'Atrial Fibrillation',
  'Asthma',
  'Inflammatory Bowel Disease',
  'Schizophrenia'
];


class DiseaseHistoryForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      type: 'Polygenic',
      person: '',
      disease: '',
      status: '',
      mutation: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.type && this.state.person &&
        ((this.state.disease && this.state.status !== 'Normal') || this.state.status === 'Normal')) {
      this.setState({...this.state, submitted: false})
      this.props.onSubmit(this.state);
    } else {
      this.setState({...this.state, submitted: true})
    }
  }

  render() {
    const { submitted, type, person, disease, status, mutation } = this.state
    return (
      <form onSubmit={(e) => {
        e.preventDefault()
      }} autoComplete="off">
        <FormGroup row>
          <Label for="type" md={4}>
            Type
            <span className="text-danger">*</span>
          </Label>
          <Col md={8}>
            <Input
              type="select"
              className={submitted && !type && 'is-invalid'}
              name="type"
              id="type"
              value={type}
              onChange={(e) => {
                this.setState({...this.state, type: e.target.value})
              }}
            >
              <option value="Polygenic">Polygenic</option>
              <option value="Monogenic">Monogenic</option>
              <option value="Translocation">Translocation</option>
            </Input>
            <div className="invalid-feedback">
              <span>Required</span>
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="person" md={4}>Person
            <span className="text-danger">*</span>
          </Label>
          <Col md={8}>
            {
              type !== 'Translocation' ? (
                <Input
                  type="select"
                  className={submitted && !person && 'is-invalid'}
                  name="person"
                  id="person"
                  value={person}
                  onChange={(e) => {
                    this.setState({...this.state, person: e.target.value})
                  }}
                >

                  <option value={''} disabled>Select</option>
                  <option value="Self">Self</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Existing Child - Current Partner">Existing Child - Current Partner</option>
                  <option value="Existing Child - Previous Partner">Existing Child - Previous Partner</option>

                  <optgroup label="Mother">
                    <option value="Mother">Mother</option>
                    <option value="Mother's Father">Mother's Father</option>
                    <option value="Mother's Mother">Mother's Mother</option>
                    <option value="Mother's Sister">Mother's Sister</option>
                    <option value="Mother's Brother">Mother's Brother</option>
                  </optgroup>

                  <optgroup label="Father">
                    <option value="Father">Father</option>
                    <option value="Father's Father">Father's Father</option>
                    <option value="Father's Mother">Father's Mother</option>
                    <option value="Father's Sister">Father's Sister</option>
                    <option value="Father's Brother">Father's Brother</option>
                  </optgroup>
                </Input>
              ) : (
                <Input
                  type="select"
                  className={submitted && !person && 'is-invalid'}
                  name="person"
                  id="person"
                  value={person}
                  onChange={(e) => {
                    this.setState({...this.state, person: e.target.value})
                  }}
                >

                  <option value={''} disabled>Select</option>
                  <option value="Self">Self</option>
                  <option value="Existing Child - Current Partner">Existing Child - Current Partner</option>
                  <option value="Existing Child - Previous Partner">Existing Child - Previous Partner</option>
                </Input>
              )
            }
                <div className="invalid-feedback">
                  <span>Required</span>
                </div>
          </Col>
        </FormGroup>

        {
            type !== 'Translocation' && (
            <FormGroup row>
              <Label md={4}>Status</Label>
              <Col md={8}>
                <Input
                  type="select"
                  className={submitted && !status && 'is-invalid'}
                  name="status"
                  id="status"
                  value={status}
                  onChange={(e) => {
                    this.setState({...this.state, status: e.target.value})
                  }}
                >
                  <option value={''} disabled>Select</option>
                  {type === 'Monogenic' && <option value="Unaffected/Negative">Unaffected/Negative</option>}
                  {type === 'Monogenic' && <option value="Affected/Positive">Affected/Positive</option>}
                  {type === 'Monogenic' && <option value="Unaffected/Carrie">Unaffected/Carrier</option>}

                  {type !== 'Monogenic' && <option value="Unaffected">Unaffected</option>}
                  {type !== 'Monogenic' && <option value="Affected">Affected</option>}
                </Input>
                <div className="invalid-feedback">
                  <span>Required</span>
                </div>
              </Col>
            </FormGroup>
          )
        }

        {
          (status !== 'Normal' && (type === 'Monogenic' || type === 'Translocation')) && (
            <FormGroup row>
              <Label for="disease" md={4}>
                {type === 'Translocation' ? 'Karyotype' : 'Disease Description'}
                <span className="text-danger">*</span>
              </Label>
              <Col md={8}>
                <Input
                  type="textarea"
                  className={submitted && !disease && 'is-invalid'}
                  name="disease"
                  id="disease"
                  value={disease}
                  onChange={(e) => {
                    this.setState({...this.state, disease: e.target.value})
                  }}
                />
                <div className="invalid-feedback">
                  <span>Required</span>
                </div>
              </Col>
            </FormGroup>
          )
        }

        {
          (status !== 'Normal' && type === 'Polygenic') && (
            <FormGroup row>
              <Label for="disease" md={4}>
                Disease
                <span className="text-danger">*</span>
              </Label>
              <Col md={8}>
                <Input
                  type="select"
                  className={submitted && !disease && 'is-invalid'}
                  name="disease"
                  id="disease"
                  value={disease}
                  onChange={(e) => {
                    this.setState({...this.state, disease: e.target.value})
                  }}
                >
                  <option value={''} disabled key="disease-empty">Select</option>
                  {
                    diseases.map((item, i) => {
                      return <option value={item} key={'disease-' + i}>{item}</option>
                    })
                  }
                </Input>
                <div className="invalid-feedback">
                  <span>Required</span>
                </div>
              </Col>
            </FormGroup>
          )
        }

        <FormGroup row>
          <Label for="mutation" md={4}>Mutation</Label>
          <Col md={8}>
            <Input
              type="text"
              name="mutation"
              id="mutation"
              value={mutation}
              onChange={(e) => {
                this.setState({...this.state, mutation: e.target.value})
              }}
            />
          </Col>
        </FormGroup>

        <Row style={{ marginTop: 30 }} className="justify-content-start">
          <Col md="auto">
            <Button color="success"
                    disabled={
                      !(this.state.type && this.state.person &&
                      ((this.state.disease && this.state.status !== 'Normal')
                          || this.state.status === 'Normal')
                      )
                    }
                    onClick={(e) => this.onSubmit(e)}>Submit</Button>
          </Col>
          <Col md="auto">
            <Button color="light" onClick={() => this.props.onCancel()}>Cancel</Button>
          </Col>
        </Row>

      </form>
    )
  }
}

export default connect(

)(DiseaseHistoryForm);