import axios from "axios";

export function call_delete_token(token) {
    axios({
        method: 'POST',
        responseType: 'json',
        data: {
            'token': token
        },
        baseURL: process.env.REACT_APP_API_HOST,
        url: 'delete-token/'
    })
        .then((resp) => {
            window.localStorage.removeItem('token_expiration_time');
            window.localStorage.removeItem('clinic_list');
            window.localStorage.removeItem('persist:clinic-portal');
            window.localStorage.removeItem('clinic_list_expire');
            window.location.href = '/login';
        })
        .catch((e) => {
            if (e.response.status === 400 || e.response.status === 401) {
                window.localStorage.removeItem('token_expiration_time');
                window.localStorage.removeItem('clinic_list');
                window.localStorage.removeItem('persist:clinic-portal');
                window.localStorage.removeItem('clinic_list_expire');
                window.location.href = '/login';
            }
        })
}