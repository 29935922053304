import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalHeader} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
// import {submitPerson} from "actions/person";
import {Tab, Tabs} from "@blueprintjs/core";
import PersonModalCreateEditSection from "components/Patient/Form/PersonModalCreateEditSection";
import PersonModalSelectSection from "components/Patient/Form/PersonModalSelectSection";
import axios from "axios";
import {getToken} from "../../../auth/token";

export default connect(
    null,
    null
)(
    (props) => {

      const updateCasePerson = (values) => {
        const url = `patients/${props.case_id}/update_${props.patient ? 'patient' : 'partner'}/`;

        return axios({
          baseURL: process.env.REACT_APP_API_HOST,
          url: url,
          method: 'PATCH',
          responseType: 'json',
          validateStatus: () => true,
          headers: {
            "Authorization": `Token ${getToken()}`
          },
          data: {person_id: values.id}
        });
      };

      const submit = async (values) => {
          return updateCasePerson(values);
      };

      const handleResponse = (data) => {
          // if (data.type === '@@person/PERSON_UPDATE_SUCCESS' || data.type === '@@person/PERSON_CREATE_SUCCESS') {
          //    props.onSuccess(data.payload)
          // }
          props.onSuccess();
      };

      const [activeTab, setActiveTab] = useState('edit');  // edit or select

      return (
          <Modal isOpen={props.isOpen} toggle={props.close}>
              <ModalHeader toggle={props.close}>
                  {props.patient && 'Patient'}
                  {props.partner && 'Partner'}
              </ModalHeader>

              <ModalBody>
                  <Tabs id="TabsExample" onChange={(tab) => {setActiveTab(tab)}} selectedTabId={activeTab}>
                      <Tab id="edit" title="Create/Edit" panel={<PersonModalCreateEditSection {...props}/>}/>
                      <Tab id="select" title="Select" panel={<PersonModalSelectSection submit={submit} handleResponse={handleResponse} {...props}/>} />
                      <Tabs.Expander />
                  </Tabs>

              </ModalBody>
          </Modal>
        )
    }
)


