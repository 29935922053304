import React, {useState} from 'react';
import useData from "api/useData";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardTable, Page, PageContent, TableCellWithLink, TableRowWithLink} from "components/Views";
import {Paginator} from 'utils';


export default (props) => {
  const [{data, error, loading}, {updateParams}] = useData('CASE');
  const [setModalIsOpen] = useState(false);

  if (loading) {
    return 'loading'
  }

  if (error) {
    return <p>{error.data.detail}</p>
  }

  if (data) {
    return (
      <Page>

        <div className="page-header">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h3 className="page-title">Cases</h3>
            </Col>

            <Col md="auto">
              <Button
                color="light"
                onClick={() => setModalIsOpen(true)}
              >
                <FontAwesomeIcon icon="plus"/> Create
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>

            </Col>
          </Row>
        </div>

        <PageContent>
          <div>
            {
              data.count > 0 ? (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h6 style={{margin: 0}}>Total: {data.count}</h6>
                      </Col>
                      <Col md="auto">
                        <Paginator
                          start={1}
                          end={data.pages}
                          onChange={(page) => updateParams({page: page})}
                          total={data.count}
                          loading={loading}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <CardTable
                      id={'patients'}
                      columns={['ID', 'Name', 'Patient, Partner', 'Samples', 'Consent Form', 'Registration', 'GC', 'Created']}
                    >
                      {
                        data.results.map((item, i) =>
                          <TableRowWithLink
                            key={'list-item-' + i}
                            to={'/patients/' + item.id}
                          >
                            <TableCellWithLink>
                              {item.case_id ? <strong>{item.case_id}</strong> :
                                <span className="text-muted">{`TEMP${item.id}`}</span>}
                            </TableCellWithLink>

                            <TableCellWithLink>
                              <strong>
                                {item.case_name}
                              </strong>
                            </TableCellWithLink>

                            <TableCellWithLink>
                              <strong>
                                {
                                  item.partner &&
                                  <>
                                    {item.patient.first_name}&nbsp;{item.patient.last_name},<br/>
                                  </>
                                }

                                {
                                  item.partner &&
                                  <>
                                    {item.partner.first_name}&nbsp;{item.partner.last_name}
                                  </>
                                }
                              </strong>
                            </TableCellWithLink>

                            <TableCellWithLink>
                              {item.sample_count}
                            </TableCellWithLink>

                            <TableCellWithLink>
                              {
                                item.consent_form ?
                                  <FontAwesomeIcon icon="check" className="text-success"/> :
                                  <FontAwesomeIcon icon="exclamation-triangle" className="text-warning"/>
                              }
                            </TableCellWithLink>

                            <TableCellWithLink>
                              {
                                item.application_completed ?
                                  <FontAwesomeIcon icon="check" className="text-success"/> :
                                  <span className="text-muted">-</span>
                              }
                            </TableCellWithLink>

                            <TableCellWithLink>
                              {item.gc_requested ? (
                                <div>
                                  <div className="text-success">requested</div>
                                    <div>
                                      Scheduled: {
                                      item.gc_scheduled ?
                                        <span className="text-success">Yes</span> :
                                        <span className="text-muted">No</span>
                                    }
                                    </div>
                                </div>
                              ) : <div className="text-muted">declined</div>}
                              {
                                // item.gc_requested && item.gc_scheduled && (
                                //   <div>
                                //     Status: {item.gc_completed ?
                                //     <span className="text-success">Completed</span> :
                                //     <span className="text-muted">Not Completed</span>
                                //   }
                                //   </div>
                                // )
                              }
                            </TableCellWithLink>

                            <TableCellWithLink width={160}>

                            </TableCellWithLink>
                          </TableRowWithLink>
                        )
                      }
                    </CardTable>
                  </CardBody>
                </Card>
              ) : <p>No patients</p>
            }
          </div>
        </PageContent>
      </Page>
    )
  }

  return null;
}
