import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Link from './RBACLink';

export const PROVIDER_ALLOWED_ROLES = ['provider', 'distributor', 'gc', 'super', 'super-readonly'];
export const DISTRIBUTOR_ALLOWED_ROLES = ['distributor', 'gc', 'super', 'super-readonly'];
export const GC_ALLOWED_ROLES = ['gc', 'super', 'super-readonly'];
export const SUPER_ADMIN_ALLOWED_ROLES = ['super'];
export const SUPER_READONLY_ALLOWED_ROLES = ['super-readonly'];
export const SUPER_ADMIN_OR_READONLY_ROLES = ['super', 'super-readonly'];


const Authorization = (allowedRoles) => (WrappedComponent) => {
  return connect(
    state => ({
      user: state.auth
    })
  )(
    class extends PureComponent {
      render() {
        const {role} = this.props.user;
        if (allowedRoles.includes(role)) {
          return <WrappedComponent {...this.props} />
        } else {
          return <h1>No page for you!</h1>
        }
      }
    }
  )
};

export const AuthProvider = Authorization(PROVIDER_ALLOWED_ROLES);
export const AuthDistributor = Authorization(DISTRIBUTOR_ALLOWED_ROLES);
export const AuthGC = Authorization(GC_ALLOWED_ROLES);

export const IfAuthProvider = connect(
  state => ({user: state.auth})
)(({children, user}) => PROVIDER_ALLOWED_ROLES.includes(user.role) && children);

export const IfAuthDistributor = connect(
  state => ({user: state.auth})
)(({children, user}) => DISTRIBUTOR_ALLOWED_ROLES.includes(user.role) && children);

export const IfAuthGC = connect(
  state => ({user: state.auth})
)(({children, user}) => GC_ALLOWED_ROLES.includes(user.role) && <div className={user.first_name === 'Leslie' ? 'show-auth' : ''}>{children}</div>);

export const IfAuthSuper = connect(
  state => ({user: state.auth})
)(({children, user}) => SUPER_ADMIN_ALLOWED_ROLES.includes(user.role) ?
      children :
      null
);

export const IfAuthSuperReadonly = connect(
  state => ({user: state.auth})
)(
  ({children, user}) =>
    SUPER_READONLY_ALLOWED_ROLES.includes(user.role) ?
      children :
      null
);

export const IfNotAuthSuperReadonly = connect(
  state => ({user: state.auth})
)(
  ({children, user}) =>
    !SUPER_READONLY_ALLOWED_ROLES.includes(user.role) ?
      children :
      null
);

export const IfAuthSuperAdminOrReadonly = connect(
  state => ({user: state.auth})
)(
  ({children, user}) =>
    SUPER_ADMIN_OR_READONLY_ROLES.includes(user.role) ?
      children :
      null
);


export const IfAuthCanReport = connect(
  state => ({user: state.auth})
)(
  ({children, user}) =>
    user.can_report ?
      children :
      null
);

export const IfAuthHasPermissions = connect(
  state => ({user: state.auth})
)(
  ({permissionsList, children, user}) => {
    if (user.permissions && user.permissions.includes('super_user')) {
      return children;
    }

    for (const permission of permissionsList) {
      if (user.permissions && user.permissions.includes(permission)) {
        return children;
      }
    }

    return null;
  }
);

export const IfAuthSuper2 = connect(
  state => ({user: state.auth})
)(
  ({children, user, component}) => {
    const Elem = (props) => {
      switch (component) {
        case 'td':
          return <td {...props}/>;

        case 'th':
          return <th {...props}/>;

        case 'span':
          return <span {...props}/>;

        case 'div':
          return <div {...props}/>;

        default:
          return <div {...props}/>;
      }
    };

    return SUPER_ADMIN_ALLOWED_ROLES.includes(user.role) ?
      <Elem className={user.first_name === 'Leslie' ? 'show-auth' : ''}>{children}</Elem> :
      null
  }
);

export const IfAuthSuperTd = connect(
  state => ({user: state.auth})
)(
  ({children, user}) =>
    SUPER_ADMIN_ALLOWED_ROLES.includes(user.role) ?
      <td>{children}</td> :
      null
);

export const IfAuthProviderOnly = connect(
  state => ({user: state.auth})
)(({children, user}) => user.role === 'provider' && children);


export const isAuthDistributor = (user) => DISTRIBUTOR_ALLOWED_ROLES.includes(user.role);
export const isAuthSuper = (user) => SUPER_ADMIN_ALLOWED_ROLES.includes(user.role);
export const isAuthSuperReadonly = (user) => SUPER_READONLY_ALLOWED_ROLES.includes(user.role);
export const isAuthSuperAdminOrReadonly = (user) => SUPER_ADMIN_OR_READONLY_ROLES.includes(user.role);
export const isAuthProvider = (user) => user.role === 'provider';

export const IfAuthM2 = connect(
  state => ({user: state.auth})
)(({children, user}) => {
  if (isAuthSuperAdminOrReadonly(user) || user.m2_enabled) {
    return (
      <div>
        {children}
      </div>
    )
  }

  return null;
});

export {
  Link
}
