import * as sample from '../actions/sample';
import * as modal from '../actions/modal';
import * as sampleCase from '../actions/case';


const initialState = {
  sample: null,
  items: null,
  hoveredChromosome: null,
  activeChromosome: null,
  saliva_sample_list: null,
  new_item: null,
};

let array = [];
let new_array = [];

export const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case sample.SAMPLE_DETAIL_SUCCESS:
      return {
        ...state,
        sample: action.payload
      };

    case modal.SAMPLE_MODAL_CLOSE:
      return {
        ...state,
        sample: null
      };

    case sample.SET_HOVERED_CHROMOSOME:
      return {
        ...state,
        hoveredChromosome: action.chromosome
      };

    case sample.SET_ACTIVE_CHROMOSOME:
      return {
        ...state,
        activeChromosome: action.chromosome
      };

    case sample.SAMPLE_CREATE_SUCCESS:
      array = state.items.slice();
      array.push(action.payload);
      return {
        ...state,
        items: array
      };

    case sample.SAMPLE_UPDATE_SUCCESS:
      const id = action.payload.id;
      array = state.items.slice();

      new_array = array.map((item) => {
        if(item.id !== id){
          return item;
        }

        return{
          ...item,
          ...action.payload
        }
      });

      return {
        ...state,
        items: new_array
      };

    case sampleCase.CASE_DETAIL_SUCCESS:
      return {
        ...state,
        items: action.payload.samples
      };

    case sampleCase.CASE_CREATE_SUCCESS:
      return {
        ...state,
        items: action.payload.samples
      };

    case sampleCase.CASE_DELETE_SUCCESS:
      return {
        ...state,
        items: null
      };

    case sample.SAMPLE_DELETE_SUCCESS:
      array = state.items.slice();
      array.splice(action.meta.index, 1);

      return {
        ...state,
        items: array
      };

    case sample.SALIVA_SAMPLE_LIST_REQUEST:
      return {
        ...state,
        saliva_sample_list: null
      };

    case sample.SALIVA_SAMPLE_LIST_SUCCESS:
      return {
        ...state,
        saliva_sample_list: action.payload
      };

    case sample.SALIVA_SAMPLE_CREATE_SUCCESS:
      array = state.saliva_sample_list.slice();
      array.unshift(action.payload);
      return {
        ...state,
        saliva_sample_list: array
      };

    case sample.SALIVA_SAMPLE_UPDATE_SUCCESS:
      const saliva_id = action.payload.id;
      array = state.saliva_sample_list.slice();

      new_array = array.map((item) => {
        if(item.id !== saliva_id){
          return item;
        }

        return{
          ...item,
          ...action.payload
        }
      });

      return {
        ...state,
        new_item: action.payload,
        saliva_sample_list: new_array
      };

    case sample.SALIVA_SAMPLE_DELETE_SUCCESS:
      array = state.saliva_sample_list.slice();
      array.splice(action.meta.index, 1);

      return {
        ...state,
        saliva_sample_list: array
      };

    default:
      return state;
  }
};