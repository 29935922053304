import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Form from './Form';
import {closeModal, submitProvider} from "actions/provider";
import Alert from "utils/Alert";

export default connect(
  state => ({
    errors: state.provider.errors,
    isOpen: state.provider.modalIsOpen,
    data: state.provider.modalData
  }),
  {
    submitProvider: submitProvider,
    close: closeModal
  }
)(
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(data){
      await this.props.submitProvider(data);
      window.localStorage.removeItem('provider');
      window.location.href = '/clinics';
    }

    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={() => this.props.close()} size={'lg'}>
          <ModalHeader toggle={() => this.props.close()}>
            Clinic
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit} initialValues={this.props.data} onCancel={() => this.props.close()}/>
            {
              this.props.errors && this.props.errors.length > 0 ? (
                <Alert color="danger">
                  {
                    this.props.errors.map((error, i) => (
                      <div key={'error-' + i}>{error}</div>
                    ))
                  }
                </Alert>
              ) : ''
            }
          </ModalBody>
        </Modal>
      )
    }
  }
)
