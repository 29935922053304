import React, {PureComponent} from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {Button, Col, FormFeedback, FormGroup, Label} from "reactstrap";
import classNames from 'classnames';
import * as Yup from 'yup';
import * as CountriesJSON from './country_state_code';


const Schema = Yup.object().shape({
  street: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  unit: Yup.string()
    .max(120, 'Too Long!'),
  country: Yup.string()
    .required('Required'),
  state: Yup.string()
    .when('country', {
      is: (val) => val && CountriesJSON.default[val]['states'].length > 0,
      then: Yup.string().required('Required')
    }),
  city: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  postal: Yup.string()
    .required('Required'),
});

export default class extends PureComponent {
  render() {
    const Countries = CountriesJSON.default;

    return (
      <Formik
        initialValues={
          {
            street: '',
            unit: '',
            postal: '',
            country: '',
            state: '',
            city: '',
            ...this.props.initialValues
          }
        }
        validationSchema={Schema}
        onSubmit={
          (values, {setSubmitting}) => {
            this.props.onSubmit(values);
            setSubmitting(false)
          }
        }
      >

        {
          ({errors, touched, values, isSubmitting}) => (
            <Form>
              <FormGroup row>
                <Label for="country" sm={4}>Country</Label>
                <Col sm={8}>
                  <Field
                    component="select"
                    name="country"
                    className={classNames('form-control', {'is-invalid': touched.country && !!errors.country})}
                  >
                    <option value={''} key={'country-none'} disabled>Select Country</option>
                    {
                      Object.keys(Countries).map((code, i) => {
                        return <option value={code} key={'country-' + i}>{Countries[code].name}</option>
                      })
                    }
                  </Field>
                  <FormFeedback>
                    <ErrorMessage name="country" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="street" sm={4}>Address Line 1</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="street"
                    className={classNames('form-control', {'is-invalid': touched.street && !!errors.street})}
                  />
                  <FormFeedback>
                    <ErrorMessage name="street" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="unit" sm={4}>Address Line 2</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="unit"
                    className={classNames('form-control', {'is-invalid': touched.unit && !!errors.unit})}
                  />
                  <FormFeedback>
                    <ErrorMessage name="unit" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="city" sm={4}>City</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="city"
                    className={classNames('form-control', {'is-invalid': touched.city && !!errors.city})}
                  />
                  <FormFeedback>
                    <ErrorMessage name="city" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>



              {
                values.country && Countries[values.country] && Countries[values.country]['states'].length ? (
                  <FormGroup row>
                    <Label for="state" sm={4}>State/Province/Region</Label>
                    <Col sm={8}>
                      <Field
                        component="select"
                        name="state"
                        className={
                          classNames('form-control', {
                            'is-invalid': values.country && touched.state && errors.state,
                          })
                        }
                        disabled={!values.country}
                      >
                        <option value={''} key={'state-none'} disabled>Select...</option>
                        {
                          Countries[values.country]['states'].map((item, i) => {
                            return <option key={'state-' + i}>{item}</option>
                          })
                        }
                      </Field>
                      <FormFeedback>
                        <ErrorMessage name="state" component="span"/>
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                ) : ''
              }

              <FormGroup row>
                <Label for="postal" sm={4}>Zip Code</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="postal"
                    className={classNames('form-control', {'is-invalid': touched.postal && !!errors.postal})}
                  />
                  <FormFeedback>
                    <ErrorMessage name="postal" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>



              <FormGroup className="text-center" style={{marginTop: 30}}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !!errors.length}
                  color="success"
                  outline
                >
                  Add this Address
                </Button>
              </FormGroup>
            </Form>
          )
        }
      </Formik>
    );
  }
}
