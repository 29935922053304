import React, {useState} from 'react';
import {Button, Classes, ControlGroup, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import axios from "axios";
import {getToken} from "auth/token";


export default (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');

  const {id} = props.provider;

  const submit = () => {
    setLoading(true);
    setError(null);

    axios({
      method: 'POST',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `providers/${id}/send_password/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: {email: email}
    })
      .then((resp) => {
        setIsOpen(false);
        alert("The password was sent. Please check the email.");
      })
      .catch((e) => {
        if (e.response && e.response.data) {
          setError(e.response.data.status);
          if (e.response.data.status === 'email_not_allowed') {
            setError('Email not allowed');
          }
        } else {
          setError('Unexpected error');
        }
      })
      .finally(() => {
        setLoading(false);
        setEmail('');
      });
  }

  return (
    <div>
      <button
        className="link-button"
        onClick={(e) => {
        e.preventDefault();
        setIsOpen(true)
      }}>
        Send
      </button>

      <Dialog
        icon="warning-sign"
        onClose={() => setIsOpen(false)}
        title="Send Password"
        isOpen={isOpen}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <div className={Classes.DIALOG_BODY}>

            <p>The password will be automatically regenerated and sent to the email. Only GP emails are allowed.</p>

            <FormGroup
              helperText={error ? error : null}
              label="GP Email"
              labelFor="email"
              intent={error ? Intent.DANGER : null}
            >
              <ControlGroup>
                <InputGroup
                  id="email"
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  intent={error ? Intent.DANGER : null}
                />
                <Button disabled intent={error ? Intent.DANGER : null}>@genomicprediction.com</Button>
              </ControlGroup>
            </FormGroup>

          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                type="submit"
                intent="primary"
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}
