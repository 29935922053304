import React from 'react';
import {Button, Col, FormFeedback, FormGroup, Label} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import classNames from "classnames";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  address: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  phone: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Required'),
  phone2: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!'),
  email2: Yup.string()
    .email('Must be a valid email')
});


const fixValues = (data) => (
  JSON.parse(
    JSON.stringify(data, (key, value) => value === null ? '' : value)
  )
);

const fixValues2 = (data) => (
  JSON.parse(
    JSON.stringify(data, (key, value) => value === '' ? null : value)
  )
);


export default (props) => {
  return (
    <div>
      <Formik
        initialValues={
          {
            address: '',
            email: '',
            email2: '',
            phone: '',
            phone2: '',
            ...fixValues(props.initialValues)
          }
        }
        validationSchema={Schema}
        onSubmit={
          (values, {setSubmitting}) => {
            props.onSubmit(fixValues2(values));
            setSubmitting(false)
          }
        }
      >
        {
          ({errors, touched, values, isSubmitting}) => (
            <Form autoComplete="off">
              <FormGroup row>
                <Label for="address" sm={4}>Address</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="address"
                    className={classNames('form-control', {
                      'is-invalid': touched.address && !!errors.address
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="company_name" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email" sm={4}>Email 1</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="email"
                    className={classNames('form-control', {
                      'is-invalid': touched.email && !!errors.email
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="email" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email2" sm={4}>Email 2</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="email2"
                    className={classNames('form-control', {
                      'is-invalid': touched.email2 && !!errors.email2
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="email2" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phone" sm={4}>Phone 1</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="phone"
                    className={classNames('form-control', {
                      'is-invalid': touched.phone && !!errors.phone
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="phone" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phone2" sm={4}>Phone 2</Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="phone2"
                    className={classNames('form-control', {
                      'is-invalid': touched.phone2 && !!errors.phone2
                    })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="phone2" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>



              <FormGroup className="text-center" style={{marginTop: 30}}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !!errors.length}
                  color="success"
                  outline
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )
        }
      </Formik>
    </div>
  )
}
