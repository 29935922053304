import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';

export const PERSON_CREATE_REQUEST = '@@person/PERSON_CREATE_REQUEST';
export const PERSON_CREATE_SUCCESS = '@@person/PERSON_CREATE_SUCCESS';
export const PERSON_CREATE_FAILURE = '@@person/PERSON_CREATE_FAILURE';

export const PERSON_UPDATE_REQUEST = '@@person/PERSON_UPDATE_REQUEST';
export const PERSON_UPDATE_SUCCESS = '@@person/PERSON_UPDATE_SUCCESS';
export const PERSON_UPDATE_FAILURE = '@@person/PERSON_UPDATE_FAILURE';

export const submitPerson = (data) => {
  const uri = `persons/${data.id ? (data.id + '/') : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  return ({
    [RSAA]: {
      endpoint: uri,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types: data.id ? [PERSON_UPDATE_REQUEST, PERSON_UPDATE_SUCCESS, PERSON_UPDATE_FAILURE] :
        [PERSON_CREATE_REQUEST, PERSON_CREATE_SUCCESS, PERSON_CREATE_FAILURE],
      body: JSON.stringify(data)
    }
  })
};
