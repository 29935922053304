import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deleteProvider, getProviderDetails, openModal} from "actions/provider";
import {Page, PageContent, PageHeader} from "components/Views";
import {Button, Col, Row} from "reactstrap";
import {withRouter} from 'react-router-dom';
import PatientSection from './PatientSection';
import {addAlert} from "actions/alert";
import FormatLegacy from "components/Address/FormatLegacy";
import {IfAuthSuper, IfNotAuthSuperReadonly} from "components/RBAC";
import UsernameEditModal from "components/Provider/UsernameEditModal";
import SendPasswordModal from "components/Provider/SendPasswordModal";


export default withRouter(
  connect(
    state => ({
      provider: state.provider.provider,
      errors: state.provider.errors
    }),
    {
      getProviderDetails: getProviderDetails,
      deleteProvider: deleteProvider,
      addAlert: addAlert,
      openModal: openModal
    }
  )(
    class extends Component {
      constructor(props) {
        super(props);
        this._id = props.match.params.id;
        this.deleteProvider = this.deleteProvider.bind(this);
        this.state = {
          newUsername: null
        }
      }

      componentDidMount() {
        this.props.getProviderDetails(this._id);
      }

      async deleteProvider(id) {
        const result = window.confirm("Are you sure?");
        if (result) {
          await this.props.deleteProvider(id);

          const clinics = JSON.parse(window.localStorage.getItem('clinic_list'));
          clinics['Clinics'] = clinics['Clinics'].filter(clinic => clinic.id !== id);
          window.localStorage.setItem('clinic_list', JSON.stringify(clinics));

          window.localStorage.removeItem('provider');
          window.location.href = '/clinics';
        }
      }

      componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.errors !== this.props.errors && !!this.props.errors) {
          this.props.addAlert({type: 'danger', message: this.props.errors});
        }
      }
      
      render() {
        const {provider} = this.props;
        return provider ? (
          <Page className="page">
            <PageHeader>
              <Row className="align-items-center justify-content-between">
                <Col>
                  <h6 className="text-muted">Clinic</h6>
                  <h3 className="page-title">{provider.company_name}</h3>
                  <br/><br/>
                  <p><strong>Email:</strong> {provider.email}</p>
                  {provider.tel && <p><strong>Phone Number:</strong> {provider.tel}</p>}
                  <p><strong>Sex of Embryo:</strong> {provider.hide_sex ? 'Do Not Report' : 'Report'}</p>
                  <p><strong>Report Embryo ID:</strong> {provider.include_embryo_id ? 'Yes' : 'No'}</p>
                  <p><strong>Report Embryo Grade:</strong> {provider.include_embryo_grade ? 'Yes' : 'No'}</p>
                  <p><strong>Report Biopsy Day:</strong> {provider.include_biopsy_day ? 'Yes' : 'No'}</p>
                  <p><strong>Show Cycle Number:</strong> {provider.show_cycle_number? 'Yes' : 'No'}</p>
                  <div><strong>Billing Structure</strong>
                    <table style={{marginTop: "10px", marginLeft: "30px"}}>
                      <tbody>
                      <tr>
                        <td style={{verticalAlign:'top'}}><b>PGT-A</b></td>
                        <td style={{verticalAlign:'top'}}><b>:</b></td>
                        <td style={{verticalAlign:'top'}}>
                          <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{provider.billing_structure_a}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign:'top'}}><b>PGT-A+</b></td>
                        <td style={{verticalAlign:'top'}}><b>:</b></td>
                        <td style={{verticalAlign:'top'}}>
                          <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{provider.billing_structure_aplus}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign:'top'}}><b>PGT-M</b></td>
                        <td style={{verticalAlign:'top'}}><b>:</b></td>
                        <td style={{verticalAlign:'top'}}>
                          <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{provider.billing_structure_m}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign:'top'}}><b>PGT-SR</b></td>
                        <td style={{verticalAlign:'top'}}><b>:</b></td>
                        <td style={{verticalAlign:'top'}}>
                          <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{provider.billing_structure_sr}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign:'top'}}><b>PGT-P</b></td>
                        <td style={{verticalAlign:'top'}}><b>:</b></td>
                        <td style={{verticalAlign:'top'}}>
                          <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{provider.billing_structure_p}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td style={{verticalAlign: 'top', textAlign: "right"}}><b>M2</b></td>
                        <td style={{verticalAlign:'top'}}><b>:</b></td>
                        <td style={{verticalAlign:'top'}}>
                          <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit"}}>{provider.billing_structure_m2}</pre>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{marginTop: '15px'}}><strong>Final Report Release Reporting Structure:</strong>
                    <pre style={{fontFamily: "Roboto,sans-serif", fontSize: "inherit", marginLeft: '20px'}}>{provider.final_report_release_reporting_structure}</pre>
                  </div>

                  <span>
                    <strong>Address: {' '}</strong>
                    {
                      provider.company_address && (
                        <div style={{marginLeft: '15px'}}>
                          <FormatLegacy value={provider.company_address}/>
                        </div>
                      )
                    }
                  </span>

                  <br/><br/>

                  <div>
                    <div>
                      Username: {this.state.newUsername ? this.state.newUsername : provider.username}
                      <span style={{display: 'inline-block', marginLeft: 12}}>
                        <IfAuthSuper>
                          <UsernameEditModal
                            provider={provider}
                            onSuccess={(username) => {
                              this.setState({
                                ...this.state,
                                newUsername: username
                              })
                            }}
                          />
                        </IfAuthSuper>
                      </span>
                    </div>
                    <div>
                      Password: *******
                      <span style={{display: 'inline-block', marginLeft: 12}}>
                        <IfAuthSuper>
                          <SendPasswordModal
                            provider={provider}
                          />
                        </IfAuthSuper>
                      </span>
                    </div>
                  </div>
                </Col>

                <Col md="auto">
                  <IfNotAuthSuperReadonly>
                    <Button
                      onClick={() => this.props.openModal(provider)}
                      color="light"
                    >
                      Edit
                    </Button>
                  </IfNotAuthSuperReadonly>
                </Col>

                <Col md="auto">
                  <IfNotAuthSuperReadonly>
                    <Button
                      onClick={async () => {
                        await this.deleteProvider(provider.id);
                      }}
                      color="danger"
                      disabled={
                        (provider.cases && provider.cases.length > 0) ||
                        (provider.patients && provider.patients.length) > 0
                      }
                    >
                      Delete
                    </Button>
                  </IfNotAuthSuperReadonly>
                </Col>
              </Row>
            </PageHeader>

            <PageContent>
              {/*<CaseSection provider={provider} history={this.props.history}/>*/}
              <PatientSection provider={provider} history={this.props.history}/>
            </PageContent>
          </Page>
        ) : ''
      }
    }
  )
)
