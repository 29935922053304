import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';

export const CARD_ADD_REQUEST = '@@card/CARD_ADD_REQUEST';
export const CARD_PATIENT_ADD_SUCCESS = '@@card/CARD_PATIENT_ADD_SUCCESS';
export const CARD_PROVIDER_ADD_SUCCESS = '@@card/CARD_PROVIDER_ADD_SUCCESS';
export const CARD_ADD_FAILURE = '@@card/CARD_ADD_FAILURE';

export const CARD_DELETE_REQUEST = '@@card/CARD_DELETE_REQUEST';
export const CARD_DELETE_SUCCESS = '@@card/CARD_DELETE_SUCCESS';
export const CARD_DELETE_FAILURE = '@@card/CARD_DELETE_FAILURE';


export const addCard = (data) => {
  return ({
    [RSAA]: {
      endpoint: 'card/',
      method: 'POST',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        CARD_ADD_REQUEST,
        data.hasOwnProperty('provider') ? CARD_PROVIDER_ADD_SUCCESS :
        (data.hasOwnProperty('patient') ? CARD_PATIENT_ADD_SUCCESS : null),
        CARD_ADD_FAILURE
      ],
      body: JSON.stringify(data)
    }
  })
};

export const deleteCard = (id, index) => {
  return ({
    [RSAA]: {
      endpoint: `card/${id}/`,
      method: 'DELETE',
      headers: withAuth({}),
      types: [
        CARD_DELETE_REQUEST,
        {
          type: CARD_DELETE_SUCCESS,
          payload: {},
          meta: {
            id: id,
            index: index
          }
        },
        CARD_DELETE_FAILURE
      ]
    }
  })
};