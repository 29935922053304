import React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";


export default (props) => {
  const {provider} = props;

  return (
    <div>
      <Row>
        <Col md={3}>
          <Card>
            <CardBody className="with-link text-center">
              <Link to={`/patients`}>
              <h2>{provider.cases_count}</h2>
              <h5>Cases</h5>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardBody className="with-link text-center">
              <Link to={`/kits`}>
                <h2>{provider.kits_count}</h2>
                <h5>Kits</h5>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardBody className="with-link text-center">
              <Link to={`/providers`}>
                <h2>{provider.providers_count}</h2>
                <h5>Providers</h5>
              </Link>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardBody className="with-link text-center">
              <Link to={`/nurses`}>
                <h2>{provider.nurses_count}</h2>
                <h5>Nurses</h5>
              </Link>
            </CardBody>
          </Card>
        </Col>
        {/*<Col md={12}>*/}
          {/*<Card>*/}
            {/*<CardHeader>*/}
              {/*<Row>*/}
                {/*<Col>*/}
                  {/*<h6 style={{margin: 0}}>Patients</h6>*/}
                {/*</Col>*/}
                {/*{*/}
                  {/*provider.patients.length > 0 && (*/}
                    {/*<Col md="auto">*/}
                      {/*<Button*/}
                        {/*onClick={() => history.push('/patients?provider=' + provider.id)}*/}
                        {/*size="sm"*/}
                        {/*color="light"*/}
                      {/*>*/}
                        {/*View All*/}
                      {/*</Button>*/}
                    {/*</Col>*/}
                  {/*)*/}
                {/*}*/}
              {/*</Row>*/}
            {/*</CardHeader>*/}
            {/*<CardBody>*/}
              {/*{*/}
                {/*provider.patients.length > 0 ? (*/}
                  {/*<CardTable*/}
                    {/*id={'cases'}*/}
                    {/*columns={['Patient', 'Samples', 'Status', 'GC', 'Created']}*/}
                  {/*>*/}
                    {/*{*/}
                      {/*provider.patients.map((item, i) =>*/}
                        {/*<TableRowWithLink*/}
                          {/*key={'case-row-' + item.id}*/}
                          {/*className="card-table-row clickable"*/}
                          {/*to={'/patients/' + item.id}*/}
                        {/*>*/}
                          {/*<TableCellWithLink>*/}
                            {/*{item.first_name} {item.last_name},{' '}*/}
                            {/*{item.partner_first_name} {item.partner_last_name}*/}
                          {/*</TableCellWithLink>*/}
                          {/*<TableCellWithLink>{item.number_of_samples}</TableCellWithLink>*/}
                          {/*<TableCellWithLink>*/}
                            {/*{*/}
                              {/*!item.consent_form &&*/}
                              {/*<div><FontAwesomeIcon icon="exclamation-triangle" className="text-warning"/> Consent Form*/}
                              {/*</div>*/}
                            {/*}*/}
                            {/*{*/}
                              {/*!item.application_completed &&*/}
                              {/*<div><FontAwesomeIcon icon="exclamation-triangle" className="text-warning"/> Patient*/}
                                {/*Application</div>*/}
                            {/*}*/}
                          {/*</TableCellWithLink>*/}
                          {/*<TableCellWithLink>*/}
                            {/*<div>*/}
                              {/*{*/}
                                {/*(item.gc_requested && !item.gc_completed) && (*/}
                                  {/*<span>{item.gc_scheduled ? 'GC Scheduled' : 'GC Requested'}</span>*/}
                                {/*)*/}
                              {/*}*/}
                            {/*</div>*/}
                          {/*</TableCellWithLink>*/}
                          {/*<TableCellWithLink><TimeAgo value={item.created_at}/></TableCellWithLink>*/}
                        {/*</TableRowWithLink>*/}
                      {/*)*/}
                    {/*}*/}
                  {/*</CardTable>*/}
                {/*) : 'No Patients'*/}
              {/*}*/}
            {/*</CardBody>*/}
          {/*</Card>*/}
        {/*</Col>*/}
      </Row>
    </div>
  )
}
