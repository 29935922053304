import history from '../history';
import URI from "urijs";

export const SET_PROVIDER = '@@provider/SET_PROVIDER';

export const setProvider = (provider_id, current_route, match, redirect = true) => {
  const match_path = match.split('/:');
  const new_route = match_path[0];

  const url = new URI(redirect ? new_route : current_route)
    .addSearch('provider', provider_id)
    .normalizeSearch();

  if (redirect) {
    history.push(url.toString());
  }

  return ({
    type: SET_PROVIDER,
    provider_id
  })
};