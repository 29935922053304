import React, {Component} from 'react';
import {connect} from 'react-redux';
import SampleList from "./SampleList";
import SampleDetail from "./SampleDetail";


class Sample extends Component{
    render(){
        const {id} = this.props.route.match.params;
        let content = <p>Loading...</p>;

        if(id){
            content = <SampleDetail id={id} />
        }else{
            content = <SampleList/>
        }

        return content;
    }
}

export default connect()(Sample);