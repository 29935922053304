import React, {useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import {Position, Toast, Toaster} from "@blueprintjs/core";
import ResultSectionSRFinal from "components/Patient/Detail/ResultSectionSRFinal";
import ResultSectionM2 from "components/Patient/Detail/ResultSectionM2";
import PanelView from './PanelView';
import Moment from "moment";


const renderMstatus = (string) => {
  let res = []

  if (!!string) {
    try {
      let json = JSON.parse(string)
      Object.keys(json).forEach((geneName) => {
        let st = geneName + '('
        const item = json[geneName]
        let variants = []
        Object.keys(item).forEach((variant) => {
          const value = item[variant]
          if(!!value){
            variants.push(`${variant}: ${value}`)
          }
        })
        st += variants.join(', ') + ')'

        res.push(st)
      })
    } catch {

    }
  }

  return res.join(', ')
}


export default (props) => {
  // const data = props.data;
  const [data, setData] = useState(props.data);
  const [toasts, setToasts] = useState([]);

  // data.samples && data.samples.map(sample => {
  //   console.log(sample)
  // })

  return (
    <div>
      <Toaster position={Position.TOP_RIGHT}>
        {
          toasts.map((toast, index) => {
            const {intent, message} = toast;
            return (
              <Toast
                message={message}
                intent={intent}
                timeout={1000}
                onDismiss={(e) => {
                  //const new_items = toasts.filter((item) => item.id !== id);
                  setToasts([]);
                }}
              />
            )
          })
        }
      </Toaster>

      <Card>
        <CardHeader>
          <Row className="justify-content-between">
            <Col>
              <h6 style={{margin: 0}}>
                Results
              </h6>
            </Col>
            <Col md="auto">
              {
                data.samples_result_created_at &&
                  <span className="text-muted">Results first synced at
                    <span> {Moment(data.samples_result_created_at, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                  </span>
              }
            </Col>
            <Col md="auto">
              {
                data.gseq && (
                  <Button size="sm" color="success" onClick={() => {
                    props.onSave(data)
                  }}>Save</Button>
                )
              }
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="with-table">
            <table className="table">
              {
                data.m2 ? (
                  <thead>
                  <tr>
                    <th>Tube Label</th>
                    <th>Result</th>
                  </tr>
                  </thead>
                ) : (
                  <thead>
                  <tr>
                    <th>Embryo #</th>
                    <th>Tube Label</th>
                    <th>Status</th>
                    <th>Karyotype</th>
                    {(!data.gseq && data.pgt_m) && <th>PGT-M</th>}
                    {(!data.gseq && data.pgt_s) && <th>PGT-SR</th>}
                    {(!data.gseq && data.pgt_s) && <th>PGT-SR Final</th>}
                    {(!data.gseq && data.pgt_p) && <th>PGT-P</th>}
                  </tr>
                  </thead>
                )
              }

              {
                data.m2 ? (
                  <tbody>
                  {
                    data.saliva_samples.map((item, index) => {
                      return (
                        <tr key={'sample-row-' + index}>
                          <td>{item.label}</td>
                          <td>
                            <ResultSectionM2
                              sample={item}
                              onLoading={() => {
                                setToasts([...toasts, <Toast message="Saving..."/>])
                              }}
                              onUpdateSuccess={() => {
                                setToasts([...toasts, {id: 'loading-' + Date.now(), intent: 'success', message: 'Saved'}])
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                ) : (
                  <tbody>
                  {
                    data.samples.map((sample, i) => {
                      const status = () => {
                        if (!sample.aneuploid && !sample.no_amp && !sample.inconclusive && !sample.complex_aneuploid) {
                          return 'euploid';
                        }

                        if (sample.aneuploid) {
                          return 'aneuploid';
                        }

                        if (sample.no_amp) {
                          return 'no_amp';
                        }

                        if (sample.inconclusive) {
                          return 'inconclusive';
                        }

                        if (sample.complex_aneuploid) {
                          return 'complex_aneuploid';
                        }

                        return ''
                      };

                      let panel = [];

                      if (data.pgt_p && sample.panel) {
                        try {
                          panel = JSON.parse(sample.panel);
                        } catch (e) {
                          console.error('Error parsing the panel for sample id:' + sample.id);
                          console.error(e);
                        }
                      }

                      return (
                        <tr key={'sample-row-' + i}>
                          <td>{sample.embryo_number}</td>
                          <td>{sample.tube_label}</td>

                          <td>
                            {
                              data.gseq ? (
                                <div>
                                  <Input
                                    type="select"
                                    value={status()}
                                    onChange={(e) => {
                                      const new_samples = data.samples.slice();

                                      new_samples[i].aneuploid = e.target.value === 'aneuploid';
                                      new_samples[i].no_amp = e.target.value === 'no_amp';
                                      new_samples[i].inconclusive = e.target.value === 'inconclusive';
                                      new_samples[i].complex_aneuploid = e.target.value === 'complex_aneuploid';

                                      setData({
                                        ...data,
                                        samples: new_samples
                                      })
                                    }}
                                  >
                                    <option value="euploid">Euploid</option>
                                    <option value="aneuploid">Aneuploid</option>
                                    <option value="complex_aneuploid">Complex Aneuploid</option>
                                    <option value="no_amp">No Amp</option>
                                    <option value="inconclusive">Inconclusive</option>
                                  </Input>
                                </div>
                              ) : (
                                <div>
                                  {(sample.aneuploid && !sample.complex_aneuploid) && <span>Aneuploid</span>}
                                  {sample.complex_aneuploid && <span>Complex Aneuploid</span>}
                                  {sample.no_amp && <span>No Amp</span>}
                                  {sample.inconclusive && <span>Inconclusive</span>}
                                  {(!!sample.karyotype && !sample.aneuploid && !sample.no_amp && !sample.inconclusive && !sample.complex_aneuploid) && <span>Euploid</span>}
                                </div>
                              )
                            }
                          </td>
                          <td>
                            {
                              data.gseq ? (
                                <div>
                                  <Input type="text" value={sample.karyotype || ''} onChange={(e) => {
                                    const new_samples = data.samples.slice();
                                    new_samples[i].karyotype = e.target.value;
                                    setData({
                                      ...data,
                                      samples: new_samples
                                    })
                                  }}/>
                                </div>
                              ) : (
                                <div>
                                  {sample.karyotype}
                                </div>
                              )
                            }
                          </td>
                          {
                            (!data.gseq && data.pgt_m) && (
                              <td>
                                {renderMstatus(sample.m_status)}
                              </td>
                            )
                          }
                          {
                            (!data.gseq && data.pgt_s) && (
                              <td>
                                {sample.sr_status}
                              </td>
                            )
                          }
                          {
                            (!data.gseq && data.pgt_s) && (
                              <td>
                                <ResultSectionSRFinal
                                  sample={sample}
                                  onLoading={() => {
                                    setToasts([...toasts, <Toast message="Saving..."/>])
                                  }}
                                  onUpdateSuccess={() => {
                                    setToasts([...toasts, {id: 'loading-' + Date.now(), intent: 'success', message: 'Saved'}])
                                  }}
                                />
                              </td>
                            )
                          }

                          {
                            data.pgt_p && (
                              <td>
                                <PanelView
                                  sample={sample}
                                  data={panel}
                                />
                              </td>
                            )
                          }
                        </tr>
                      )
                    })
                  }
                  </tbody>
                )
              }

            </table>
          </div>
        </CardBody>
      </Card>

    </div>
  )
}
