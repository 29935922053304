import React, {PureComponent} from 'react';
import Moment from "moment/moment";
import {Row, Col} from "reactstrap";

const MONTHS = {
  '01': 'January', '02': 'February', '03': 'March',
  '04': 'April', '05': 'May', '06': 'June',
  '07': 'July', '08': 'August', '09': 'September',
  '10': 'October', '11': 'November', '12': 'December'
};

export const AGE_RANGE = {youngest: 18, oldest: 105};
export const YOUNGEST_YEAR = () => Moment().year() - AGE_RANGE.youngest - 1;
export const OLDEST_YEAR = () => Moment().year() - AGE_RANGE.oldest;


export default class extends PureComponent {
  static defaultProps = {
    proportion: [4, 8]
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      month: '01',
      year: YOUNGEST_YEAR(),
      day: '01'
    };
  }

  //#1

  renderYears() {
    const youngest = this.props.youngest ? this.props.youngest : AGE_RANGE.youngest;

    return [...Array(AGE_RANGE.oldest - youngest).keys()].map((i) => {
      const year = OLDEST_YEAR() + i;
      return <option value={year} key={`option-year-${i}`}>{year}</option>;
    }).reverse()
  }

  renderMonths() {
    const result = [];
    Object.keys(MONTHS).sort().forEach((month) => {
      result.push(
        <option value={month} key={`option-month-${month}`}>{MONTHS[month]}</option>
      )
    });
    return result;
  }

  renderDays() {
    const daysCount = Moment(`${this.state.year} ${this.state.month}`, 'YYYY-MM').daysInMonth();

    return [...Array(daysCount).keys()].map((i) => {
      const string = '00' + (i + 1);
      const day = string.substr(string.length - 2);
      return <option value={day} key={`option-day-${i}`}>{day}</option>;
    })
  }

  onChange(value) {
    const {field, form: {setFieldValue, setFieldTouched}} = this.props;
    this.setState({
      ...this.state,
      ...value
    }, () => {
      setFieldValue(field.name, `${this.state.year}-${this.state.month}-${this.state.day}`);
      setFieldTouched(field.name, true);
    })
  }

  onBlur() {
    const {field, form: {setFieldTouched}} = this.props;
    setFieldTouched(field.name, true);
  }

  componentWillMount() {
    const {field} = this.props;

    if (field.value && field.value !== 'null') {
      const [year, month, day] = field.value.split('-');
      this.setState({year, month, day});
    }

    // if(this.props.item){
    //   this.setState({
    //     ...this.state,
    //     text: this.props.item.content || ''
    //   })
    // }
  }

  render() {
    return (
      <Row noGutters>
        <Col md={3}>
          <select
            className="form-control"
            value={this.state.day}
            onChange={(e) => this.onChange({day: e.target.value})}
          >
            <option value={''}> </option>
            {this.renderDays()}
          </select>
        </Col>

        <Col md={5} style={{padding: '0 3px'}}>
          <select
            className="form-control"
            value={this.state.month}
            onChange={(e) => this.onChange({month: e.target.value})}
          >
            <option value={''}> </option>
            {this.renderMonths()}
          </select>
        </Col>

        <Col md={4}>
          <select
            className="form-control"
            value={this.state.year}
            onChange={(e) => this.onChange({year: e.target.value})}
          >
            <option value={''}> </option>
            {this.renderYears()}
          </select>
        </Col>
      </Row>
    )
  }
}
