import React, {useState} from 'react';
import {FormGroup, InputGroup} from "@blueprintjs/core";


export default (props) => {
  const {onChange} = props
  const [value, setValue] = useState(props.value || '')

  return (
    <div>
      <FormGroup
        label={false}
        labelFor={'gene-name'}
      >
        <InputGroup
          id={'gene-name'}
          placeholder="(No Name)"
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          onBlur={(e) => onChange(value)}
        />
      </FormGroup>
    </div>
  )
}
