import React, {useEffect, useState} from 'react';
import {ReactBingmaps} from 'react-bingmaps';
import marker from './images/marker.svg';
//import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

const COLOR = {
  blue: '#42A5BE',
  purple: '#722282',
  transparent: 'transparent'
};

const mapStyle = {
  "version": "1.0",
  "settings": {
    "landColor": "#ffffff"
  },
  "elements": {
    "mapElement": {
      "labelColor": COLOR.transparent,
      "labelOutlineColor": COLOR.transparent
    },
    "political": {
      "borderStrokeColor": COLOR.blue,
      "borderOutlineColor": COLOR.transparent
    },
    "point": {
      "iconColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
      "strokeColor": COLOR.transparent
    },
    "transportation": {
      "strokeColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
    },
    "highway": {
      "strokeColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
    },
    "controlledAccessHighway": {
      "strokeColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
    },
    "arterialRoad": {
      "strokeColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
    },
    "majorRoad": {
      "strokeColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
    },
    "railway": {
      "strokeColor": COLOR.transparent,
      "fillColor": COLOR.transparent,
    },
    "structure": {
      "fillColor": COLOR.transparent,
    },
    "water": {
      "fillColor": COLOR.blue
    },
    "area": {
      "fillColor": COLOR.transparent,
    }
  }
};


export default (props) => {
  const {data} = props;
  const [pins, setPins] = useState([]);

  useEffect(() => {
    const res = [];

    if (data) {
      Object.keys(data).forEach((key) => {
        const item = data[key];

        if (!!item.geo_location) {
          const location = item.geo_location.split(',');
          if (location.length === 2) {
            const lat = parseFloat(location[0]);
            const long = parseFloat(location[1]);
            res.push({
              "location": [lat, long],
              "option": {
                color: 'red',
                icon: marker,
                title: item.company_name
              },
              "addHandler": {
                "type": "click", callback: () => {
                  // console.log(item)
                  window.location.href = `/clinics/${item.id}?provider=${item.id}`
                }
              }
            });
          }
        }
      });

      setPins(res);
    }
  }, [data]);

  return (
    <div
      style={{
        overflow: 'hidden',
        // width: 720,
        height: 560
      }}
    >
      <div
        style={{
          width: '100%',
          height: 600
        }}
      >
        {
          (pins && pins.length > 0) && (
            <ReactBingmaps
              bingmapKey="AkQp3TuTMWtcqUsjU-_TiaJWU30v68_1zCIw4EHAb9hPw_M8mAhQrTTCoqqUueV4"
              center={[16, 32]}
              zoom={1}
              pushPins={pins}
              getLocation={
                {
                  addHandler: "click", callback: (location) => {
                    console.log(location)

                  }
                }
              }
              mapOptions={{
                customMapStyle: mapStyle,
                navigationBarMode: 'minified',
                disableStreetside: true
              }}
            />
          )
        }
      </div>
    </div>
  )
}
