import React from 'react';
import useData from "api/useData";
import ListItem from './ListItem';
import ReactPaginate from "react-paginate";
import {Col, Row} from "reactstrap";


export default (props) => {
  const [{data, loading}, {updateParams}] = useData('PERSON', {});

  return (
    <div>
      {loading &&
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: 'rgba(255,255,255, 0.65)',
          zIndex: 9999
        }}
      >
        <Row style={{height: '100%'}} className="justify-content-center align-items-center">
          <Col>
            <p style={{
              textAlign: 'center',
              fontSize: 24
            }}>Loading...</p>
          </Col>
        </Row>
      </div>}
      <h4>Patients</h4>
      {
        !data && <p className='mt-5'>No patients</p>
      }
      {
        data && (
          <div>
            <div>
              <Row>
                <Col>
                  Total: {data.count}
                </Col>
                <Col md="auto">
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={Math.ceil(data.count / data.page_size)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => {
                      updateParams({page: data.selected + 1});
                    }}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    initialPage={parseInt(1) - 1}
                    disableInitialCallback={true}
                  />
                </Col>
              </Row>
            </div>

            <div>
              {
                data.results.map((item) => {
                  return (
                    <ListItem data={item} key={'patient-' + item.id}/>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}
