import React, {useEffect} from 'react';
import useData from "v2.0/data/api/useData";


export default (props) => {
  const [/* {data} */, actions] = useData('patients');
  const {retrieve} = actions;

  useEffect(() => {
    retrieve(238)
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      sample


    </div>
  )
}
