import {RSAA} from 'redux-api-middleware';

export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';
export const LOGOUT = '@@auth/LOGOUT';

export const login = (username, password) => ({
  [RSAA]: {
    endpoint: 'auth/token/',
    method: 'GET',
    headers: {
      'Authorization': 'Basic ' + btoa(username + ":" + password)
    },
    types: [
      LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE
    ]
  }
});

export const logout = () => ({
  type: LOGOUT
});
