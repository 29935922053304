import React from 'react';
import Map from './Map';
import useData from "api/useData";


export default (props) => {
  const [{data, loading}] = useData('PROVIDERS_SIMPLE', {});

  return (
    <div>
      {
        loading && <p>Loading...</p>
      }
      {
        !!(data) && (
          <div>
            <Map data={data}/>
          </div>
        )
      }
    </div>
  )
}
