import React from 'react';
import {HTMLTable} from "@blueprintjs/core";


export default (props) => {
  const {data} = props;

  return data ? (
    <div style={{margin: '0 -20px 0 -20px'}}>
      <HTMLTable
        condensed
        interactive
        style={{width: '100%'}}
      >
        <thead>
          <tr>
            <th style={{paddingLeft: 20, width: '20%'}}>Label</th>
            <th style={{width: '20%'}}>Partner</th>
            <th style={{width: '20%'}}>Samples</th>
            <th style={{width: '20%'}}>Status</th>
            <th style={{width: '20%', paddingRight: 20}}>Payment</th>
          </tr>
        </thead>
        <tbody>
        {
          data.map((item) => {
            const {id, partner_name, case_id, case_status, case_payment_status, sample_count} = item;

            const label = case_id ? case_id : `TEMP${id}`;

            return (
              <tr
                key={'case-' + id}
                onClick={() => {window.location.href=`/patients/${id}`}}
              >
                <td  style={{paddingLeft: 20}}>{label}</td>
                <td>{partner_name}</td>
                <td>{sample_count}</td>
                <td>{case_status}</td>
                <td style={{paddingRight: 20}}>{case_payment_status}</td>
              </tr>
            )
          })
        }
        </tbody>
      </HTMLTable>

    </div>
  ) : null
}
