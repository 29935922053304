import React from 'react';
import useData from "api/useData";
import {Page, PageContent} from "components/Views";
import {Card, CardBody} from "reactstrap";
import TimeCreated from "utils/TimeCreated";


export default (props) => {
  const [{data, loading}] = useData('ACTION_LOG');

  return (
    <Page>
      <h4>Action Log</h4>
      <PageContent>
        {
          loading ? ('Loading...') : (
            <div>
              {
                (data) ? (
                  <Card>
                    <CardBody>
                    <div className="with-table">
                      <table className="table table-sm">
                        <thead>
                        <tr>
                          <th>Time</th>
                          <th>User</th>
                          <th>Action</th>
                          <th>Info</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          data.results.map((item) => {
                            return (
                              <tr key={'item-' + item.id}>
                                <td><TimeCreated value={item.created_at}/></td>
                                <td>{item.username}</td>
                                <td>{item.action}</td>
                                <td>{item.info}</td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                    </CardBody>
                  </Card>
                ) : 'No entries'
              }
            </div>
          )
        }
      </PageContent>
    </Page>
  )
}
