import React from 'react';
//import SvgLoading from './loading.svg';

class LoadingIndicator extends React.Component{
    static get defaultProps() {
        return {
            //image: require('./loading.svg'),
        }
    }

    render(){
        return(
            <div className="loading loading-indicator">
                {/*<SvgLoading />*/}
                <p> </p>
            </div>
        )
    }
}

export default LoadingIndicator;
