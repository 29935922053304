import * as sampleCase from 'actions/case';


const initialState = {
  item: null,
  items: null,
  paymentToken: null,
  getPaymentTokenRequest: false,
  chargeID: null,
  reloadItems: false,
};

export const caseReducer = (state = initialState, action) => {
  switch (action.type) {
    case sampleCase.CASE_LIST_REQUEST:
    case sampleCase.CASE_DETAIL_REQUEST:
    case sampleCase.CASE_CREATE_REQUEST:
      return {
        ...state,
        item: null,
        items: null,
        reloadItems: false
      };

    case sampleCase.CASE_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload
      };

    case sampleCase.CASE_DETAIL_SUCCESS:
      return {
        ...state,
        item: action.payload
      };

    case sampleCase.UPDATE_SHIPPING_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload.case
        }
      };

    case sampleCase.CASE_UPDATE_SUCCESS:
      return state;

    case sampleCase.CASE_CREATE_SUCCESS:
      return {
        ...state,
        item: action.payload,
        reloadItems: true
      };

    case sampleCase.CASE_DELETE_SUCCESS:
      return {
        ...state,
        reloadItems: true
      };

    default:
      return state;
  }
};