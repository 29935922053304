import React, {PureComponent} from 'react';
import './style.css';

class Alert extends PureComponent{
  render(){
    return(
      <div className="g-alert">
        {this.props.children}
      </div>
    )
  }
}

export default Alert;