import React from 'react';


export default (props) => (
  <div className="provider-request-success">
    <h2>Your Request Has Been Submitted!</h2>
    <p>We will contact you as soon as possible.</p>
    <div style={{marginTop: 30}}>
      <a href="https://genomicprediction.com/">Go To Home Page</a>
    </div>
  </div>
)