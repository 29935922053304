import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from 'react-redux';
import {clinicianModalClose} from 'actions/modal';
import {submitClinician} from "actions/clinician";
import ClinicianForm from "components/forms/ClinicianForm";


class ClinicianModal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(json) {
    this.props.submit(json);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.close}>
        <ModalHeader toggle={() => this.props.close()}>
          Provider
        </ModalHeader>
        <ModalBody>
          <ClinicianForm
            onSubmit={this.onSubmit}
            onCancel={this.props.close}
            initialValues={this.props.clinician}
            index={this.props.clinicianIndex}
            id={this.props.clinician ? this.props.clinician.id : null}
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    isOpen: state.modal.clinicianModalIsOpen,
    clinician: state.modal.clinician,
    clinicianIndex: state.modal.clinicianIndex
  }),
  {
    close: clinicianModalClose,
    submit: submitClinician
  }
)(ClinicianModal)