import React, {useState} from 'react';
import useData from "api/useData";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardTable, Page, PageContent, TableCellWithLink, TableRowWithLink} from "components/Views";
import {Classes, Dialog} from "@blueprintjs/core";
import Form from "./Form";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import {deleteRole, submitRole} from "../../actions/role";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


export default connect(() => {}, {submitRole: submitRole, deleteRole: deleteRole})(withRouter((props) => {
  const [{data, error, loading}, {updateParams}] = useData('ROLE');
  const [currentPage, setCurrentPage] = useState(1);
  const [modalState, setModalState] = useState({
    modalIsOpen: false,
    initialFormValues: {}
  })

  if (loading) {
    return 'loading'
  }

  const submit = async (values) => {
    return props.submitRole(values);
  };

  const deleteRole = async (id) => {
    return props.deleteRole({id});
  }

  const handleResponse = (data) => {
    if (['@@roles/ROLES_CREATE_SUCCESS', '@@roles/ROLES_UPDATE_SUCCESS', '@@roles/ROLES_DELETE_SUCCESS'].includes(data.type)) {
      props.history.push('/roles')
    }
    setModalState({initialFormValues: {}, modalIsOpen: false})
  };

  if (error) {
    return <p>{error.data.detail}</p>
  }

  if (data) {
    return (
      <Page>

        <div className="page-header">
          <Row className="align-items-center justify-content-between">
            <Col>
              <h3 className="page-title">Roles</h3>
            </Col>

            <Col md="auto">
              <Button
                color="light"
                onClick={() => {
                  setModalState({
                    modalIsOpen: true,
                    initialFormValues: {}
                  })
                }}
              >
                <FontAwesomeIcon icon="plus"/> Create
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>

            </Col>
          </Row>
        </div>

        <PageContent>
          <div>
            {
              data.count > 0 ? (
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h6 style={{margin: 0}}>Total: {data.count}</h6>
                      </Col>
                      <Col md="auto">
                        <ReactPaginate
                          previousLabel={'previous'}
                          nextLabel={'next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={data.pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={(data) => {
                            setCurrentPage(data.selected + 1);
                            updateParams({page: data.selected + 1});
                          }}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                          initialPage={parseInt(currentPage) - 1}
                          disableInitialCallback={true}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <CardTable
                      id={'roles'}
                      columns={['ID', 'Name', 'Description', 'Created']}
                    >
                      {
                        data.results.map((item, i) =>
                          <TableRowWithLink
                            key={'list-item-' + i}
                            onClick={() => {
                              setModalState({
                                modalIsOpen: true,
                                initialFormValues: {
                                  id: item.id,
                                  role_name: item.role_name,
                                  role_description: item.role_description,
                                  is_super_user: item.is_super_user,
                                  manage_users: item.manage_users,
                                  manage_roles: item.manage_roles
                                }
                              })
                            }}
                          >
                            <TableCellWithLink>
                              <strong>
                                {item.id}
                              </strong>
                            </TableCellWithLink>

                            <TableCellWithLink>
                              {item.role_name}
                            </TableCellWithLink>

                            <TableCellWithLink>
                              {item.role_description}
                            </TableCellWithLink>

                            <TableCellWithLink width={160}>
                              {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('h:mm a')}<br/>
                              {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MMM Do YYYY')}
                            </TableCellWithLink>
                          </TableRowWithLink>
                        )
                      }
                    </CardTable>
                  </CardBody>
                </Card>
              ) : <p>No Roles</p>
            }
          </div>
        </PageContent>

        <Dialog
          icon="plus"
          title={modalState.initialFormValues.id ? "Edit Role" : "New Role"}
          isOpen={modalState.modalIsOpen}
          onClose={() => {
            setModalState({
              ...modalState,
              modalIsOpen: false
            })
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <Form
              onSubmit={(values) => {
                submit(values).then(handleResponse);
              }}
              onDelete={(id) => {
                deleteRole(id).then(handleResponse);
              }}
              onCancel={() => setModalState({...modalState, modalIsOpen: false})}
              initialValues={modalState.initialFormValues}
            />
          </div>
        </Dialog>
      </Page>
    )
  }

  return null;
}))
