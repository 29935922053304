import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {deletePatient, getPatientList} from 'actions/patient';
import TablePlaceholder from 'components/Placeholders/TablePlaceholder';
import {patientModalOpen, cardModalOpen} from "actions/modal";
import {Button, Col, Row, Card, CardHeader, CardBody} from "reactstrap";
import {Page, TableRowWithLink, TableCellWithLink, PageContent} from 'components/Views';
import {CardTable} from "components/Views";
import TimeAgo from "utils/TimeAgo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isAuthDistributor} from "components/RBAC";
import {Link} from "react-router-dom";

export default connect(
  state => ({
    user: state.auth,
    items: state.patient.archived_items,
    patient: state.patient.item,
    selected_provider_id: state.common.selected_provider_id,
    providers: state.provider.items
  }),
  {
    getList: getPatientList,
    patientModalOpen: patientModalOpen,
    deletePatient: deletePatient,
    cardModalOpen: cardModalOpen
  }
)(
  class extends PureComponent {
    componentDidMount() {
      this.props.getList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.selected_provider_id !== prevProps.selected_provider_id && !!this.props.selected_provider_id) {
        this.props.getList();
      }
    }

    render() {
      const {items} = this.props;

      return (
        <Page>
          <div className="page-header">
            <Row className="align-items-center justify-content-between">
              <Col>
                <h3 className="page-title">Patients Archive</h3>
                <p><Link to="/patients">Back To Patients</Link></p>
              </Col>

              <Col md="auto">
                <Button
                  color="light"
                  onClick={() => {
                    if (isAuthDistributor(this.props.user) && this.props.providers && this.props.providers.length <= 0) {
                      alert('Create clinic first')
                    }else{
                      this.props.patientModalOpen()
                    }
                  }}
                >
                  <FontAwesomeIcon icon="plus"/>{' '}Create
                </Button>
              </Col>
            </Row>
          </div>

          <PageContent>
            {
              items ? (
                <div>
                  {
                    items.length > 0 ? (
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col>
                              <h6 style={{margin: 0}}>Total: {items.length}</h6>
                            </Col>
                            <Col md="auto">
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <CardTable
                            id={'patients'}
                            columns={['Name', 'Contact', 'Consent Form', 'Registration', 'GC', 'Created']}
                          >
                            {
                              items.map((patient, i) =>
                                <TableRowWithLink
                                  key={'list-item-' + i}
                                  to={'/patients/' + patient.id}
                                >
                                  <TableCellWithLink>
                                    <strong>
                                      {patient.first_name}&nbsp;{patient.last_name},<br/>
                                      {patient.partner_first_name}&nbsp;{patient.partner_last_name}
                                    </strong>
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    <div>
                                      {patient.address}
                                    </div>
                                    <div>
                                      {patient.phone}
                                    </div>
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {
                                      patient.consent_form ?
                                        <FontAwesomeIcon icon="check" className="text-success"/> :
                                        <FontAwesomeIcon icon="exclamation-triangle" className="text-warning"/>
                                    }
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {
                                      patient.application_completed ?
                                        <FontAwesomeIcon icon="check" className="text-success"/> :
                                        <span className="text-muted">-</span>
                                    }
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {patient.gc_requested ? (
                                      <div>
                                        <div className="text-success">requested</div>
                                          <div>
                                            Scheduled: {
                                            patient.gc_scheduled ?
                                              <span className="text-success">Yes</span> :
                                              <span className="text-muted">No</span>
                                          }
                                          </div>
                                      </div>
                                    ) : <div className="text-muted">declined</div>}
                                    {
                                      // patient.gc_requested && patient.gc_scheduled && (
                                      //   <div>
                                      //     Status: {patient.gc_completed ?
                                      //     <span className="text-success">Completed</span> :
                                      //     <span className="text-muted">Not Completed</span>
                                      //   }
                                      //   </div>
                                      // )
                                    }
                                  </TableCellWithLink>

                                  <TableCellWithLink width={160}>
                                    <TimeAgo value={patient.created_at}/>
                                  </TableCellWithLink>
                                </TableRowWithLink>
                              )
                            }
                          </CardTable>
                        </CardBody>
                      </Card>
                    ) : <p>No patients</p>
                  }
                </div>
              ) : <TablePlaceholder rowHeight={46}/>
            }
          </PageContent>
        </Page>
      )
    }
  }
)

