import React, {useState} from 'react';
import useData from "api/useData";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardTable, Page, PageContent, TableCellWithLink, TableRowWithLink} from "components/Views";
import {Classes, Dialog} from "@blueprintjs/core";
import Form from "./Form";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import {deleteUser, submitUser} from "../../actions/user";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


export default connect(state => ({user: state.auth}), {submitUser: submitUser, deleteUser: deleteUser})(withRouter((props) => {
  const [{data, error, loading}, {updateParams}] = useData('USER');
  const [{data: rolesData}] = useData('ROLE', {'short': true, 'page_size': 1000});
  const [modalState, setModalState] = useState({
    modalIsOpen: false,
    initialFormValues: {},
    fieldsErrors: {username: '', password: '', email: ''},
    errorMessage: ''
  })
  const [currentPage, setCurrentPage] = useState(1);

  if (loading) {
    return 'loading'
  }

  const submit = async (values) => {
    return props.submitUser(values);
  };

  const deleteUser = async (id) => {
    return props.deleteUser({id});
  }

  const defineErrorMessage = fieldsErrors => {
    let errorMessages = ['Errors found: '];
    for (let fieldName in fieldsErrors){
      let errorMessage = fieldsErrors[fieldName];
      if (errorMessage) {
        errorMessages.push(`"${fieldName[0].toUpperCase()}${fieldName.slice(1)}" - ${errorMessage}`)
      }
    }
    return errorMessages.join(' ')
  }

  const handleResponse = (data) => {
    if (data.type === '@@users/USERS_DELETE_FAILURE') {
      if (data.payload.response.message) {
        setModalState({...modalState, errorMessage: data.payload.response.message})
      } else {
        setModalState({...modalState, errorMessage: 'Unknown error occurred'})
      }
      return
    }

    if (['@@users/USERS_CREATE_SUCCESS', '@@users/USERS_UPDATE_SUCCESS', '@@users/USERS_DELETE_SUCCESS'].includes(data.type)) {
      props.history.push('/users')
      setModalState({...modalState, modalIsOpen: false, fieldsErrors: {username: '', password: '', email: ''},
        errorMessage: ''})
      return;
    }

    let { username, password, email } = data.payload.response;
    [username, password, email] =
        [!!username ? username[0] : '', !!password ? password[0] : '', !!email ? email[0] : ''];
    const fieldsErrors = {username, password, email}
    setModalState({...modalState, modalIsOpen: true, fieldsErrors, errorMessage: defineErrorMessage(fieldsErrors)})

  };

  if (error) {
    return <p>{error.data.detail}</p>
  }

  if (data && rolesData) {
    return (
        <Page>

          <div className="page-header">
            <Row className="align-items-center justify-content-between">
              <Col>
                <h3 className="page-title">Users</h3>
              </Col>

              <Col md="auto">
                <Button
                    color="light"
                    onClick={() => {
                      setModalState({
                        fieldsErrors: {username: '', password: '', email: ''},
                        errorMessage: '',
                        modalIsOpen: true,
                        initialFormValues: {}
                      })
                    }}
                >
                  <FontAwesomeIcon icon="plus"/> Create
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>

              </Col>
            </Row>
          </div>

          <PageContent>
            <div>
              {
                data.count > 0 ? (
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col>
                            <h6 style={{margin: 0}}>Total: {data.count}</h6>
                          </Col>
                          <Col md="auto">
                            <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={data.pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(data) => {
                                  setCurrentPage(data.selected + 1);
                                  updateParams({page: data.selected + 1});
                                }}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                initialPage={parseInt(currentPage) - 1}
                                disableInitialCallback={true}
                            />
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <CardTable
                            id={'roles'}
                            columns={['ID', 'Username', 'First name', 'Last name', 'Email', 'Is active', 'Date joined']}
                        >
                          {
                            data.results.map((item, i) =>
                                <TableRowWithLink
                                    key={'list-item-' + i}
                                    onClick={() => {
                                      setModalState({
                                        fieldsErrors: {username: '', password: '', email: ''},
                                        errorMessage: '',
                                        modalIsOpen: true,
                                        initialFormValues: {
                                          id: item.id,
                                          username: item.username,
                                          first_name: item.first_name,
                                          last_name: item.last_name,
                                          email: item.email,
                                          is_active: item.is_active,
                                          roles: item.roles
                                        }})
                                    }}
                                >
                                  <TableCellWithLink>
                                    <strong>
                                      {item.id}
                                    </strong>
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.username}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.first_name}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.last_name}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    {item.email}
                                  </TableCellWithLink>

                                  <TableCellWithLink>
                                    <input style={{cursor: "pointer"}} type="checkbox" checked={item.is_active} />
                                  </TableCellWithLink>

                                  <TableCellWithLink width={160}>
                                    {moment.utc(item.date_joined, 'YYYY-MM-DD hh:mm:ss').local().format('h:mm a')}<br/>
                                    {moment.utc(item.date_joined, 'YYYY-MM-DD hh:mm:ss').local().format('MMM Do YYYY')}
                                  </TableCellWithLink>
                                </TableRowWithLink>
                            )
                          }
                        </CardTable>
                      </CardBody>
                    </Card>
                ) : <p>No Users</p>
              }
            </div>
          </PageContent>

          <Dialog
              icon="plus"
              title={modalState.initialFormValues.id ? "Edit User" : "New User"}
              isOpen={modalState.modalIsOpen}
              onClose={() => {
                setModalState({...modalState, modalIsOpen: false})
              }}
          >
            <div className={Classes.DIALOG_BODY}>
              <Form
                  user={props.user}
                  roles={rolesData.results}
                  fieldsErrors={modalState.fieldsErrors}
                  errorMessage={modalState.errorMessage}
                  onSubmit={(values) => {
                    submit(values).then(handleResponse);
                  }}
                  onDelete={(id) => {
                    deleteUser(id).then(handleResponse);
                  }}
                  onCancel={() => {
                    setModalState({...modalState, modalIsOpen: false,
                      fieldsErrors: {username: '', password: '', email: ''}, errorMessage: ''}
                    )
                  }}
                  initialValues={modalState.initialFormValues}
              />
            </div>
          </Dialog>
        </Page>
    )
  }

  return null;
}))