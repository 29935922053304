import React from 'react';
import './style/fdh.css';


export default function FDH(props) {
  const {value, prefix} = props;
  const list = JSON.parse(value);

  return list && (
    <table className="table table-sm">
      <tbody>
      {
        list.map((item, i) => (
          <tr key={'fdh-item-' + i + '-' + prefix}>
            <td><strong>{item.person}</strong></td>
            <td>{item.type}</td>
            <td>{item.status}</td>
            <td>{item.disease}</td>
            <td>{item.mutation}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  )
}
