import React, {useEffect, useState} from 'react';
import {Button, Classes, ControlGroup, Dialog, InputGroup, TextArea} from "@blueprintjs/core";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import './style/notes.css';
import axios from "axios";
import CardLoading from 'components/Common/CardLoading';
import {IfNotAuthSuperReadonly} from "../../RBAC";


export default (props) => {
  const {case_id, field, title} = props;
  const [data, setData] = useState(props.data);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [updatingNoteText, setUpdatingNoteText] = useState(null);
  const [updatingNoteIndex, setUpdatingNoteIndex] = useState(null);

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(JSON.parse(window.localStorage.getItem('persist:clinic-portal')).auth));
    } catch (e) {
      console.error(e);
    }
  }, [])

  useEffect(() => {
    if (data) {
      let jsonData = []
      try {
        jsonData = JSON.parse(data);
      } catch (e) {
        jsonData = [{
          text: data.toString(),
          author: 'Unknown',
          time: 'Unknown',
          deleted: false
        }];
      }

      setNotes(jsonData);
    }
  }, [data]);

  const submitNewNote = () => {
    if (!!newNote && !!userInfo.username) {
      const n = notes.slice();
      n.push({
        text: newNote,
        author: userInfo.username,
        time: 'Now',
        deleted: false
      });
      //setNotes(n);
      saveNotes(n);
    }
  }

  const editNote = (index, newText) => {
    const n = notes.slice();
    n[index].text = newText;
    setUpdatingNoteIndex(null);
    setUpdatingNoteText(null);
    saveNotes(n);
  }

  const deleteNote = (index) => {
    const n = notes.slice();
    n.splice(index, 1);
    saveNotes(n);
  }

  const saveNotes = (data) => {
    if (!!userInfo.token) {
      setLoading(true);
      const payload = {};
      payload[field] = JSON.stringify(data)

      axios({
        method: 'PATCH',
        responseType: 'json',
        baseURL: process.env.REACT_APP_API_HOST,
        url: `patients/${case_id}/`,
        headers: {
          "Authorization": `Token ${userInfo.token}`
        },
        data: payload
      })
        .then((resp) => {
          if (resp.data) {
            setData(resp.data[field]);
          }
          setSuccess(true);
          setNewNote('');
        })
        .catch((e) => {
          setError('Error saving data');
          setSuccess(false);
        })
        .finally(() => {
          setLoading(null);
        });
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
  }, [success])

  return (
    <div>
      <Card>
        {loading && <CardLoading/>}
        <CardHeader>
          <Row>
            <Col xs><h6 style={{margin: 0}}>{title ? title : 'Notes'}</h6></Col>
            <Col xs>{loading && (<span>Saving...</span>)}</Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div>
            {
              notes.length > 0 ? (
                <div className={'note-list'}>
                  {
                    notes.map((note, index) => {
                      return (
                        <div className={'note-item'} key={'note-' + index}>
                          <div className={'note-text'}>
                            {
                              note.deleted ? (
                                <p className={'note-text-deleted'}>Note was removed</p>
                              ) : (
                                <p>{note.text}</p>
                              )
                            }
                          </div>

                          <div className={'note-meta'}>
                            <p className={'note-author'}>{note.author}</p>
                            <p className={'note-time'}>{note.time}</p>
                            {
                              (note.author === userInfo.username || note.author === 'Unknown')  && (
                                <IfNotAuthSuperReadonly>
                                  <p className={'note-edit'}>
                                    <button
                                      className="link-button"
                                      onClick={e => {
                                        e.preventDefault();
                                        setUpdatingNoteText(note.text);
                                        setUpdatingNoteIndex(index);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </p>
                                </IfNotAuthSuperReadonly>
                              )
                            }
                            {
                              (note.author === userInfo.username || note.author === 'Unknown') && (
                                <IfNotAuthSuperReadonly>
                                  <p className={'note-delete'}>
                                    <button
                                      className="link-button"
                                      onClick={e => {
                                        e.preventDefault();
                                        if (window.confirm("Are you sure?")) {
                                          deleteNote(index);
                                        }
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </p>
                                </IfNotAuthSuperReadonly>
                              )
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              ) : (
                <IfNotAuthSuperReadonly>
                  <div style={{padding: '12px 0'}}>
                    <span className={'text-muted'}>Add the first note using the field below</span>
                  </div>
                </IfNotAuthSuperReadonly>
              )
            }
          </div>

          <IfNotAuthSuperReadonly>
            <div style={{margin: '20px 0'}}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitNewNote();
                }}
              >
                <ControlGroup style={{flexGrow: 1}}>
                  <InputGroup placeholder="Note text..." fill value={newNote} onChange={e => setNewNote(e.target.value)} />
                  <Button icon="arrow-right" type="submit" />
                </ControlGroup>
              </form>
            </div>
          </IfNotAuthSuperReadonly>

          {
            !!error && (
              <div className={'text-danger'} style={{padding: '6px 0'}}>
                Unable to save note. Please try again or report an issue
              </div>
            )
          }
        </CardBody>
      </Card>

      <Dialog
        icon="edit"
        onClose={() => {setUpdatingNoteIndex(null); setUpdatingNoteText(null);}}
        title="Edit Note"
        isOpen={!!updatingNoteIndex || updatingNoteIndex === 0}
      >
        <div className={Classes.DIALOG_BODY}>
          <TextArea
            growVertically={true}
            large={true}
            onChange={e => setUpdatingNoteText(e.target.value)}
            value={updatingNoteText}
            fill
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => {setUpdatingNoteIndex(null); setUpdatingNoteText(null);}}>Cancel</Button>
            <Button onClick={() => editNote(updatingNoteIndex, updatingNoteText)} intent={'primary'}>Save</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
