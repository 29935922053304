import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';
import URI from 'urijs';


export const PROVIDER_KIT_GET_LIST_REQUEST = '@@provider_kit/PROVIDER_KIT_GET_LIST_REQUEST';
export const PROVIDER_KIT_GET_LIST_SUCCESS = '@@provider_kit/PROVIDER_KIT_GET_LIST_SUCCESS';
export const PROVIDER_KIT_GET_LIST_FAILURE = '@@provider_kit/PROVIDER_KIT_GET_LIST_FAILURE';

export const PROVIDER_KIT_CREATE_REQUEST = '@@provider_kit/PROVIDER_KIT_CREATE_REQUEST';
export const PROVIDER_KIT_CREATE_SUCCESS = '@@provider_kit/PROVIDER_KIT_CREATE_SUCCESS';
export const PROVIDER_KIT_CREATE_FAILURE = '@@provider_kit/PROVIDER_KIT_CREATE_FAILURE';

export const PROVIDER_KIT_UPDATE_REQUEST = '@@provider_kit/PROVIDER_KIT_UPDATE_REQUEST';
export const PROVIDER_KIT_UPDATE_SUCCESS = '@@provider_kit/PROVIDER_KIT_UPDATE_SUCCESS';
export const PROVIDER_KIT_UPDATE_FAILURE = '@@provider_kit/PROVIDER_KIT_UPDATE_FAILURE';

export const PROVIDER_KIT_DELETE_REQUEST = '@@provider_kit/PROVIDER_KIT_DELETE_REQUEST';
export const PROVIDER_KIT_DELETE_SUCCESS = '@@provider_kit/PROVIDER_KIT_DELETE_SUCCESS';
export const PROVIDER_KIT_DELETE_FAILURE = '@@provider_kit/PROVIDER_KIT_DELETE_FAILURE';


export const list = (params = {}) => {
  const uri = new URI('provider-kits/');
  const {page, ...other_params} = params;
  uri.setSearch({page: page, ...other_params});

  return ({
    [RSAA]: {
      endpoint: uri.normalizeSearch().toString(),
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_KIT_GET_LIST_REQUEST,
        PROVIDER_KIT_GET_LIST_SUCCESS,
        PROVIDER_KIT_GET_LIST_FAILURE
      ]
    }
  })
};

export const submit = (data) => {
  const url =  `provider-kits/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  if (data.shipping_address && !data.shipping_address.id) {
    delete data.shipping_address.id
  }

  return ({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types: data.id ? [
        PROVIDER_KIT_UPDATE_REQUEST,
        {
          type: PROVIDER_KIT_UPDATE_SUCCESS,
          meta: {
            index: data.index
          }
        },
        PROVIDER_KIT_UPDATE_FAILURE
      ] : [
        PROVIDER_KIT_CREATE_REQUEST,
        {
          type: PROVIDER_KIT_CREATE_SUCCESS,
          payload: (action, state, res) => {
            return res.json().then(json => {
              window.location.reload();
              return json;
            });
          }
        },
        PROVIDER_KIT_CREATE_FAILURE
      ],
      body: JSON.stringify(data)
    }
  })
};

export const deleteKit = (id, i, isLast) => {
  return ({
    [RSAA]: {
      endpoint: 'provider-kits/' + id + '/',
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_KIT_DELETE_REQUEST,
        {
          type: PROVIDER_KIT_DELETE_SUCCESS,
          meta: {
            index: i,
            isLast: isLast
          }
        },
        PROVIDER_KIT_DELETE_FAILURE
      ]
    }
  })
};
