import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getSampleDetail, setActiveChromosome, setHoveredChromosome} from 'actions/sample';
import {chromosomeModalOpen} from "actions/modal";
// import {GPlot, Karyotype, Sex, ChromosomesPlot} from 'components/utilities';
import SampleDetailPlaceholder from 'components/Placeholders/SampleDetailPlaceholder';

class SampleDetail extends Component {
  componentWillMount() {
    this.props.getSampleDetail(this.props.id);
  }

  render() {
    const {sample} = this.props;

    return sample ? (
      <div className="content-with-sidebar sample-detail">
        {/*<h4>Sample #{sample.number} {sample.name}</h4>*/}
        {/*<Row className="align-items-top" style={{marginTop: 40, marginBottom: 20}}>*/}
        {/*  <Col md="auto">*/}
        {/*    <div style={{width: 240, margin: '0 auto'}}>*/}
        {/*      <GPlot*/}
        {/*        sample={sample}*/}
        {/*        video={require('images/embryo.mp4')}*/}
        {/*        onHover={this.props.setHoveredChromosome}*/}
        {/*        onClick={(chromosome) => {this.props.chromosomeModalOpen(chromosome, sample.targets)}}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Row className="align-items-center" style={{marginBottom: 30}}>*/}
        {/*      <Col md="auto"><Sex sex={sample.sex}/></Col>*/}
        {/*      <Col md="auto"><Karyotype sample={sample}/></Col>*/}
        {/*      <Col>{Moment(sample.date, 'YYYY-MM-DD').format('MMM DD, YYYY')}</Col>*/}
        {/*    </Row>*/}

        {/*    <Row>*/}
        {/*      <Col>Total Reads</Col>*/}
        {/*      <Col>{(sample.total_reads / 1000).toFixed(1)} K</Col>*/}
        {/*    </Row>*/}

        {/*    <Row>*/}
        {/*      <Col>Aligned Reads</Col>*/}
        {/*      <Col>{(sample.total_properly_paired_reads / 1000).toFixed(1)} K</Col>*/}
        {/*    </Row>*/}

        {/*    <Row>*/}
        {/*      <Col>Average Base Quality Score</Col>*/}
        {/*      <Col>{sample.avg_baseq_score}</Col>*/}
        {/*    </Row>*/}

        {/*    <Row>*/}
        {/*      <Col>Average Alignment Quality Score</Col>*/}
        {/*      <Col>{parseFloat(sample.avg_mapq_score).toFixed(1)}</Col>*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*</Row>*/}



        {/*<Row style={{marginTop: 20, marginBottom: 20}}>*/}
          {/*<Col>*/}
            {/*<small>Copy Numbers</small>*/}
            {/*<table className="copy-number-table">*/}
              {/*<tbody>*/}
              {/*<tr>*/}
                {/*{*/}
                  {/*sample.chromosomes.map((chr, i) => <td*/}
                    {/*key={'chr-td-' + i}>{(i + 1).toString().replace('23', 'X').replace('24', 'Y')}</td>)*/}
                {/*}*/}
              {/*</tr>*/}
              {/*<tr>*/}
                {/*{*/}
                  {/*sample.chromosomes.map((chr, i) => <td key={'chr-td2-' + i}>{chr.copy_number}</td>)*/}
                {/*}*/}
              {/*</tr>*/}
              {/*</tbody>*/}
            {/*</table>*/}
          {/*</Col>*/}
        {/*</Row>*/}


        {/* // This is commented Feb 02 2021 */ }
        {/*<Row style={{marginBottom: 60}}>*/}
        {/*  <Col>*/}
        {/*    <ChromosomesPlot*/}
        {/*      sample={sample}*/}
        {/*      onHover={this.props.setHoveredChromosome}*/}
        {/*      onClick={(chromosome) => {this.props.chromosomeModalOpen(chromosome, sample.targets)}}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        {/*<div className="sidebar-detail sidebar-detail-sample">*/}
        {/*  <h4>{sample.name}</h4>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Patient:</Col>*/}
        {/*    <Col>Mom Momson,<br/>Dad Dadson</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Tube Top Marking:</Col>*/}
        {/*    <Col>E01</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Embryo Grade:</Col>*/}
        {/*    <Col>5AA</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Tissue Type:</Col>*/}
        {/*    <Col>Trophectoderm</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Biopsy Day:</Col>*/}
        {/*    <Col>5</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Cycle:</Col>*/}
        {/*    <Col>1</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Rebiopsy:</Col>*/}
        {/*    <Col>0</Col>*/}
        {/*  </Row>*/}

        {/*  <hr/>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Biopsy Clinician:</Col>*/}
        {/*    <Col>John Doe</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Biopsy Time:</Col>*/}
        {/*    <Col>06/25/2018 15:14</Col>*/}
        {/*  </Row>*/}

        {/*  <Row className="align-items-center">*/}
        {/*    <Col>Analysis Ready:</Col>*/}
        {/*    <Col>07/01/2018 12:32</Col>*/}
        {/*  </Row>*/}

        {/*</div>*/}

        {/*<ChromosomeModal/>*/}
      </div>
    ) : <SampleDetailPlaceholder/>
  }
}

export default connect(
  state => ({
    sample: state.sample.sample
  }),
  {
    getSampleDetail: getSampleDetail,
    setHoveredChromosome: setHoveredChromosome,
    setActiveChromosome: setActiveChromosome,
    chromosomeModalOpen: chromosomeModalOpen
  }
)(SampleDetail)
