import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalHeader} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import Form from './CaseBillForm';
import {submitPatient} from "actions/patient";
import {withRouter} from 'react-router-dom';

export default connect(
    null,
  {
    submitPatient: submitPatient,
  }
)(
  withRouter((props) => {
    const [data, setData] = useState({});
    const [responseErrorMessages, setResponseErrorMessages] = useState({});

    useEffect(() => {
      const cleaned_data = {};
      if (props.data) {
        Object.keys(props.data).forEach((key) => {
          cleaned_data[key] = props.data[key] === null ? undefined : props.data[key]
        });
        setData(cleaned_data);
      }

    }, [props.data]);

    const submit = async (values) => {
      return await props.submitPatient(values);
    };

    const handleResponse = (resp) => {
      if (resp.type === '@@patient/PATIENT_UPDATE_FAILURE') {
        setResponseErrorMessages(resp.payload.response)
      }
      if (resp.type === '@@patient/PATIENT_UPDATE_SUCCESS') {
        setResponseErrorMessages({})
        props.history.push(`/patients/${resp.payload.id}?${localStorage.getItem('temp-provider') ? 'temp-provider' : 'provider'}=${resp.payload.provider}`)
      }
    };

    return (
      <Modal isOpen={props.isOpen} toggle={props.close}>
        <ModalHeader toggle={props.close}>
          Case Billing
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(values) => submit(values).then(handleResponse)}
            onCancel={props.close}
            initialValues={data}
            responseErrors={responseErrorMessages}
          />
        </ModalBody>
      </Modal>
    )
  })
)
