import React, {PureComponent} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from 'react-redux';
import {cardModalClose} from 'actions/modal';
import {submit} from 'actions/case';
import CardForm from "components/forms/CardForm";
import {Elements, StripeProvider} from "react-stripe-elements";
import Script from 'react-load-script';
import LoadingIndicator from 'components/LoadingIndicator';


class CardModal extends PureComponent {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onFormReady = this.onFormReady.bind(this);

    this.state = {
      scriptLoaded: false,
      formReady: false
    };
  }

  onLoad() {
    this.setState({
      ...this.state,
      scriptLoaded: true,
    })
  }

  onFormReady() {
    this.setState({
      ...this.state,
      formReady: true,
    })
  }

  onSubmit(data) {
    data.chargeID = this.props.chargeID;
    this.props.submit(data);
  }

  render() {
    const {close, isOpen, cardOwner} = this.props;
    const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Roboto:400" }];

    return (
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader>
          Add New Card
          <span className="ion ion-md-close modal-close" onClick={close}/>
        </ModalHeader>

        <ModalBody>
          <Script
            url="https://js.stripe.com/v3/"
            onLoad={this.onLoad}
          />

          {
            this.state.scriptLoaded ? (
              <div>
                <div style={{display: this.state.formReady ? 'block' : 'none'}}>
                  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
                    <Elements fonts={fonts}>
                      <CardForm
                        onReady={this.onFormReady}
                        onCancel={close}
                        owner={cardOwner}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
                <div style={{display: this.state.formReady ? 'none' : 'block'}}>
                  <LoadingIndicator/>
                </div>
              </div>
            ) : <LoadingIndicator/>
          }
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    isOpen: state.modal.cardModalIsOpen,
    cardOwner: state.modal.cardOwner
  }),
  {
    close: cardModalClose,
    submit: submit
  }
)(CardModal)
