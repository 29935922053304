import React from 'react';
import {string, number} from 'prop-types';
import {ETHNICITIES} from "constants/case";
import Moment from "moment-timezone";
import FDH from "components/Patient/Detail/FDH";

PatientInfoTable.propTypes = {
  fullName: string,
  dob: string,
  sex: string,
  ethnicity: number,
  fdh: string
};

function PatientInfoTable(props) {
  const {fullName, dob, sex, ethnicity, fdh} = props;

  return (
    <table className="table table-sm table-borderless">
      <tbody>
      <tr>
        <th>Full Name:</th>
        <td>{fullName}</td>
      </tr>
      <tr>
        <th>Date&nbsp;of&nbsp;Birth:</th>
        <td>{Moment(dob).format('MM/DD/YYYY')} (Age: {Moment().diff(dob, 'years')})</td>
      </tr>
      <tr>
        <th>Ethnicity:</th>
        <td>{ETHNICITIES[ethnicity] || '-'}</td>
      </tr>
      <tr>
        <th>Sex:</th>
        <td>{sex}</td>
      </tr>
      <tr>
        <th colSpan={2} style={{paddingTop: 20}}>Family Disease History:</th>
      </tr>
      <tr>
        <td colSpan={2}>
          {fdh ? <FDH value={fdh} prefix={dob}/> : <span className="text-muted">Not Specified</span>}
        </td>
      </tr>
      </tbody>
    </table>
  )
}

export default PatientInfoTable;