import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';

export const SAMPLE_LIST_REQUEST = '@@sample/SAMPLE_LIST_REQUEST';
export const SAMPLE_LIST_SUCCESS = '@@sample/SAMPLE_LIST_SUCCESS';
export const SAMPLE_LIST_FAILURE = '@@sample/SAMPLE_LIST_FAILURE';

export const SAMPLE_DETAIL_REQUEST = '@@sample/SAMPLE_DETAIL_REQUEST';
export const SAMPLE_DETAIL_SUCCESS = '@@sample/SAMPLE_DETAIL_SUCCESS';
export const SAMPLE_DETAIL_FAILURE = '@@sample/SAMPLE_DETAIL_FAILURE';

export const SAMPLE_CREATE_REQUEST = '@@sample/SAMPLE_CREATE_REQUEST';
export const SAMPLE_CREATE_SUCCESS = '@@sample/SAMPLE_CREATE_SUCCESS';
export const SAMPLE_CREATE_FAILURE = '@@sample/SAMPLE_CREATE_FAILURE';

export const SAMPLE_UPDATE_REQUEST = '@@sample/SAMPLE_UPDATE_REQUEST';
export const SAMPLE_UPDATE_SUCCESS = '@@sample/SAMPLE_UPDATE_SUCCESS';
export const SAMPLE_UPDATE_FAILURE = '@@sample/SAMPLE_UPDATE_FAILURE';

export const SAMPLE_DELETE_REQUEST = '@@sample/SAMPLE_DELETE_REQUEST';
export const SAMPLE_DELETE_SUCCESS = '@@sample/SAMPLE_DELETE_SUCCESS';
export const SAMPLE_DELETE_FAILURE = '@@sample/SAMPLE_DELETE_FAILURE';

export const SET_HOVERED_CHROMOSOME = '@@sample/SET_HOVERED_CHROMOSOME';
export const SET_ACTIVE_CHROMOSOME = '@@sample/SET_ACTIVE_CHROMOSOME';

export const SALIVA_SAMPLE_LIST_REQUEST = '@@sample/SALIVA_SAMPLE_LIST_REQUEST';
export const SALIVA_SAMPLE_LIST_SUCCESS = '@@sample/SALIVA_SAMPLE_LIST_SUCCESS';
export const SALIVA_SAMPLE_LIST_FAILURE = '@@sample/SALIVA_SAMPLE_LIST_FAILURE';

export const SALIVA_SAMPLE_CREATE_REQUEST = '@@sample/SALIVA_SAMPLE_CREATE_REQUEST';
export const SALIVA_SAMPLE_CREATE_SUCCESS = '@@sample/SALIVA_SAMPLE_CREATE_SUCCESS';
export const SALIVA_SAMPLE_CREATE_FAILURE = '@@sample/SALIVA_SAMPLE_CREATE_FAILURE';

export const SALIVA_SAMPLE_UPDATE_REQUEST = '@@sample/SALIVA_SAMPLE_UPDATE_REQUEST';
export const SALIVA_SAMPLE_UPDATE_SUCCESS = '@@sample/SALIVA_SAMPLE_UPDATE_SUCCESS';
export const SALIVA_SAMPLE_UPDATE_FAILURE = '@@sample/SALIVA_SAMPLE_UPDATE_FAILURE';

export const SALIVA_SAMPLE_DELETE_REQUEST = '@@sample/SALIVA_SAMPLE_DELETE_REQUEST';
export const SALIVA_SAMPLE_DELETE_SUCCESS = '@@sample/SALIVA_SAMPLE_DELETE_SUCCESS';
export const SALIVA_SAMPLE_DELETE_FAILURE = '@@sample/SALIVA_SAMPLE_DELETE_FAILURE';

export const PAGE_SIZE = 48;


export const getSampleList = () => {
  return ({
    [RSAA]: {
      endpoint: 'samples/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        SAMPLE_LIST_REQUEST, SAMPLE_LIST_SUCCESS, SAMPLE_LIST_FAILURE
      ]
    }
  })
};

export const getSampleDetail = (sample_id) => {
  return ({
    [RSAA]: {
      endpoint: 'samples/' + sample_id + '/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        SAMPLE_DETAIL_REQUEST, SAMPLE_DETAIL_SUCCESS, SAMPLE_DETAIL_FAILURE
      ]
    }
  })
};

export const submitSample = (data) => {
  const url = `samples/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  return ({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types: data.id ? [SAMPLE_UPDATE_REQUEST, SAMPLE_UPDATE_SUCCESS, SAMPLE_UPDATE_FAILURE] :
        [SAMPLE_CREATE_REQUEST, SAMPLE_CREATE_SUCCESS, SAMPLE_CREATE_FAILURE],
      body: JSON.stringify(data)
    }
  })
};

export const deleteSample = (sample_id, index) => {
  return ({
    [RSAA]: {
      endpoint: 'samples/' + sample_id + '/',
      method: 'DELETE',
      headers: withAuth({}),
      types: [
        SAMPLE_DELETE_REQUEST,
        {
          type: SAMPLE_DELETE_SUCCESS,
          payload: {},
          meta: {
            sample_id: sample_id,
            index: index
          }
        },
        SAMPLE_DELETE_FAILURE
      ]
    }
  })
};

export const setHoveredChromosome = (chromosome = null) => ({
  type: SET_HOVERED_CHROMOSOME,
  chromosome: chromosome
});

export const setActiveChromosome = (chromosome = null) => ({
  type: SET_ACTIVE_CHROMOSOME,
  chromosome: chromosome
});

export const getSalivaSampleList = (filter = {}) => {
  return ({
    [RSAA]: {
      endpoint: 'saliva-samples/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        SALIVA_SAMPLE_LIST_REQUEST, SALIVA_SAMPLE_LIST_SUCCESS, SALIVA_SAMPLE_LIST_FAILURE
      ]
    }
  })
};

export const submitSalivaSampleList = (data, id = null, index = null) => {
  if(data.person && !data.person.first_name && !data.person.last_name){
    data.person = null
  }

  if (data.sent_at === '') {
    data.sent_at = null;
  }

  if (data.received_at === '') {
    data.received_at = null;
  }

  return ({
    [RSAA]: {
      endpoint: `saliva-samples/${id ? id + '/' : ''}`,
      method: id ? 'PUT' : 'POST',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: id ? [
        SALIVA_SAMPLE_UPDATE_REQUEST, SALIVA_SAMPLE_UPDATE_SUCCESS, SALIVA_SAMPLE_UPDATE_FAILURE
      ] : [
        SALIVA_SAMPLE_CREATE_REQUEST, SALIVA_SAMPLE_CREATE_SUCCESS, SALIVA_SAMPLE_CREATE_FAILURE
      ],
      body: JSON.stringify(data)
    }
  })
};

export const deleteSalivaSampleList = (id, index) => {
  return ({
    [RSAA]: {
      endpoint: 'saliva-samples/' + id + '/',
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        SALIVA_SAMPLE_DELETE_REQUEST,
        {
          type: SALIVA_SAMPLE_DELETE_SUCCESS,
          payload: {},
          meta: {
            index: index
          }
        },
        SALIVA_SAMPLE_DELETE_FAILURE
      ],
    }
  })
};
