import * as clinician from 'actions/clinician';


const initialState = {
  clinician: null,
  clinicians: null,
};


let array = [];
let new_array = [];


export const clinicianReducer = (state = initialState, action) => {
  switch (action.type) {
    case clinician.CLINICIAN_LIST_REQUEST:
      return {
        ...state,
        clinicians: null
      };

    case clinician.CLINICIAN_LIST_SUCCESS:
      return {
        ...state,
        clinicians: action.payload
      };

    case clinician.CLINICIAN_DETAIL_SUCCESS:
      return {
        ...state,
        clinician: action.payload
      };

    case clinician.CLINICIAN_UPDATE_SUCCESS:
      const id = action.payload.id;

      array = state.clinicians.slice();
      new_array = array.map((item) => {
        if(item.id !== id){
          return item;
        }

        return{
          ...item,
          ...action.payload
        }
      });

      return {
        ...state,
        clinicians: new_array
      };

    case clinician.CLINICIAN_CREATE_SUCCESS:
      array = state.clinicians.slice();
      array.unshift(action.payload);
      return {
        ...state,
        clinicians: array
      };

    case clinician.CLINICIAN_DELETE_SUCCESS:
      new_array = state.clinicians.slice();
      new_array.splice(action.meta.index, 1);

      return {
        ...state,
        clinicians: new_array
      };

    default:
      return state;
  }
};