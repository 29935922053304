import React, {PureComponent} from 'react';
import logo from 'images/lab-logo-v.svg';

export default class extends PureComponent{
  render(){
    const {patient} = this.props;

    return (
      <div className="submitted text-center">
        <div style={{marginBottom: 50, marginTop: 40}}>
          <img src={logo} alt="" style={{width: 240}}/>
        </div>

        <h4>Thank you! Your application has been submitted.</h4>
        {patient && patient.gc_requested ? (
          <p>We will contact you as soon as possible to schedule your appointment with your Genetic Counselor.</p>
        ) : (
          <p>Your clinician will contact you as soon as possible.</p>
        )}
      </div>
    )
  }
}