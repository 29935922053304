import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Card, CardBody, CardTitle, Form, FormGroup, Input} from "reactstrap";
import {login} from "../../actions/auth";
import {isAuthenticated} from "../../reducers/auth";
import {Redirect} from 'react-router'
import Alert from "utils/Alert";
import logo from "images/logo-v2.svg";
import { datadogRum } from '@datadog/browser-rum';


class Login extends Component {
  state = {
    username: '',
    password: ''
  };

  submitForm = (e) => {
    e.preventDefault();
    window.localStorage.removeItem('provider');
    window.localStorage.removeItem('caseStatusFilter');
    window.localStorage.removeItem('caseTypesFilters');
    window.localStorage.removeItem('consentSigned');
    window.localStorage.removeItem('invoicePaid');
    this.props.login(this.state.username, this.state.password, this.props.route.location.nextUrl);
    datadogRum.setUser({
      id: this.state.username
    });
  };

  handleInputChange = (event) => {
    const target = event.target,
      value = target.type === 'checkbox' ? target.checked : target.value,
      name = target.name;

    this.setState({
      [name]: value
    });
  };

  render() {
    const errors = this.props.errors;

    if (this.props.isAuthenticated) {
      const { nextUrl } = this.props.route.location
      const to = nextUrl === undefined ? '/' : nextUrl
      return (<Redirect to={to}/>);
    } else {
      return (
        <div>
          <img src={logo} style={{width: 220, margin: '60px auto 60px auto', display: 'block'}} alt=""/>
          <Card style={{
            boxShadow: '0 4px 24px rgba(0,0,0,0.08)'
          }}>
            <CardBody>
              <CardTitle className="text-center">
                <h4 data-test-id="login-text">Login</h4>
              </CardTitle>
              <div>
                <Form onSubmit={this.submitForm}>
                  <FormGroup>
                    <Input data-test-id="username" name="username" label="Username" onChange={this.handleInputChange}/>
                  </FormGroup>

                  <FormGroup>
                    <Input data-test-id="password" name="password" label="Password" onChange={this.handleInputChange} type="password"/>
                  </FormGroup>

                  <FormGroup className="text-center">
                    <Button
                      data-test-id="submit"
                      type="submit"
                      color="success"
                      size="lg"
                      outline
                      block
                    >
                      Submit
                    </Button>
                  </FormGroup>

                  {errors && Object.keys(errors).length ? <Alert color="danger">{errors}</Alert> : ''}
                </Form>

                <div style={{marginTop: 20}}>
                  Don't have account? <a href="/provider-request">Become a Provider</a>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      )
    }
  }
}

export default connect(
  state => ({
    errors: state.auth.errors,
    isAuthenticated: isAuthenticated(state)
  }),
  {
    login: login
  }
)(Login);
