import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import AlertItem from './Item';
import {removeAlert} from "actions/alert";
import './style/main.css';


export default connect(
  state => ({
    items: state.alert.list
  }), {
    removeAlert: removeAlert
  }
)(
  class extends PureComponent {
    render() {
      return !!this.props.items ? (
        <div className="toast-popup-container">
          {
            Object.keys(this.props.items).sort((a, b) => b - a).map((id) =>
              {
                if (this.props.items.hasOwnProperty(id)) {
                  return (
                    <AlertItem
                      type={this.props.items[id].type}
                      key={'toast-popup-' + id}
                      remove={() => this.props.removeAlert(id)}
                    >
                      {this.props.items[id].message}
                    </AlertItem>
                  )
                }
                return null
              }
            )
          }
        </div>
      ) : ''
    }
  }
)