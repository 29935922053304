import * as sample from 'actions/sample';
import * as sampleCase from 'actions/case';
import * as patient from 'actions/patient';
import * as clinician from 'actions/clinician';
import * as nurse from 'actions/nurse';
import * as card from 'actions/card';
import * as auth from 'actions/auth';
import * as provider from 'actions/provider';
import * as kit from "actions/kit";
import * as common from 'actions/common';
import * as provider_kit from 'actions/provider_kit';

const initialState = {
  loading: false,
  selected_provider_id: null
};

/* eslint no-fallthrough: "off" */

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case sample.SAMPLE_LIST_REQUEST:
    case sample.SAMPLE_DETAIL_REQUEST:
    case sample.SAMPLE_DELETE_REQUEST:
    case sample.SAMPLE_CREATE_REQUEST:
    case sample.SAMPLE_UPDATE_REQUEST:
    case sample.SALIVA_SAMPLE_CREATE_REQUEST:
    case sample.SALIVA_SAMPLE_UPDATE_REQUEST:
    case sample.SALIVA_SAMPLE_DELETE_REQUEST:

    case sampleCase.CASE_CREATE_REQUEST:
    case sampleCase.CASE_UPDATE_REQUEST:
    case sampleCase.CASE_DELETE_REQUEST:
    case sampleCase.UPDATE_SHIPPING_REQUEST:
    case sampleCase.CASE_DETAIL_REQUEST:

    case clinician.CLINICIAN_CREATE_REQUEST:
    case clinician.CLINICIAN_UPDATE_REQUEST:
    case clinician.CLINICIAN_DELETE_REQUEST:

    case nurse.NURSE_CREATE_REQUEST:
    case nurse.NURSE_UPDATE_REQUEST:
    case nurse.NURSE_DELETE_REQUEST:

    case card.CARD_ADD_REQUEST:
    case card.CARD_DELETE_REQUEST:

    case patient.PATIENT_CREATE_REQUEST:
    case patient.PATIENT_UPDATE_REQUEST:
    case patient.PATIENT_CONSENT_FORM_REQUEST:
    case patient.PATIENT_CONSENT_FORM_DELETE_REQUEST:
    case patient.PATIENT_FILES_REQUEST:
    case patient.PATIENT_FILES_DELETE_REQUEST:
    case patient.PATIENT_APPLICATION_SUBMIT_REQUEST:
    case patient.PATIENT_APPLICATION_GET_REQUEST:
    case patient.PATIENT_APPLICATION_RESEND_REQUEST:

    case auth.LOGIN_REQUEST:

    case kit.GET_ORDER_SUMMARY_REQUEST:
    case kit.ORDER_COMPLETE_REQUEST:

    case provider.PROVIDER_SUBMIT_REQUEST_REQUEST:
    case provider.PROVIDER_GET_DETAILS_REQUEST:
    case provider.PROVIDER_CREATE_REQUEST:
    case provider.PROVIDER_UPDATE_REQUEST:
    case provider.PROVIDER_DELETE_REQUEST:

    case provider_kit.PROVIDER_KIT_CREATE_REQUEST:
    case provider_kit.PROVIDER_KIT_UPDATE_REQUEST:

    case provider_kit.PROVIDER_KIT_GET_LIST_REQUEST:
    case provider_kit.PROVIDER_KIT_DELETE_REQUEST:

    case patient.PATIENT_ARCHIVE_PUT_REQUEST:

    case patient.PATIENT_REPORTS_REQUEST:
    case patient.PATIENT_REPORTS_DELETE_REQUEST:

      return {
        ...state,
        loading: true
      };

    case sample.SAMPLE_LIST_SUCCESS:
    case sample.SAMPLE_DETAIL_SUCCESS:
    case sample.SAMPLE_DELETE_SUCCESS:
    case sample.SAMPLE_LIST_FAILURE:
    case sample.SAMPLE_DETAIL_FAILURE:
    case sample.SAMPLE_DELETE_FAILURE:
    case sample.SAMPLE_CREATE_SUCCESS:
    case sample.SAMPLE_CREATE_FAILURE:
    case sample.SAMPLE_UPDATE_SUCCESS:
    case sample.SAMPLE_UPDATE_FAILURE:
    case sample.SALIVA_SAMPLE_UPDATE_SUCCESS:
    case sample.SALIVA_SAMPLE_UPDATE_FAILURE:
    case sample.SALIVA_SAMPLE_CREATE_SUCCESS:
    case sample.SALIVA_SAMPLE_CREATE_FAILURE:
    case sample.SALIVA_SAMPLE_DELETE_SUCCESS:
    case sample.SALIVA_SAMPLE_DELETE_FAILURE:

    case sampleCase.CASE_CREATE_SUCCESS:
    case sampleCase.CASE_UPDATE_SUCCESS:
    case sampleCase.CASE_DELETE_SUCCESS:
    case sampleCase.CASE_CREATE_FAILURE:
    case sampleCase.CASE_UPDATE_FAILURE:
    case sampleCase.CASE_DELETE_FAILURE:
    case sampleCase.UPDATE_SHIPPING_SUCCESS:
    case sampleCase.UPDATE_SHIPPING_FAILURE:
    case sampleCase.CASE_DETAIL_FAILURE:
    case sampleCase.CASE_DETAIL_SUCCESS:

    case clinician.CLINICIAN_CREATE_SUCCESS:
    case clinician.CLINICIAN_UPDATE_SUCCESS:
    case clinician.CLINICIAN_DELETE_SUCCESS:
    case clinician.CLINICIAN_CREATE_FAILURE:
    case clinician.CLINICIAN_UPDATE_FAILURE:
    case clinician.CLINICIAN_DELETE_FAILURE:

    case nurse.NURSE_CREATE_SUCCESS:
    case nurse.NURSE_UPDATE_SUCCESS:
    case nurse.NURSE_DELETE_SUCCESS:
    case nurse.NURSE_CREATE_FAILURE:
    case nurse.NURSE_UPDATE_FAILURE:
    case nurse.NURSE_DELETE_FAILURE:

    case card.CARD_PATIENT_ADD_SUCCESS:
    case card.CARD_PROVIDER_ADD_SUCCESS:
    case card.CARD_DELETE_SUCCESS:
    case card.CARD_ADD_FAILURE:
    case card.CARD_DELETE_FAILURE:

    case patient.PATIENT_CREATE_SUCCESS:
    case patient.PATIENT_UPDATE_SUCCESS:
    case patient.PATIENT_CREATE_FAILURE:
    case patient.PATIENT_UPDATE_FAILURE:
    case patient.PATIENT_CONSENT_FORM_SUCCESS:
    case patient.PATIENT_CONSENT_FORM_FAILURE:
    case patient.PATIENT_CONSENT_FORM_DELETE_SUCCESS:
    case patient.PATIENT_CONSENT_FORM_DELETE_FAILURE:
    case patient.PATIENT_FILES_SUCCESS:
    case patient.PATIENT_FILES_FAILURE:
    case patient.PATIENT_FILES_DELETE_SUCCESS:
    case patient.PATIENT_FILES_DELETE_FAILURE:
    case patient.PATIENT_APPLICATION_SUBMIT_SUCCESS:
    case patient.PATIENT_APPLICATION_SUBMIT_FAILURE:
    case patient.PATIENT_APPLICATION_GET_SUCCESS:
    case patient.PATIENT_APPLICATION_GET_FAILURE:
    case patient.PATIENT_APPLICATION_RESEND_SUCCESS:
    case patient.PATIENT_APPLICATION_RESEND_FAILURE:

    case auth.LOGIN_SUCCESS:
    case auth.LOGIN_FAILURE:

    case kit.GET_ORDER_SUMMARY_SUCCESS:
    case kit.GET_ORDER_SUMMARY_FAILURE:
    case kit.ORDER_COMPLETE_SUCCESS:
    case kit.ORDER_COMPLETE_FAILURE:

    case provider.PROVIDER_SUBMIT_REQUEST_FAILURE:
    case provider.PROVIDER_SUBMIT_REQUEST_SUCCESS:
    case provider.PROVIDER_GET_LIST_SUCCESS:
    case provider.PROVIDER_GET_LIST_FAILURE:
    case provider.PROVIDER_GET_DETAILS_SUCCESS:
    case provider.PROVIDER_GET_DETAILS_FAILURE:
    case provider.PROVIDER_CREATE_SUCCESS:
    case provider.PROVIDER_CREATE_FAILURE:
    case provider.PROVIDER_UPDATE_SUCCESS:
    case provider.PROVIDER_UPDATE_FAILURE:

    case provider_kit.PROVIDER_KIT_CREATE_SUCCESS:
    case provider_kit.PROVIDER_KIT_CREATE_FAILURE:
    case provider_kit.PROVIDER_KIT_UPDATE_SUCCESS:
    case provider_kit.PROVIDER_KIT_UPDATE_FAILURE:
    case provider_kit.PROVIDER_KIT_GET_LIST_SUCCESS:
    case provider_kit.PROVIDER_KIT_GET_LIST_FAILURE:
    case provider_kit.PROVIDER_KIT_DELETE_SUCCESS:
    case provider_kit.PROVIDER_KIT_DELETE_FAILURE:
    case provider.PROVIDER_DELETE_SUCCESS:
    case provider.PROVIDER_DELETE_FAILURE:
    case patient.PATIENT_ARCHIVE_PUT_SUCCESS:
    case patient.PATIENT_ARCHIVE_PUT_FAILURE:

    case patient.PATIENT_REPORTS_SUCCESS:
    case patient.PATIENT_REPORTS_DELETE_SUCCESS:
    case patient.PATIENT_REPORTS_FAILURE:
    case patient.PATIENT_REPORTS_DELETE_FAILURE:

      return {
        ...state,
        loading: false
      };

    case common.SET_PROVIDER:
      return {
        ...state,
        selected_provider_id: action.provider_id
      };

    default:
      return state;
  }
};
