import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getSampleList} from 'actions/sample';
import {Link} from "react-router-dom";
import {Card, CardBody, CardLink, CardTitle} from "reactstrap";
// import {Karyotype, Sex, SamplePreviewPlot} from 'components/utilities';
import TablePlaceholder from "components/Placeholders/TablePlaceholder";


class SampleList extends Component {
  static defaultProps = {
    limit: 48,
    seeAllLink: null,
    title: 'Samples'
  };

  componentWillMount() {
    this.props.getSampleList();
  }

  render() {
    const {samples} = this.props;

    return samples ? (
      <div>
        <Card>
          <CardBody>
            <CardTitle>{this.props.title}</CardTitle>
            {
              samples.length ? (
                <div className="items-table-wrapper">
                  <div className="items-table">
                    {
                      samples.map((sample, i) => {
                        return sample.karyotype.display && i <= this.props.limit ? (
                          <Link to={`/sample/${sample.id}`} className="table-row"
                                key={`sample-row-${i}`}>
                            {/*<div>*/}
                            {/*  <div className="sample-preview-plot" style={{width: 120}}>*/}
                            {/*    <SamplePreviewPlot sample={sample}/>*/}
                            {/*  </div>*/}
                            {/*</div>*/}

                            {/*<div>*/}
                            {/*  <span className="sample-row-title">{sample.name} #{sample.number}</span><br/>*/}
                            {/*  <small className="text-muted">{sample.run_id}</small>*/}
                            {/*</div>*/}

                            {/*<div md="auto" className="text-center sex">*/}
                            {/*  <Sex sex={sample.sex}/>*/}
                            {/*</div>*/}

                            {/*<div><Karyotype sample={sample}/></div>*/}

                            {/*<div md="auto" style={{paddingRight: 20}}>*/}
                            {/*  {sample.status === 'ready' ? <span>Ready</span> : (sample.status === 'updating' ? <span>Updating...</span>: '')}*/}
                            {/*</div>*/}

                            {/*<div className="text-right">*/}
                            {/*  {Moment(sample.date, 'YYYY-MM-DD').format('MMM DD')}*/}
                            {/*</div>*/}
                          </Link>
                        ) : ''
                      })
                    }
                  </div>
                </div>
              ) : <TablePlaceholder/>
            }

            {
              this.props.seeAllLink ? (
                <div style={{marginTop: 30}} className="text-center">
                  <CardLink href={this.props.seeAllLink} style={{fontSize: '18px'}}>View All</CardLink>
                </div>
              ) : ''
            }
          </CardBody>
        </Card>

      </div>
    ) : ('loading samples...')
  }
}

export default connect(
  state => ({
    samples: state.sample.samples
  }),
  {
    getSampleList: getSampleList
  }
)(SampleList)
