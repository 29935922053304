import React from 'react';
import PasswordUpdateForm from "components/Account/PasswordUpdateForm";


export default (props) => {
  return (
    <div>
      <h3>Account Settings</h3>

      <PasswordUpdateForm onSuccess={() => {
        window.localStorage.removeItem('persist:clinic-portal');
        alert('Password successfully set. You will be logged out now.');
        window.location.href = '/login';
      }}/>
    </div>
  )
}
