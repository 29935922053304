import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import './style.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import pdf_thumb from 'images/blank_pdf.svg';
import docx_thumb from 'images/blank_docx.svg';
import {IfAuthSuper} from "components/RBAC";


class ImageSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: '',
      files: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value && this.props.value) {
      this.updateFiles(this.props.value);
    }
  }

  componentDidMount() {
    this.updateFiles(this.props.value);
  }

  updateFiles(value) {
    if (value) {
      this.setState({
        ...this.state,
        files: value.split(',')
      });
    }
  }

  handleChange(e, url = null) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        }, () => {
          const file_name = (file.name).toLowerCase().split('.').slice(0, -1).join('.');
          this.props.onFileUpdate(reader.result, file_name, url);
        });
      };

      reader.readAsDataURL(file);
    }
  }

  handleDelete(e, url) {
    e.preventDefault();
    const result = window.confirm("Are you sure?");
    if (result) {
      this.props.onFileDelete(url);
    }
  }

  render() {
    return (
      <div>
        {
          this.state.files.map((url, i) => {
              let ext = null;
              let bg_url = url;

              if (url) {
                ext = url.split('.').pop().toLowerCase();
              }

              if (ext === 'pdf') {
                bg_url = pdf_thumb;
              } else if (ext === 'doc' || ext === 'docx') {
                bg_url = docx_thumb;
              }

              return (
                <Row
                  key={'file-item-' + url}
                  className="justify-content-between align-items-center file-row"
                  onClick={(e) => {
                    window.location.href = url
                  }}
                >
                  <Col md="auto">
                    <img
                      src={bg_url}
                      alt=""
                      style={{width: 40}}
                    />
                  </Col>
                  <Col>
                    {url.split('/').pop()}
                  </Col>
                  <Col md="auto">
                    {
                      this.props.superOnly ? (
                        <IfAuthSuper>
                          <Button color="link" size="sm" onClick={(e) => {
                            e.stopPropagation();
                            this.handleDelete(e, url)
                          }}>
                      <span className="text-danger">
                        <FontAwesomeIcon icon="trash-alt"/>
                      </span>
                          </Button>
                        </IfAuthSuper>
                      ) : (
                        <Button color="link" size="sm" onClick={(e) => {
                          e.stopPropagation();
                          this.handleDelete(e, url)
                        }}>
                      <span className="text-danger">
                        <FontAwesomeIcon icon="trash-alt"/>
                      </span>
                        </Button>
                      )
                    }

                  </Col>
                </Row>
              )
            }
          )
        }
        {
          this.props.superOnly ? (
            <IfAuthSuper>
              <Row style={{marginTop: 40}}>
                <Col className="text-center">
                  <label className="file-controls">
                    <input
                      className="fileInput"
                      type="file"
                      onChange={(e) => this.handleChange(e)}
                      style={{display: 'none'}}
                    />
                    <span className="btn btn-primary btn-sm">
                <FontAwesomeIcon icon="cloud-upload-alt"/> Upload File
              </span>
                  </label>
                </Col>
              </Row>
            </IfAuthSuper>
          ) : (
            <Row style={{marginTop: 40}}>
              <Col className="text-center">
                <label className="file-controls">
                  <input
                    className="fileInput"
                    type="file"
                    onChange={(e) => this.handleChange(e)}
                    style={{display: 'none'}}
                  />
                  <span className="btn btn-primary btn-sm">
                <FontAwesomeIcon icon="cloud-upload-alt"/> Upload File
              </span>
                </label>
              </Col>
            </Row>
          )
        }
      </div>
    )
  }
}

export default ImageSelect;
