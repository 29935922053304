import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';

export const PROVIDER_GET_REQUEST = '@@provider/PROVIDER_GET_REQUEST';
export const PROVIDER_GET_SUCCESS = '@@provider/PROVIDER_GET_SUCCESS';
export const PROVIDER_GET_FAILURE = '@@provider/PROVIDER_GET_FAILURE';

export const PROVIDER_DELETE_REQUEST = '@@provider/PROVIDER_DELETE_REQUEST';
export const PROVIDER_DELETE_SUCCESS = '@@provider/PROVIDER_DELETE_SUCCESS';
export const PROVIDER_DELETE_FAILURE = '@@provider/PROVIDER_DELETE_FAILURE';

export const PROVIDER_CREATE_REQUEST = '@@provider/PROVIDER_CREATE_REQUEST';
export const PROVIDER_CREATE_SUCCESS = '@@provider/PROVIDER_CREATE_SUCCESS';
export const PROVIDER_CREATE_FAILURE = '@@provider/PROVIDER_CREATE_FAILURE';

export const PROVIDER_UPDATE_REQUEST = '@@provider/PROVIDER_UPDATE_REQUEST';
export const PROVIDER_UPDATE_SUCCESS = '@@provider/PROVIDER_UPDATE_SUCCESS';
export const PROVIDER_UPDATE_FAILURE = '@@provider/PROVIDER_UPDATE_FAILURE';

export const PROVIDER_GET_DETAILS_REQUEST = '@@provider/PROVIDER_GET_DETAILS_REQUEST';
export const PROVIDER_GET_DETAILS_SUCCESS = '@@provider/PROVIDER_GET_DETAILS_SUCCESS';
export const PROVIDER_GET_DETAILS_FAILURE = '@@provider/PROVIDER_GET_DETAILS_FAILURE';

export const PROVIDER_GET_LIST_REQUEST = '@@provider/PROVIDER_GET_LIST_REQUEST';
export const PROVIDER_GET_LIST_SUCCESS = '@@provider/PROVIDER_GET_LIST_SUCCESS';
export const PROVIDER_GET_LIST_FAILURE = '@@provider/PROVIDER_GET_LIST_FAILURE';

export const PROVIDER_SUBMIT_REQUEST_REQUEST = '@@provider/PROVIDER_SUBMIT_REQUEST_REQUEST';
export const PROVIDER_SUBMIT_REQUEST_SUCCESS = '@@provider/PROVIDER_SUBMIT_REQUEST_SUCCESS';
export const PROVIDER_SUBMIT_REQUEST_FAILURE = '@@provider/PROVIDER_SUBMIT_REQUEST_FAILURE';

export const PROVIDER_MODAL_OPEN = '@@provider/PROVIDER_MODAL_OPEN';
export const PROVIDER_MODAL_CLOSE = '@@provider/PROVIDER_MODAL_CLOSE';

export const PROVIDER_GET_SIMPLE_LIST_REQUEST = '@@provider/PROVIDER_GET_SIMPLE_LIST_REQUEST';
export const PROVIDER_GET_SIMPLE_LIST_SUCCESS = '@@provider/PROVIDER_GET_SIMPLE_LIST_SUCCESS';
export const PROVIDER_GET_SIMPLE_LIST_FAILURE = '@@provider/PROVIDER_GET_SIMPLE_LIST_FAILURE';

export const PROVIDER_GET_SIMPLE_DETAIL_REQUEST = '@@provider/PROVIDER_GET_SIMPLE_DETAIL_REQUEST';
export const PROVIDER_GET_SIMPLE_DETAIL_SUCCESS = '@@provider/PROVIDER_GET_SIMPLE_DETAIL_SUCCESS';
export const PROVIDER_GET_SIMPLE_DETAIL_FAILURE = '@@provider/PROVIDER_GET_SIMPLE_DETAIL_FAILURE';

//
export const getProviderList = () => {
  return ({
    [RSAA]: {
      endpoint: 'providers-simple-list',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_GET_LIST_REQUEST,
        PROVIDER_GET_LIST_SUCCESS,
        PROVIDER_GET_LIST_FAILURE
      ]
    }
  })
};

export const getProviderSimpleList = () => {
  return ({
    [RSAA]: {
      endpoint: 'provider-list',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_GET_SIMPLE_LIST_REQUEST,
        PROVIDER_GET_SIMPLE_LIST_SUCCESS,
        PROVIDER_GET_SIMPLE_LIST_FAILURE
      ]
    }
  })
};

export const getProviderSimpleDetail = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'providers/'+id+'/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_GET_SIMPLE_DETAIL_REQUEST,
        PROVIDER_GET_SIMPLE_DETAIL_SUCCESS,
        PROVIDER_GET_SIMPLE_DETAIL_FAILURE
      ]
    }
  })
};

export const submitProvider = (data) => {
  const url =  `providers/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  return ({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types: data.id ? [
        PROVIDER_UPDATE_REQUEST,
        PROVIDER_UPDATE_SUCCESS,
        PROVIDER_UPDATE_FAILURE
      ] : [
        PROVIDER_CREATE_REQUEST,
        PROVIDER_CREATE_SUCCESS,
        PROVIDER_CREATE_FAILURE
      ],
      body: JSON.stringify(data)
    }
  })
};

export const getProviderDetails = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'providers/' + id + '/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_GET_DETAILS_REQUEST,
        PROVIDER_GET_DETAILS_SUCCESS,
        PROVIDER_GET_DETAILS_FAILURE
      ]
    }
  })
};

export const getProvider = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'provider/' + id + '/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_GET_REQUEST,
        PROVIDER_GET_SUCCESS,
        PROVIDER_GET_FAILURE
      ]
    }
  })
};

export const submitRequest = (data) => {
  return ({
    [RSAA]: {
      endpoint: 'provider-request/',
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      types: [
        PROVIDER_SUBMIT_REQUEST_REQUEST,
        PROVIDER_SUBMIT_REQUEST_SUCCESS,
        PROVIDER_SUBMIT_REQUEST_FAILURE
      ],
      body: JSON.stringify(data)
    }
  })
};

export const deleteProvider = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'providers/' + id + '/',
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        PROVIDER_DELETE_REQUEST,
        {
          type: PROVIDER_DELETE_SUCCESS,
          payload: (action, state, res) => {
            //history.push(`/clinics`);
          }
        },
        PROVIDER_DELETE_FAILURE
      ]
    }
  })
};

export const openModal = (data = {}) => ({
  type: PROVIDER_MODAL_OPEN,
  data
});

export const closeModal = () => ({
  type: PROVIDER_MODAL_CLOSE,
});
