import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from 'react-redux';
import {patientModalClose} from 'actions/modal';
import {submitPatient} from "actions/patient";
import PatientForm from "components/forms/PatientForm";


class PatientModal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(json) {
    this.props.submitPatient(json);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.close} size={'lg'}>
        <ModalHeader toggle={this.props.close}>Patient Info</ModalHeader>
        <ModalBody>
          <PatientForm onSubmit={this.onSubmit} initialValues={this.props.item}/>
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    isOpen: state.modal.patientModalIsOpen,
    //isOpen: true,
    item: state.modal.patient,
  }),
  {
    close: patientModalClose,
    submitPatient: submitPatient
  }
)(PatientModal)
