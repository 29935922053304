import {combineReducers} from 'redux';
import auth from 'reducers/auth';
import {sampleReducer} from 'reducers/sample';
import {modalReducer} from 'reducers/modal';
import {patientReducer} from 'reducers/patient';
import {patientApplicationReducer} from 'reducers/patient_application';
import {clinicianReducer} from "reducers/clinician";
import {nurseReducer} from "reducers/nurse";
import {reducer as formReducer} from 'redux-form';
import {caseReducer} from "reducers/case";
import {commonReducer} from "reducers/common";
import {cardReducer} from "reducers/card";
import {providerReducer} from "reducers/provider";
import {kitReducer} from "reducers/kit";
import {providerKitReducer} from "reducers/provider_kit";
import {WSClientReducer} from "reducers/wsclient";
import {alertReducer} from "reducers/alert";

export default combineReducers({
  auth: auth,
  sample: sampleReducer,
  patient: patientReducer,
  patientApplication: patientApplicationReducer,
  clinician: clinicianReducer,
  nurse: nurseReducer,
  modal: modalReducer,
  form: formReducer,
  case: caseReducer,
  common: commonReducer,
  card: cardReducer,
  provider: providerReducer,
  kit: kitReducer,
  providerKit: providerKitReducer,
  wsclient: WSClientReducer,
  alert: alertReducer
})

export function withAuth(headers = {}) {
  return (state) => ({
    ...headers,
    'Authorization': `Token ${state.auth.token}`
  })
}
