import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';
import history from "../history";

export const CASE_LIST_REQUEST = '@@case/CASE_LIST_REQUEST';
export const CASE_LIST_SUCCESS = '@@case/CASE_LIST_SUCCESS';
export const CASE_LIST_FAILURE = '@@case/CASE_LIST_FAILURE';

export const CASE_DETAIL_REQUEST = '@@case/CASE_DETAIL_REQUEST';
export const CASE_DETAIL_SUCCESS = '@@case/CASE_DETAIL_SUCCESS';
export const CASE_DETAIL_FAILURE = '@@case/CASE_DETAIL_FAILURE';

export const CASE_CREATE_REQUEST = '@@case/CASE_CREATE_REQUEST';
export const CASE_CREATE_SUCCESS = '@@case/CASE_CREATE_SUCCESS';
export const CASE_CREATE_FAILURE = '@@case/CASE_CREATE_FAILURE';

export const CASE_UPDATE_REQUEST = '@@case/CASE_UPDATE_REQUEST';
export const CASE_UPDATE_SUCCESS = '@@case/CASE_UPDATE_SUCCESS';
export const CASE_UPDATE_FAILURE = '@@case/CASE_UPDATE_FAILURE';

export const CASE_DELETE_REQUEST = '@@case/CASE_DELETE_REQUEST';
export const CASE_DELETE_SUCCESS = '@@case/CASE_DELETE_SUCCESS';
export const CASE_DELETE_FAILURE = '@@case/CASE_DELETE_FAILURE';

export const UPDATE_SHIPPING_REQUEST = '@@case/UPDATE_SHIPPING_REQUEST';
export const UPDATE_SHIPPING_SUCCESS = '@@case/UPDATE_SHIPPING_SUCCESS';
export const UPDATE_SHIPPING_FAILURE = '@@case/UPDATE_SHIPPING_FAILURE';

export const PAGE_SIZE = 48;


export const submit = (data) => {
  return ({
    [RSAA]: {
      endpoint: `cases/${data.id ? data.id + '/' : ''}`,
      method: data.id ? 'PUT' : 'POST',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: data.id ?
        [
          CASE_UPDATE_REQUEST,
          CASE_UPDATE_SUCCESS,
          CASE_UPDATE_FAILURE
        ] :
        [
          CASE_CREATE_REQUEST,
          {
            type: CASE_CREATE_SUCCESS,
            payload: (action, state, res) => {
              return res.json().then(json => {
                history.push(`/cases/${json.id}`);
                return json;
              });
            }
          },
          CASE_CREATE_FAILURE
        ],
      body: JSON.stringify(data)
    }
  })
};

export const getList = (filter = {}) => {
  return ({
    [RSAA]: {
      endpoint: 'cases/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        CASE_LIST_REQUEST, CASE_LIST_SUCCESS, CASE_LIST_FAILURE
      ]
    }
  })
};

export const getCaseDetail = (kit_id) => {
  return ({
    [RSAA]: {
      endpoint: 'cases/' + kit_id + '/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        CASE_DETAIL_REQUEST, CASE_DETAIL_SUCCESS, CASE_DETAIL_FAILURE
      ]
    }
  })
};

export const deleteCase = (id) => {
  return ({
    [RSAA]: {
      endpoint: 'cases/' + id + '/',
      method: 'DELETE',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        CASE_DELETE_REQUEST,
        {
          type: CASE_DELETE_SUCCESS,
          payload: (action, state, res) => {
            history.push(`/cases`);
          }
        },
        CASE_DELETE_FAILURE
      ]
    }
  })
};

export const updateShipping = (data) => {
  const {case_id, shipping_carrier, shipping_tracking_number} = data;

  return ({
    [RSAA]: {
      endpoint: 'case-shipping-update/' + case_id + '/',
      method: 'POST',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        UPDATE_SHIPPING_REQUEST, UPDATE_SHIPPING_SUCCESS, UPDATE_SHIPPING_FAILURE
      ],
      body: JSON.stringify({shipping_carrier, shipping_tracking_number})
    }
  })
};
