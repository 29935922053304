export const data = [
  {
    id: 1,
    case_id: 14390,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 2,
    case_id: 14188,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/24/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 3,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/01/2024',
    axiom_date: '07/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 4,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 5,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 6,
    case_id: 14490,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 7,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/30/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 8,
    case_id: 14444,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 9,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 10,
    case_id: 14211,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 11,
    case_id: 14370,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 12,
    case_id: 14181,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/01/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 13,
    case_id: 14116,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 14,
    case_id: 14458,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 15,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 16,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 17,
    case_id: 14231,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 18,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: '07/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 19,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: '07/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 20,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/20/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 21,
    case_id: 14406,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/11/2024',
    axiom_date: '08/16/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 22,
    case_id: 14153,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 23,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 24,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 25,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 26,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '08/28/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 27,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 28,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 29,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 30,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: '08/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 31,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 32,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 33,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 34,
    case_id: 14448,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 35,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/19/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 36,
    case_id: 14111,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 37,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 38,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 39,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 40,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 41,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 42,
    case_id: 14391,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 43,
    case_id: 14355,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 44,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '06/04/2024',
    axiom_date: '07/17/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 45,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 46,
    case_id: 14420,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 47,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 48,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 49,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 50,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 51,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '07/31/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 52,
    case_id: 14307,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 53,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/07/2024',
    axiom_date: '08/13/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 54,
    case_id: 14225,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 55,
    case_id: 14382,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 56,
    case_id: 14434,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 57,
    case_id: 14336,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 58,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 59,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 60,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 61,
    case_id: 14411,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 62,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 63,
    case_id: 14333,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/31/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 64,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/01/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 65,
    case_id: 14402,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 66,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 67,
    case_id: 14201,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 68,
    case_id: 14207,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 69,
    case_id: 14282,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/15/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 70,
    case_id: 14375,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 71,
    case_id: 14408,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/26/2024',
    axiom_date: '08/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 72,
    case_id: 14472,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/22/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 73,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 74,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/27/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 75,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 76,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 77,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 78,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 79,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 80,
    case_id: 14293,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 81,
    case_id: 14275,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 82,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 83,
    case_id: 14233,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 84,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 85,
    case_id: 14477,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 86,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 87,
    case_id: 14359,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 88,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 89,
    case_id: 14162,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/30/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 90,
    case_id: 14161,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 91,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 92,
    case_id: 14488,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/05/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 93,
    case_id: 14470,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 94,
    case_id: 14252,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 95,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/07/2024',
    axiom_date: '07/20/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 96,
    case_id: 14358,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: '08/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 97,
    case_id: 14151,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/23/2024',
    axiom_date: '07/15/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 98,
    case_id: 14433,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 99,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '07/01/2024',
    axiom_date: '07/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 100,
    case_id: 14275,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/14/2024',
    axiom_date: '07/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 101,
    case_id: 14372,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 102,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 103,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 104,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 105,
    case_id: 14263,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/26/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 106,
    case_id: 14343,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 107,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '07/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 108,
    case_id: 14338,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 109,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 110,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'research',
    preamp_date: '07/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 111,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 112,
    case_id: 14341,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 113,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/21/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 114,
    case_id: 14240,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 115,
    case_id: 14268,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 116,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 117,
    case_id: 14197,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/27/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 118,
    case_id: 14255,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 119,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/12/2024',
    axiom_date: '08/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 120,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/27/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 121,
    case_id: 14192,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/08/2024',
    axiom_date: '08/04/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 122,
    case_id: 14336,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 123,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/30/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 124,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 125,
    case_id: 14407,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 126,
    case_id: 14411,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 127,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 128,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 129,
    case_id: 14128,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: '08/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 130,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 131,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 132,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 133,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/20/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 134,
    case_id: 14468,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '08/05/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 135,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: '08/13/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 136,
    case_id: 14456,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/10/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 137,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '08/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 138,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 139,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/22/2024',
    axiom_date: '08/25/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 140,
    case_id: 14248,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 141,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 142,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 143,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'unprocessed',
    preamp_date: '08/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 144,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 145,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 146,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 147,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 148,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/22/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 149,
    case_id: 14280,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 150,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 151,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 152,
    case_id: 14263,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 153,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 154,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 155,
    case_id: 14332,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 156,
    case_id: 14102,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 157,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 158,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 159,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 160,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 161,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 162,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 163,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 164,
    case_id: 14157,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 165,
    case_id: 14155,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 166,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 167,
    case_id: 14286,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 168,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 169,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 170,
    case_id: 14102,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 171,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 172,
    case_id: 14328,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 173,
    case_id: 14270,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 174,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/09/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 175,
    case_id: 14455,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 176,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 177,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 178,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 179,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 180,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 181,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 182,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: '08/19/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 183,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/30/2024',
    axiom_date: '08/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 184,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 185,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 186,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 187,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 188,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 189,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/15/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 190,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 191,
    case_id: 14448,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 192,
    case_id: 14230,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 193,
    case_id: 14425,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/21/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 194,
    case_id: 14355,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 195,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 196,
    case_id: 14262,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 197,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 198,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 199,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 200,
    case_id: 14431,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 201,
    case_id: 14333,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/30/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 202,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 203,
    case_id: 14103,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 204,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 205,
    case_id: 14137,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/31/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 206,
    case_id: 14498,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 207,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 208,
    case_id: 14232,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 209,
    case_id: 14193,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 210,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 211,
    case_id: 14470,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 212,
    case_id: 14473,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 213,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/05/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 214,
    case_id: 14158,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 215,
    case_id: 14232,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 216,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 217,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 218,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 219,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 220,
    case_id: 14368,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 221,
    case_id: 14161,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 222,
    case_id: 14336,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 223,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 224,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 225,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 226,
    case_id: 14101,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 227,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 228,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/26/2024',
    axiom_date: '07/17/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 229,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 230,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 231,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/27/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 232,
    case_id: 14414,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/15/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 233,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 234,
    case_id: 14174,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 235,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/22/2024',
    axiom_date: '08/13/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 236,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 237,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/05/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 238,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 239,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: '08/06/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 240,
    case_id: 14224,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/14/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 241,
    case_id: 14267,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 242,
    case_id: 14358,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 243,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 244,
    case_id: 14239,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/12/2024',
    axiom_date: '08/23/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 245,
    case_id: 14419,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 246,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/07/2024',
    axiom_date: '07/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 247,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/28/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 248,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 249,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 250,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 251,
    case_id: 14449,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '06/05/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 252,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/03/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 253,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 254,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 255,
    case_id: 14366,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 256,
    case_id: 14267,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 257,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: '07/11/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 258,
    case_id: 14268,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 259,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: '07/02/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 260,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 261,
    case_id: 14450,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: '08/08/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 262,
    case_id: 14171,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 263,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 264,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 265,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: '08/21/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 266,
    case_id: 14269,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 267,
    case_id: 14162,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 268,
    case_id: 14442,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 269,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/14/2024',
    axiom_date: '08/19/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 270,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/05/2024',
    axiom_date: '08/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 271,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 272,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 273,
    case_id: 14327,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 274,
    case_id: 14278,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 275,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 276,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 277,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 278,
    case_id: 14144,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 279,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 280,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/05/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 281,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 282,
    case_id: 14262,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 283,
    case_id: 14247,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/11/2024',
    axiom_date: '06/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 284,
    case_id: 14432,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 285,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 286,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/12/2024',
    axiom_date: '08/03/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 287,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 288,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 289,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 290,
    case_id: 14307,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 291,
    case_id: 14216,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 292,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/21/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 293,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 294,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: '07/07/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 295,
    case_id: 14124,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 296,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 297,
    case_id: 14137,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 298,
    case_id: 14300,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: '08/10/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 299,
    case_id: 14332,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 300,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 301,
    case_id: 14230,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/14/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 302,
    case_id: 14124,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 303,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 304,
    case_id: 14487,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/11/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 305,
    case_id: 14343,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 306,
    case_id: 14132,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 307,
    case_id: 14146,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 308,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 309,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: '06/21/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 310,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 311,
    case_id: 14394,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 312,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 313,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 314,
    case_id: 14464,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 315,
    case_id: 14274,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 316,
    case_id: 14479,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 317,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 318,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/12/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 319,
    case_id: 14464,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/25/2024',
    axiom_date: '07/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 320,
    case_id: 14369,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 321,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 322,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 323,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 324,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 325,
    case_id: 14116,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 326,
    case_id: 14370,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 327,
    case_id: 14168,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 328,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 329,
    case_id: 14234,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 330,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/26/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 331,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 332,
    case_id: 14435,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/09/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 333,
    case_id: 14109,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 334,
    case_id: 14175,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/11/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 335,
    case_id: 14308,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 336,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/14/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 337,
    case_id: 14282,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 338,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/23/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 339,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/19/2024',
    axiom_date: '08/22/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 340,
    case_id: 14212,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/09/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 341,
    case_id: 14193,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 342,
    case_id: 14350,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 343,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 344,
    case_id: 14209,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 345,
    case_id: 14332,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 346,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'research',
    preamp_date: '06/28/2024',
    axiom_date: '08/04/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 347,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'research',
    preamp_date: '06/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 348,
    case_id: 14477,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 349,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 350,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'research',
    preamp_date: '06/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 351,
    case_id: 14177,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 352,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 353,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/05/2024',
    axiom_date: '07/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 354,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 355,
    case_id: 14307,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 356,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 357,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 358,
    case_id: 14312,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 359,
    case_id: 14478,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/14/2024',
    axiom_date: '08/03/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 360,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/15/2024',
    axiom_date: '08/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 361,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 362,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 363,
    case_id: 14109,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 364,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 365,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 366,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 367,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/15/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 368,
    case_id: 14472,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 369,
    case_id: 14488,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/11/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 370,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/21/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 371,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/26/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 372,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 373,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 374,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 375,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/28/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 376,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/23/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 377,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '07/22/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 378,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 379,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 380,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 381,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '06/06/2024',
    axiom_date: '08/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 382,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'unprocessed',
    preamp_date: '06/29/2024',
    axiom_date: '08/02/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 383,
    case_id: 14483,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 384,
    case_id: 14482,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 385,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 386,
    case_id: 14131,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/21/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 387,
    case_id: 14220,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 388,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 389,
    case_id: 14120,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 390,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/14/2024',
    axiom_date: '07/22/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 391,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/10/2024',
    axiom_date: '08/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 392,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/16/2024',
    axiom_date: '07/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 393,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/25/2024',
    axiom_date: '08/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 394,
    case_id: 14179,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 395,
    case_id: 14325,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 396,
    case_id: 14236,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/15/2024',
    axiom_date: '06/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 397,
    case_id: 14164,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 398,
    case_id: 14442,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 399,
    case_id: 14420,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 400,
    case_id: 14482,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 401,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 402,
    case_id: 14180,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/21/2024',
    axiom_date: '07/21/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 403,
    case_id: 14238,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 404,
    case_id: 14350,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/08/2024',
    axiom_date: '07/13/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 405,
    case_id: 14106,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 406,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 407,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 408,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 409,
    case_id: 14151,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/09/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 410,
    case_id: 14221,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 411,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 412,
    case_id: 14491,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 413,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 414,
    case_id: 14269,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 415,
    case_id: 14488,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/19/2024',
    axiom_date: '07/21/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 416,
    case_id: 14173,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '06/03/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 417,
    case_id: 14478,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/12/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 418,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 419,
    case_id: 14433,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 420,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 421,
    case_id: 14452,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/22/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 422,
    case_id: 14310,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/22/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 423,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/20/2024',
    axiom_date: '07/02/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 424,
    case_id: 14284,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 425,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 426,
    case_id: 14451,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 427,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 428,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 429,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 430,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 431,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 432,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/23/2024',
    axiom_date: '07/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 433,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/17/2024',
    axiom_date: '08/10/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 434,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '06/28/2024',
    axiom_date: '08/22/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 435,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/12/2024',
    axiom_date: '08/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 436,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 437,
    case_id: 14238,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/29/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 438,
    case_id: 14191,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 439,
    case_id: 14407,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '07/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 440,
    case_id: 14456,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 441,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/28/2024',
    axiom_date: '08/13/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 442,
    case_id: 14468,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 443,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 444,
    case_id: 14197,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/21/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 445,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/12/2024',
    axiom_date: '08/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 446,
    case_id: 14131,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 447,
    case_id: 14184,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 448,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 449,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 450,
    case_id: 14346,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/11/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 451,
    case_id: 14442,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 452,
    case_id: 14213,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/09/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 453,
    case_id: 14335,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 454,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 455,
    case_id: 14366,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 456,
    case_id: 14490,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/14/2024',
    axiom_date: '08/22/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 457,
    case_id: 14373,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 458,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/30/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 459,
    case_id: 14217,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 460,
    case_id: 14226,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 461,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 462,
    case_id: 14288,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 463,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 464,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 465,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/22/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 466,
    case_id: 14381,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/15/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 467,
    case_id: 14148,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 468,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 469,
    case_id: 14287,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: '07/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 470,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 471,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 472,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 473,
    case_id: 14399,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: '08/12/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 474,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 475,
    case_id: 14191,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/22/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 476,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 477,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/30/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 478,
    case_id: 14466,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 479,
    case_id: 14298,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 480,
    case_id: 14151,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 481,
    case_id: 14480,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/19/2024',
    axiom_date: '08/20/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 482,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 483,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 484,
    case_id: 14380,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 485,
    case_id: 14118,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 486,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 487,
    case_id: 14367,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 488,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/26/2024',
    axiom_date: '08/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 489,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 490,
    case_id: 14469,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 491,
    case_id: 14395,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 492,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 493,
    case_id: 14132,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 494,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '07/23/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 495,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/14/2024',
    axiom_date: '08/17/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 496,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: '08/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 497,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/05/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 498,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 499,
    case_id: 14410,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 500,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 501,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 502,
    case_id: 14277,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 503,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '06/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 504,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 505,
    case_id: 14432,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: '07/12/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 506,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 507,
    case_id: 14411,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 508,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 509,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '06/11/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 510,
    case_id: 14478,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 511,
    case_id: 14283,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 512,
    case_id: 14258,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 513,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/09/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 514,
    case_id: 14486,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 515,
    case_id: 14483,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/12/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 516,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 517,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '08/27/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 518,
    case_id: 14421,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 519,
    case_id: 14493,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 520,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 521,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 522,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 523,
    case_id: 14107,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 524,
    case_id: 14252,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 525,
    case_id: 14246,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 526,
    case_id: 14263,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 527,
    case_id: 14396,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: '08/16/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 528,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 529,
    case_id: 14489,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 530,
    case_id: 14379,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/27/2024',
    axiom_date: '08/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 531,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 532,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 533,
    case_id: 14253,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 534,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 535,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 536,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 537,
    case_id: 14285,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 538,
    case_id: 14314,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/25/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 539,
    case_id: 14492,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/16/2024',
    axiom_date: '08/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 540,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 541,
    case_id: 14414,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 542,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/05/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 543,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 544,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 545,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 546,
    case_id: 14135,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 547,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/28/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 548,
    case_id: 14348,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 549,
    case_id: 14145,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 550,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 551,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 552,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 553,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '07/11/2024',
    axiom_date: '07/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 554,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 555,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/16/2024',
    axiom_date: '08/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 556,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 557,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/31/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 558,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: '08/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 559,
    case_id: 14216,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 560,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 561,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/28/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 562,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/22/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 563,
    case_id: 14439,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 564,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 565,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 566,
    case_id: 14466,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 567,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 568,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 569,
    case_id: 14320,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '06/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 570,
    case_id: 14469,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/24/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 571,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: '07/10/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 572,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/21/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 573,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '06/09/2024',
    axiom_date: '06/21/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 574,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 575,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 576,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '08/31/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 577,
    case_id: 14360,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 578,
    case_id: 14344,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 579,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/03/2024',
    axiom_date: '08/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 580,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 581,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 582,
    case_id: 14482,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 583,
    case_id: 14259,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 584,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 585,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 586,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '08/15/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 587,
    case_id: 14236,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 588,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 589,
    case_id: 14300,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/29/2024',
    axiom_date: '07/04/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 590,
    case_id: 14122,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 591,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/26/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 592,
    case_id: 14260,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 593,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/01/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 594,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/10/2024',
    axiom_date: '08/22/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 595,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/14/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 596,
    case_id: 14257,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 597,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 598,
    case_id: 14319,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 599,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 600,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/07/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 601,
    case_id: 14242,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 602,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 603,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 604,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 605,
    case_id: 14128,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/20/2024',
    axiom_date: '08/19/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 606,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 607,
    case_id: 14352,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/11/2024',
    axiom_date: '08/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 608,
    case_id: 14349,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/13/2024',
    axiom_date: '07/23/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 609,
    case_id: 14123,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 610,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'qc',
    preamp_date: '07/09/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 611,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 612,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/24/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 613,
    case_id: 14447,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/21/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 614,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 615,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 616,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 617,
    case_id: 14355,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 618,
    case_id: 14466,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 619,
    case_id: 14167,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 620,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 621,
    case_id: 14290,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/17/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 622,
    case_id: 14443,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: '08/15/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 623,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 624,
    case_id: 14290,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 625,
    case_id: 14226,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 626,
    case_id: 14139,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 627,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/27/2024',
    axiom_date: '08/05/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 628,
    case_id: 14157,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/21/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 629,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/28/2024',
    axiom_date: '08/06/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 630,
    case_id: 14339,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 631,
    case_id: 14355,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: '07/12/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 632,
    case_id: 14379,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 633,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/14/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 634,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/01/2024',
    axiom_date: '08/24/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 635,
    case_id: 14235,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/08/2024',
    axiom_date: '06/28/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 636,
    case_id: 14232,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 637,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 638,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 639,
    case_id: 14138,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 640,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'research',
    preamp_date: '08/15/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 641,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 642,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 643,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/15/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 644,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 645,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 646,
    case_id: 14301,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/09/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 647,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/29/2024',
    axiom_date: '08/19/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 648,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '08/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 649,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/06/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 650,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: '06/06/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 651,
    case_id: 14213,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 652,
    case_id: 14379,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 653,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 654,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: '07/10/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 655,
    case_id: 14201,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 656,
    case_id: 14313,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 657,
    case_id: 14180,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: '08/19/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 658,
    case_id: 14381,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 659,
    case_id: 14217,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 660,
    case_id: 14361,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 661,
    case_id: 14320,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 662,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 663,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 664,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 665,
    case_id: 14305,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/27/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 666,
    case_id: 14296,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 667,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'qc',
    preamp_date: '08/13/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 668,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 669,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/14/2024',
    axiom_date: '07/05/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 670,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 671,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/30/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 672,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: '07/11/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 673,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 674,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/05/2024',
    axiom_date: '08/17/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 675,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 676,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 677,
    case_id: 14438,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 678,
    case_id: 14328,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 679,
    case_id: 14495,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/25/2024',
    axiom_date: '08/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 680,
    case_id: 14403,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/13/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 681,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: '08/23/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 682,
    case_id: 14187,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 683,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 684,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 685,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 686,
    case_id: 14324,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 687,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 688,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 689,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 690,
    case_id: 14445,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/21/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 691,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/09/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 692,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 693,
    case_id: 14456,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 694,
    case_id: 14113,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 695,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 696,
    case_id: 14457,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 697,
    case_id: 14411,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 698,
    case_id: 14366,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/14/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 699,
    case_id: 14270,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 700,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 701,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 702,
    case_id: 14465,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 703,
    case_id: 14133,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 704,
    case_id: 14498,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 705,
    case_id: 14103,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/09/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 706,
    case_id: 14176,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 707,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 708,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/15/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 709,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 710,
    case_id: 14226,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/26/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 711,
    case_id: 14201,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/08/2024',
    axiom_date: '08/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 712,
    case_id: 14119,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 713,
    case_id: 14165,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 714,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 715,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 716,
    case_id: 14402,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 717,
    case_id: 14474,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 718,
    case_id: 14481,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 719,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 720,
    case_id: 14170,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/18/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 721,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 722,
    case_id: 14124,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/22/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 723,
    case_id: 14139,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 724,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 725,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 726,
    case_id: 14271,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 727,
    case_id: 14102,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 728,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 729,
    case_id: 14112,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 730,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/14/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 731,
    case_id: 14232,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 732,
    case_id: 14254,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/09/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 733,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 734,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/21/2024',
    axiom_date: '08/05/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 735,
    case_id: 14124,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 736,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '06/13/2024',
    axiom_date: '06/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 737,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 738,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 739,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 740,
    case_id: 14183,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 741,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 742,
    case_id: 14447,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 743,
    case_id: 14101,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 744,
    case_id: 14140,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 745,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '07/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 746,
    case_id: 14300,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 747,
    case_id: 14247,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 748,
    case_id: 14435,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/18/2024',
    axiom_date: '08/02/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 749,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: '08/02/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 750,
    case_id: 14373,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 751,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 752,
    case_id: 14408,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 753,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 754,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '07/21/2024',
    axiom_date: '07/28/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 755,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 756,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/14/2024',
    axiom_date: '07/19/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 757,
    case_id: 14481,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 758,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 759,
    case_id: 14346,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 760,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/21/2024',
    axiom_date: '08/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 761,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/05/2024',
    axiom_date: '07/08/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 762,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 763,
    case_id: 14180,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 764,
    case_id: 14366,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 765,
    case_id: 14255,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '08/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 766,
    case_id: 14211,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 767,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 768,
    case_id: 14202,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 769,
    case_id: 14495,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 770,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '07/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 771,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 772,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 773,
    case_id: 14242,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/13/2024',
    axiom_date: '08/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 774,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 775,
    case_id: 14160,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 776,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 777,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 778,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 779,
    case_id: 14343,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 780,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 781,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 782,
    case_id: 14194,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 783,
    case_id: 14113,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 784,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'unprocessed',
    preamp_date: '08/03/2024',
    axiom_date: '08/20/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 785,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 786,
    case_id: 14276,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 787,
    case_id: 14425,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 788,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/30/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 789,
    case_id: 14365,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 790,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 791,
    case_id: 14321,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 792,
    case_id: 14350,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 793,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 794,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 795,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 796,
    case_id: 14406,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 797,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 798,
    case_id: 14116,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 799,
    case_id: 14207,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 800,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 801,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 802,
    case_id: 14204,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 803,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '08/15/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 804,
    case_id: 14298,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 805,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/14/2024',
    axiom_date: '08/04/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 806,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 807,
    case_id: 14277,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 808,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 809,
    case_id: 14269,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 810,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/11/2024',
    axiom_date: '08/30/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 811,
    case_id: 14341,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 812,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 813,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/13/2024',
    axiom_date: '08/12/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 814,
    case_id: 14447,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 815,
    case_id: 14432,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/21/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 816,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/10/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 817,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/16/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 818,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '08/29/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 819,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 820,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 821,
    case_id: 14150,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/05/2024',
    axiom_date: '08/03/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 822,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/05/2024',
    axiom_date: '07/15/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 823,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 824,
    case_id: 14480,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 825,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 826,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/15/2024',
    axiom_date: '08/15/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 827,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 828,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '07/17/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 829,
    case_id: 14151,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/29/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 830,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 831,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/22/2024',
    axiom_date: '07/06/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 832,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 833,
    case_id: 14198,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: '07/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 834,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 835,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 836,
    case_id: 14132,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/19/2024',
    axiom_date: '08/22/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 837,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/15/2024',
    axiom_date: '08/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 838,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/04/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 839,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 840,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 841,
    case_id: 14284,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/14/2024',
    axiom_date: '08/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 842,
    case_id: 14424,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 843,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 844,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/23/2024',
    axiom_date: '08/19/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 845,
    case_id: 14395,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 846,
    case_id: 14486,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 847,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'unprocessed',
    preamp_date: '08/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 848,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 849,
    case_id: 14180,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/27/2024',
    axiom_date: '08/13/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 2
  },
  {
    id: 850,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 851,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 852,
    case_id: 14113,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 853,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 854,
    case_id: 14384,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 855,
    case_id: 14244,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 856,
    case_id: 14406,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 857,
    case_id: 14339,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 2
  },
  {
    id: 858,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'qc',
    preamp_date: '08/21/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 859,
    case_id: 14239,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 860,
    case_id: 14410,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 861,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 862,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/26/2024',
    axiom_date: '08/08/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 863,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'research',
    preamp_date: '06/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 864,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/12/2024',
    axiom_date: '08/21/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 865,
    case_id: 14378,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 866,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 867,
    case_id: 14353,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 868,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: '06/29/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 869,
    case_id: 14364,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 870,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 871,
    case_id: 14459,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 872,
    case_id: 14454,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 873,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 874,
    case_id: 14408,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 875,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: '07/10/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 876,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/14/2024',
    axiom_date: '07/16/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 877,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 878,
    case_id: 14249,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 879,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '08/27/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 880,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 881,
    case_id: 14202,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 882,
    case_id: 14302,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 883,
    case_id: 14315,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 884,
    case_id: 14369,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 885,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '08/27/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 886,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 887,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: '06/30/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 888,
    case_id: 14446,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 889,
    case_id: 14273,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 890,
    case_id: 14170,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/09/2024',
    axiom_date: '07/18/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 891,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: '08/06/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 892,
    case_id: 14215,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 893,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 894,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 895,
    case_id: 14226,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 896,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 897,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/06/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 898,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 899,
    case_id: 14199,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/16/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 900,
    case_id: 14233,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: '08/28/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 901,
    case_id: 14135,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/18/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 902,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 903,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: '08/10/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 904,
    case_id: 14494,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 905,
    case_id: 14205,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 906,
    case_id: 14489,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 907,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 908,
    case_id: 14261,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 909,
    case_id: 14258,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 910,
    case_id: 14200,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 911,
    case_id: 14198,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '06/24/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 912,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 913,
    case_id: 14447,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 914,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/31/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 915,
    case_id: 14315,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/08/2024',
    axiom_date: '07/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 916,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 917,
    case_id: 14234,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/09/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 918,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 919,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 920,
    case_id: 14261,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'qc',
    preamp_date: '06/19/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 1
  },
  {
    id: 921,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/18/2024',
    axiom_date: '08/20/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 922,
    case_id: 14389,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 923,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 924,
    case_id: 14439,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/27/2024',
    axiom_date: '08/31/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 925,
    case_id: 14455,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/03/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 926,
    case_id: 14468,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 927,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 928,
    case_id: 14439,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/13/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 929,
    case_id: 14145,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 930,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 931,
    case_id: 14124,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 932,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/13/2024',
    axiom_date: '08/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 933,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 934,
    case_id: 14126,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/29/2024',
    axiom_date: '07/14/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 935,
    case_id: 14213,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 936,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '07/21/2024',
    axiom_date: '08/06/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 937,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 938,
    case_id: 14474,
    clinic: 'Ovation',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 939,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 940,
    case_id: 14213,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 941,
    case_id: 14430,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/26/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 942,
    case_id: 14406,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 943,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 944,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 945,
    case_id: 14398,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/12/2024',
    axiom_date: '08/09/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 946,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 947,
    case_id: 14276,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 948,
    case_id: 14128,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/13/2024',
    axiom_date: '08/23/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 1
  },
  {
    id: 949,
    case_id: 14117,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 950,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 951,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 952,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 953,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 954,
    case_id: 14310,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/07/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 955,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: '06/05/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 956,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: '06/25/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 957,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/07/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 958,
    case_id: 14476,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 959,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 960,
    case_id: 14334,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 961,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/28/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 962,
    case_id: 14420,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/01/2024',
    axiom_date: '08/26/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 963,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/23/2024',
    axiom_date: '08/03/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 964,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/03/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 965,
    case_id: 14331,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '07/12/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 966,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 967,
    case_id: 14226,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '08/04/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 2
  },
  {
    id: 968,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 32
  },
  {
    id: 969,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 970,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 2
  },
  {
    id: 971,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/25/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 972,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 2
  },
  {
    id: 973,
    case_id: 14437,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/08/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 974,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'research',
    preamp_date: '06/05/2024',
    axiom_date: '06/25/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 975,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 976,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/25/2024',
    axiom_date: '08/07/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 977,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/24/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 978,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'research',
    preamp_date: '08/05/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 979,
    case_id: 14183,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '08/19/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 980,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/22/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 981,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'clinical',
    preamp_date: '08/17/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 982,
    case_id: 14356,
    clinic: 'Centro Procrea',
    sample_type: 'embryonic',
    accessioning_type: 'unprocessed',
    preamp_date: '07/23/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 983,
    case_id: 14125,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 1
  },
  {
    id: 984,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '08/13/2024',
    axiom_date: '08/28/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 985,
    case_id: 14393,
    clinic: 'Novagen',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
  {
    id: 986,
    case_id: 14247,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 96
  },
  {
    id: 987,
    case_id: 14491,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/11/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 988,
    case_id: 14168,
    clinic: 'Genomic Prediction',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '07/18/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 32
  },
  {
    id: 989,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'qc',
    preamp_date: '07/07/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 990,
    case_id: null,
    clinic: null,
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: '06/20/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 991,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'clinical',
    preamp_date: '06/10/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 96
  },
  {
    id: 992,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 993,
    case_id: 14473,
    clinic: 'Kindbody',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 1
  },
  {
    id: 994,
    case_id: null,
    clinic: null,
    sample_type: 'dna',
    accessioning_type: 'research',
    preamp_date: '06/10/2024',
    axiom_date: '07/23/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 32
  },
  {
    id: 995,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '06/29/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 96
  },
  {
    id: 996,
    case_id: 14424,
    clinic: 'Shady Grove',
    sample_type: 'embryonic',
    accessioning_type: 'research',
    preamp_date: '08/26/2024',
    axiom_date: '08/27/2024',
    accessioning_status: 'axiom-complete',
    sample_quantity: 96
  },
  {
    id: 997,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/02/2024',
    axiom_date: null,
    accessioning_status: 'preamp-complete',
    sample_quantity: 32
  },
  {
    id: 998,
    case_id: null,
    clinic: null,
    sample_type: 'saliva',
    accessioning_type: 'clinical',
    preamp_date: '07/06/2024',
    axiom_date: null,
    accessioning_status: 'axiom-queued',
    sample_quantity: 1
  },
  {
    id: 999,
    case_id: null,
    clinic: null,
    sample_type: 'other',
    accessioning_type: 'qc',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'accessioned',
    sample_quantity: 96
  },
  {
    id: 1000,
    case_id: 14275,
    clinic: 'Elite',
    sample_type: 'embryonic',
    accessioning_type: 'clinical',
    preamp_date: null,
    axiom_date: null,
    accessioning_status: 'preamp-queued',
    sample_quantity: 32
  },
];
