import React, {useState, useEffect} from 'react';
import {Card, CardHeader, CardBody, Col, Row} from "reactstrap";
import FormM, {BLANK_ENTRY as M_BLANK_ENTRY} from './FormM';
import FormSR, {BLANK_ENTRY as SR_BLANK_ENTRY} from './FormSR';
import axios from "axios";
import {getToken} from "auth/token";
import {Button, Toaster, Intent, Position, Toast} from "@blueprintjs/core";
import {IfNotAuthSuperReadonly} from "../../../RBAC";


export default (props) => {
  const {data} = props;

  // const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [mFdh, setMFdh] = useState('');
  const [srFdh, setSRFdh] = useState('');
  const [toasts, setToasts] = useState([]);

  const saveValues = (values) => {
    if (data) {
      setLoading(Object.keys(values)[0]);

      axios({
        method: 'PATCH',
        responseType: 'json',
        baseURL: process.env.REACT_APP_API_HOST,
        url: `patients/${data.id}/`,
        headers: {
          "Authorization": `Token ${getToken()}`
        },
        data: values
      })
        .then((resp) => {
          setToasts([
            {
              intent: Intent.SUCCESS, message: 'Saved'
            }
          ])
        })
        .catch((e) => {
          // setError('Error saving data')
          console.log('ERROR patching patient', e);
          alert('Changes were not saved - Please re-enter and try again');
        })
        .finally(() => {
          setLoading(null);
        });
    }
  };

  const {pgt_m, pgt_s} = data;

  useEffect(() => {
    if (!!mFdh && mFdh !== data.m_fdh && !loading && mFdh !== JSON.stringify(M_BLANK_ENTRY)) {
      saveValues({m_fdh: mFdh});
    }
  }, [mFdh]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!srFdh && srFdh !== data.sr_fdh && !loading && srFdh !== JSON.stringify(SR_BLANK_ENTRY)) {
      saveValues({sr_fdh: srFdh});
    }
  }, [srFdh]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {
        pgt_m && (
          <Card>
            <CardHeader>
              <Row className="justify-content-between align-items-center">
                <Col>
                  <h6 style={{margin: 0}}>
                    PGT-M Family Disease History
                  </h6>
                </Col>
                <Col md="auto">
                  <IfNotAuthSuperReadonly>
                    <Button
                      minimal
                      icon={'tick'}
                      intent={Intent.SUCCESS}
                      loading={loading === 'm_fdh'}
                      onClick={() => saveValues({m_fdh: mFdh})}
                    >
                      Save
                    </Button>
                  </IfNotAuthSuperReadonly>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormM data={data.m_fdh} onChange={(v) => setMFdh(v)}/>
            </CardBody>
          </Card>
        )
      }

      {
        pgt_s && (
          <Card>
            <CardHeader>
              <Row className="justify-content-between align-items-center">
                <Col>
                  <h6 style={{margin: 0}}>
                    PGT-SR Family Disease History
                  </h6>
                </Col>
                <Col md="auto">
                  <IfNotAuthSuperReadonly>
                    <Button
                      minimal
                      icon={'tick'}
                      intent={Intent.SUCCESS}
                      loading={loading === 'sr_fdh'}
                      onClick={() => saveValues({sr_fdh: srFdh})}
                    >
                      Save
                    </Button>
                  </IfNotAuthSuperReadonly>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormSR data={data.sr_fdh} onChange={(v) => setSRFdh(v)}/>
            </CardBody>
          </Card>
        )
      }

      <Toaster position={Position.TOP_RIGHT}>
        {
          toasts.map((toast, index) => {
            const {intent, message} = toast;
            return (
              <Toast
                message={message}
                intent={intent}
                timeout={1000}
                onDismiss={(e) => {
                  setToasts([]);
                }}
              />
            )
          })
        }
      </Toaster>
    </div>
  )
}
