import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import './style.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import pdf_thumb from './images/blank_pdf.svg';
import docx_thumb from './images/blank_docx.svg';
import image_thumb from './images/image.svg';
import {IfAuthSuper, IfNotAuthSuperReadonly} from "components/RBAC";
import axios from "axios";
import {getToken} from "../../auth/token";
import FinalReportSelect from "./finalReport";


class ImageSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: '',
      files: [],
      inputKey: 0
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value && this.props.value) {
      this.updateFiles(this.props.value);
    }
  }

  componentDidMount() {
    this.updateFiles(this.props.value);
  }

  updateFiles(value) {
    if (value) {
      this.setState({
        ...this.state,
        files: value
      });
    }
  }

  gotoSignedUrl(file_name) {
    const token = getToken();
    axios.post('/api/get-uploaded-files-presigned-url', {
      file_name
    }, {
      headers: {
        "Authorization": `Token ${token}`
      },
    }).then((res) => {
      window.open(res.data.presigned_url, '_blank')
    })
  }

  handleChange(e, url = null) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        this.setState(prevState => ({
          file: file,
          imagePreviewUrl: reader.result,
          inputKey: prevState.inputKey + 1
        }), () => {
          const file_name = (file.name).split('.').slice(0, -1).join('.');
          this.props.onFileUpdate(reader.result, file_name, url);
        });
      };

      reader.readAsDataURL(file);
    }
  }

  handleDelete(e, file) {
    e.preventDefault();
    const result = window.confirm("Are you sure?");
    if (result) {
      this.props.onFileDelete({
        file_name: file.file_name,
        url: file.url,
        ext: file.ext,
        datetime: file.datetime
      });
    }
  }

  render() {
    return (
      <div>
        {
          this.state.files.map((file, i) => {
              let url = file.url;
              let bg_url = null;

              let ext = file.ext;

              if (ext === 'pdf') {
                bg_url = pdf_thumb;
              } else if (ext === 'doc' || ext === 'docx') {
                bg_url = docx_thumb;
              } else {
                bg_url = image_thumb;
              }

              return (
                <Row
                  key={'file-item-' + url}
                  className="justify-content-between align-items-center file-row"
                  onClick={(e) => {
                    this.gotoSignedUrl(url)
                  }}
                >
                  <Col md="auto">
                    <img
                      src={bg_url}
                      alt=""
                      style={{width: 40}}
                    />
                  </Col>

                  <Col data-dd-action-name="file_link">
                    {file.file_name}
                  </Col>

                  <Col>
                    {file.datetime}
                  </Col>

                  <Col md="auto">
                    {
                      this.props.superOnly ? (
                        <IfAuthSuper>
                          <Button color="link" size="sm" onClick={(e) => {
                            e.stopPropagation();
                            this.handleDelete(e, file)
                          }}>
                      <span className="text-danger">
                        <FontAwesomeIcon icon="trash-alt"/>
                      </span>
                          </Button>
                        </IfAuthSuper>
                      ) : (
                        <IfNotAuthSuperReadonly>
                          <Button color="link" size="sm" onClick={(e) => {
                            e.stopPropagation();
                            this.handleDelete(e, file)
                          }}>
                        <span className="text-danger">
                          <FontAwesomeIcon icon="trash-alt"/>
                        </span>
                          </Button>
                        </IfNotAuthSuperReadonly>
                      )
                    }
                  </Col>
                </Row>
              )
            }
          )
        }
        {
          this.props.superOnly ? (
            <IfAuthSuper>
              <Row style={{marginTop: 40}}>
                <Col className="text-center">
                  <label className="file-controls">
                    <input
                      className="fileInput"
                      type="file"
                      key={this.state.inputKey}
                      onChange={(e) => this.handleChange(e)}
                      style={{display: 'none'}}
                    />
                    <span className="btn btn-primary btn-sm">
                        <FontAwesomeIcon icon="cloud-upload-alt"/> Upload File
                    </span>
                  </label>
                </Col>
              </Row>
            </IfAuthSuper>
          ) : (
            <Row style={{marginTop: 40}}>
              <Col className="text-center">
                <IfNotAuthSuperReadonly>
                  <label className="file-controls">
                    <input
                      className="fileInput"
                      type="file"
                      key={this.state.inputKey}
                      onChange={(e) => this.handleChange(e)}
                      style={{display: 'none'}}
                    />
                    <span className="btn btn-primary btn-sm">
                  <FontAwesomeIcon icon="cloud-upload-alt"/> Upload File
                </span>
                  </label>
                </IfNotAuthSuperReadonly>
              </Col>
            </Row>
          )
        }
      </div>
    )
  }
}

export {
  FinalReportSelect,
  ImageSelect
}
