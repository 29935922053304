import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {connect} from 'react-redux';
import {nurseModalClose} from 'actions/modal';
import {submitNurse} from "actions/nurse";
import NurseForm from "components/forms/NurseForm";


class NurseModal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(json) {
    this.props.submit(json);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.close}>
        <ModalHeader toggle={() => this.props.close()}>
          Nurse
        </ModalHeader>
        <ModalBody>
          <NurseForm
            onSubmit={this.onSubmit}
            onCancel={this.props.close}
            initialValues={this.props.nurse}
            index={this.props.nurseIndex}
            id={this.props.nurse ? this.props.nurse.id : null}
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    isOpen: state.modal.nurseModalIsOpen,
    nurse: state.modal.nurse,
    nurseIndex: state.modal.nurseIndex
  }),
  {
    close: nurseModalClose,
    submit: submitNurse
  }
)(NurseModal)