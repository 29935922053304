import validator from 'validator';


/**
 * The field is required
 * @param {string} value
 * @returns {string}
 */
export const required = value => value ? undefined : 'Required';


/**
 * Max length of string
 * @param {number} max
 * @returns {string}
 */
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined;

export const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined;

export const aol = value =>
  value && /.+@aol\.com/.test(value) ?
    'Really? You still use AOL for your email?' : undefined;

export const phone = value =>
    value && !validator.isMobilePhone(value) ? 'Invalid phone number' : undefined;
