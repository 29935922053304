import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import SalivaSampleForm from 'components/SalivaSample/Form';
import {submitSalivaSampleList} from "actions/sample";
import {getPatientList} from 'actions/patient';

export default connect(
  state => ({
    cases: state.patient.items
  }),
  {
    submitSalivaSampleList: submitSalivaSampleList,
    getPatientList: getPatientList
  }
)(
  class extends Component {
    constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(data){
      this.props.submitSalivaSampleList(data, this.props.defaultValues.id, this.props.index)
    }

    componentDidMount() {
      this.props.getPatientList();
    }

    render() {
      return (
        <Modal isOpen={this.props.isOpen} toggle={() => this.props.close()} modalClassName={'saliva-sample-modal'}>
          <ModalHeader toggle={() => this.props.close()}>
            Saliva Sample
          </ModalHeader>
          <ModalBody>
            {
              this.props.cases ? (
                <SalivaSampleForm
                  onSubmit={this.onSubmit}
                  initialValues={this.props.defaultValues}
                  cases={this.props.cases}
                />
              ) : <p>Loading...</p>
            }
          </ModalBody>
        </Modal>
      )
    }
  }
)
