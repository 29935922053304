import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import InputText from 'utils/form/InputText';
import {Button, Col, Row} from "reactstrap";
import {email, phone, required} from 'utils/validators';
import {withRouter} from 'react-router-dom';
import queryString from "query-string";


class NurseForm extends PureComponent {
  constructor(props) {
    super(props);
    this._qs = queryString.parse(props.location.search);
  }

  componentDidMount() {
    this.props.change('provider', this._qs.provider || null)
  }

  getSelectedProviderName(){
    let result = '';

    if (this.props.initialValues && this.props.initialValues.provider_name){
      return this.props.initialValues.provider_name
    }

    if (this._qs.provider && this.props.providers){
      Object.keys(this.props.providers).forEach((key) => {
        if (this.props.providers.hasOwnProperty(key) && this.props.providers[key].id === parseInt(this._qs.provider)){
          result = this.props.providers[key].company_name
        }
      });
    }
    return result;
  }

  render() {
    const {handleSubmit, pristine, submitting, invalid} = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field
            label={<span>First Name <span className="text-danger">*</span></span>}
            name="first_name"
            component={InputText}
            type="text"
            validate={[required]}
          />

          <Field
            label={<span>Last Name <span className="text-danger">*</span></span>}
            name="last_name"
            component={InputText}
            type="text"
            validate={[required]}
          />

          <Field
            label="Email"
            name="email"
            component={InputText}
            type="text"
            validate={[email]}
          />

          <Field
            label="Phone"
            name="phone"
            component={InputText}
            type="text"
            validate={[phone]}
          />

          <Row style={{ marginTop: 30 }} className="justify-content-start">
            <Col md="auto">
              <Button
                  color="success"
                  type="submit"
                  disabled={submitting || pristine || invalid}
              >
                Submit
              </Button>
            </Col>
            <Col md="auto">
              <Button color="light" onClick={() => this.props.onCancel()}>Cancel</Button>
            </Col>
          </Row>

        </form>
      </div>
    )
  }
}

NurseForm = reduxForm({form: 'nurse'})(NurseForm);

export default withRouter(connect(
  state => ({
    providers: state.provider.items
  })
)(NurseForm));
