import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Input} from "reactstrap";
import {getProviderList} from "actions/provider";

export default connect(
  state => ({
    items: state.provider.items
  }),
  {
    getProviderList: getProviderList
  }
)(
  class extends PureComponent {
    componentDidMount() {
      // if (!this.props.providers){
      //   this.props.getProviderList(true)
      // }
    }

    render() {
      const {items, selected, onChange, disabled, providers} = this.props;
      let list = providers ? providers : items;

      return (
        <Input
          type="select"
          onChange={(e) => onChange(parseInt(e.target.value))}
          value={selected && parseInt(selected)}
          disabled={disabled}
        >
          <option value={''}>All Providers</option>
          {
            list && list.map((item) =>
              <option
                key={'select-provider-' + item.id}
                value={item.id}
              >
                {item.company_name}
              </option>
            )
          }
        </Input>
      )
    }
  }
);
