import React from 'react';
import CountriesJSON from "./country_state_code";


export default (props) => {
  const value = props.value;
  let address = null;

  if (typeof value === 'string') {
    try {
      address = JSON.parse(value);
    } catch (e) {
      // TODO Do something with bad format of address here
      console.log(e)
    }
  }

  if (typeof value === 'object') {
    address = value
  }

  return address ? (
    <div>
      {address.address_1 && <span>{address.address_1}{address.address_2 && ', ' + address.address_2}{' '}</span>}
      {address.city && address.city + ', '}{address.state && address.state + ','}{' '}
      {address.country !== 'US' && CountriesJSON[address.country] && CountriesJSON[address.country].name + ', '}{address.zip}
    </div>
  ) : ''
}
