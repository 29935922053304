import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import './style/panel_view.css';
import {Checkbox, Position, Toast, Toaster} from "@blueprintjs/core";
import axios from "axios";
import {getToken} from "auth/token";


const NAMES = {
  'diabetes.type1': 'Type 1 Diabetes',
  'diabetes.type2': 'Type 2 Diabetes',
  'testicular': 'Testicular Cancer',
  'prostate': 'Prostate Cancer',
  'breast': 'Breast Cancer',
  'basal.cell.carcinoma': 'Basal Cell Carcinoma',
  'malignant.melanoma': 'Malignant Melanoma',
  'heart.attack': 'Heart Attack',
  'atrial.fibrillation': 'Atrial Fibrillation',
  'coronaryArteryDisease': 'Coronary Artery Disease',
  'inflammatory.bowel.disease': 'Inflammatory Bowel Disease',
  'asthma': 'Asthma',
  'schizophrenia': 'Schizophrenia'
};

const ordering = {};

Object.keys(NAMES).forEach((condition_key, index) => {
  ordering[condition_key] = index;
});


export default (props) => {
  const {data, sample} = props;

  const [panel, setPanel] = useState([]);
  // const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [toasts, setToasts] = useState([]);

  const submitChanges = (id, value) => {
    // setLoading(true);

    axios({
      method: 'PATCH',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `samples/${id}/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      },
      data: {
        panel: JSON.stringify(value)
      }
    })
      .then((resp) => {
        // all good. do nothing
        //onUpdateSuccess();
        setToasts([...toasts, {id: 'loading-' + Date.now(), intent: 'success', message: 'Saved'}])
      })
      .catch((e) => {
        // setError('Error saving data')
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      data.sort((a, b) => {
        return (ordering[a.name] - ordering[b.name]);
      });
      const items = data.slice();
      setPanel(items);
    }
  }, [data]);

  return (
    <div>
      <Toaster position={Position.TOP_RIGHT}>
        {
          toasts.map((toast, index) => {
            const {intent, message} = toast;
            return (
              <Toast
                key={'toast-' + index}
                message={message}
                intent={intent}
                timeout={1000}
                onDismiss={(e) => {
                  setToasts([]);
                }}
              />
            )
          })
        }
      </Toaster>

      {
        panel.length > 0 ? (
          <div>
            <table className="panel-table">
              <tr>
                <td>Condition</td>
                <td> </td>
                <td>Risk</td>
                <td>Avg</td>
                <td>Ratio</td>
                <td>PCTL</td>
                <td>Footnote</td>
                <td>High Risk</td>
              </tr>
              {
                panel.map((condition, index) => {
                  return (
                    <tr
                      key={`sample${sample.id}condition${index}`}
                      className={classNames('panel-table-row', {
                      'risk-high': condition.risk === 'high'
                    })}
                    >
                      <td>{NAMES[condition.name] || condition.name}</td>
                      <td>{condition.risk}</td>
                      <td>{((Number(condition.risk_value) || 0) * 100).toFixed(2)}%</td>
                      <td>{((Number(condition.risk_avg) || 0) * 100).toFixed(2)}%</td>
                      <td>{(Number(condition.risk_ratio) || 0).toFixed(2)}x</td>
                      <td>{condition.risk_percentile}%</td>
                      <td>
                        {
                          (condition.risk === 'high' || !!condition.high_risk_force) && (
                            <Checkbox
                              checked={!!condition.show_footnote}
                              label=""
                              onChange={(e) => {
                                const new_panel = panel.slice();
                                new_panel[index] = {
                                  ...condition,
                                  show_footnote: e.target.checked
                                };
                                submitChanges(sample.id, new_panel);
                                setPanel(new_panel);
                              }}
                            />
                          )
                        }
                      </td>
                      <td>
                        <Checkbox
                          checked={!!condition.high_risk_force}
                          label=""
                          onChange={(e) => {
                            const new_panel = panel.slice();
                            new_panel[index] = {
                              ...condition,
                              high_risk_force: e.target.checked
                            };
                            submitChanges(sample.id, new_panel);
                            setPanel(new_panel);
                          }}
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </table>

          </div>
        ) : (
          <div>
            Panel Empty
          </div>
        )
      }
    </div>
  )
}
