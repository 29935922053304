import React, {PureComponent} from 'react';
import {Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";


class InputSelect extends PureComponent {
  static defaultProps = {
    proportion: [4, 8]
  };

  render() {
    const {input, label, meta: {touched, error}, proportion, children} = this.props;

    return (
      <FormGroup row className="align-items-center">
        {label ? (<Label md={proportion[0]} htmlFor={input.name} className={touched && error ? 'text-danger' : ''}>{label}</Label>) : ''}

        <Col md={label ? proportion[1] : 12}>
          <Input
            {...input}
            type="select"
            value={input.value}
            invalid={touched && error}
          >
            {children}
          </Input>
          {touched && error && <FormFeedback>{error}</FormFeedback>}
        </Col>
      </FormGroup>
    )
  }
}

export default InputSelect;