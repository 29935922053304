import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';

export const CLINICIAN_LIST_REQUEST = '@@clinician/CLINICIAN_LIST_REQUEST';
export const CLINICIAN_LIST_SUCCESS = '@@clinician/CLINICIAN_LIST_SUCCESS';
export const CLINICIAN_LIST_FAILURE = '@@clinician/CLINICIAN_LIST_FAILURE';

export const CLINICIAN_DETAIL_REQUEST = '@@clinician/CLINICIAN_DETAIL_REQUEST';
export const CLINICIAN_DETAIL_SUCCESS = '@@clinician/CLINICIAN_DETAIL_SUCCESS';
export const CLINICIAN_DETAIL_FAILURE = '@@clinician/CLINICIAN_DETAIL_FAILURE';

export const CLINICIAN_CREATE_REQUEST = '@@clinician/CLINICIAN_CREATE_REQUEST';
export const CLINICIAN_CREATE_SUCCESS = '@@clinician/CLINICIAN_CREATE_SUCCESS';
export const CLINICIAN_CREATE_FAILURE = '@@clinician/CLINICIAN_CREATE_FAILURE';

export const CLINICIAN_UPDATE_REQUEST = '@@clinician/CLINICIAN_UPDATE_REQUEST';
export const CLINICIAN_UPDATE_SUCCESS = '@@clinician/CLINICIAN_UPDATE_SUCCESS';
export const CLINICIAN_UPDATE_FAILURE = '@@clinician/CLINICIAN_UPDATE_FAILURE';

export const CLINICIAN_DELETE_REQUEST = '@@clinician/CLINICIAN_DELETE_REQUEST';
export const CLINICIAN_DELETE_SUCCESS = '@@clinician/CLINICIAN_DELETE_SUCCESS';
export const CLINICIAN_DELETE_FAILURE = '@@clinician/CLINICIAN_DELETE_FAILURE';

export const getClinicianList = (filter = {}) => {
  return ({
    [RSAA]: {
      endpoint: 'clinicians/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        CLINICIAN_LIST_REQUEST, CLINICIAN_LIST_SUCCESS, CLINICIAN_LIST_FAILURE
      ]
    }
  })
};

export const submitClinician = (data) => {
  const url =  `clinicians/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  return({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types:
        data.id ? [
          CLINICIAN_UPDATE_REQUEST,
          CLINICIAN_UPDATE_SUCCESS,
          CLINICIAN_UPDATE_FAILURE
        ] :
        [
          CLINICIAN_CREATE_REQUEST,
          CLINICIAN_CREATE_SUCCESS,
          CLINICIAN_CREATE_FAILURE
        ],
      body: JSON.stringify(data)
    }
  });
};

export const deleteClinician = (id, index) => {
  return ({
    [RSAA]: {
      endpoint: 'clinicians/' + id + '/',
      method: 'DELETE',
      headers: withAuth({}),
      types: [
        CLINICIAN_DELETE_REQUEST,
        {
          type: CLINICIAN_DELETE_SUCCESS,
          payload: {},
          meta: {
            id: id,
            index: index
          }
        },
        CLINICIAN_DELETE_FAILURE
      ]
    }
  })
};