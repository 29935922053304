import React, {PureComponent} from 'react';
import card_unknown from 'images/card/unknown.svg';
import card_visa from 'images/card/visa.svg';
import card_mastercard from 'images/card/mastercard.svg';
import card_discover from 'images/card/discover.svg';
import card_amex from 'images/card/amex.svg';
import card_diners from 'images/card/diners.svg';
import card_jcb from 'images/card/jcb.svg';
import card_unionpay from 'images/card/union.svg';


class CardBrand extends PureComponent{
  constructor(props){
    super(props);

    this.cardBrandImages = {
      card_unknown, card_visa, card_mastercard,
      card_discover, card_amex, card_diners, card_jcb, card_unionpay
    };
  }
  render(){
    return(
      <img
        src={this.cardBrandImages[`card_${this.props.brand}`]}
        alt=""
        className={this.props.className}
        style={{
          height: 30
        }}
      />
    )
  }
}

export default CardBrand;