import React, {useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import './SendEmailForm.css'


export default props => {
    const { emailsRecipients, onSubmit, onCancel, submitError, setSubmitError } = props;
    const options = [{label: "Select All", value: "*"}, ...emailsRecipients];

    const [modalState, setModalState] = useState({selectedOptions: [], errorText: ''});

    let { selectedOptions, errorText }  = modalState;

    const onChange = (values, event) => {
        if (submitError) {
            setSubmitError('')
        }
        if (event.action === "select-option" && event.option.value === "*") {
            setModalState({selectedOptions: options, errorText: ''})
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setModalState({selectedOptions: [], errorText: 'Select at least 1 recipient'})
        } else if (event.action === "deselect-option") {
            setModalState({
                selectedOptions: values.filter(option => option.value !== "*"),
                errorText: !values.length ? 'Select at least 1 recipient' : ''
            })
        } else if (values.length === options.length - 1) {
            setModalState({selectedOptions: options, errorText: ''})
        } else {
            setModalState({selectedOptions: values, errorText: ''})
        }
    }

    if (submitError) {
        errorText = submitError
    }

    const onModalSubmit = () => {
        const data = selectedOptions.filter(option => option.value !== "*")
        if (!data.length) {
            setModalState({selectedOptions: [], errorText: 'Select at least 1 recipient'})
        } else {
            onSubmit(data.map(option => {
                let { email, first_name, last_name } = option.value;
                first_name = `${first_name[0].toUpperCase()}${first_name.slice(1)}`;
                last_name = `${last_name[0].toUpperCase()}${last_name.slice(1)}`;
                return {email,  first_name, last_name}
            }
            ))
        }
    }

    return (
        <React.Fragment>
            <div className="row form-group d-flex justify-content-around recipients-form">
                <div className="col-sm-4 col-form-label d-flex align-items-end">
                    Email Recipients:
                    <span className="text-danger">*</span>
                </div>
                <div className="multi-select">
                    <ReactMultiSelectCheckboxes
                        className="some"
                        options={options}
                        value={selectedOptions}
                        onChange={onChange}
                        setState={setModalState}
                    />
                </div>
            </div>
            <div className={`d-flex flex-column justify-content-end mt-5 height-options-${options.length}`}>
                <Row className="justify-content-center text-danger">
                    <p className="error-text">{errorText}</p>
                </Row>
                <Row className="d-flex justify-content-start align-items-end">
                    <Col md="auto">
                        <Button color="success" onClick={onModalSubmit}>Send</Button>
                    </Col>
                    <Col md="auto">
                        <Button color="light" onClick={onCancel}>Cancel</Button>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}
