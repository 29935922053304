import React from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment-timezone";


export default ({data, ...props}) => {
  return data ? (
    <div>
      <Card>
        <CardHeader>
          <h6 style={{margin: 0}}>Family Linked Cases</h6>
        </CardHeader>
        <CardBody>
          {
            (data.linked_cases && data.linked_cases.length) > 0 ? (
              <div>
                <table style={{width: '100%'}} cellSpacing={0} cellPadding={0}>
                  <thead>
                    <tr>
                      <th style={{padding: 0}}>Label</th>
                      <th style={{padding: 0}}>Samples</th>
                      <th style={{padding: 0}}>Created</th>
                      <th style={{padding: 0}}>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    data.linked_cases.map((item, i) => {
                      const linkUrl = `/patients/${item.id}/?provider=${item.provider_id}`;
                      const linkStyle = {display: 'block', padding: '4px 0'};

                      return(
                        <tr key={'linked-case' + i}>
                          <td style={{padding: 0}}>
                            <Link to={linkUrl} style={{...linkStyle, paddingLeft: 0}}>
                              <strong>{item.case_id}</strong>
                            </Link>
                          </td>
                          <td style={{padding: 0}}>
                            <Link to={linkUrl} style={linkStyle}>
                              {item.sample_count})
                            </Link>
                          </td>
                          <td style={{padding: 0}}>
                            <Link to={linkUrl} style={linkStyle}>
                              {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MM/DD/YY')}
                            </Link>
                          </td>
                          <td style={{padding: 0}}>
                            <Link to={linkUrl} style={linkStyle}>

                            </Link>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>

              </div>
            ) : 'No linked cases'
          }
        </CardBody>
      </Card>
    </div>
  ) : ''
}
