import * as auth from 'actions/auth';


const initialState = {
  token: null,
  email: null,
  errors: null,
  first_name: null,
  last_name: null,
  company_name: null,
  company_address: null,
  created_at: null,
  last_login: null,
  id: null,
  role: null,
  permissions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case auth.LOGIN_SUCCESS:
      return {
        ...action.payload,
        errors: {}
      };

    case auth.LOGIN_FAILURE:
      return {
        token: null,
        email: null,
        errors: action.payload.response.detail
      };

    case auth.LOGOUT:
      window.localStorage.removeItem('clinic_list');
      window.localStorage.removeItem('clinic_list_expire');
      return initialState;

    default:
      return state
  }
}

export function isAuthenticated(state) {
  return state.auth && state.auth.token;
}

export function isAdmin(state) {
  return state.auth && (state.auth.role === 'super' || state.auth.role === 'super-readonly');
}
