import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faStroopwafel, faStickyNote, faClock, faCreditCard, faExclamationTriangle, faTruck,
  faTruckMoving, faTimes, faTrashAlt, faPlus, faUserMd, faUsers, faMicroscope, faSearch,
  faSignOutAlt, faBoxOpen, faCheck, faHourglassHalf, faShippingFast, faFileInvoice,
  faFileSignature, faEdit, faCog, faAt, faEnvelope, faMapMarkerAlt, faPhone, faBox, faBoxes, faVial,
  faProjectDiagram, faBuilding, faArrowRight, faAngleDown, faFileUpload, faFileDownload, faCloudUploadAlt,
  faSync, faStopwatch, faPencilAlt, faList, faBug, faBars, faBan, faBookOpen, faExternalLinkAlt,
  faIdCard, faUser, faFile, faEllipsisV
} from '@fortawesome/free-solid-svg-icons';

library.add(faIdCard);
library.add(faUser);
library.add(faStroopwafel);
library.add(faStickyNote);
library.add(faClock);
library.add(faCreditCard);
library.add(faExclamationTriangle);
library.add(faTruck);
library.add(faTruckMoving);
library.add(faTimes);
library.add(faTrashAlt);
library.add(faPlus);
library.add(faUserMd);
library.add(faUsers);
library.add(faMicroscope);
library.add(faSearch);
library.add(faSignOutAlt);
library.add(faBoxOpen);
library.add(faCheck);
library.add(faHourglassHalf);
library.add(faShippingFast);
library.add(faFileInvoice);
library.add(faFileSignature);
library.add(faEdit);
library.add(faCog);
library.add(faAt);
library.add(faEllipsisV);
library.add(faEnvelope);
library.add(faMapMarkerAlt);
library.add(faPhone);
library.add(faBox);
library.add(faBoxes);
library.add(faVial);
library.add(faProjectDiagram);
library.add(faBuilding);
library.add(faArrowRight);
library.add(faAngleDown);
library.add(faFileUpload);
library.add(faFileDownload);
library.add(faCloudUploadAlt);
library.add(faSync);
library.add(faStopwatch);
library.add(faPencilAlt);
library.add(faList);
library.add(faBug);
library.add(faBars);
library.add(faBan);
library.add(faBookOpen);
library.add(faExternalLinkAlt);
library.add(faFile);
