import React from 'react';
import {ErrorMessage, Field, Form, Formik, getIn} from "formik";
import {Button, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from "classnames";
import * as Yup from "yup";
import * as CountriesJSON from "components/Address/country_state_code";

const Schema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  company_name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  tel: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required')
    .email(),
  company_address: Yup.object().shape({
    street: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Required'),
    unit: Yup.string()
      .max(120, 'Too Long!'),
    country: Yup.string()
      .required('Required'),
    state: Yup.string()
      .when('country', {
        is: (val) => val && CountriesJSON.default[val]['states'].length > 0,
        then: Yup.string().required('Required')
      }),
    city: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Required'),
    postal: Yup.string()
      .required('Required'),
  })
});


export default (props) => {
  const Countries = CountriesJSON.default;

  return (
    <div style={{marginBottom: 120}}>
      <Formik
        initialValues={
          {
            first_name: '',
            last_name: '',
            tel: '',
            email: '',
            company_name: '',

            company_address: {
              street: '',
              unit: '',
              postal: '',
              country: '',
              state: '',
              city: '',
            },

            ...props.initialValues
          }
        }
        validationSchema={Schema}
        onSubmit={
          (values, {setSubmitting}) => {
            props.onSubmit(values);
            setSubmitting(false);
          }
        }
      >
        {
          props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
            } = props;

            return (
              <Form>
                <Row style={{marginTop: 40}} className="justify-content-center">
                  <Col md={5}>
                    <FormGroup row>
                      <Label for="first_name" sm={4} className="text-right">First Name</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="first_name"
                          className={classNames('form-control', {'is-invalid': touched.first_name && !!errors.first_name})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="first_name" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="last_name" sm={4} className="text-right">Last Name</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="last_name"
                          className={classNames('form-control', {'is-invalid': touched.last_name && !!errors.last_name})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="last_name" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="tel" sm={4} className="text-right">Phone Number</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="tel"
                          className={classNames('form-control', {'is-invalid': touched.tel && !!errors.tel})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="tel" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="email" sm={4} className="text-right">Email</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="email"
                          className={classNames('form-control', {'is-invalid': touched.email && !!errors.email})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="email" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="company_name" sm={4} className="text-right">Company Name</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="company_name"
                          className={classNames('form-control', {'is-invalid': touched.company_name && !!errors.company_name})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="company_name" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col md={5}>
                    <FormGroup row>
                      <Label for="company_address.street" sm={4} className="text-right">Address Line 1</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="company_address.street"
                          className={classNames('form-control', {
                            'is-invalid':
                              getIn(errors, 'company_address.street') &&
                              getIn(touched, 'company_address.street')
                          })}
                        />
                        <p>{getIn(touched, 'first_name')}</p>
                        <FormFeedback>
                          <ErrorMessage name="company_address.street" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="company_address.unit" sm={4} className="text-right">Address Line 2</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="company_address.unit"
                          className={classNames('form-control', {'is-invalid': touched.company_address && touched.company_address.unit && errors.company_address && !!errors.company_address.unit})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="company_address.unit" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="company_address.postal" sm={4} className="text-right">Zip Code</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="company_address.postal"
                          className={classNames('form-control', {'is-invalid': touched.company_address && touched.company_address.postal && errors.company_address && !!errors.company_address.postal})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="company_address.postal" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="company_address.country" sm={4} className="text-right">Country/Region</Label>
                      <Col sm={8}>
                        <Field
                          component="select"
                          name="company_address.country"
                          className={classNames('form-control', {'is-invalid': touched.company_address && touched.company_address.country && errors.company_address && !!errors.company_address.country})}
                        >
                          <option value={''} key={'country-none'} disabled>Select Country</option>
                          {
                            Object.keys(Countries).map((code, i) => {
                              return <option value={code} key={'country-' + i}>{Countries[code].name}</option>
                            })
                          }
                        </Field>
                        <FormFeedback>
                          <ErrorMessage name="company_address.country" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    {
                      values.company_address.country && Countries[values.company_address.country] && Countries[values.company_address.country]['states'].length ? (
                        <FormGroup row>
                          <Label for="state" sm={4} className="text-right">State/Province/Region</Label>
                          <Col sm={8}>
                            <Field
                              component="select"
                              name="company_address.state"
                              className={
                                classNames('form-control', {
                                  'is-invalid': values.company_address.country && touched.company_address && touched.company_address.state && errors.company_address && errors.company_address.state,
                                })
                              }
                              disabled={!values.company_address.country}
                            >
                              <option value={''} key={'state-none'} disabled>Select...</option>
                              {
                                Countries[values.company_address.country]['states'].map((item, i) => {
                                  return <option key={'state-' + i}>{item}</option>
                                })
                              }
                            </Field>
                            <FormFeedback>
                              <ErrorMessage name="company_address.state" component="span"/>
                            </FormFeedback>
                          </Col>
                        </FormGroup>
                      ) : ''
                    }

                    <FormGroup row>
                      <Label for="company_address.city" sm={4} className="text-right">City</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="company_address.city"
                          className={classNames('form-control', {'is-invalid': touched.company_address && touched.company_address.city && errors.company_address && !!errors.company_address.city})}
                        />
                        <FormFeedback>
                          <ErrorMessage name="company_address.city" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{marginTop: 40}}>
                  <Col className="text-center">
                    <p style={{marginBottom: 20}}>
                      By clicking on "Submit Request" button below, you agree to the{' '}
                      <a href="https://genomicprediction.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Use</a>.
                    </p>
                    <FormGroup>
                      <Button
                        type="submit"
                        disabled={isSubmitting || !!errors.length}
                        color="success"
                        outline
                      >
                        Submit Request
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )
          }
        }
      </Formik>
    </div>
  )
}