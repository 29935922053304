import React from 'react';
import { Checkbox, FormGroup, InputGroup, HTMLSelect } from "@blueprintjs/core";
import './style/form.css';


export default (props) => {
  const {data, onChange} = props;
  const {name, chromosome, position, linkage, taqman} = data;

  return (
    <div>
      <FormGroup
        label="Variant"
        labelFor="name"
        inline
      >
        <InputGroup
          id="name"
          placeholder="Variant"
          value={name}
          onChange={(e) => onChange({name: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Chromosome"
        labelFor="chromosome"
        inline
      >
         <HTMLSelect
          id="chromosome"
          value={chromosome}
          iconProps={{icon:"caret-down"}}
          onChange={(e) => onChange({ chromosome: e.target.value })}
        >
          {
            Array(22).fill(1).map((x, y) => x + y).concat(['X', 'Y']).map((item, i) => {
              return (
                <option value={item} key={i}>{item}</option>
              )
            })
          }
        </HTMLSelect>
      </FormGroup>

      <FormGroup
        label="Position"
        labelFor="position"
        inline
      >
        <InputGroup
          id="position"
          value={position}
          onChange={(e) => onChange({position: e.target.value})}
        />
      </FormGroup>

      <FormGroup
        label="Linkage"
        labelFor="linkage"
        inline
      >
        <Checkbox
          checked={!!linkage}
          onChange={(e) => onChange({linkage: e.target.checked})}
        />
      </FormGroup>

      <FormGroup
        label="Taqman"
        labelFor="taqman"
        inline
      >
        <Checkbox
          checked={!!taqman}
          onChange={(e) => onChange({taqman: e.target.checked})}
        />
      </FormGroup>

    </div>
  )
}
