import React from 'react';
import {withRouter} from "react-router";
import URI from "urijs";
import {Link} from "react-router-dom";
import classNames from 'classnames';
import './style/main.css';


export default withRouter((props) => {
    const {start, end, onChange} = props;
    const items = Array(end - start + 1).fill(null).map((x, i) => i + 1);
    const search = props.location.search;

    const url = new URI(search).search(true);
    const current = url.p ? parseInt(url.p) : 1;

    // console.log(current)

    return (
      <div style={{margin: '12px 0'}} className={classNames('paginator', {'skeleton': props.loading})}>
        <div style={{minWidth: 20}}>
          {
            items.map((item) => (
              <Link
                to={{pathname: props.location.pathname, search: new URI(search).search({...url, p: item}).search()}}
                key={`paginator-item-${item}`}
                onClick={() => onChange(item)}
                className={classNames('paginator-btn', {
                  'active': item === current,
                  'skeleton': props.loading
                })}
              >
                {item}
              </Link>
            ))
          }
          {/*<span className={classNames('paginator-total', {'skeleton': props.loading})}>*/}
            {/*Total items: {props.total}*/}
          {/*</span>*/}
        </div>
      </div>
    )
  }
)
