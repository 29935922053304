import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from "classnames";
import ResultsSection from './ResultsSection';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from "axios";


export default (props) => {
  const [data, setData] = useState(props.data);
  const [invalid, setInvalid] = useState(null);
  const [warn, setWarn] = useState(false);

  /** Report disclaimer field no longer used.  Dash now handles this */

  let url_pdf_v2 = `${process.env.REACT_APP_DASH_HOST}report/${data.id}`;

  function pgtpValid() {
    let pgtpErrors = '';
    if (!data.patient.ethnicity || !data.patient.ethnicity.length) {
      setInvalid('Please enter patient ethnicity.');
      return false;
    }
    if (!data.partner.ethnicity || !data.partner.ethnicity.length) {
      setInvalid('Please enter partner ethnicity.');
      return false;
    }
    if (pgtpErrors.length) {
      setInvalid(pgtpErrors);
      return false
    }
    return true
  }

  function isExclusivelyPgtAAPlus(data) {
    return ((data.pgt_a || data.pgt_a_plus) && !data.pgt_m && !data.pgt_p && !data.pgt_s && !data.m2);
  }

  function validateReportData() {
    if (!data.pgt_p || (data.pgt_p && pgtpValid())) {
      const modifiedData = {
        ...data,
        samples: isExclusivelyPgtAAPlus(data)  // report Whole Embryo (arrested) samples when only test-type is A/A+
          ? data.samples.filter(sample => ['trophectoderm', 'arrested'].includes(sample.tissue_type))
          : data.samples.filter(sample => ['trophectoderm'].includes(sample.tissue_type))
      };
      if (modifiedData.m2) {
        delete(modifiedData.partner);
        if (!modifiedData.blinded) {
          delete(modifiedData.client_reference)
        }
        if (modifiedData.blinded) {
          delete(modifiedData.patient)
        }
      }
      if (!modifiedData.m2) {
        delete(modifiedData.test_performed_by);
        delete(modifiedData.client_reference);
        delete(modifiedData.saliva_samples);
      }
      const resp = axios({
      method: 'POST',
      contentType: 'application/json',
      baseURL: process.env.REACT_APP_API_HOST,
      data: {case: modifiedData},
      url: `validate-report-data/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      }
    }).then(res=>{
      if (res.status === 200) {
        openUrlPdfV2()
      }
    })
    .catch(e => {
      if (e.response.status === 400) {
        setInvalid(e.response.data.body.detail)
      }
    })
    return resp
    }
  }

  function getToken() {
    const storage = window.localStorage.getItem('persist:clinic-portal');
    const auth = JSON.parse(JSON.parse(storage).auth);
    return auth.token;
  }

  function openUrlPdfV2() {
    if (data.case_status === 'report_ready' || data.m2) {
        axios.post(process.env.REACT_APP_API_HOST + 'one-time-token/', {}, {
          headers: {
            "Authorization": `Token ${getToken()}`
          }
        }).then(response => {
          window.open(`${url_pdf_v2}?t=${response.data.token}`);
        }).catch(e => console.error(e));
      } else {
        setWarn(true)
      }
    }

  return (
    <div>
      {
         data.case_status !== 'report_hold' && <Card>
                                                    <CardBody>
                                                      <div className="generateOrBlock">
                                                       <Button
                                                          onClick={validateReportData}
                                                           target="_blank"
                                                           color="info"
                                                           size="sm"
                                                        >
                                                           Generate
                                                       </Button>
                                                       {
                                                        warn && (
                                                            <p className="blockReport">Cannot generate report for case status '{data.case_status}'.</p>
                                                          )
                                                        }
                                                        {
                                                          invalid && (
                                                            <p className="blockReport">{`Error validating report data: ${invalid}`}</p>
                                                          )
                                                        }
                                                      </div>
                                                    </CardBody>
                                               </Card>
      }

      <ResultsSection
        data={data}
        onSave={(values) => {
          props.onSubmit({...data, ...values});
          setData({...data, ...values});
        }}/>

      <Formik
        initialValues={{
          report_info:        data.report_info,
          report_disclaimer:  data.report_disclaimer,
          pgt_m_target:       data.pgt_m_target,
          pgt_m_target_code:  data.pgt_m_target_code,
          hide_sex_in_table:  data.hide_sex_in_table,
          show_failed:        data.show_failed,
          test_performed_by:  data.test_performed_by,
          client_reference:   data.client_reference,
          show_risk:          data.show_risk,
          show_index:         data.show_index,
        }}

        onSubmit={
          (values, {setSubmitting}) => {
            // values.report_disclaimer = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            props.onSubmit({id: data.id, ...values});
            setData({...data, ...values});
            setSubmitting(false);
          }
        }
      >
        {
          ({errors, touched, values, isSubmitting}) => {
            return (
              <Form>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h6 style={{margin: 0}}>
                          Test
                        </h6>
                      </Col>
                      <Col md="auto">
                        <Button color="success" size="sm" type="submit">Save</Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="test_performed_by">Test Performed By</Label>
                      <Field
                        type="text"
                        name="test_performed_by"
                        className={classNames('form-control', {
                          'is-invalid': touched.test_performed_by && !!errors.test_performed_by
                        })}
                      />
                      <FormFeedback>
                        <ErrorMessage name="test_performed_by" component="span"/>
                      </FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_reference">Client Reference</Label>
                      <Field
                        type="text"
                        name="client_reference"
                        className={classNames('form-control', {
                          'is-invalid': touched.client_reference && !!errors.client_reference
                        })}
                      />
                      <FormFeedback>
                        <ErrorMessage name="client_reference" component="span"/>
                      </FormFeedback>
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <h6 style={{margin: 0}}>
                          Report Language
                        </h6>
                      </Col>
                      <Col md="auto">
                        <Button color="success" size="sm" type="submit">Save</Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {
                      !data.m2 && (
                        <div>
                          <FormGroup>
                            <Label for="pgt_m_target_code">PGT-M Target Code</Label>
                            <Field
                              type="text"
                              name="pgt_m_target_code"
                              className={classNames('form-control', {
                                'is-invalid': touched.pgt_m_target_code && !!errors.pgt_m_target_code
                              })}
                            />
                            <FormFeedback>
                              <ErrorMessage name="pgt_m_target_code" component="span"/>
                            </FormFeedback>
                          </FormGroup>

                          <FormGroup row>
                            <Label md="12" for="hide_sex_in_table">Hide Sex In Tables</Label>
                            <Col md="3">
                              <Field
                                component="select"
                                name="hide_sex_in_table"
                                className={'form-control'}
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Field>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label md="12" for="show_failed">Show Circos Plots for Failed</Label>
                            <Col md="3">
                              <Field
                                component="select"
                                name="show_failed"
                                className={'form-control'}
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Field>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label md="12" for="show_risk">Show Risk</Label>
                            <Col md="3">
                              <Field
                                component="select"
                                name="show_risk"
                                className={'form-control'}
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Field>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label md="12" for="show_index">Show Index</Label>
                            <Col md="3">
                              <Field
                                component="select"
                                name="show_index"
                                className={'form-control'}
                              >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </Field>
                            </Col>
                          </FormGroup>

                          <FormGroup>
                            <Label for="report_info">Info Section</Label>
                            <Field
                              type="text"
                              name="report_info"
                              className={classNames('form-control', {
                                'is-invalid': touched.report_info && !!errors.report_info
                              })}
                            />
                            <FormFeedback>
                              <ErrorMessage name="report_info" component="span"/>
                            </FormFeedback>
                          </FormGroup>
                        </div>
                      )
                    }

                    {/*<p>Disclaimer Text</p>*/}
                    {/*<Editor*/}
                    {/*  editorState={editorState}*/}
                    {/*  toolbarClassName="editor-toolbar"*/}
                    {/*  wrapperClassName="editor-wrapper"*/}
                    {/*  editorClassName="editor"*/}
                    {/*  onEditorStateChange={setEditorState}*/}
                    {/*  toolbar={{options: ['inline', 'blockType']}}*/}
                    {/*/>*/}
                  </CardBody>
                </Card>
              </Form>
            )
          }
        }

      </Formik>

      {/*<Card>*/}
      {/*  <CardHeader>*/}
      {/*    <h6 style={{margin: 0}}>Logo</h6>*/}
      {/*  </CardHeader>*/}
      {/*  <CardBody>*/}
      {/*    <FileUploadBase64*/}
      {/*      value={data.report_logo}*/}
      {/*      onFileUpdate={(value, file_name, old_file) => {*/}
      {/*        props.onSubmit({...data, report_logo: value})*/}
      {/*      }}*/}
      {/*      onFileDelete={(url) => {*/}
      {/*        props.onSubmit({...data, report_logo: ''})*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </CardBody>*/}
      {/*</Card>*/}
    </div>
  )
}
