export const SAMPLE_MODAL_OPEN = '@@modal/SAMPLE_MODAL_OPEN';
export const SAMPLE_MODAL_CLOSE = '@@modal/SAMPLE_MODAL_CLOSE';
export const SAMPLE_RESULTS_MODAL_OPEN = '@@modal/SAMPLE_RESULTS_MODAL_OPEN';
export const SAMPLE_RESULTS_MODAL_CLOSE = '@@modal/SAMPLE_RESULTS_MODAL_CLOSE';
export const KIT_MODAL_OPEN = '@@modal/KIT_MODAL_OPEN';
export const KIT_MODAL_CLOSE = '@@modal/KIT_MODAL_CLOSE';
export const BATCH_MODAL_OPEN = '@@modal/BATCH_MODAL_OPEN';
export const BATCH_MODAL_CLOSE = '@@modal/BATCH_MODAL_CLOSE';
export const CLINICIAN_MODAL_OPEN = '@@modal/CLINICIAN_MODAL_OPEN';
export const CLINICIAN_MODAL_CLOSE = '@@modal/CLINICIAN_MODAL_CLOSE';
export const NURSE_MODAL_OPEN = '@@modal/NURSE_MODAL_OPEN';
export const NURSE_MODAL_CLOSE = '@@modal/NURSE_MODAL_CLOSE';
export const PATIENT_MODAL_OPEN = '@@modal/PATIENT_MODAL_OPEN';
export const PATIENT_MODAL_CLOSE = '@@modal/PATIENT_MODAL_CLOSE';
export const CHROMOSOME_MODAL_OPEN = '@@modal/CHROMOSOME_MODAL_OPEN';
export const CHROMOSOME_MODAL_CLOSE = '@@modal/CHROMOSOME_MODAL_CLOSE';
export const CASE_MODAL_OPEN = '@@modal/CASE_MODAL_OPEN';
export const CASE_MODAL_CLOSE = '@@modal/CASE_MODAL_CLOSE';
export const CARD_MODAL_OPEN = '@@modal/CARD_MODAL_OPEN';
export const CARD_MODAL_CLOSE = '@@modal/CARD_MODAL_CLOSE';
export const PATIENT_PAYMENT_MODAL_OPEN = '@@modal/PATIENT_PAYMENT_MODAL_OPEN';
export const PATIENT_PAYMENT_MODAL_CLOSE = '@@modal/PATIENT_PAYMENT_MODAL_CLOSE';
export const SHIPPING_MODAL_OPEN = '@@modal/SHIPPING_MODAL_OPEN';
export const SHIPPING_MODAL_CLOSE = '@@modal/SHIPPING_MODAL_CLOSE';
export const DISEASE_HISTORY_MODAL_OPEN = '@@modal/DISEASE_HISTORY_MODAL_OPEN';
export const DISEASE_HISTORY_MODAL_CLOSE = '@@modal/DISEASE_HISTORY_MODAL_CLOSE';
export const KIT_ORDER_MODAL_OPEN = '@@modal/KIT_ORDER_MODAL_OPEN';
export const KIT_ORDER_MODAL_CLOSE = '@@modal/KIT_ORDER_MODAL_CLOSE';


export const sampleModalOpen = (case_id, patientID, sample = null) => ({
  type: SAMPLE_MODAL_OPEN,
  caseID: case_id,
  patientID: patientID,
  sample: sample
});

export const sampleModalClose = () => ({
  type: SAMPLE_MODAL_CLOSE
});

export const sampleResultsModalOpen = (data = null) => ({
  type: SAMPLE_RESULTS_MODAL_OPEN,
  data: data
});

export const sampleResultsModalClose = () => ({
  type: SAMPLE_RESULTS_MODAL_CLOSE
});

export const batchModalOpen = (data = null) => ({
  type: BATCH_MODAL_OPEN,
  data: data
});

export const batchModalClose = () => ({
  type: BATCH_MODAL_CLOSE
});

export const clinicianModalOpen = (data = null, index = null) => ({
  type: CLINICIAN_MODAL_OPEN,
  data: data,
  index: index
});

export const clinicianModalClose = () => ({
  type: CLINICIAN_MODAL_CLOSE
});

export const nurseModalOpen = (data = null, index = null) => ({
  type: NURSE_MODAL_OPEN,
  data: data,
  index: index
});

export const nurseModalClose = () => ({
  type: NURSE_MODAL_CLOSE
});

export const patientModalOpen = (data = null) => ({
  type: PATIENT_MODAL_OPEN,
  data: data
});

export const patientModalClose = () => ({
  type: PATIENT_MODAL_CLOSE
});

export const chromosomeModalOpen = (chromosome, targets) => ({
  type: CHROMOSOME_MODAL_OPEN,
  data: {...chromosome, targets: targets[chromosome.name]}
});

export const chromosomeModalClose = () => ({
  type: CHROMOSOME_MODAL_CLOSE
});

export const caseModalOpen = (data = null) => ({
  type: CASE_MODAL_OPEN,
  data: data
});

export const caseModalClose = () => ({
  type: CASE_MODAL_CLOSE
});

export const  cardModalOpen = (data = null) => ({
  type: CARD_MODAL_OPEN,
  data: data
});

export const cardModalClose = () => ({
  type: CARD_MODAL_CLOSE
});

// export const  patientPaymentModalOpen = (data = null) => ({
//   type: PATIENT_PAYMENT_MODAL_OPEN,
//   data: data
// });
//
// export const patientPaymentModalClose = () => ({
//   type: PATIENT_PAYMENT_MODAL_CLOSE
// });

export const  shippingModalOpen = (data = null) => ({
  type: SHIPPING_MODAL_OPEN,
  data: data
});

export const shippingModalClose = () => ({
  type: SHIPPING_MODAL_CLOSE
});

export const  diseaseHistoryModalToggle = (open, data = null) => ({
  type: open ? DISEASE_HISTORY_MODAL_OPEN : DISEASE_HISTORY_MODAL_CLOSE,
  data: data
});

export const  kitOrderModalSetOpen = (open) => ({
  type: open ? KIT_ORDER_MODAL_OPEN : KIT_ORDER_MODAL_CLOSE,
});


export const kitModalOpen = (kit_id, data=null) => ({
  type: KIT_MODAL_OPEN,
  kit_id,
  data
});

export const kitModalClose = () => ({
  type: KIT_MODAL_CLOSE
});
