import React from 'react';
import useData from "api/useData";
import {Page, PageContent} from "components/Views";
import {Card, CardBody} from "reactstrap";
import TimeCreated from "utils/TimeCreated";


const renderItem = (item) => {
  if (item === null) {
    return '(none)';
  }

  if (item === '') {
    return '(blank)';
  }

  return item;
}

const diff = function (obj1, obj2) {

  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return `(none) -> ${renderItem(obj1)}`;
  }

  //
  // Variables
  //

  const diffs = {};
  let key;


  //
  // Methods
  //

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  const arraysMatch = function (arr1, arr2) {

    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;

  };

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  const compare = function (item1, item2, key) {
    // Get the object type
    const type1 = Object.prototype.toString.call(item1);
    const type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = `${key}: (none) -> ${renderItem(item1)}`;  // no change to show
      return;
    }

    // If items are different types
    if (type1 !== type2) {
      // diffs[key] = item2;
      diffs[key] = `${key}: ${renderItem(item2)} -> ${renderItem(item1)}`;
      return;
    }

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      const objDiff = diff(item1, item2);
      if (Object.keys(objDiff).length > 1) {
        diffs[key] = objDiff;
      }
      return;
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;  // TODO: show differences in arrays
      }
      return;
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        // diffs[key] = item2;
        diffs[key] = `${key}: fn ${renderItem(item2)} -> fn ${renderItem(item1)}`;
      }
    } else {
      if (item1 !== item2 ) {
        // diffs[key] = item2;
        diffs[key] = `${key}: ${renderItem(item2)} -> ${renderItem(item1)}`;
      }
    }
  };


  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key] ) {
        diffs[key] = `${key}: ${renderItem(obj2[key])} -> ${renderItem(obj1[key])}`;
      }
    }
  }

  // Return the object of differences
  return diffs;
};


export default (props) => {
  const case_data = props.data;
  const [{data, loading}] = useData('CASE_LOG', {id: case_data.id});

  return (
    <Page>
      <h4>Case Logs</h4>

      <PageContent>
        {
          loading ? ('Loading...') : (
            <div>
              {
                (data) ? (
                  <Card>
                    <CardBody>
                      <div className="with-table">
                        <table className="table table-sm">
                          <thead>
                          <tr>
                            <th style={{width: 180}}>Time</th>
                            <th>User</th>
                            <th>Change</th>
                            <th>IP Address</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            data.map((item) => {
                              let changes = null;

                              if (item.state && item.previous_state) {
                                try {
                                  const state = JSON.parse(item.state);
                                  const previous_state = JSON.parse(item.previous_state);
                                  changes = diff(state, previous_state);
                                } catch (e) {
                                  changes = null;
                                }
                              }

                              return (
                                <tr key={'item-' + item.id}>
                                  <td><TimeCreated value={item.created_at}/></td>
                                  <td>{item.username}</td>
                                  <td>{
                                    changes ? (
                                      Object.keys(changes).map((key, index) => {
                                        return (
                                          <p key={key + '-' + index}>{changes[key]}</p>
                                        )
                                      })
                                    ) : '-'
                                  }</td>
                                  <td>{item.ip_address}</td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                ) : 'No entries'
              }
            </div>
          )
        }
      </PageContent>
    </Page>
  )
}
