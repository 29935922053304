import * as provider from "actions/provider";
import * as card from 'actions/card';
import * as auth from 'actions/auth';


const initialState = {
  item: null,
  card: null,
  request_submitted: false,
  request_submit_error: null,
  items: null,
  provider: null,
  errors: null,
  modalIsOpen: false,
  modalData: {},
  simple_list: null
};

let array = [];

export const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case provider.PROVIDER_GET_DETAILS_REQUEST:
      return {
        item: null,
        card: null,
      };

    case provider.PROVIDER_GET_SUCCESS:
      return {
        ...state,
        item: action.payload,
        card: action.payload.card,
      };

    case provider.PROVIDER_GET_DETAILS_SUCCESS:
      return {
        ...state,
        provider: action.payload,
      };

    case card.CARD_PROVIDER_ADD_SUCCESS:
      return {
        ...state,
        card: action.payload.data
      };

    case card.CARD_DELETE_SUCCESS:
      return {
        ...state,
        card: null
      };

    case provider.PROVIDER_SUBMIT_REQUEST_REQUEST:
      return {
        ...state,
        request_submitted: false,
        request_submit_error: null,
      };

    case provider.PROVIDER_SUBMIT_REQUEST_SUCCESS:
      return {
        ...state,
        request_submitted: true,
        request_submit_error: null,
      };

    case provider.PROVIDER_SUBMIT_REQUEST_FAILURE:
      return {
        ...state,
        request_submitted: false,
        request_submit_error: action.payload.response.detail,
      };

    case provider.PROVIDER_GET_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload
      };

    case provider.PROVIDER_GET_SIMPLE_LIST_SUCCESS:
      return {
        ...state,
        simple_list: action.payload
      };

    case provider.PROVIDER_CREATE_REQUEST:
      return {
        ...state,
        errors: null
      };


    case provider.PROVIDER_CREATE_SUCCESS:
      array = state.items.slice();
      array.unshift(action.payload);
      //console.log(action.payload)
      window.localStorage.removeItem('clinic_list');
      window.localStorage.removeItem('clinic_list_expire');
      return {
        ...state,
        items: array,
        modalIsOpen: false,
        modalData: {},
        errors: null
      };

    case provider.PROVIDER_UPDATE_SUCCESS:
      return {
        ...state,
        provider: action.payload,
        modalIsOpen: false,
        modalData: {},
        errors: null
      };

    case provider.PROVIDER_CREATE_FAILURE:
    case provider.PROVIDER_DELETE_FAILURE:
      return {
        ...state,
        errors: action.payload.response
      };

    case auth.LOGOUT:
      return {
        ...state,
        items: null,
        simple_list: null
      };

    case provider.PROVIDER_MODAL_OPEN:
      return {
        ...state,
        modalIsOpen: true,
        modalData: action.data,
        errors: null
      };

    case provider.PROVIDER_MODAL_CLOSE:
      return {
        ...state,
        modalIsOpen: false,
        modalData: {},
        errors: null
      };

    default:
      return state
  }
};

