import React, {Component} from 'react';
// import {GPlot} from 'gp-utilities';
import './style.css';
import {Col, Row} from "reactstrap";


class SampleDetailPlaceholder extends Component {
  render() {
    return (
      <div className="content-with-sidebar sample-detail">
        <div className="placeholder placeholder-sample-detail">
          <div className="element bg-animated-darker" style={{height: 28, width: 280, marginBottom: 8}}/>
          <Row className="align-items-top" style={{marginTop: 40, marginBottom: 20}}>
            <Col md="auto">
              <div style={{width: 240, margin: '0 auto'}}>
                {/*<GPlot sample={sample}/>*/}
                <div className="g-plot bg-animated-darker"/>
              </div>
            </Col>
            <Col>
              <div className="element bg-animated-darker" style={{height: 30, width: 280, marginBottom: 30}}/>
              <div className="element line-3 bg-animated-darker" style={{height: 80, width: 420,}}/>
            </Col>
          </Row>

          <div className="element bg-animated-darker" style={{height: 74, marginBottom: 20, marginTop: 20}}/>
          <div className="element bg-animated-darker" style={{height: 240, marginBottom: 20, marginTop: 20}}/>
        </div>
      </div>
    )
  }
}

export default SampleDetailPlaceholder;
