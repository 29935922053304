import React from 'react';
import {Container} from "reactstrap";
import Sidebar from './Sidebar';
import LoadingOverlay from "utils/LoadingOverlay";
import LabTopBar from './LabTopBar';
import TopBar from './TopBar';
import {ProviderModal} from "components/Provider";
import './style/main.css';
import {
  BrowserView
} from "react-device-detect";
// import { useIdleTimer } from 'react-idle-timer'
// import {logout} from "actions/auth";


export default (props) => {
  const Component = props.component;
  const route = props.route;

  // const handleOnIdle = event => {
  //   logout()
  // }
  //
  // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * 60,
  //   onIdle: handleOnIdle,
  //   debounce: 500
  // })

  return (
    <div className="wrap">
      {!route.location.pathname.startsWith('/accessioning') && <TopBar/>}
      {route.location.pathname.startsWith('/accessioning') && <LabTopBar/>}

      <BrowserView>
        <Sidebar route={route}/>
      </BrowserView>

      <Container fluid={true}>
        <Component route={route}/>
      </Container>

      <ProviderModal/>

      <LoadingOverlay/>
    </div>
  );
};
