import {RSAA} from 'redux-api-middleware';
import {withAuth} from "reducers";

export const GET_ORDER_SUMMARY_REQUEST = '@@kit/GET_ORDER_SUMMARY_REQUEST';
export const GET_ORDER_SUMMARY_SUCCESS = '@@kit/GET_ORDER_SUMMARY_SUCCESS';
export const GET_ORDER_SUMMARY_FAILURE = '@@kit/GET_ORDER_SUMMARY_FAILURE';

export const ORDER_COMPLETE_REQUEST = '@@kit/ORDER_COMPLETE_REQUEST';
export const ORDER_COMPLETE_SUCCESS = '@@kit/ORDER_COMPLETE_SUCCESS';
export const ORDER_COMPLETE_FAILURE = '@@kit/ORDER_COMPLETE_FAILURE';

export const GET_ORDER_LIST_REQUEST = '@@kit/GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = '@@kit/GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_FAILURE = '@@kit/GET_ORDER_LIST_FAILURE';


export const getOrderSummary = (data = {}) => {
  return ({
    [RSAA]: {
      endpoint: `order-summary/`,
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      types: [
        GET_ORDER_SUMMARY_REQUEST, GET_ORDER_SUMMARY_SUCCESS, GET_ORDER_SUMMARY_FAILURE
      ],
      body: JSON.stringify(data)
    }
  })
};

export const completeOrder = (id) => {
  return ({
    [RSAA]: {
      endpoint: `order-complete/${id}/`,
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      types: [
        ORDER_COMPLETE_REQUEST, ORDER_COMPLETE_SUCCESS, ORDER_COMPLETE_FAILURE
      ],
    }
  })
};

export const getKitOrders = (filter = {}) => {
  return ({
    [RSAA]: {
      endpoint: 'provider-orders/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        GET_ORDER_LIST_REQUEST, GET_ORDER_LIST_SUCCESS, GET_ORDER_LIST_FAILURE
      ],
    }
  })
};