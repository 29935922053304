import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import 'utils/icons_loader';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import configureStore from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'css/main.css';
import 'css/provider.css';
// import 'components/utilities/lib/style.bundle.css';
import * as serviceWorker from './serviceWorker';
import {PersistGate} from "redux-persist/integration/react";
import routes from './routes';

datadogRum.init({
applicationId: 'a09c0aa3-b20c-4a40-afc2-20129107eed3',
clientToken: 'pub0130c0fadca800dce8408b5d4387eb74',
site: 'datadoghq.com',
service:'cpv1',
env:'prod',
version: '1.0.0',
sampleRate: 100,
sessionReplaySampleRate: 100,
trackInteractions: true,
trackResources: true,
trackLongTasks: true,
defaultPrivacyLevel:'mask',
actionNameAttribute: ['file_link', 'patient_name', 'patient_dob', 'patient_ethnicity', 'patient_sex', 'patient_email', 'patient_phone', 'patient_address'],
});

datadogRum.startSessionReplayRecording();

const configuredStore = configureStore();

ReactDOM.render(
  <Provider store={configuredStore.store}>
    <PersistGate loading={<p>Loading...</p>} persistor={configuredStore.persistor}>
      <Router>
        {routes({store: configuredStore.store})}
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


