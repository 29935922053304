import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Row} from "reactstrap";
import PaymentSection from './PaymentSection';
import GCSection from './GCSection';
import PatientInfoSection from './PatientInfoSection';
import {submitApplication} from "actions/patient";
import logo from "images/logo-v2.svg";


export default connect(
  state => ({
    patientApplication: state.patientApplication
  }),
  {
    submitApplication: submitApplication
  }
)(
  class extends PureComponent {
    constructor(props) {
      super(props);

      this.submit = this.submit.bind(this);
      this.onGCChange = this.onGCChange.bind(this);

      this.state = {
        gc_requested: props.patientApplication.patient.gc_requested,
        gc_date_time: null
      }
    }

    submit() {
      this.props.submitApplication(
        this.props.patientApplication.patient.application_id,
        this.state.gc_requested,
        this.state.gc_date_time
      )
    }

    onGCChange(val) {
      this.setState({
        ...this.state,
        gc_requested: val
      })
    }

    render() {
      const {patient, card} = this.props.patientApplication;

      return patient ? (
        <div>
          <Row className="align-items-center justify-content-between" style={{marginTop: 30, marginBottom: 40}}>
            <Col>
              <img src={logo} alt="" style={{width: 180}}/>
            </Col>
            <Col className="text-right">
              <h4 style={{margin: 0}}>Patient Registration</h4>
            </Col>
          </Row>

          <PatientInfoSection patient={patient}/>

          <Row>
            <Col>
              <GCSection
                patient={patient}
                onChange={(val) => {
                  this.onGCChange(val)
                }}
                onGCDateTimeChange={(val) => {
                  this.setState({...this.state, gc_date_time: val})
                }}
                value={this.state.gc_requested}

              />
            </Col>
            <Col md={4}>
              <PaymentSection patient={patient} card={card}/>
            </Col>
          </Row>

          <Row className="justify-content-center" style={{marginTop: 20}}>
            <Col>
              <p>By submitting this information and clicking submit, I verify that this information is correct and
                accurate. I agree to having my credit card charged an agreed-upon case fee upon my case being accepted
                by GPCL (usually within a few days), and, if we choose to proceed, to have my credit card charged a
                second time with an agreed-upon per-embryo testing fee at the time embryo biopsies are submitted and
                testing begins at GPCL. These fees may be changed, in agreement with Genetic Counselor, if new tests
                prove necessary. In the event that testing on a particular biopsy sample(s) does not yield a result
                (categorized as “inconclusive” or “no amp”), a second test of a biopsy from the same embryo(s) will be
                performed by GPCL at no extra charge.</p>
              <div style={{marginTop: 20}} className="text-center">
                <Button color="success" size="lg" onClick={() => this.submit()}>Submit</Button>
              </div>
            </Col>
          </Row>
        </div>
      ) : 'Loading...'
    }
  }
);
