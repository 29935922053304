import {withAuth} from "reducers";
import {RSAA} from 'redux-api-middleware';

export const NURSE_LIST_REQUEST = '@@nurse/NURSE_LIST_REQUEST';
export const NURSE_LIST_SUCCESS = '@@nurse/NURSE_LIST_SUCCESS';
export const NURSE_LIST_FAILURE = '@@nurse/NURSE_LIST_FAILURE';

export const NURSE_DETAIL_REQUEST = '@@nurse/NURSE_DETAIL_REQUEST';
export const NURSE_DETAIL_SUCCESS = '@@nurse/NURSE_DETAIL_SUCCESS';
export const NURSE_DETAIL_FAILURE = '@@nurse/NURSE_DETAIL_FAILURE';

export const NURSE_CREATE_REQUEST = '@@nurse/NURSE_CREATE_REQUEST';
export const NURSE_CREATE_SUCCESS = '@@nurse/NURSE_CREATE_SUCCESS';
export const NURSE_CREATE_FAILURE = '@@nurse/NURSE_CREATE_FAILURE';

export const NURSE_UPDATE_REQUEST = '@@nurse/NURSE_UPDATE_REQUEST';
export const NURSE_UPDATE_SUCCESS = '@@nurse/NURSE_UPDATE_SUCCESS';
export const NURSE_UPDATE_FAILURE = '@@nurse/NURSE_UPDATE_FAILURE';

export const NURSE_DELETE_REQUEST = '@@nurse/NURSE_DELETE_REQUEST';
export const NURSE_DELETE_SUCCESS = '@@nurse/NURSE_DELETE_SUCCESS';
export const NURSE_DELETE_FAILURE = '@@nurse/NURSE_DELETE_FAILURE';

export const getNurseList = (filter = {}) => {
  return ({
    [RSAA]: {
      endpoint: 'nurses/',
      method: 'GET',
      headers: withAuth({'Content-Type': 'application/json'}),
      types: [
        NURSE_LIST_REQUEST, NURSE_LIST_SUCCESS, NURSE_LIST_FAILURE
      ]
    }
  })
};

export const submitNurse = (data) => {
  const url =  `nurses/${data.id ? data.id + '/' : ''}`;
  const method = data.id ? 'PUT' : 'POST';

  return({
    [RSAA]: {
      endpoint: url,
      method: method,
      headers: withAuth({'Content-Type': 'application/json'}),
      types:
        data.id ? [
          NURSE_UPDATE_REQUEST,
          NURSE_UPDATE_SUCCESS,
          NURSE_UPDATE_FAILURE
        ] :
        [
          NURSE_CREATE_REQUEST,
          NURSE_CREATE_SUCCESS,
          NURSE_CREATE_FAILURE
        ],
      body: JSON.stringify(data)
    }
  });
};

export const deleteNurse = (id, index) => {
  return ({
    [RSAA]: {
      endpoint: 'nurses/' + id + '/',
      method: 'DELETE',
      headers: withAuth({}),
      types: [
        NURSE_DELETE_REQUEST,
        {
          type: NURSE_DELETE_SUCCESS,
          payload: {},
          meta: {
            id: id,
            index: index
          }
        },
        NURSE_DELETE_FAILURE
      ]
    }
  })
};