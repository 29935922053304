import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deletePatientFile, submitPatientFile} from "actions/patient";
import { ImageSelect } from "utils/FileUpload";
import {Card, CardBody, CardHeader} from "reactstrap";


export default connect(
  state => ({
    patient: state.patient.item
  }),
  {
    submitPatientFile: submitPatientFile,
    deletePatientFile: deletePatientFile
  }
)(
  class extends Component {
    render() {
      const { description, fieldName, headerText, patient } = this.props;

      return (
        <Card>
          <CardHeader>
            <h6 className="m-0">{headerText}</h6>
          </CardHeader>
          <CardBody>
            {
              description && <p className="text-muted mb-4">{description}</p>
            }
            <ImageSelect
              value={patient[fieldName] ? JSON.parse(patient[fieldName]) : []}
              onFileUpdate={(data, file_name) => this.props.submitPatientFile(patient.id, data, file_name, fieldName)}
              onFileDelete={data => this.props.deletePatientFile(patient.id, data, fieldName)}
            />
          </CardBody>
        </Card>
      )
    }
  }
)
