import * as alert_actions from 'actions/alert';


const initialState = {
  list: {}
};

let new_list = {};

export const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case alert_actions.ALERT_ADD:
      new_list = Object.assign({}, state.list);
      new_list[Date.now()] = {type: action.alert_type, message: action.message};

      // Object.keys(new_list).sort().forEach(function(key) {
      //   ordered_list[key] = new_list[key];
      // });

      return {
        ...state,
        list: new_list
      };

    case alert_actions.ALERT_REMOVE:
      new_list = Object.assign({}, state.list);
      delete new_list[action.index];

      return {
        ...state,
        list: new_list
      };

    default:
      return state;
  }
};